import React, { useState } from "react";
import { Grid, Button, Stack, Modal, Box } from "@mui/material";
import { CgClose } from "react-icons/cg";
import "./modal.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { putMethod } from "../../apis";
import {useSelector} from "react-redux";

const AddContact = ({ open, setOpen, userData, getContactsDetails }) => {

  const count = useSelector((state) => state.WalletConnect)
  const [contactName, setContactName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255, 255, 255, 1)",
    boxShadow: "0px 1px 13px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    width: "100%",
    // height: "calc(80% - 50px)",
    padding: "2%",
  };

  const handleValidation = () => {
    { contactName === "" ? (toast.warn("Contact Name Can't Be Empty")) : contactNo?.length !== 11 ? (toast.warn("Phone Number Can't Be Empty")) : email === "" ? (toast.warn("Email Address Can't Be Empty")) : addcontact() }
  };

  const validationemails = (email) => {
    /* eslint-disable */
    const regExp =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regExp.test(email);
  };

  const addcontact = async () => {
    
   if(!validationemails(email)) {
    toast("Please Enter Valid Email ")
    return
  }
    try {
      setLoading(true);
      const params = {
        email: userData?.email,
        contact: {
          Name: contactName,
          mail: email,
          phone: contactNo,
          status: true
        }
      };
      let headers = {
        "Content-Type": "application/json",
        "x-access-token": count?.userData?.accessToken,
        "Access-Control-Allow-Origin": "*"
      };
      let url = "api/organization/addContact";
      let response = await putMethod({ url, params,headers });
      if (response.message === "User not Found") {
        toast.warn("Email doesn't exist");
        setLoading(false);
      }
      else if (response.message === "contatct add failed") {
        toast.warn("Try Again");
        setLoading(false);
      }
      else if (response.message === "contact added") {
        toast.success("Contact Added");
        getContactsDetails();
        setLoading(false);
        setContactName("");
        setContactNo("");
        setEmail("");
        setOpen(false);
      }
    }
    catch (err) {
      console.log("err in addContacts", err);
    }
  }

  const closemodal = async()=>{
    setContactName("");
    setContactNo("");
    setEmail("");
    setOpen(false)
  }

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="center">
          <Grid lg={3} md={6} sm={8} xs={11} container sx={style}>
            <Grid
              lg={12}
              md={12}
              sm={12}
              xs={12}
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid lg={11} md={11} xs={11}>
                <h4 className="textBlack InterBd m-0"> Add New Contact</h4>
              </Grid>
              <Grid lg={1} md={1} xs={1}>
                <CgClose
                  size={32}
                  color={"rgba(0, 0, 0, 1)"}
                  className="pointer"
                  onClick={() => closemodal()}
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Grid lg={12} xs={12}>
                <Grid lg={12} md={12} sm={12} xs={12} container className="">
                  <Stack direction="column" spacing={2} className="w-100">
                    <Grid
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <p className="interMd textGray1 mt-2">
                        Creating a contact enables you to send notifications and
                        reports to people within or outside of your
                        organization.
                      </p>
                      <font className="textBlack InterRg py-2">
                        Contact Name
                      </font>
                      <input
                        className="inputBox1 w-100  px-2 py-2 LoginTextCapitalize"
                        placeholder="Enter Contact Name"
                        value={contactName}
                        onChange={(e) => setContactName(e.target.value)}
                      />

                      <font className="textBlack InterRg py-2">
                        Phone Number
                      </font>
                      <PhoneInput
                        className="phoneinput_modal textBlack"
                        country={"us"}
                        containerStyle={{ width: "100%" }}
                        style={{ zIndex: 0 }}
                        countryCodeEditable={false}
                        disableSearchIcon={true}
                        disableDropdown={true}
                        placeholder="Enter Contact Number"
                        value={contactNo}
                        onChange={(e) => setContactNo(e)}
                      />
                      <font className="textBlack InterRg py-2">Email</font>
                      <input
                        className="inputBox1 w-100  px-2 py-2"
                        placeholder="Enter Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Grid>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            <Grid
              lg={12}
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
              className="mt-4"
            >
              <Grid
                lg={10}
                xs={10}
                container
                justifyContent="space-around"
                alignItems="center"
              >
                <Grid lg={5} xs={5}>
                  <Button
                    className="btngray shipmentsbtnspadding"
                    onClick={() => closemodal()}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid lg={5} xs={5}>
                  <Button
                    className="btn shipmentsbtnspadding"
                    onClick={handleValidation}
                  >
                    {loading ? (
                      <>
                        <Spinner animation="border" variant="light" />
                      </>
                    ) : (
                      <>Save</>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default AddContact;
