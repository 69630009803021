import React from "react";
import { Grid, TableBody, TableCell, TableContainer, TableHead, TableRow, Table } from "@mui/material";
import "../table.css";
import { RiErrorWarningFill } from "react-icons/ri";
import NoData from "../../../assets/images/dashboardImages/noData.svg";

const AlertTable = ({ RecentAlerts }) => {
  function timeconvertdays(date) {
    var a = new Date(date);
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var dates = a.getDate();
    // var time = a.toLocaleTimeString();
    var hour = a.getHours();
    var min = a.getMinutes();
    var AmOrPm = hour >= 12 ? "PM" : "AM";
    hour = hour % 12 || 12;
    var result =
      dates +
      " " +
      month +
      " " +
      year +
      "   " +
      hour +
      ":" +
      min + " " +

      AmOrPm 
    return result;
  }
  return (
    <div>
      {RecentAlerts && RecentAlerts.length !== 0 ? (<>
        <Grid lg={12} xs={12} sx={{ overflow: "auto" }}>
          <TableContainer className="pt-2 mobileviewInTable">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" className="dashboardTbcell">
                    <font className="textBlack InterMd" size={3}>Detected Alerts</font>
                  </TableCell>
                  <TableCell align="left" className="dashboardTbcell">
                    <font className="textBlack InterMd" size={3}>Shipment Name</font>
                  </TableCell>
                  <TableCell align="left" className="dashboardTbcell">
                    <font className="textBlack InterMd" size={3}>Date & Time</font>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {RecentAlerts.map((row, i) => {
                  return (<>
                    <TableRow key={i} >
                      <TableCell align="left">
                        <Grid lg={12} xs={12} container justifyContent="flex-start" alignItems="center">
                          <RiErrorWarningFill size={20} color={"#EB5642"} />
                          <font className="textBlack InterRg pl-1">{row?.sensorName =="temp" ?"TEMPERATURE"+ " " + row?.sensorRange + " " + row?.sensorType:row?.sensorName.toUpperCase()+ " " + row?.sensorRange + " " + row?.sensorType}</font>
                        </Grid>
                      </TableCell>
                      <TableCell align="left">
                        <Grid lg={12} xs={12} container justifyContent="flex-start" alignItems="center" className="pointer">
                          <font className="textBlue InterRg textHoveringgreen">{row?.ShipmentName?.length > 24 ? row?.ShipmentName?.slice(0, 24) + ".." : row?.ShipmentName}</font>
                        </Grid>
                      </TableCell>
                      <TableCell align="left">
                        <Grid lg={12} xs={12} container justifyContent="flex-start" alignItems="center">
                          <font className="textBlack InterRg">{ timeconvertdays(row?.createdAt)}</font>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  </>)
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </>) : (<>
        <Grid lg={12} xs={12} container justifyContent="center" alignItems="center" alignContent="center" className="">
          <Grid lg={10} xs={12} container justifyContent="center" alignItems="center" className="">
            <img src={NoData} className="img-fluid" alt="NoDataImage" />
          </Grid>
          <Grid lg={10} xs={12} container justifyContent="center" className="">
            <h4 className="textBlack InterBd">No Alerts Available</h4>
          </Grid>
        </Grid>
      </>)}
    </div>
  );
};

export default AlertTable;