import React, { useState } from "react";
import { Grid, Modal, Button, Stack } from "@mui/material";
import { CgClose } from "react-icons/cg";
import "./modal.css";
import { FormatDate1 } from "../../helpers/index";
import { putMethod } from "../../apis";
import { toast } from "react-toastify";
import {useSelector} from "react-redux";

// import TableToolTip from "../../helpers/toolbar";
const ApprovalRequest = ({ open, setOpen, organizationData,getApproveUsers }) => {

  const count = useSelector((state) => state.WalletConnect)
  const [openReject, setOpenReject] = useState(false);
  const [approveStatus,setApproveStatus] = useState("");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255, 255, 255, 1)",
    boxShadow: "0px 1px 13px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    width: "100%",
    padding: "2%",
  };

  const style1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255, 255, 255, 1)",
    boxShadow: "0px 1px 13px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    width: "100%",
    padding: "1.5%"
  };

  const onhandleApproved = async() =>{
    try{
      const params = {
        email: organizationData?.email,
      };
      let headers = {
        "Content-Type": "application/json",
        "x-access-token": count?.userData?.accessToken,
        "Access-Control-Allow-Origin": "*"
      };
      let url = "api/admin/organization/approve";
      let response = await putMethod({ url,params,headers });
      if(response.status === false){
        toast.warn(response.message);
      }
      else if(response.status === true){
        if(response.message === "invite success"){
          toast.success("User Approved");
          setOpenReject(false);
          getApproveUsers();
        }
      }
    }
    catch(err){
      console.log("Error in usersApproved",err);
    }
  };

  const onhandleRejected = async() =>{
    try{
      const params = {
        email: organizationData?.email,
      };
      let headers = {
        "Content-Type": "application/json",
        "x-access-token": count?.userData?.accessToken,
        "Access-Control-Allow-Origin": "*"
      };
      let url = "api/admin/organization/reject";
      let response = await putMethod({ url,params,headers });
      if(response.status === false){
        toast.warn(response.message);
      }
      else if(response.status === true){
        if(response.message === "invite Rejected"){
          toast.success("User Rejected");
          setOpenReject(false);
          getApproveUsers();
        }
      }
    }
    catch(err){
      console.log("Error in usersRejected",err);
    }
  };

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="center">
          <Grid lg={4} md={6} sm={8} xs={11} container sx={style}>
            <Grid
              lg={12}
              md={12}
              sm={12}
              xs={12}
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid lg={11} md={11} xs={11}>
                <h4 className="textBlack InterBd m-0">Approval Request</h4>
              </Grid>
              <Grid lg={1} md={1} xs={1}>
                <CgClose
                  size={32}
                  color={"rgba(0, 0, 0, 1)"}
                  className="pointer"
                  onClick={() => setOpen(false)}
                />
              </Grid>
            </Grid>
            <Grid lg={12} md={12} sm={12} xs={12} container className="pt-3">
              <font className="textBlack InterSm">Given Request Details</font>
              <div
                className="borderBox w-100 px-3 py-3 mt-2 example"
                style={{ overflow: "scroll" }}
              >
                <Grid lg={12} xs={12} container>
                  <Stack direction="column" spacing={3} className="w-100">
                    <Grid
                      lg={12}
                      xs={12}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid lg={6} xs={6} container>
                        <font className="InterRg textGray1">
                          Organization Name
                        </font>
                      </Grid>
                      <Grid lg={6} xs={6} container>
                        <font className="InterRg textBlack">
                          {organizationData?.organizationName}
                        </font>
                      </Grid>
                    </Grid>
                    <Grid
                      lg={12}
                      xs={12}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid lg={6} xs={6} container>
                        <font className="InterRg textGray1">
                          Organization Type
                        </font>
                      </Grid>
                      <Grid lg={6} xs={6} container>
                        <font className="InterRg textBlack">
                          {organizationData?.organizationType}
                        </font>
                      </Grid>
                    </Grid>
                    <Grid
                      lg={12}
                      xs={12}
                      container
                      justifyContent="space-evenly"
                    >
                      <Grid lg={6} xs={6} container>
                        <font className="InterRg textGray1">
                          Contact Number
                        </font>
                      </Grid>
                      <Grid lg={6} xs={6} container direction="column">
                        <font className="InterMd textBlack">
                          {organizationData?.conatctNumber}
                        </font>
                      </Grid>
                    </Grid>
                    <Grid
                      lg={12}
                      xs={12}
                      container
                      justifyContent="space-evenly"
                      flexDirection="row"
                      flexWrap="no-wrap"
                    >
                      <Grid lg={6} xs={6} container>
                        <font className="InterRg textGray1">Work Email</font>
                      </Grid>
                      <Grid lg={6} xs={6} container>
                        <font className="InterMd textBlack">
                          {organizationData?.email}
                        </font>
                      </Grid>
                    </Grid>
                    <Grid
                      lg={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                    >
                      <Grid lg={6} xs={6} container>
                        <font className="InterRg textGray1">Address</font>
                      </Grid>
                      <Grid lg={6} xs={6} container>
                        <font className="InterMd textBlack">
                          {organizationData?.address}
                        </font>
                      </Grid>
                    </Grid>
                    <Grid
                      lg={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                    >
                      <Grid lg={6} xs={6} container>
                        <font className="InterRg textGray1">State</font>
                      </Grid>
                      <Grid lg={6} xs={6} container>
                        <font className="InterMd textBlack">
                          {organizationData?.state}
                        </font>
                      </Grid>
                    </Grid>
                    <Grid
                      lg={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                    >
                      <Grid lg={6} xs={6} container>
                        <font className="InterRg textGray1">Requested On</font>
                      </Grid>
                      <Grid lg={6} xs={6} container>
                        <font className="InterMd textBlack">
                          {FormatDate1(organizationData?.createdAt)}
                        </font>
                      </Grid>
                    </Grid>
                  </Stack>
                </Grid>
              </div>
            </Grid>
            <Grid
              lg={12}
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
              className="mt-3"
            >
              <Grid
                lg={8}
                xs={10}
                container
                justifyContent="space-around"
                alignItems="center"
              >
                <Grid lg={5} xs={5}>
                  <Button
                    className="organizationdeactivatebtn shipmentsbtnspadding"
                    onClick={() => {
                      setOpen(false);
                      setApproveStatus("Rejected");
                      setOpenReject(true);
                    }}
                  >
                    Reject
                  </Button>
                </Grid>
                <Grid lg={5} xs={5}>
                  <Button
                    className="organizationactivatebtn shipmentsbtnspadding"
                    onClick={() => {
                      setOpen(false);
                      setOpenReject(true);
                      setApproveStatus("Approved");
                    }}
                  >
                    Approve
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <Modal
        open={openReject}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="center">
          <Grid lg={3.5} md={6} sm={8} xs={11} container sx={style1}>
            <Grid
              lg={12}
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
            >
              <h5 className="textBlack text-left InterBd m-0">
                Are you sure you want to {approveStatus}?
              </h5>
            </Grid>
            <Grid
              lg={12}
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
              className="pt-4"
            >
              <Grid
                lg={8}
                xs={10}
                container
                justifyContent="space-around"
                alignItems="center"
              >
                <Grid lg={5} xs={5}>
                  <Button
                    className="organizationCalcelbtn shipmentsbtnspadding"
                    onClick={() => {
                      setOpenReject(false);
                      setOpen(true);
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid lg={5} xs={5}>
                  {approveStatus === "Approved" ? (
                    <>
                      <Button
                        className="organizationactivatebtn shipmentsbtnspadding"
                        onClick={onhandleApproved}
                      >
                        Approve
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        className="organizationdeactivatebtn shipmentsbtnspadding"
                        onClick={onhandleRejected}
                      >
                        Reject
                      </Button>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
}

export default ApprovalRequest;