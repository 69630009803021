import React from "react";
import { Grid, TableBody, TableCell, TableContainer, TableHead, TableRow, Table, } from "@mui/material";
import "../table.css";
import NoData from "../../../assets/images/dashboardImages/noData.svg";

const RecentOrganization = ({ Organization }) => {
  function timeconvertdays(date) {
    var a = new Date(date);
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var dates = a.getDate();
    // var time = a.toLocaleTimeString();
    var hour = a.getHours();
    var min = a.getMinutes();
    var AmOrPm = hour >= 12 ? "PM" : "AM";
    hour = hour % 12 || 12;
    var result =
      dates +
      " " +
      month +
      " " +
      year +
      "   " +
      hour +
      ":" +
      min +
      " " +
      AmOrPm 
    return result;
  }


  return (
    <div>
      {Organization !== "" ? (<>
        <Grid lg={12} xs={12} >
          <TableContainer className="pt-2 mobileviewInTable">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" className="dashboardTbcell">
                    <font className="textBlack InterMd" size={3}>Organization Name</font>
                  </TableCell>
                  <TableCell align="left" className="dashboardTbcell">
                    <font className="textBlack InterMd" size={3}>Plan Name</font>
                  </TableCell>
                  <TableCell align="left" className="dashboardTbcell">
                    <font className="textBlack InterMd" size={3}>Joined On</font>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  Organization.Organization.length <= 5 ? 
                    Organization.Organization.map((row, i) => {
                      return (<>
                        <TableRow key={i} >
                          <TableCell align="left">
                            <Grid lg={12} xs={12} container justifyContent="flex-start" alignItems="center">
                              {/* <Avatar sx={{ width: "20px", height: "20px" }} /> */}
                              <font className="textBlack InterRg pl-2">{row?.organizationName?.length > 20 ? row?.organizationName?.slice(0, 20) + ".." : row?.organizationName}</font>
                            </Grid>
                          </TableCell>
                          <TableCell align="left">
                            <Grid lg={12} xs={12} container justifyContent="flex-start" alignItems="center">
                              <font className="textBlack InterRg">  {row?.Plan[0].PlanName === undefined ? "- "  :  row?.Plan[0].PlanName?.length > 24 ? row?.Plan[0].PlanName?.slice(0, 24) + ".." : row?.Plan[0].PlanName}</font>
                            </Grid>
                          </TableCell>
                          <TableCell align="left">
                            <Grid lg={12} xs={12} container justifyContent="flex-start" alignItems="center">
                              <font className="textBlack InterRg">{timeconvertdays(row?.createdAt)}</font>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      </>)
                    }) : 
                    Organization.Organization.slice(0,5).map((row, i) => {
                      return (<>
                        <TableRow key={i} >
                          <TableCell align="left">
                            <Grid lg={12} xs={12} container justifyContent="flex-start" alignItems="center">
                              {/* <Avatar sx={{ width: "20px", height: "20px" }} /> */}
                              <font className="textBlack InterRg pl-2">{row?.organizationName?.length > 20 ? row?.organizationName?.slice(0, 20) + ".." : row?.organizationName}</font>
                            </Grid>
                          </TableCell>
                          <TableCell align="left">
                            <Grid lg={12} xs={12} container justifyContent="flex-start" alignItems="center">
                              <font className="textBlack InterRg">  {row?.Plan[0].PlanName === undefined ? "- "  :  row?.Plan[0].PlanName?.length > 24 ? row?.Plan[0].PlanName?.slice(0, 24) + ".." : row?.Plan[0].PlanName}</font>
                            </Grid>
                          </TableCell>
                          <TableCell align="left">
                            <Grid lg={12} xs={12} container justifyContent="flex-start" alignItems="center">
                              <font className="textBlack InterRg">{timeconvertdays(row?.createdAt)}</font>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      </>)
                    })
              
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

      </>) : (<>
        <Grid lg={12} xs={12} container justifyContent="center" alignItems="center" alignContent="center" className="">
          <Grid lg={10} xs={12} container justifyContent="center" alignItems="center" className="">
            <img src={NoData} className="img-fluid" alt="NoDataImage" />
          </Grid>
          <Grid lg={10} xs={12} container justifyContent="center" className="">
            <h4 className="textBlack InterBd">No Mails Available</h4>
          </Grid>
        </Grid>
      </>)}
    </div>
  );
};

export default RecentOrganization;