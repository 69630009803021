import React, { useState, useEffect } from "react";
import { Grid, Button, Stack, Badge } from "@mui/material";
import { CgSearch } from "react-icons/cg";
import TrackerTableAdmin from "../../components/table/trackerTable/admintracker/adminTracker";
import { getMethod } from "../../apis";
import getHeaders from "../../services";
import { CSVLink } from "react-csv";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import WhitelistTrackers from "../../components/modal/WhiteListTrackerModal";
// import { PDFDocument, StandardFonts, rgb} from "pdf-lib";
// import autoTable from 'jspdf-autotable';
import { useSelector } from "react-redux";
import PendingTrackers from "../../components/table/trackerTable/admintracker/pendingTrackers";
const TrackersAdmin = () => {
  const count = useSelector((state) => state.WalletConnect);
  const [TrackersTable, setTrackersTable] = useState([]);
  const [trackerstable, settrackerstable] = useState([]);
  const [NoDatas, setNoDatas] = useState(false);
  const [loader, setLoader] = useState(true);
  const [changeBtn, setChangeBtn] = useState("");
  const headers = getHeaders();
  const [whiteListData, setWhitelistData] = useState([]);

  const [open, setOpen] = useState(false);
  useEffect(() => {
    // setProgress(false);
    handleSections("ActiveTrackers");
    getMyTrackersLive();
    setTimeout(() => {
      setNoDatas(true);
    }, 3000);
  }, []);
  const getMyTrackersLive = async () => {
    let url = "api/tracker/getAllTrackersLive";
    const response = await getMethod({ url, headers });
    const filterData = response.result.filter(
      (e) => e.ActivationStatus !== "Activated"
    );
    setWhitelistData(filterData);
    const liveTracker = response.result.filter(
      (e) => e.ActivationStatus === "Activated"
    );
    // const liveTracker = response.result
    if (response.status === true) {
      setTrackersTable(liveTracker);
      settrackerstable(liveTracker);
      setLoader(false);
    }
  };
  const columns = [
    { label: "Tracker Serial", key: "TrackerSerial" },
    { label: "Activated By", key: "ActivatedBy" },
    { label: "Activated On", key: "ActivatedOn" },
    { label: "Current Location", key: "CurrentLocation" },
  ];
  function timeconvertdays(date) {
    var a = new Date(date);
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var dates = a.getDate();
    // var time = a.toLocaleTimeString();
    var hour = a.getHours();
    var min = a.getMinutes();
    var AmOrPm = hour >= 12 ? "PM" : "AM";
    hour = hour % 12 || 12;
    var result =
      dates +
      " " +
      month +
      " " +
      year +
      "   " +
      hour +
      ":" +
      min +
      " : " +
      AmOrPm;
    return result;
  }
  const DownloadData = TrackersTable.map((row) => ({
    TrackerSerial: row.TrackerSerial,
    ActivatedBy: row?.user[0]?.organizationName,
    ActivatedOn: timeconvertdays(row.ActivatedOn),
    CurrentLocation: "725 5th Ave, New York, NY 10022, USA",
  }));

  const DownloadData1 = TrackersTable.map((row) => ({
    TrackerSerial: row.TrackerSerial,
    ActivatedBy: row?.user[0]?.organizationName,
    ActivatedOn: timeconvertdays(row.ActivatedOn),
    CurrentLocation: "725 5th Ave, New York, NY 10022, USA",
    Status: row?.ActivationStatus == "Activated" ? "Activated" : "Deactivated",
  }));

  const downloadPDF = async (datas) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);
    const title = "Tracker Details";
    const headers = [
      [
        "Tracker Serial",
        "Activated By",
        "Activated On",
        "Current Location",
        "Status",
      ],
    ];
    const data = datas.map((elt) => [
      elt.TrackerSerial,
      elt.ActivatedBy,
      elt.ActivatedOn,
      elt.CurrentLocation,
      elt.Status,
    ]);
    let content = {
      startY: 50,
      head: headers,
      body: data,
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Tracker Details.pdf");
  };

  const searchtracker = async (value) => {
    if (value != "") {
      let result = trackerstable.filter(
        (item) =>
          item.TrackerSerial.toLowerCase().includes(value.toLowerCase()) ||
          item.user[0]?.organizationName
            .toLowerCase()
            .includes(value.toLowerCase())
      );
      setTrackersTable(result);
    } else {
      setTrackersTable(trackerstable);
    }
  };

  const activebyhandleAZsort = async (value) => {
    if (value) {
      const strAscending = [...TrackersTable].sort((a, b) =>
        a.user[0]?.organizationName.toLowerCase() >
        b.user[0]?.organizationName.toLowerCase()
          ? 1
          : -1
      );
      setTrackersTable(strAscending);
    } else {
      const strAscending = [...TrackersTable].sort((a, b) =>
        a.user[0]?.organizationName.toLowerCase() >
        b.user[0]?.organizationName.toLowerCase()
          ? -1
          : 1
      );
      setTrackersTable(strAscending);
    }
  };

  const activatedonhandleAZsort = async (value) => {
    if (value) {
      setTrackersTable(TrackersTable.reverse());
    } else {
      setTrackersTable(TrackersTable);
    }
  };

  const handleSections = (check) => {
    if (check === "ActiveTrackers") {
      setChangeBtn("ActiveTrackers");
      getMyTrackersLive();
    } else if (check === "pendingTrackers") {
      setChangeBtn("pendingTrackers");
      getMyTrackersLive();
    }
  };

  const getWhitelistedTrackers = async () => {
    try {
      let url = "api/tracker/viewWhitelistedtracker";
      let headers = {
        "Content-Type": "application/json",
        "x-access-token": count?.userData?.accessToken,
        "Access-Control-Allow-Origin": "*",
      };
      let response = await getMethod({ url, headers });
      if (response.status === true) {
        getMyTrackersLive();
      } else {
        setWhitelistData([]);
      }
    } catch (err) {
      console.log("Error in getUserDetails", err);
    }
  };
  return (
    <>
      <Grid
        lg={12}
        md={12}
        sm={12}
        xs={12}
        container
        sx={{ padding: "0% 4%", marginTop: "4%" }}
      >
        <Grid lg={12} md={12} sm={12} xs={12}>
          <Stack direction="column" spacing={3} className="w-100">
            <h5 className="textBlack InterBd m-0">Trackers</h5>
            <Grid lg={12} md={12} xs={12} container>
              <Grid
                lg={6}
                md={8}
                sm={8}
                xs={12}
                container
                justifyContent={{
                  lg: "flex-start",
                  md: "flex-start",
                  sm: "flex-start",
                  xs: "center",
                }}
                alignItems="center"
              >
                <Grid
                  lg={5}
                  md={5}
                  sm={5}
                  xs={12}
                  className="mr-3 topalignmentinTickets"
                >
                  <Button
                    className={
                      changeBtn === "ActiveTrackers"
                        ? "reportsbtnBlueKeyMetrices shipmentsbtnspadding"
                        : "reportsbtnKeyMetrices shipmentsbtnspadding"
                    }
                    onClick={() => handleSections("ActiveTrackers")}
                  >
                    Activated Trackers
                  </Button>
                </Grid>

                <Grid
                  lg={5}
                  md={5}
                  sm={5}
                  xs={12}
                  className="mr-3 topalignmentinTickets"
                >
                  <Badge
                    badgeContent={whiteListData.length}
                    color="success"
                    sx={{
                      "& .MuiBadge-badge": {
                        color: "#fff",
                        backgroundColor: "#C92828",
                        fontFamily: "InterRegular",
                      },
                      width: "100%",
                    }}
                  >
                    <Button
                      className={
                        changeBtn === "pendingTrackers"
                          ? "reportsbtnBlueKeyMetrices shipmentsbtnspadding"
                          : "reportsbtnKeyMetrices shipmentsbtnspadding"
                      }
                      onClick={() => handleSections("pendingTrackers")}
                    >
                      Whitelisted Trackers
                    </Button>
                  </Badge>
                </Grid>
              </Grid>
              <Grid lg={3}></Grid>
              <Grid lg={3} xs={3} justifyContent="flex-end">
                <Button className="btn" onClick={() => setOpen(true)}>
                  + Whitelist Trackers
                </Button>
              </Grid>
            </Grid>

            <div
              className="FlydeCard3 h-100 mb-5"
              style={{ overflow: "hidden" }}
            >
              <Grid
                lg={12}
                md={12}
                sm={12}
                xs={12}
                container
                justifyContent="space-between"
                alignItems="center"
                sx={{ padding: "2%" }}
              >
                <h6 className="textGray1 InterMd m-0">
                  {changeBtn === "ActiveTrackers"
                    ? TrackersTable?.length
                    : whiteListData.length}{" "}
                  Results
                </h6>
                <Grid
                  lg={6}
                  md={8}
                  sm={10}
                  xs={12}
                  container
                  justifyContent="space-between"
                >
                  <Grid
                    lg={6.2}
                    md={5}
                    sm={5}
                    xs={12}
                    container
                    className="topalignmentinTickets"
                  >
                    <div className="inputBox1 px-2 py-2 d-flex align-items-center">
                      <input
                        className="shipmentsSearchbar "
                        placeholder="Search Customers Name"
                        onChange={(e) => searchtracker(e.target.value)}
                      />
                      <CgSearch size={22} color={"#999999"} />
                    </div>
                  </Grid>
                  <Grid
                    lg={2.5}
                    md={3}
                    sm={3}
                    xs={12}
                    container
                    className="topalignmentinTickets"
                  >
                    <Button className="btn py-2">
                      <CSVLink className="text-light" data={DownloadData} headers={columns}>
                        Export as CSV
                      </CSVLink>
                    </Button>
                  </Grid>
                  <Grid
                    lg={2.5}
                    md={3}
                    sm={3}
                    xs={12}
                    container
                    className="topalignmentinTickets"
                  >
                    <Button
                      className="btn py-2"
                      onClick={() => downloadPDF(DownloadData1)}
                    >
                      Export as PDF
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              {changeBtn === "ActiveTrackers" ? (
                <TrackerTableAdmin
                  TrackersTable={TrackersTable}
                  NoDatas={NoDatas}
                  loader={loader}
                  activebyhandleAZsort={activebyhandleAZsort}
                  activatedonhandleAZsort={activatedonhandleAZsort}
                  getMyTrackersLive={getMyTrackersLive}
                />
              ) : (
                <PendingTrackers
                  TrackersTable={whiteListData}
                  NoDatas={NoDatas}
                  loader={loader}
                  activebyhandleAZsort={activebyhandleAZsort}
                  activatedonhandleAZsort={activatedonhandleAZsort}
                  getMyTrackersLive={getMyTrackersLive}
                />
              )}
            </div>
          </Stack>
        </Grid>
      </Grid>
      <WhitelistTrackers
        open={open}
        setOpen={setOpen}
        getWhitelistedTrackers={getWhitelistedTrackers}
        getMyTrackersLive={getMyTrackersLive}
      />
    </>
  );
};

export default TrackersAdmin;
