import React, { useState, useEffect } from "react";
import { Grid, Stack, Button, Box } from "@mui/material";
// import SettingsTable from "../../components/table/settingsTable/SettingsTable";
// import WhitelistTrackers from "../../components/modal/WhiteListTrackerModal";
import {  postMethod, putMethod } from "../../apis";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const Settings = () => {

  const count = useSelector((state) => state.WalletConnect)

  // const [open, setOpen] = useState(false);
  // const [comingData, setComingData] = useState(false);
  const [btnShow, setbtnShow] = useState(false);

  //input
  const [updateBasicPlan, setUpdateBasicPlan] = useState("");
  const [trackerBasicPlan, setTrackerBasicPlan] = useState("");
  const [dataBasicPlan, setDataBasicPlan] = useState("");
  const [updateInterMediaPlan, setUpdateInterMediaPlan] = useState("");
  const [trackerInterMediaPlan, setTrackerInterMediaPlan] = useState("");
  const [dataInterMediaPlan, setDataInterMediaPlan] = useState("");
  const [updateAdvancePlan, setUpdateAdvancePlan] = useState("");
  const [trackerAdvancePlan, setTrackerAdvancePlan] = useState("");
  const [dataAdvancePlan, setDataAdvancePlan] = useState("");
  const [basicPlanAmount, setBasicPlanAmount] = useState("");
  const [interMediaPlanAmount, setInterMediaPlanAmount] = useState("");
  const [advancePlanAmount, setAdvancePlanAmount] = useState("");

  //toogle settings
  const [toggle, setToggle] = useState(true);
  const [toggle1, setToggle1] = useState(true);
  const [toggle2, setToggle2] = useState(true);
  const [toggle3, setToggle3] = useState(true);
  const [toggle4, setToggle4] = useState(true);
  const [toggle5, setToggle5] = useState(true);
  const [toggle6, setToggle6] = useState(true);
  const [toggle7, setToggle7] = useState(true);
  const [toggle8, setToggle8] = useState(true);
  const [toggle9, setToggle9] = useState(true);
  const [toggle10, setToggle10] = useState(true);
  const [toggle11, setToggle11] = useState(true);

  //DownloadBtns settings
  const [basicBtnPdfDownload, setBasicBtnPdfDownload] = useState(false);
  const [basicBtnCsvDownload, setBasicCsvBtnDownload] = useState(false);
  const [interMediaBtnPdfDownload, setInterMediaBtnPdfDownload] = useState(false);
  const [interMediaBtnCsvDownload, setInterMediaCsvBtnDownload] = useState(false);
  const [activeBtnPdfDownload, setActiveBtnPdfDownload] = useState(false);
  const [activeBtnCsvDownload, setActiveCsvBtnDownload] = useState(false);
  const [settingsPlanData, setSettingsPlanData] = useState([]);

  const mapping = (data) => {
    if (data?.length !== 0) {
      setUpdateBasicPlan(data[0]?.Plan[0]?.UpdateInterval);
      setUpdateInterMediaPlan(data[0]?.Plan[1]?.UpdateInterval);
      setUpdateAdvancePlan(data[0]?.Plan[2]?.UpdateInterval);
      setTrackerBasicPlan(data[0]?.Plan[0]?.Trackers);
      setTrackerInterMediaPlan(data[0]?.Plan[1]?.Trackers);
      setTrackerAdvancePlan(data[0]?.Plan[2]?.Trackers);
      setDataBasicPlan(data[0]?.Plan[0]?.DataUsage);
      setDataInterMediaPlan(data[0]?.Plan[1]?.DataUsage);
      setDataAdvancePlan(data[0]?.Plan[2]?.DataUsage);
      setToggle(data[0]?.Plan[0]?.AppNotification);
      setToggle1(data[0]?.Plan[1]?.AppNotification);
      setToggle2(data[0]?.Plan[2]?.AppNotification);
      setToggle3(data[0]?.Plan[0]?.EmailNotification);
      setToggle4(data[0]?.Plan[1]?.EmailNotification);
      setToggle5(data[0]?.Plan[2]?.EmailNotification);
      setToggle6(data[0]?.Plan[0]?.SMSnotification);
      setToggle7(data[0]?.Plan[1]?.SMSnotification);
      setToggle8(data[0]?.Plan[2]?.SMSnotification);
      setToggle9(data[0]?.Plan[0]?.Accesstomobile);
      setToggle10(data[0]?.Plan[1]?.Accesstomobile);
      setToggle11(data[0]?.Plan[2]?.Accesstomobile);
      setBasicBtnPdfDownload(data[0]?.Plan[0]?.PdfStatus);
      setBasicCsvBtnDownload(data[0]?.Plan[0]?.CsvStatus);
      setInterMediaBtnPdfDownload(data[0]?.Plan[1]?.PdfStatus);
      setInterMediaCsvBtnDownload(data[0]?.Plan[1]?.CsvStatus);
      setActiveBtnPdfDownload(data[0]?.Plan[2]?.PdfStatus);
      setActiveCsvBtnDownload(data[0]?.Plan[2]?.CsvStatus);
      setBasicPlanAmount(data[0]?.Plan[0]?.Amount);
      setInterMediaPlanAmount(data[0]?.Plan[1]?.Amount);
      setAdvancePlanAmount(data[0]?.Plan[2]?.Amount);
    }
  }

  const handleChangeSwitchs = (check) => {
    if (check === "basic") {
      setToggle(!toggle);
    }
    else if (check === "intermedia") {
      setToggle1(!toggle1);
    }
    else if (check === "active") {
      setToggle2(!toggle2);
    }
    else if (check === "email1") {
      setToggle3(!toggle3);
    }
    else if (check === "email2") {
      setToggle4(!toggle4);
    }
    else if (check === "email3") {
      setToggle5(!toggle5);
    }
    else if (check === "sms1") {
      setToggle6(!toggle6);
    }
    else if (check === "sms2") {
      setToggle7(!toggle7);
    }
    else if (check === "sms3") {
      setToggle8(!toggle8);
    }
  }

  const getPlans = async () => {
    try {
      let url = "api/plan/viewAll";
      let headers = {
        "Content-Type": "application/json",
        "x-access-token": count?.userData?.accessToken,
        "Access-Control-Allow-Origin": "*"
      };
      let response = await postMethod({ url, headers });
      if (response.status === true) {
        mapping(response?.result);
        setSettingsPlanData(response?.result);
      }
      else {
        mapping([]);
        setSettingsPlanData([]);
      }
    }
    catch (err) {
      console.log("Error in getUserDetails", err);
    }
  }

  const editSettings = async () => {
    try {
      const params = {
        Plan: [{
          PlanName: "BasicPlan",
          Amount: basicPlanAmount,
          UpdateInterval: updateBasicPlan,
          AppNotification: toggle,
          EmailNotification: toggle3,
          SMSnotification: toggle6,
          Trackers: trackerBasicPlan,
          DataUsage: dataBasicPlan,
          PdfStatus: basicBtnPdfDownload,
          CsvStatus: basicBtnCsvDownload,
          Accesstomobile: toggle9,
        },
        {
          PlanName: "Intermedia",
          Amount: interMediaPlanAmount,
          UpdateInterval: updateInterMediaPlan,
          AppNotification: toggle1,
          EmailNotification: toggle4,
          SMSnotification: toggle7,
          Trackers: trackerInterMediaPlan,
          DataUsage: dataInterMediaPlan,
          PdfStatus: interMediaBtnPdfDownload,
          CsvStatus: interMediaBtnCsvDownload,
          Accesstomobile: toggle10,
        },
        {
          PlanName: "Essential",
          Amount: advancePlanAmount,
          UpdateInterval: trackerAdvancePlan,
          AppNotification: toggle2,
          EmailNotification: toggle5,
          SMSnotification: toggle8,
          Trackers: trackerAdvancePlan,
          DataUsage: dataAdvancePlan,
          PdfStatus: activeBtnPdfDownload,
          CsvStatus: activeBtnCsvDownload,
          Accesstomobile: toggle11,
        }],
      };
      let headers = {
        "Content-Type": "application/json",
        "x-access-token": count?.userData?.accessToken,
        "Access-Control-Allow-Origin": "*"
      };
      let url = "api/plan/updatePlan";
      let response = await putMethod({ url, params, headers });
      if (response.message === "plan can't find") {
        toast.warn("Plan can't find");
      }
      else if (response.message === "plan update failed") {
        toast.warn("Plan update failed,Try Again");
      }
      else if (response.message === "Plan Updated") {
        toast.success("Plan Updated");
        getPlans();
        setbtnShow(false);
      }
    }
    catch (err) {
      console.log("Error in EditSettings", err);
    }
  }

  useEffect(() => {
    if(btnShow){
      console.log("")
    }
    getPlans();
  }, []);

  return (
    <>
      <Grid
        lg={12}
        md={12}
        sm={12}
        xs={12}
        container
        sx={{ padding: "0% 3%", marginTop: "4%", marginBottom: "4%" }}
      >
        <Grid lg={12} md={12} sm={12} xs={12}>
          <Stack direction="column" spacing={3} className="w-100">            
            <Grid
              lg={12}
              xs={12}
              container
              justifyContent="space-between"
              alignItems="center"
              className="mt-3"
            >
              <Grid lg={6} md={6}>
                <h5 className="textBlack InterBd m-0">Settings</h5>
              </Grid>
              <Grid
                lg={6}
                xs={6}
                container
                justifyContent={{ lg: "flex-end", xs: "space-between" }}
                alignItems="center"
              >
                <Grid lg={3} xs={2} className="pr-3">
                  <Button
                    className="btngray shipmentsbtnspadding"
                    onClick={() => {
                      mapping(settingsPlanData);
                      setbtnShow(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid lg={3} xs={2}>
                  <Button
                    className="btn shipmentsbtnspadding"
                    onClick={editSettings}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              lg={12}
              md={12}
              sm={12}
              xs={12}
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid
                lg={2.9}
                md={3.8}
                sm={5.8}
                xs={12}
                className="mb-3"
                container
              >
                <Box
                  className="FlydeCard4 px-3 d-flex align-items-center"
                  sx={{ height: "35vh" }}
                >
                  <Stack direction="column" spacing={3} className="w-100">
                    <h6 className="textBlack InterSm m-0">Plan Price</h6>
                    <Grid
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid lg={5} md={5} sm={5} xs={5} container>
                        <font className="textBlack InterMd">Basic Plan</font>
                      </Grid>
                      <Grid
                        lg={5}
                        md={5}
                        sm={5}
                        xs={5}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        className="blackBorderssettingsBox"
                      >
                        <font className="textBlack InterRg">$</font>
                        <input
                          type="number"
                          className="settingsTrackersInputBox"
                          value={basicPlanAmount}
                          onChange={(e) => {
                            setBasicPlanAmount(e.target.value);
                            setbtnShow(true);
                          }}
                        />
                        <small className="textGray InterRg">Month</small>
                      </Grid>
                    </Grid>
                    <Grid
                      lg={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid lg={5} md={5} sm={5} xs={5} container>
                        <font className="textBlack InterMd">
                          Intermedia Plan
                        </font>
                      </Grid>
                      <Grid
                        lg={5}
                        md={5}
                        sm={5}
                        xs={5}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        className="blackBorderssettingsBox"
                      >
                        <font className="textBlack InterRg">$</font>
                        <input
                          type="number"
                          className="settingsTrackersInputBox"
                          value={interMediaPlanAmount}
                          onChange={(e) => {
                            setInterMediaPlanAmount(e.target.value);
                            setbtnShow(true);
                          }}
                        />
                        <small className="textGray InterRg">Month</small>
                      </Grid>
                    </Grid>
                    <Grid
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid lg={5} md={5} sm={5} xs={5} container>
                        <font className="textBlack InterMd">Advance Plan</font>
                      </Grid>
                      <Grid
                        lg={5}
                        md={5}
                        sm={5}
                        xs={5}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        className="blackBorderssettingsBox"
                      >
                        <font className="textBlack InterRg">$</font>
                        <input
                          type="number"
                          className="settingsTrackersInputBox"
                          value={advancePlanAmount}
                          onChange={(e) => {
                            setAdvancePlanAmount(e.target.value);
                            setbtnShow(true);
                          }}
                        />
                        <small className="textGray InterRg">Month</small>
                      </Grid>
                    </Grid>
                  </Stack>
                </Box>
              </Grid>
              <Grid
                lg={2.9}
                md={3.8}
                sm={5.8}
                xs={12}
                className="mb-3"
                container
              >
                <Box
                  className="FlydeCard4 px-3 d-flex align-items-center"
                  sx={{ height: "35vh" }}
                >
                  <Stack direction="column" spacing={3} className="w-100">
                    <h6 className="textBlack InterSm m-0">
                      Update Interval for Trackers
                    </h6>
                    <Grid
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid lg={5} md={5} sm={5} xs={5} container>
                        <font className="textBlack InterMd">Basic Plan</font>
                      </Grid>
                      <Grid
                        lg={5}
                        md={5}
                        sm={5}
                        xs={5}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        className="blackBorderssettingsBox"
                      >
                        <input
                          type="number"
                          className="settingsTrackersInputBox"
                          value={updateBasicPlan}
                          onChange={(e) => {
                            setUpdateBasicPlan(e.target.value);
                            setbtnShow(true);
                          }}
                        />
                        <small className="textGray InterRg">Minutes</small>
                      </Grid>
                    </Grid>
                    <Grid
                      lg={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid lg={5} md={5} sm={5} xs={5} container>
                        <font className="textBlack InterMd">
                          Intermedia Plan
                        </font>
                      </Grid>
                      <Grid
                        lg={5}
                        md={5}
                        sm={5}
                        xs={5}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        className="blackBorderssettingsBox"
                      >
                        <input
                          type="number"
                          className="settingsTrackersInputBox"
                          value={updateInterMediaPlan}
                          onChange={(e) => {
                            setUpdateInterMediaPlan(e.target.value);
                            setbtnShow(true);
                          }}
                        />
                        <small className="textGray InterRg">Minutes</small>
                      </Grid>
                    </Grid>
                    <Grid
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid lg={5} md={5} sm={5} xs={5} container>
                        <font className="textBlack InterMd">Advance Plan</font>
                      </Grid>
                      <Grid
                        lg={5}
                        md={5}
                        sm={5}
                        xs={5}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        className="blackBorderssettingsBox"
                      >
                        <input
                          type="number"
                          className="settingsTrackersInputBox"
                          value={updateAdvancePlan}
                          onChange={(e) => {
                            setUpdateAdvancePlan(e.target.value);
                            setbtnShow(true);
                          }}
                        />
                        <small className="textGray InterRg">Minutes</small>
                      </Grid>
                    </Grid>
                  </Stack>
                </Box>
              </Grid>
              <Grid
                lg={2.9}
                md={3.8}
                sm={5.8}
                xs={12}
                className="mb-3"
                container
              >
                <Box
                  className="FlydeCard4 px-3 d-flex align-items-center"
                  sx={{ height: "35vh" }}
                >
                  <Stack direction="column" spacing={3} className="w-100">
                    <h6 className="textBlack InterSm m-0">Trackers Count</h6>
                    <Grid
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid lg={5} md={5} sm={5} xs={5} container>
                        <font className="textBlack InterMd">Basic Plan</font>
                      </Grid>
                      <Grid
                        lg={5}
                        md={5}
                        sm={5}
                        xs={5}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        className="blackBorderssettingsBox"
                      >
                        <input
                          type="number"
                          className="settingsTrackersInputBox"
                          value={trackerBasicPlan}
                          onChange={(e) => {
                            setTrackerBasicPlan(e.target.value);
                            setbtnShow(true);
                          }}
                        />
                        <small className="textGray InterRg">Trackers</small>
                      </Grid>
                    </Grid>
                    <Grid
                      lg={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid lg={5} md={5} sm={5} xs={5} container>
                        <font className="textBlack InterMd">
                          Intermedia Plan
                        </font>
                      </Grid>
                      <Grid
                        lg={5}
                        md={5}
                        sm={5}
                        xs={5}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        className="blackBorderssettingsBox"
                      >
                        <input
                          type="number"
                          className="settingsTrackersInputBox"
                          value={trackerInterMediaPlan}
                          onChange={(e) => {
                            setTrackerInterMediaPlan(e.target.value);
                            setbtnShow(true);
                          }}
                        />
                        <small className="textGray InterRg">Trackers</small>
                      </Grid>
                    </Grid>
                    <Grid
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid lg={5} md={5} sm={5} xs={5} container>
                        <font className="textBlack InterMd">Advance Plan</font>
                      </Grid>
                      <Grid
                        lg={5}
                        md={5}
                        sm={5}
                        xs={5}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        className="blackBorderssettingsBox"
                      >
                        <input
                          type="number"
                          className="settingsTrackersInputBox"
                          value={trackerAdvancePlan}
                          onChange={(e) => {
                            setTrackerAdvancePlan(e.target.value);
                            setbtnShow(true);
                          }}
                        />
                        <small className="textGray InterRg">Trackers</small>
                      </Grid>
                    </Grid>
                  </Stack>
                </Box>
              </Grid>
              <Grid
                lg={2.9}
                md={3.8}
                sm={5.8}
                xs={12}
                className="mb-3"
                container
              >
                <Box
                  className="FlydeCard4 px-3 d-flex align-items-center"
                  sx={{ height: "35vh" }}
                >
                  <Stack direction="column" spacing={2.5} className="w-100">
                    <h6 className="textBlack InterSm m-0">Download Reports</h6>
                    <Grid
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid lg={5} md={5} sm={5} xs={5} container>
                        <font className="textBlack InterMd">Basic Plan</font>
                      </Grid>
                      <Grid
                        lg={4.8}
                        md={5}
                        sm={5}
                        xs={5}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        className="blackBorderssettingsBox1"
                      >
                        <Grid lg={5.6} md={5.6} sm={5.6} xs={5.6} container>
                          <button
                            className={
                              basicBtnPdfDownload === false
                                ? "reportsbtnKeyMetrices"
                                : "reportsbtnBlueKeyMetrices"
                            }
                            onClick={() => {
                              setBasicBtnPdfDownload(!basicBtnPdfDownload);
                              setbtnShow(true);
                            }}
                          >
                            <small>PDF</small>
                          </button>
                        </Grid>
                        <Grid lg={5.6} md={5.6} sm={5.6} xs={5.6} container>
                          <button
                            className={
                              basicBtnCsvDownload === false
                                ? "reportsbtnKeyMetrices"
                                : "reportsbtnBlueKeyMetrices"
                            }
                            onClick={() => {
                              setBasicCsvBtnDownload(!basicBtnCsvDownload);
                              setbtnShow(true);
                            }}
                          >
                            <small>CSV</small>
                          </button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      lg={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid lg={5} md={5} sm={5} xs={5} container>
                        <font className="textBlack InterMd">
                          Intermedia Plan
                        </font>
                      </Grid>
                      <Grid
                        lg={4.8}
                        md={5}
                        sm={5}
                        xs={5}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        className="blackBorderssettingsBox1"
                      >
                        <Grid lg={5.6} md={5.6} sm={5.6} xs={5.6} container>
                          <button
                            className={
                              interMediaBtnPdfDownload === false
                                ? "reportsbtnKeyMetrices"
                                : "reportsbtnBlueKeyMetrices"
                            }
                            onClick={() => {
                              setInterMediaBtnPdfDownload(
                                !interMediaBtnPdfDownload
                              );
                              setbtnShow(true);
                            }}
                          >
                            <small>PDF</small>
                          </button>
                        </Grid>
                        <Grid lg={5.6} md={5.6} sm={5.6} xs={5.6} container>
                          <button
                            className={
                              interMediaBtnCsvDownload === false
                                ? "reportsbtnKeyMetrices"
                                : "reportsbtnBlueKeyMetrices"
                            }
                            onClick={() => {
                              setInterMediaCsvBtnDownload(
                                !interMediaBtnCsvDownload
                              );
                              setbtnShow(true);
                            }}
                          >
                            <small>CSV</small>
                          </button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid lg={5} md={5} sm={5} xs={5} container>
                        <font className="textBlack InterMd">Advance Plan</font>
                      </Grid>
                      <Grid
                        lg={4.8}
                        md={5}
                        sm={5}
                        xs={5}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        className="blackBorderssettingsBox1"
                      >
                        <Grid lg={5.6} md={5.6} sm={5.6} xs={5.6} container>
                          <button
                            className={
                              activeBtnPdfDownload === false
                                ? "reportsbtnKeyMetrices"
                                : "reportsbtnBlueKeyMetrices"
                            }
                            onClick={() => {
                              setActiveBtnPdfDownload(!activeBtnPdfDownload);
                              setbtnShow(true);
                            }}
                          >
                            <small>PDF</small>
                          </button>
                        </Grid>
                        <Grid lg={5.6} md={5.6} sm={5.6} xs={5.6} container>
                          <button
                            className={
                              activeBtnCsvDownload === false
                                ? "reportsbtnKeyMetrices"
                                : "reportsbtnBlueKeyMetrices"
                            }
                            onClick={() => {
                              setActiveCsvBtnDownload(!activeBtnCsvDownload);
                              setbtnShow(true);
                            }}
                          >
                            <small>CSV</small>
                          </button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Stack>
                </Box>
              </Grid>
              {/* <Grid
                lg={2.9}
                md={3.8}
                sm={5.8}
                xs={12}
                className="mb-3"
                container
              >
                <Box
                  className="FlydeCard4 px-3 d-flex align-items-center"
                  sx={{ height: "35vh" }}
                >
                  <Stack direction="column" spacing={3} className="w-100">
                    <h6 className="textBlack InterSm m-0">Data Limitations</h6>
                    <Grid
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid lg={5} md={5} sm={5} xs={5} container>
                        <font className="textBlack InterMd">Basic Plan</font>
                      </Grid>
                      <Grid
                        lg={5}
                        md={5}
                        sm={5}
                        xs={5}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        className="blackBorderssettingsBox"
                      >
                        <input
                          type="number"
                          className="settingsTrackersInputBox"
                          value={dataBasicPlan}
                          onChange={(e) => {
                            setDataBasicPlan(e.target.value);
                            setbtnShow(true);
                          }}
                        />
                        <small className="textGray InterRg">GB</small>
                      </Grid>
                    </Grid>
                    <Grid
                      lg={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid lg={5} md={5} sm={5} xs={5} container>
                        <font className="textBlack InterMd">
                          Intermedia Plan
                        </font>
                      </Grid>
                      <Grid
                        lg={5}
                        md={5}
                        sm={5}
                        xs={5}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        className="blackBorderssettingsBox"
                      >
                        <input
                          type="number"
                          className="settingsTrackersInputBox"
                          value={dataInterMediaPlan}
                          onChange={(e) => {
                            setDataInterMediaPlan(e.target.value);
                            setbtnShow(true);
                          }}
                        />
                        <small className="textGray InterRg">GB</small>
                      </Grid>
                    </Grid>
                    <Grid
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid lg={5} md={5} sm={5} xs={5} container>
                        <font className="textBlack InterMd">Advance Plan</font>
                      </Grid>
                      <Grid
                        lg={5}
                        md={5}
                        sm={5}
                        xs={5}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        className="blackBorderssettingsBox"
                      >
                        <input
                          type="number"
                          className="settingsTrackersInputBox"
                          value={dataAdvancePlan}
                          onChange={(e) => {
                            setDataAdvancePlan(e.target.value);
                            setbtnShow(true);
                          }}
                        />
                        <small className="textGray InterRg">GB</small>
                      </Grid>
                    </Grid>
                  </Stack>
                </Box>
              </Grid> */}
              <Grid
                lg={2.9}
                md={3.8}
                sm={5.8}
                xs={12}
                className="mb-3"
                container
              >
                <Box
                  className="FlydeCard4 px-3 d-flex align-items-center"
                  sx={{ height: "35vh" }}
                >
                  <Stack direction="column" spacing={3} className="w-100">
                    <h6 className="textBlack InterSm m-0">App Notification</h6>
                    <Grid
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid lg={5} md={5} sm={5} xs={5} container>
                        <font className="textBlack InterMd">Basic Plan</font>
                      </Grid>
                      <Grid
                        lg={5}
                        md={5}
                        sm={5}
                        xs={5}
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                        className="blackBorderssettingsBox1"
                      >
                        <div className="gpsswitch m-0">
                          <input
                            type="checkbox"
                            className="checking"
                            checked={toggle ? true : false}
                            onChange={() => {
                              handleChangeSwitchs("basic");
                              setbtnShow(true);
                            }}
                          />
                          <span className="gpsslider round"></span>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid
                      lg={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid lg={5} md={5} sm={5} xs={5} container>
                        <font className="textBlack InterMd">
                          Intermedia Plan
                        </font>
                      </Grid>
                      <Grid
                        lg={5}
                        md={5}
                        sm={5}
                        xs={5}
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                        className="blackBorderssettingsBox1"
                      >
                        <div className="gpsswitch m-0">
                          <input
                            type="checkbox"
                            className="checking"
                            checked={toggle1 ? true : false}
                            onChange={() => {
                              handleChangeSwitchs("intermedia");
                              setbtnShow(true);
                            }}
                          />
                          <span className="gpsslider round"></span>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid lg={5} md={5} sm={5} xs={5} container>
                        <font className="textBlack InterMd">Advance Plan</font>
                      </Grid>
                      <Grid
                        lg={5}
                        md={5}
                        sm={5}
                        xs={5}
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                        className="blackBorderssettingsBox1"
                      >
                        <div className="gpsswitch m-0">
                          <input
                            type="checkbox"
                            className="checking"
                            checked={toggle2 ? true : false}
                            onChange={() => {
                              handleChangeSwitchs("active");
                              setbtnShow(true);
                            }}
                          />
                          <span className="gpsslider round"></span>
                        </div>
                      </Grid>
                    </Grid>
                  </Stack>
                </Box>
              </Grid>
              <Grid
                lg={2.9}
                md={3.8}
                sm={5.8}
                xs={12}
                className="mb-3"
                container
              >
                <Box
                  className="FlydeCard4 px-3 d-flex align-items-center"
                  sx={{ height: "35vh" }}
                >
                  <Stack direction="column" spacing={3} className="w-100">
                    <h6 className="textBlack InterSm m-0">
                      Email Notification
                    </h6>
                    <Grid
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid lg={5} md={5} sm={5} xs={5} container>
                        <font className="textBlack InterMd">Basic Plan</font>
                      </Grid>
                      <Grid
                        lg={5}
                        md={5}
                        sm={5}
                        xs={5}
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                        className="blackBorderssettingsBox1"
                      >
                        <div className="gpsswitch m-0">
                          <input
                            type="checkbox"
                            className="checking"
                            checked={toggle3 ? true : false}
                            onChange={() => {
                              handleChangeSwitchs("email1");
                              setbtnShow(true);
                            }}
                          />
                          <span className="gpsslider round"></span>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid
                      lg={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid lg={5} md={5} sm={5} xs={5} container>
                        <font className="textBlack InterMd">
                          Intermedia Plan
                        </font>
                      </Grid>
                      <Grid
                        lg={5}
                        md={5}
                        sm={5}
                        xs={5}
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                        className="blackBorderssettingsBox1"
                      >
                        <div className="gpsswitch m-0">
                          <input
                            type="checkbox"
                            className="checking"
                            checked={toggle4 ? true : false}
                            onChange={() => {
                              handleChangeSwitchs("email2");
                              setbtnShow(true);
                            }}
                          />
                          <span className="gpsslider round"></span>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid lg={5} md={5} sm={5} xs={5} container>
                        <font className="textBlack InterMd">Advance Plan</font>
                      </Grid>
                      <Grid
                        lg={5}
                        md={5}
                        sm={5}
                        xs={5}
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                        className="blackBorderssettingsBox1"
                      >
                        <div className="gpsswitch m-0">
                          <input
                            type="checkbox"
                            className="checking"
                            checked={toggle5 ? true : false}
                            onChange={() => {
                              handleChangeSwitchs("email3");
                              setbtnShow(true);
                            }}
                          />
                          <span className="gpsslider round"></span>
                        </div>
                      </Grid>
                    </Grid>
                  </Stack>
                </Box>
              </Grid>
              <Grid
                lg={2.9}
                md={3.8}
                sm={5.8}
                xs={12}
                className="mb-3"
                container
              >
                <Box
                  className="FlydeCard4 px-3 d-flex align-items-center"
                  sx={{ height: "35vh" }}
                >
                  <Stack direction="column" spacing={3} className="w-100">
                    <h6 className="textBlack InterSm m-0">SMS Notification</h6>
                    <Grid
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid lg={5} md={5} sm={5} xs={5} container>
                        <font className="textBlack InterMd">Basic Plan</font>
                      </Grid>
                      <Grid
                        lg={5}
                        md={5}
                        sm={5}
                        xs={5}
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                        className="blackBorderssettingsBox1"
                      >
                        <div className="gpsswitch m-0">
                          <input
                            type="checkbox"
                            className="checking"
                            checked={toggle6 ? true : false}
                            onChange={() => {
                              handleChangeSwitchs("sms1");
                              setbtnShow(true);
                            }}
                          />
                          <span className="gpsslider round"></span>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid
                      lg={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid lg={5} md={5} sm={5} xs={5} container>
                        <font className="textBlack InterMd">
                          Intermedia Plan
                        </font>
                      </Grid>
                      <Grid
                        lg={5}
                        md={5}
                        sm={5}
                        xs={5}
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                        className="blackBorderssettingsBox1"
                      >
                        <div className="gpsswitch m-0">
                          <input
                            type="checkbox"
                            className="checking"
                            checked={toggle7 ? true : false}
                            onChange={() => {
                              handleChangeSwitchs("sms2");
                              setbtnShow(true);
                            }}
                          />
                          <span className="gpsslider round"></span>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid lg={5} md={5} sm={5} xs={5} container>
                        <font className="textBlack InterMd">Advance Plan</font>
                      </Grid>
                      <Grid
                        lg={5}
                        md={5}
                        sm={5}
                        xs={5}
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                        className="blackBorderssettingsBox1"
                      >
                        <div className="gpsswitch m-0">
                          <input
                            type="checkbox"
                            className="checking"
                            checked={toggle8 ? true : false}
                            onChange={() => {
                              handleChangeSwitchs("sms3");
                              setbtnShow(true);
                            }}
                          />
                          <span className="gpsslider round"></span>
                        </div>
                      </Grid>
                    </Grid>
                  </Stack>
                </Box>
              </Grid>
              <Grid
                lg={2.9}
                md={3.8}
                sm={5.8}
                xs={12}
                className="mb-3"
                container
              >
                <Box
                  className="FlydeCard4 px-3 d-flex align-items-center"
                  sx={{ height: "35vh" }}
                >
                  <Stack direction="column" spacing={3} className="w-100">
                    <h6 className="textBlack InterSm m-0">
                      Access to Mobile App
                    </h6>
                    <Grid
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid lg={5} md={5} sm={5} xs={5} container>
                        <font className="textBlack InterMd">Basic Plan</font>
                      </Grid>
                      <Grid
                        lg={5}
                        md={5}
                        sm={5}
                        xs={5}
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                        className="blackBorderssettingsBox1"
                      >
                        <div className="gpsswitch m-0">
                          <input
                            type="checkbox"
                            className="checking"
                            checked={toggle9 ? true : false}
                            onChange={() => {
                              setToggle9(!toggle9);
                              setbtnShow(true);
                            }}
                          />
                          <span className="gpsslider round"></span>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid
                      lg={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid lg={5} md={5} sm={5} xs={5} container>
                        <font className="textBlack InterMd">
                          Intermedia Plan
                        </font>
                      </Grid>
                      <Grid
                        lg={5}
                        md={5}
                        sm={5}
                        xs={5}
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                        className="blackBorderssettingsBox1"
                      >
                        <div className="gpsswitch m-0">
                          <input
                            type="checkbox"
                            className="checking"
                            checked={toggle10 ? true : false}
                            onChange={() => {
                              setToggle10(!toggle10);
                              setbtnShow(true);
                            }}
                          />
                          <span className="gpsslider round"></span>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid lg={5} md={5} sm={5} xs={5} container>
                        <font className="textBlack InterMd">Advance Plan</font>
                      </Grid>
                      <Grid
                        lg={5}
                        md={5}
                        sm={5}
                        xs={5}
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                        className="blackBorderssettingsBox1"
                      >
                        <div className="gpsswitch m-0">
                          <input
                            type="checkbox"
                            className="checking"
                            checked={toggle11 ? true : false}
                            onChange={() => {
                              setToggle11(!toggle11);
                              setbtnShow(true);
                            }}
                          />
                          <span className="gpsslider round"></span>
                        </div>
                      </Grid>
                    </Grid>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
            {/* <Grid lg={12} xs={12} container justifyContent="center" alignItems="center" className="mt-3">
              <Grid lg={6} xs={6} container justifyContent={{ lg: "center", xs: "space-between" }} alignItems="center">
                <Grid lg={2} xs={2} className="pr-3">
                  <Button className="btngray shipmentsbtnspadding" onClick={() => mapping(settingsPlanData)}>Cancel</Button>
                </Grid>
                <Grid lg={2} xs={2}>
                  <Button className="btn shipmentsbtnspadding" onClick={editSettings}>Save</Button>
                </Grid>
              </Grid>
            </Grid> */}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
export default Settings;
