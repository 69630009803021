import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { postMethod } from "../../apis";
import { useSelector } from "react-redux";
import PurchasePreview from "../../components/modal/purchasePreview";

const Plans = (props) => {

  const count = useSelector((state) => state.WalletConnect)
  const [planDetails, setPlanDetails] = useState([]);
  const [preview, setpreview] = useState(false)
  const [purchaseData, setPurchaseData] = useState(false);

  const purchasePlan = (row) => {
    setPurchaseData(row);
    setpreview(true);
  }

  const toHoursAndMinutes = (totalMinutes) => {
    if (totalMinutes >= 60) {
      const hours = Math.floor(totalMinutes / 60);
      return hours + " " + "Hour";
    }
    else {
      return totalMinutes + " " + "Minutes";
    }
  }

  const getOneUserDetails = async () => {
    try {
      let headers = {
        "Content-Type": "application/json",
        "x-access-token": count?.userData?.accessToken,
        "Access-Control-Allow-Origin": "*"
      };
      let params = {
        email: count?.userData?.email,
      };
      let url = "api/organization/viewMyprofile";
      let response = await postMethod({ url, headers, params });
      let result = response?.result;
      if (response.status === true) {
        getPlans(result);
      }
      else {
        getPlans([]);
      }
    }
    catch (err) {
      console.log("Error in getUserDetails", err);
    }
  }

  const getPlans = async (res) => {
    try {
      let url = "api/plan/viewAll";
      let headers = {
        "Content-Type": "application/json",
        "x-access-token": count?.userData?.accessToken,
        "Access-Control-Allow-Origin": "*"
      };
      let response = await postMethod({ url, headers });
      let result = response?.result[0]?.Plan;
      if (response.status === true) {
        let arr = [];
        for (let i = 0; i <= result.length - 1; i++) {
          arr.push({ plan:{...result[i]}, user:{...res} });
        }
        setPlanDetails(arr);
      }
      else {
        setPlanDetails([]);
      }
    }
    catch (err) {
      console.log("Error in getPlanDetails", err);
    }
  }
  useEffect(() => {
    getOneUserDetails();
  }, []);

  return (
    <>
      <Grid
        lg={12}
        md={12}
        xs={12}
        sx={{ padding: "1%" }}
        flexDirection="row"
      >
        <div className="" style={{ overflow: "hidden" }}>
          <Grid
            lg={12}
            xs={12}
            container
            justifyContent="space-between"
            alignItems="center"
            
          >
            <h5 className="textBlack InterBd m-0">Payment Details</h5>
            {/* <Grid lg={6} xs={6} container justifyContent="flex-end">
                <Grid lg={3.6} xs={12} container>
                  <Button className="btn ">Edit</Button>
                </Grid>
              </Grid> */}
          </Grid>
        </div>
        <Grid container>
          {planDetails?.map((row, i) => {
            return (
              <Grid id={props.id} key={i} lg={4} md={4} xs={12} sx={{ padding: "1% 0.5%" }}>
                <Grid
                  className="FlydeCard2 mt-3  h-100 scrollBars justify-content-center"
                  sx={{ padding: "5%" }}
                >
                  <Grid alignItems="center" display="flex" direction="column">
                    <font className="InterBd textBlack">{row?.plan?.PlanName}</font>
                    <font className="InterBd textBlack ">${row?.plan?.Amount}</font>
                    <font className="textGray1 interXs ">Per Month</font>
                    {row?.user?.Plan[0] == "no plan" ?  <button className=" w-100 mt-3 btn" onClick={() => purchasePlan(row)}>
                         Buy Plan
                    </button> :
                      row?.user?.Plan[0]?.PlanName === row?.plan?.PlanName ? (
                        <button className="w-100 mt-3 btngray1" style={{ padding: "0.375rem 0.75rem" }}>
                          Plan Purchased
                        </button>
                      ) : (
                        <button className=" w-100 mt-3 btn" onClick={() => purchasePlan(row)}>
                          {row?.plan?.PlanName == "BasicPlan" ? "Downgrade to BasicPlan" : row?.plan?.PlanName == "Essential" ? "Upgrade to Essential" : row?.user?.Plan[0]?.PlanName == "BasicPlan" ? "Upgrade to Intermedia" : "Downgrade to Intermedia"}
                        </button>
                      )
                    }

                  </Grid>
                  <ul className="mt-3">
                    <li>
                      <font className="InterMd textBlack">
                        {" "}
                        Maximum -{" "}
                        <span className="InterBd">
                          {row?.plan?.Trackers} Trackers{" "}
                        </span>
                      </font>
                    </li>
                    <li>
                      <font className="InterMd textBlack">
                        {" "}
                        Maximum Data Usage -{" "}
                        <span className="InterBd">{row?.plan?.DataUsage}GB/ month </span>
                      </font>
                    </li>
                    <li>
                      <font className="InterMd textBlack">
                        {" "}
                        Data Update Interval -{" "}
                        <span className="InterBd">Once in {toHoursAndMinutes(row?.plan?.UpdateInterval)}</span>{" "}
                      </font>
                    </li>
                    <li>
                      <font className="InterMd textBlack">
                        {" "}
                        App Notification -{" "}
                        <span className="InterBd">{row?.plan?.AppNotification === true ? "Yes" : "No"}  </span>{" "}
                      </font>
                    </li>
                    <li>
                      <font className="InterMd textBlack">
                        {" "}
                        Email Notification -{" "}
                        <span className="InterBd"> {row?.plan?.EmailNotification === true ? "Yes" : "No"} </span>{" "}
                      </font>
                    </li>
                    <li>
                      <font className="InterMd textBlack">
                        {" "}
                        SMS Notification -{" "}
                        <span className="InterBd"> {row?.plan?.SMSnotification === true ? "Yes" : "No"}</span>{" "}
                      </font>
                    </li>
                    <li>
                      <font className="InterMd textBlack">
                        {" "}
                        Access to Mobile App -{" "}
                        <span className="InterBd"> {row?.plan?.Accesstomobile === true ? "Yes" : "No"}</span>{" "}
                      </font>
                    </li>
                  </ul>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <PurchasePreview open={preview} setOpen={setpreview} PurchaseData={purchaseData} getOneUserDetails={getOneUserDetails}/>
    </>
  )
};
export default Plans;