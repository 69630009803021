import React,{ useRef, useEffect } from "react";
const AutoComplete = ( {type,index,name,index2,handleShipments,value}) => {
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const options = {
    componentRestrictions: { country: "us" },
  };
  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );
    autoCompleteRef.current.addListener("place_changed", async function () {
      let Values = document.getElementById(type).value
      let target = {value:Values,name:type}
      if(Values){
        handleShipments(target,index,name,index2)
      }
    });
  }, []);
  return (
    <div style={{width:"100%"}}>
      <input className="inputBox1 px-2 py-2 mt-2" id={type} value ={value} name="startAddress" placeholder="Enter Shipment Start Address" ref={inputRef} />
    </div>
  );
}
export default AutoComplete;
