import React, { useState } from "react";
import { Grid, TableBody, TableCell, TableContainer, TableHead, TableRow, Table, TablePagination, Button } from "@mui/material";
import "../table.css";
import { FaSort } from "react-icons/fa";
import OrganizationModal from "../../modal/OrganizationModal";
import { FormatDate1 } from "../../../helpers/index";
import LinkExpired from "../../../assets/images/dashboardImages/linkExpired.svg";
import LoadingData from "../../../assets/images/dashboardImages/loadingData.svg";

const OrganizationTable = ({ organizationsData, getAdminDashBoardDetails,organizationLoading,handleAZsort,ref }) => {
  // const ref = React.createRef();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = useState(false);
  const [organizationData1, setOrganizationData1] = useState("");
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  
  const [sortByorganization,setSortByOrganization] = useState(false);

  const sortorgnamefunction=async()=>{
    if(sortByorganization){
      handleAZsort(true)
      setSortByOrganization(false)
    }else{
      handleAZsort(false)
      setSortByOrganization(true)
    }
  }
  const [sortByorganizationPlan,setSortByOrganizationPlan] = useState(false);

  const handleAZsort1 = async (val) => {
    if(val === "a-z"){
      organizationsData?.sort(function(a, b){
        let x = a?.organization?.Plan[0]?.PlanName ? a.organization?.Plan[0]?.PlanName.toLowerCase() : "z";
        let y = b?.organization?.Plan[0]?.PlanName ? b.organization?.Plan[0]?.PlanName.toLowerCase() : "z";
        if (x < y) {return -1;}
        if (x > y) {return 1;}
        setSortByOrganizationPlan(true);
        return 0;
      });
    }
    else{
      organizationsData?.sort(function(a, b){
        let x = a?.organization?.Plan[0]?.PlanName ? a.organization?.Plan[0]?.PlanName.toLowerCase() : "z";
        let y = b?.organization?.Plan[0]?.PlanName ? b.organization?.Plan[0]?.PlanName.toLowerCase() : "z";
        if (x > y) {return -1;}
        if (x < y) {return 1;}
        setSortByOrganizationPlan(false);
        return 0;
      });
    }
  };

  const [sortByorganizationstatus,setSortByOrganizationstatus] = useState(false);

  const handleAZsort2 = async (val) => {
    if(val === "a-z"){
      organizationsData?.sort(function(a, b){
        let x = a?.organization?.status === false ? "Inactive" : "Active";
        let y = b?.organization?.status === false ? "Inactive" : "Active";
        if (x < y) {return -1;}
        if (x > y) {return 1;}
        setSortByOrganizationstatus(true);
        return 0;
      });
    }
    else{
      organizationsData?.sort(function(a, b){
        let x = a?.organization?.status === false ? "Inactive" : "Active";
        let y = b?.organization?.status === false ? "Inactive" : "Active";
        if (x > y) {return -1;}
        if (x < y) {return 1;}
        setSortByOrganizationstatus(false);
        return 0;
      });
    }
  };

  return (
    <div>
      {organizationsData?.length !== 0 ? (<>
        <Grid lg={12} xs={12} ref={ref} sx={{ overflow: "auto" }}>
          <TableContainer className="mobileviewInTable hide_scroll" sx={{ height: "55vh" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" className="reportTbcell">
                    <font className="textBlack InterMd" size={3}>Organization Name <FaSort color={"#000"} size={20} className="pl-2 pointer" onClick={() => sortorgnamefunction()}/></font>
                  </TableCell>
                  {/* <TableCell align="left" className="reportTbcell">
                    <font className="textBlack InterMd" size={3}>Organization ID</font>
                  </TableCell> */}
                  <TableCell align="left" className="reportTbcell">
                    <font className="textBlack InterMd" size={3}>Current Plan <FaSort color={"#000"} size={20} className="pl-2 pointer" onClick={() => handleAZsort1(sortByorganizationPlan === false ? "a-z" : "z-a")}/></font>
                  </TableCell>
                  <TableCell align="left" className="reportTbcell">
                    <font className="textBlack InterMd" size={3}>Joined On</font>
                  </TableCell>
                  <TableCell align="left" className="reportTbcell">
                    <font className="textBlack InterMd" size={3}>Active Trackers</font>
                  </TableCell>
                  <TableCell align="left" className="reportTbcell">
                    <font className="textBlack InterMd" size={3}>Active Shipments</font>
                  </TableCell>
                  <TableCell align="left" className="reportTbcell">
                    <font className="textBlack InterMd" size={3}>Status <FaSort color={"#000"} size={20} className="pl-2 pointer" onClick={() => handleAZsort2(sortByorganizationstatus === false ? "a-z" : "z-a")}/></font>
                  </TableCell>
                  <TableCell align="left" className="reportTbcell">
                    <font className="textBlack InterMd" size={3}>Action</font>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {organizationsData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                  return (<>
                    <TableRow key={i} sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}>
                      <TableCell align="left">
                        <Grid lg={12} xs={12} container direction="column">
                          <font className="textBlack InterSm">{row?.organization?.organizationName}</font>
                          <small className="textBlack InterRg">{row?.organization?.email}</small>
                        </Grid>
                      </TableCell>
                      {/* <TableCell align="left">
                        <Grid lg={12} xs={12} container justifyContent="flex-start" alignItems="center">
                          <font className="textBlack InterMd">{row?.organizationId}</font>
                        </Grid>
                      </TableCell> */}
                      <TableCell align="left">
                        <Grid lg={12} xs={12} container justifyContent="flex-start" alignItems="center">
                          <font className="textBlack InterMd">{!row?.organization?.Plan[0]?.PlanName ? "-" : row?.organization?.Plan[0]?.PlanName}</font>
                        </Grid>
                      </TableCell>
                      <TableCell align="left">
                        <Grid lg={12} xs={12} container justifyContent="flex-start" alignItems="center">
                          <font className="textBlack InterMd">{FormatDate1(row?.organization?.createdAt)}</font>
                        </Grid>
                      </TableCell>
                      <TableCell align="left">
                        <Grid lg={12} xs={12} container justifyContent="flex-start" alignItems="center">
                          <font className="textBlack InterMd">{row?.trackers?.length}</font>
                        </Grid>
                      </TableCell>
                      <TableCell align="left">
                        <Grid lg={12} xs={12} container justifyContent="flex-start" alignItems="center">
                          <font className="textBlack InterMd">{row?.shipments?.length}</font>
                        </Grid>
                      </TableCell>
                      <TableCell align="left">
                        <Grid lg={12} xs={12} container justifyContent="flex-start" alignItems="center">
                          <font className={row?.organization?.status === false ? "textRed InterMd" : "textGreen InterMd"}>{row?.organization?.status === false ? "Inactive" : "Active"}</font>
                        </Grid>
                      </TableCell>
                      <TableCell align="left">
                        <Grid lg={12} xs={12} container justifyContent="flex-start" alignItems="center">
                          <Button className="borderBluebtn" onClick={() => { setOpen(true); setOrganizationData1(row); }}>View</Button>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  </>)
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid lg={12} xs={12} container justifyContent="flex-end" alignItems="center" sx={{ borderTop: "1px solid rgba(224, 224, 224, 1)" }}>
            <TablePagination className="globalTablePagination"
              sx={{ color: "#656873" }}
              rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
              component="div"
              count={organizationsData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </>) : (<>
        <Grid lg={12} xs={12} container justifyContent="center" alignItems="center" alignContent="center" className="">
          <Grid lg={10} xs={12} container justifyContent="center" alignItems="center" className="">
            {organizationLoading === true ? (<>
              <img src={LoadingData} className="img-fluid" alt="NoDataImage" />
            </>) : (<>
              <img src={LinkExpired} className="linkExpiredImage" alt="NoDataImage" />
            </>)}
          </Grid>
          <Grid lg={10} xs={12} container justifyContent="center" className="">
            <h4 className="textBlack InterBd">No Organizations Available to Display</h4>
          </Grid>
        </Grid>
      </>)}
      <OrganizationModal open={open} setOpen={setOpen} organizationData1={organizationData1} getAdminDashBoardDetails={getAdminDashBoardDetails} />
    </div>
  );
};

export default OrganizationTable;
