import React, { useState, useEffect } from "react";
import { Grid, Button, Stack, Modal, Box } from "@mui/material";
import { CgClose } from "react-icons/cg";
import "./modal.css";
import Select from "react-select";
import { toast } from "react-toastify";
import { FaTemperatureHigh } from "react-icons/fa";
// import { WiHumidity } from "react-icons/wi";
import { MdLightMode } from "react-icons/md";
import { BsGeoFill, BsDropletFill } from "react-icons/bs";
import { GiEdgeCrack } from "react-icons/gi";
import { getMethod } from "../../apis";
import getHeaders from "../../services/index";
// import { StandaloneSearchBox } from "react-google-maps/lib/components/places/StandaloneSearchBox";
// import { compose, withProps, lifecycle } from "recompose";
import { DrawingManager } from "react-google-maps/lib/components/drawing/DrawingManager";
import {
  GoogleMap,
  withGoogleMap,
  Marker,
  withScriptjs,
  Circle,
} from "react-google-maps";
const notifyTemp = [
  { label: "Below", value: "Below" },
  { label: "Above", value: "Above" },
];
// const ButtonONOFF = [
//   { label: "ON", value: "ON" },
//   { label: "OFF", value: "OFF" },
// ];

const Geofence = [
  { label: "Outside", value: "Outside" },
  { label: "Inside", value: "Inside" },
];

const AddnewNotifications = ({
  openNotification,
  setOpenNotification,
  allTrigers,
  setAllTrigers,
  type,
  userData,
  setSelectPhone,
  alertName,
  setAlertName,
  setSelectMail,
  selectMail,
  selectPhone,
  IndexValue,
  addTrackers,
  setAddTrackers,
  newarr,
  setModalType
}) => {

  const [sensorsBtn, setSensorsBtn] = useState("");
  const [tempNotify, setTempNotify] = useState("");
  const [temperatures, setTemperatures] = useState("");
  const [humidityNotify, setHumidityNotify] = useState("");
  const [humidityData, setHumidityData] = useState("");
  const [shockNotify, setShockNotify] = useState("");
  const [shockData, setShockData] = useState(false);
  const [geolocations, setGeoLocations] = useState("");
  const [geofenceNotify, setGeofenceNotify] = useState("");
  const [crntMail, setCrntMail] = useState("");
  const [crntPhone, setcrntPhone] = useState("");
  const [circle, setCircle] = useState(null);
  const [circleProps, setCircleProps] = useState(null);
  const [currentPosition, setCurrentPosition] = useState(null);
  const [address, setAddress] = useState("");
  const [sensordata, setSensordata] = useState([]);
  const NotifiedEmails = [];
  const headers = getHeaders()

  userData?.organizationContacts?.map((e) => {
    if (e.status === true) {
      NotifiedEmails.push({
        label: e.mail,
        value: e.mail,
        name: "mail",
      });
    }
  });
  const NotifiedPhoneno = [];
  userData?.organizationContacts?.map((e) => {
    if (e.status === true) {
      NotifiedPhoneno.push({
        label: e.phone,
        value: e.phone,
      });
    }
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255, 255, 255, 1)",
    boxShadow: "0px 1px 13px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    width: "100%",
    height: "calc(100% - 50px)",
    padding: "2%",
  };

  const customStylesReactselect = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#fff",
      color: "#000",
      border: "1px solid rgba(153, 153, 153, 1)",
      outline: "0px",
      boxShadow: "0px",
      borderRadius: "4px",
      fontFamily: "InterMedium",
      cursor: "pointer",
      padding: "0.5%",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 100,
      fontFamily: "InterMedium",
    }),
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#AE059E",
        color: "#fff",
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: "#fff",
    }),
  };

  const handlesensorBtn = (val) => {
    if (val === "temp") {
      setSensorsBtn("temp");
    } else if (val === "humidity") {
      setSensorsBtn("humidity");
    } else if (val === "shock") {
      setSensorsBtn("shock");
    } else if (val === "light") {
      setSensorsBtn("light");
    } else if (val === "geofence") {
      setSensorsBtn("geofence");
    } else {
      setSensorsBtn("");
    }
  };

  const handleSensors = (val) => {
    if (val === "temp") {
      {
        tempNotify === ""
          ? toast.warn("Select Your Notify")
          : temperatures === ""
            ? toast.warn("Temperature Can't Be Empty")
            : onSensorsupload(val);
      }
    } else if (val === "humidity") {
      {
        humidityNotify === ""
          ? toast.warn("Select Your Notify")
          : humidityData === ""
            ? toast.warn("Humidity Can't Be Empty")
            : onSensorsupload(val);
      }
    } else if (val === "shock") {
      {
        shockNotify === ""
          ? toast.warn("Select Your Notify")
          :
          shockData === "" ?
            toast.warn("Shock Can't Be Empty") :
            onSensorsupload(val);
      }
    } else if (val === "light") {
      onSensorsupload(val);
    } else if (val === "geo") {
      {
        geolocations == ""
          ? toast.warn("Location Can't Be Empty")
          : geofenceNotify === ""
            ? toast.warn("Select Your Notify")
            : onSensorsupload(val);
      }
    }
  };

  const onSensorsupload = (val, e) => {
    setSensordata([])
    handlesensorBtn("");
    if (val === "temp") {
      allTrigers.push({
        tempNotify: tempNotify.value,
        temperatures: temperatures,
        type: "temp",
      });
      sensordata.push({
        tempNotify: tempNotify.value,
        temperatures: temperatures,
        type: "temp",
      })
      setAllTrigers(allTrigers);
      setSensordata(sensordata)
    } else if (val === "humidity") {
      allTrigers.push({
        tempNotify: humidityNotify.value,
        temperatures: humidityData,
        type: "humidity",
      });
      sensordata.push({
        tempNotify: humidityNotify.value,
        temperatures: humidityData,
        type: "humidity",
      });
      setAllTrigers(allTrigers);
      setSensordata(sensordata)
    } else if (val === "shock") {
      allTrigers.push({
        tempNotify: shockNotify.value,
        temperatures: shockData,
        type: "shock",
      });
      sensordata.push({
        tempNotify: shockNotify.value,
        temperatures: shockData,
        type: "shock",
      });
      setSensordata(sensordata)
      setAllTrigers(allTrigers);
    } else if (val === "light") {
      allTrigers.push({
        tempNotify: "Above",
        temperatures: "50",
        type: "light",
      });
      sensordata.push({
        tempNotify: "Above",
        temperatures: "50",
        type: "light",
      });
      setSensordata(sensordata)
      setAllTrigers(allTrigers);
    } else if (val === "geo") {
      allTrigers.push({
        tempNotify: geofenceNotify.value,
        location: geolocations,
        // location: geolocations,
        type: "geo",
      });
      sensordata.push({
        tempNotify: geofenceNotify.value,
        location: geolocations,
        // location: geolocations,
        type: "geo",
      });
      setSensordata(sensordata)
      setAllTrigers(allTrigers);
    } else if (val === "mail") {
      setCrntMail(e);
      const data = e.map((row) => row.value);
      setSelectMail(data);
    } else if (val === "phone") {
      setcrntPhone(e);
      const data = e.map((row) => row.value);
      setSelectPhone(data);
    }
  };

  const handleCloseTrigers = (i) => {
    const values = [...allTrigers];
    values.splice(i, 1);
    setAllTrigers(values);
    let sensord = [...sensordata]
    sensord.splice(i, 1);
    setSensordata(sensord)
  };

  const handleCircleComplete = async (e) => {
    const geocoder = new window.google.maps.Geocoder();
    try {
      const result = await new Promise((resolve, reject) => {
        geocoder.geocode({ location: e.overlay.getCenter() }, (results, status) => {
          if (status === "OK") {
            resolve(results[0]);
          } else {
            reject(status);
          }
        });
      });
      setGeoLocations({ lat: result.geometry.location.lat(), lng: result.geometry.location.lng(), radius: e.overlay.radius, address: result.formatted_address })
      setAddress(result.formatted_address);
    } catch (error) {
      console.error(error);
      setAddress("");
    }
    if (circle) {
      circle.setMap(null); // Remove existing circle if one exists
    }
    setCircle(e.overlay); // Set the new circle
    setCircleProps({
      center: e.overlay.getCenter(),
      radius: e.overlay.getRadius(),
    }); // Save the circle's properties in state
  };

  const handleClearCircle = () => {
    if (circle) {
      circle.setMap(null); // Remove the circle from the map
      setCircle(null); // Reset the state variable to null
      setCircleProps(null);// Reset the circle properties
      setAddress("")
    }
  };
  const MyMapComponent = withScriptjs(
    withGoogleMap(() => (
      <GoogleMap
        defaultZoom={8}
        defaultCenter={currentPosition}
      >
        {circleProps && ( // Render the circle if circleProps exist
          <Circle
            center={circleProps.center}
            radius={circleProps.radius}
            options={{
              fillColor: "#ff0000",
              fillOpacity: 0.35,
              strokeWeight: 2,
              clickable: false,
              editable: true,
              zIndex: 1,
            }}
          />
        )}
        <DrawingManager
          onOverlayComplete={handleCircleComplete}
          defaultOptions={{
            drawingControl: true,
            drawingControlOptions: {
              position: window.google.maps.ControlPosition.TOP_CENTER,
              drawingModes: [
                window.google.maps.drawing.OverlayType.CIRCLE,
                // window.google.maps.drawing.OverlayType.POLYGON,
              ],
            },
            circleOptions: {
              fillColor: "#ffff00",
              fillOpacity: 0.1,
              strokeWeight: 5,
              clickable: false,
              editable: true,
              zIndex: 1,
            },
          }}
        />
        {currentPosition && <Marker position={currentPosition} />}
        {/* {props.isMarkerShown && (
          <Marker position={{ lat: 12.975444, lng: 80.220642 }} />
        )} */}
      </GoogleMap>
    ))
  );

  const getLiveGps = async () => {
    let url = "api/traacker/getLiveGps";
    const response = await getMethod({ url, headers });
    if (response.status === true) {
      setCurrentPosition({
        lat: Number(response.result.data[0]?.location.latitude),
        lng: Number(response.result.data[0]?.location.longitude),
      });
    }
  };
  useEffect(() => {
    getLiveGps()
    setTempNotify(notifyTemp[0]);
    setHumidityNotify(notifyTemp[0]);
    setShockNotify(notifyTemp[0]);
    setGeofenceNotify(Geofence[0]);
    console.log("newarr", newarr)
    if (type == "edit") {
      const newAddTrackers = [...addTrackers];
      let data = newAddTrackers[IndexValue]?.Notification[0].sensor
      if (data) {
        setSensordata(data)
        setAlertName(newAddTrackers[IndexValue]?.Notification[0].alertName)
        let mail = newAddTrackers[IndexValue]?.Notification[0].mail
        let phone = newAddTrackers[IndexValue]?.Notification[0].phone
        setCrntMail([{
          label: mail[0], name: "mail", value: mail[0]
        }])
        setcrntPhone([{
          label: phone[0], name: "mail", value: phone[0]
        }])
      }
    }
  }, [type]);

  const saveNotification = () => {
    if (selectMail.length === 0) {
      toast.warn("Please Select Email for Receive Sensor Notifications");
      return;
    }
    if (selectPhone.length === 0) {
      toast.warn("Please Select Phone Number for Receive Sensor Notifications");
      return;
    }
    if (allTrigers.length === 0) {
      toast.warn("Please Select any Sensor and Trigger value");
      return;
    }
    if (sensordata.length === 0) {
      toast.warn("Please Select any Sensor and Trigger value");
      return;
    }
    const newAddTrackers = [...addTrackers];
    var newTrip = {}
    if (newAddTrackers[IndexValue].Notification.length > 0) {
      if (type != "edit") {
        let data = newAddTrackers[IndexValue].Notification[0].sensor
        sensordata.map((sen) => {
          data.push(sen)
        })
        newTrip = {
          mail: selectMail,
          phone: selectPhone,
          sensor: data,
          alertName: alertName,
        };
      } else {
        newTrip = {
          mail: selectMail,
          phone: selectPhone,
          sensor: sensordata,
          alertName: alertName,
        };
        // let data = newAddTrackers[IndexValue].Notification[0]
        // newAddTrackers[IndexValue].Notification.push(newTrip)
      }
    } else {
      newTrip = {
        mail: selectMail,
        phone: selectPhone,
        sensor: sensordata,
        alertName: alertName,
      };
    }
    newAddTrackers[IndexValue].Notification?.push(newTrip);
    setAddTrackers(newAddTrackers);
    setOpenNotification(false);
    setCrntMail("");
    setcrntPhone("");
    setAlertName("");
    setModalType("")
    setSensordata([])
    handleClearCircle()
    setAddress("")
  };
  const cancelModal = () => {
    setCrntMail("");
    setcrntPhone("");
    setAlertName("");
    setAddress("")
    setModalType("")
    setShockData("")
    setSensordata([])
    handleClearCircle()
    setOpenNotification(false)
  }
  const googleMapURL="https://maps.googleapis.com/maps/api/js?key="+process.env.REACT_APP_GOOGE_API_KEY+"&v=3.exp&libraries=geometry,drawing,places"
  return (
    <>
      <Modal
        open={openNotification}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="center">
          <Grid lg={4.2} md={6} sm={8} xs={11} container sx={style}>
            <Grid
              lg={12}
              md={12}
              sm={12}
              xs={12}
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <h4 className="textBlack InterBd m-0">
                {type === "edit"
                  ? "Edit Notification"
                  : "Add New Notification" + " "}
                {/* : "Add New Notification" + " " + -(IndexValue + 1)} */}
              </h4>
              <CgClose
                size={32}
                color={"rgba(0, 0, 0, 1)"}
                className="pointer"
                onClick={() => cancelModal()}
              />
            </Grid>
            <Box
              sx={{
                width: "100%",
                height: "calc(100% - 100px)",
                overflowY: "scroll",
                scrollbarWidth: "thin",
                "&::-webkit-scrollbar": {
                  width: "1px",
                  borderRadius: "1px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#fff",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#fff",
                },
              }}
            >
              <Grid
                lg={12}
                md={12}
                sm={12}
                xs={12}
                container
                className="pt-3 mb-2"
              >
                <Stack direction="column" spacing={2} className="w-100">
                  <Grid
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <font className="textBlack InterRg">Alert Name</font>
                    <input
                      className="inputBox1 px-2 py-2 mt-1"
                      placeholder="Enter Alert Name"
                      value={alertName}
                      onChange={(e) => setAlertName(e.target.value)}
                    />
                  </Grid>
                  <Grid
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <font className="textBlack InterRg">
                      Users to be Notified (via Mail)
                    </font>
                    <Select
                      styles={customStylesReactselect}
                      value={crntMail}
                      isMulti
                      isSearchable={false}
                      name="mail"
                      className="w-100 mt-1"
                      placeholder="Select Users Email"
                      onChange={(e) => onSensorsupload("mail", e)}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      options={NotifiedEmails}
                    />
                  </Grid>
                  <Grid
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <font className="textBlack InterRg">
                      Users to be Notified (via Phone)
                    </font>
                    <Select
                      styles={customStylesReactselect}
                      value={crntPhone}
                      isMulti
                      isSearchable={false}
                      className="w-100 mt-1"
                      placeholder="Select Users Phone"
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      onChange={(e) => onSensorsupload("phone", e)}
                      options={NotifiedPhoneno}
                    />
                  </Grid>
                  <Grid
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <font className="textBlack InterRg">Select Sensors</font>
                    <Grid
                      lg={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      className="pt-1"
                    >
                      <Grid lg={3.6} xs={3.6} container>
                        {sensorsBtn === "temp" ? (
                          <>
                            <Button className="btn py-2">Temperature</Button>
                          </>
                        ) : (
                          <>
                            <Button
                              className="btnTransparent py-2"
                              onClick={() => handlesensorBtn("temp")}
                            >
                              Temperature
                            </Button>
                          </>
                        )}
                      </Grid>
                      <Grid lg={3.6} xs={3.6} container>
                        {sensorsBtn === "humidity" ? (
                          <>
                            <Button className="btn py-2">Humidity</Button>
                          </>
                        ) : (
                          <>
                            <Button
                              className="btnTransparent py-2"
                              onClick={() => handlesensorBtn("humidity")}
                            >
                              Humidity
                            </Button>
                          </>
                        )}
                      </Grid>
                      <Grid lg={3.6} xs={3.6} container>
                        {sensorsBtn === "shock" ? (
                          <>
                            <Button className="btn py-2">Shock</Button>
                          </>
                        ) : (
                          <>
                            <Button
                              className="btnTransparent py-2"
                              onClick={() => handlesensorBtn("shock")}
                            >
                              Shock
                            </Button>
                          </>
                        )}
                      </Grid>
                      <Grid lg={3.6} xs={3.6} container className="pt-3">
                        {sensorsBtn === "light" ? (
                          <>
                            <Button className="btn py-2">Light</Button>
                          </>
                        ) : (
                          <>
                            <Button
                              className="btnTransparent py-2"
                              onClick={() => handlesensorBtn("light")}
                            >
                              Light
                            </Button>
                          </>
                        )}
                      </Grid>
                      <Grid lg={3.6} xs={3.6} container className="pt-3">
                        {sensorsBtn === "geofence" ? (
                          <>
                            <Button className="btn py-2">Location</Button>
                          </>
                        ) : (
                          <>
                            <Button
                              className="btnTransparent py-2"
                              onClick={() => handlesensorBtn("geofence")}
                            >
                              Location
                            </Button>
                          </>
                        )}
                      </Grid>
                      <Grid lg={3.6} xs={3.6} container className="pt-3"></Grid>
                    </Grid>
                  </Grid>
                  {sensorsBtn === "temp" ? (
                    <>
                      <Grid
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          container
                          justifyContent="space-between"
                          alignItems="center"
                          className="mb-2"
                        >
                          <font className="textBlack InterSm">Temperature</font>
                        </Grid>
                        <Grid lg={3.8} xs={3.8} container>
                          <font className="textBlack InterRg">Notify when</font>
                          <Select
                            styles={customStylesReactselect}
                            isSearchable={false}
                            className="w-100 mt-1"
                            placeholder="Notify when"
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                            options={notifyTemp}
                            value={tempNotify}
                            onChange={(e) => setTempNotify(e)}
                          />
                        </Grid>
                        <Grid lg={3.8} xs={3.8} container>
                          <font className="textBlack InterRg">Temperature</font>
                          <input
                            type="number"
                            className="inputBox1 px-2 py-2 mt-1"
                            placeholder="Temperature"
                            // value={temperatures}
                            onChange={(e) => setTemperatures(e.target.value)}
                          />
                        </Grid>
                        <Grid lg={3.8} xs={3.8} container>
                          <font className="textBlack InterRg">Unit</font>
                          <input
                            className="inputBox1 px-2 py-2 mt-1"
                            placeholder="Unit"
                            value={"°F"}
                          />
                        </Grid>
                        <Grid
                          lg={12}
                          xs={12}
                          container
                          justifyContent="flex-end"
                          className="pt-2"
                        >
                          <Grid
                            lg={10}
                            xs={10}
                            container
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <Grid lg={2} xs={2} className="mr-3">
                              <Button
                                className="btngray"
                                onClick={() => handlesensorBtn("")}
                              >
                                Cancel
                              </Button>
                            </Grid>
                            <Grid lg={2} xs={2}>
                              <Button
                                className="btn"
                                onClick={() => handleSensors("temp")}
                              >
                                Save
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  ) : sensorsBtn === "humidity" ? (
                    <>
                      <Grid
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          container
                          justifyContent="space-between"
                          alignItems="center"
                          className="mb-2"
                        >
                          <font className="textBlack InterSm">Humidity</font>
                        </Grid>
                        <Grid lg={5.8} xs={5.8} container>
                          <font className="textBlack InterRg">Notify when</font>
                          <Select
                            styles={customStylesReactselect}
                            isSearchable={false}
                            className="w-100 mt-1"
                            placeholder="Notify when"
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                            options={notifyTemp}
                            value={humidityNotify}
                            onChange={(e) => setHumidityNotify(e)}
                          />
                        </Grid>
                        <Grid lg={5.8} xs={5.8} container>
                          <font className="textBlack InterRg">Humidity(%)</font>
                          <input
                            type="number"
                            className="inputBox1 px-2 py-2 mt-1"
                            placeholder="Humidity(%)"
                            // value={humidityData}
                            onChange={(e) => setHumidityData(e.target.value)}
                          />
                        </Grid>
                        <Grid
                          lg={12}
                          xs={12}
                          container
                          justifyContent="flex-end"
                          className="pt-2"
                        >
                          <Grid
                            lg={10}
                            xs={10}
                            container
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <Grid lg={2} xs={2} className="mr-3">
                              <Button
                                className="btngray"
                                onClick={() => handlesensorBtn("")}
                              >
                                Cancel
                              </Button>
                            </Grid>
                            <Grid lg={2} xs={2}>
                              <Button
                                className="btn"
                                onClick={() => handleSensors("humidity")}
                              >
                                Save
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  ) : sensorsBtn === "shock" ? (
                    <>
                      <Grid
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          container
                          justifyContent="space-between"
                          alignItems="center"
                          className="mb-2"
                        >
                          <font className="textBlack InterSm">Shock</font>
                        </Grid>
                        <Grid lg={5.8} xs={5.8} container>
                          <font className="textBlack InterRg">Notify when</font>
                          <Select
                            styles={customStylesReactselect}
                            isSearchable={false}
                            className="w-100 mt-1"
                            placeholder="Notify when"
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                            options={notifyTemp}
                            value={shockNotify}
                            onChange={(e) => setShockNotify(e)}
                          />
                        </Grid>
                        <Grid lg={5.8} xs={5.8} container>
                          <font className="textBlack InterRg">Shock (G)</font>
                          <input
                            type="number"
                            className="inputBox1 px-2 py-2 mt-1"
                            placeholder="Shock(G)"
                            // value={shockData}
                            onChange={(e) => setShockData(e.target.value)}
                          />
                        </Grid>
                        <Grid
                          lg={12}
                          xs={12}
                          container
                          justifyContent="flex-end"
                          className="pt-2"
                        >
                          <Grid
                            lg={10}
                            xs={10}
                            container
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <Grid lg={2} xs={2} className="mr-3">
                              <Button
                                className="btngray"
                                onClick={() => handlesensorBtn("")}
                              >
                                Cancel
                              </Button>
                            </Grid>
                            <Grid lg={2} xs={2}>
                              <Button
                                className="btn"
                                onClick={() => handleSensors("shock")}
                              >
                                Save
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                    // <>
                    //   <Grid
                    //     lg={12}
                    //     md={12}
                    //     sm={12}
                    //     xs={12}
                    //     container
                    //     justifyContent="space-between"
                    //     alignItems="center"
                    //   >
                    //     <Grid
                    //       lg={12}
                    //       md={12}
                    //       sm={12}
                    //       xs={12}
                    //       container
                    //       justifyContent="space-between"
                    //       alignItems="center"
                    //       className="mb-2"
                    //     >
                    //       <font className="textBlack InterSm">Shock</font>
                    //     </Grid>
                    //     <Grid lg={12} xs={12} container>
                    //       <font className="textBlack InterRg">Notify when</font>
                    //       <input
                    //         className="inputBox1 newshipmentsinputbox px-2 py-2"
                    //         value={"The Pre-configured Threshold is Triggered"}
                    //         disabled
                    //       />
                    //     </Grid>
                    //     <Grid
                    //       lg={12}
                    //       xs={12}
                    //       container
                    //       justifyContent="flex-end"
                    //       className="pt-2"
                    //     >
                    //       <Grid
                    //         lg={10}
                    //         xs={10}
                    //         container
                    //         justifyContent="flex-end"
                    //         alignItems="center"
                    //       >
                    //         <Grid lg={2} xs={2} className="mr-3">
                    //           <Button
                    //             className="btngray"
                    //             onClick={() => handlesensorBtn("")}
                    //           >
                    //             Cancel
                    //           </Button>
                    //         </Grid>
                    //         <Grid lg={2} xs={2}>
                    //           <Button
                    //             className="btn"
                    //             onClick={() => handleSensors("shock")}
                    //           >
                    //             Save
                    //           </Button>
                    //         </Grid>
                    //       </Grid>
                    //     </Grid>
                    //   </Grid>
                    // </>
                  ) : sensorsBtn === "light" ? (
                    <>
                      <Grid
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          container
                          justifyContent="space-between"
                          alignItems="center"
                          className="mb-2"
                        >
                          <font className="textBlack InterSm">Light</font>
                        </Grid>
                        <Grid lg={12} xs={12} container>
                          <font className="textBlack InterRg">Notify when</font>
                          <input
                            className="inputBox1 newshipmentsinputbox px-2 py-2"
                            value={"The Pre-configured Threshold is Triggered"}
                            disabled
                          />
                        </Grid>
                        <Grid
                          lg={12}
                          xs={12}
                          container
                          justifyContent="flex-end"
                          className="pt-2"
                        >
                          <Grid
                            lg={10}
                            xs={10}
                            container
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <Grid lg={2} xs={2} className="mr-3">
                              <Button
                                className="btngray"
                                onClick={() => handlesensorBtn("")}
                              >
                                Cancel
                              </Button>
                            </Grid>
                            <Grid lg={2} xs={2}>
                              <Button
                                className="btn"
                                onClick={() => handleSensors("light")}
                              >
                                Save
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  ) : sensorsBtn === "geofence" ? (
                    <>
                      <Grid
                        lg={12}
                        xs={12}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid
                          lg={12}
                          xs={12}
                          container
                          justifyContent="space-between"
                          alignItems="center"
                          className="mb-2"
                        >
                          <font className="textBlack InterSm">Location</font>
                        </Grid>
                        <Grid
                          lg={12}
                          xs={12}
                          container
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid lg={5.8} xs={5.8} container>
                            <font className="textBlack InterRg">
                              Location Name
                            </font>
                            {/* <PlacesWithStandaloneSearchBox value={geolocations} onChange={(e) => setGeoLocations(e.target.value)} /> */}
                            <input
                              className="inputBox1 px-2 py-2 mt-1"
                              placeholder="Location Name"
                              value={address}
                              onChange={(e) => setGeoLocations(e.target.value)}
                            />
                          </Grid>
                          <Grid lg={5.8} xs={5.8} container>
                            <font className="textBlack InterRg">
                              Notify when
                            </font>
                            <Select
                              styles={customStylesReactselect}
                              isSearchable={false}
                              className="w-100 mt-1"
                              placeholder="Notify when"
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                              options={Geofence}
                              value={geofenceNotify}
                              onChange={(e) => setGeofenceNotify(e)}
                            />
                          </Grid>
                        </Grid>
                        <Grid lg={12} xs={12} container className="mt-2">
                          <div
                            style={{
                              width: "100%",
                              height: "300px",
                              border: "1px solid rgba(153, 153, 153, 1)",
                            }}
                          >
                            <MyMapComponent
                              isMarkerShown
                              googleMapURL={googleMapURL}
                              loadingElement={
                                <div style={{ height: "100%" }} />
                              }
                              containerElement={
                                <div style={{ height: "100%" }} />
                              }
                              mapElement={<div style={{ height: "100%" }} />}
                            />
                          </div>
                          <button onClick={handleClearCircle}>
                            Clear Circle
                          </button>
                        </Grid>
                        <Grid
                          lg={12}
                          xs={12}
                          container
                          justifyContent="flex-end"
                          className="pt-2"
                        >
                          <Grid
                            lg={10}
                            xs={10}
                            container
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <Grid lg={2} xs={2} className="mr-3">
                              <Button
                                className="btngray"
                                onClick={() => { handleClearCircle(), handlesensorBtn("") }}
                              >
                                Cancel
                              </Button>
                            </Grid>
                            <Grid lg={2} xs={2}>
                              <Button
                                className="btn"
                                onClick={() => handleSensors("geo")}
                              >
                                Save
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                  <Grid
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <font className="textBlack InterRg">Added Triggers</font>
                    {sensordata.length === 0 ? (
                      <>
                        <div className="notificationBoxinshipments px-2 py-2 w-100">
                          <Grid
                            lg={12}
                            xs={12}
                            container
                            justifyContent="center"
                            alignItems="center"
                          >
                            <p className="textGray InterRg m-0">
                              No Triggers Added
                            </p>
                          </Grid>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="notificationBoxinshipments">
                          {sensordata?.map((val, i) => {
                            // {addTrackers[IndexValue].Notification[0]?.sensor?.map((val, i) => {
                            return (
                              <>
                                <Grid
                                  lg={12}
                                  xs={12}
                                  container
                                  justifyContent="space-between"
                                  alignItems="center"
                                  key={i}
                                  className="notificationBoxinshipments spacebetween px-2 py-2 mb-2"
                                >
                                  <Grid
                                    lg={8}
                                    xs={8}
                                    container
                                    justifyContent="flex-start"
                                    alignItems="center"
                                  >
                                    {val.type === "temp" ? (
                                      <>
                                        <FaTemperatureHigh
                                          color={"#000"}
                                          className="mr-2"
                                        />
                                        <font className="textBlack InterRg">
                                          Temperature {val.tempNotify} to{" "}
                                          {val.temperatures}° F
                                        </font>
                                      </>
                                    ) : val.type === "humidity" ? (
                                      <>
                                        <BsDropletFill
                                          color={"#000"}
                                          className="mr-2"
                                        />
                                        <font className="textBlack InterRg">
                                          Humidity {val.tempNotify}{" "}
                                          {val.temperatures}%
                                        </font>
                                      </>
                                    ) : val.type === "shock" ? (
                                      <>
                                        <GiEdgeCrack
                                          color={"#000"}
                                          className="mr-2"
                                        />
                                        <font className="textBlack InterRg">
                                          Shock {val.tempNotify}{" "}
                                          {val.temperatures}G
                                        </font>
                                      </>
                                    ) : val.type === "light" ? (
                                      <>
                                        <MdLightMode
                                          color={"#000"}
                                          className="mr-2"
                                        />
                                        <font className="textBlack InterRg">
                                          Light Configured Threshold
                                        </font>
                                      </>
                                    ) : val.type === "geo" ? (
                                      <>
                                        <BsGeoFill
                                          color={"#000"}
                                          className="mr-2"
                                        />
                                        <font className="textBlack InterRg">
                                          {val.tempNotify} Geo Fence Location
                                        </font>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </Grid>
                                  <Grid
                                    lg={4}
                                    xs={4}
                                    container
                                    justifyContent="flex-end"
                                    alignItems="center"
                                  >
                                    <CgClose
                                      color={"rgba(0, 0, 0, 1)"}
                                      className="pointer"
                                      onClick={() => handleCloseTrigers(i)}
                                    />
                                  </Grid>
                                </Grid>
                              </>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </Grid>
                </Stack>
              </Grid>
            </Box>
            <Grid
              lg={12}
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
              className="mt-3"
            >
              <Grid
                lg={10}
                xs={10}
                container
                justifyContent="space-around"
                alignItems="center"
              >
                <Grid lg={5} xs={5}>
                  <Button
                    className="btngray shipmentsbtnspadding"
                    onClick={() => cancelModal()}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid lg={5} xs={5}>
                  <Button
                    className="btn shipmentsbtnspadding"
                    onClick={() => saveNotification()}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default AddnewNotifications;
