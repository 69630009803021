import React, { useState } from "react";
import {
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TablePagination,
  Button,
} from "@mui/material";
import "../../table.css";
// import AdminTrackerDetail from "../../../modal/AdminTrackerDetails";
import BatteryGauge from "react-battery-gauge";
import { FaSort } from "react-icons/fa";
import { toast } from "react-toastify";
import EditTracker from "../../../modal/EditTracker";

const PendingTrackers = ({
  TrackersTable,
  getMyTrackersLive,
  activebyhandleAZsort,
}) => {
  //   function timeconvertdays(date) {
  //     var a = new Date(date);
  //     var months = [
  //       "Jan",
  //       "Feb",
  //       "Mar",
  //       "Apr",
  //       "May",
  //       "Jun",
  //       "Jul",
  //       "Aug",
  //       "Sep",
  //       "Oct",
  //       "Nov",
  //       "Dec",
  //     ];
  //     var year = a.getFullYear();
  //     var month = months[a.getMonth()];
  //     var dates = a.getDate();
  //     // var time = a.toLocaleTimeString();
  //     var hour = a.getHours();
  //     var min = a.getMinutes();
  //     var AmOrPm = hour >= 12 ? "PM" : "AM";
  //     hour = hour % 12 || 12;
  //     var result =
  //       dates +
  //       " " +
  //       month +
  //       " " +
  //       year +
  //       "   " +
  //       " ( " +
  //       hour +
  //       ":" +
  //       min +
  //       " " +
  //       AmOrPm +
  //       " )";
  //     return result;
  //   }
  const customization = {
    batteryMeter: {
      fill: "#F91818",
      outerGap: 1,
      noOfCells: 1, // more than 1, will create cell battery
      interCellsGap: 1,
    },
    readingText: {
      display: "none",
    },
  };

  const customization1 = {
    batteryMeter: {
      fill: "#F3F82B",
      outerGap: 1,
      noOfCells: 1, // more than 1, will create cell battery
      interCellsGap: 1,
    },
    readingText: {
      display: "none",
    },
  };

  const customization2 = {
    batteryMeter: {
      fill: "#2BB372",
      outerGap: 1,
      noOfCells: 1, // more than 1, will create cell battery
      interCellsGap: 1,
    },
    readingText: {
      display: "none",
    },
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = useState(false);
  const [trackerData, setTrackerData] = useState("");
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const viewDetails = (row) => {
    if (row) {
      setTrackerData(row);
      setOpen(true);
      return;
    }
    toast.warn("view Failed");
  };

  const [sortByorganization, setSortByOrganization] = useState(false);

  const sortacrivatedby = async () => {
    if (sortByorganization) {
      activebyhandleAZsort(true);
      setSortByOrganization(false);
    } else {
      activebyhandleAZsort(false);
      setSortByOrganization(true);
    }
  };

  //   const [sortByplan,setSortByPlan] = useState(false);

  //   const activatedonsort=async()=>{
  //     if(sortByplan){
  //       activatedonhandleAZsort(true)
  //       setSortByPlan(false)
  //     }else{
  //       activatedonhandleAZsort(false)
  //       setSortByPlan(true)
  //     }
  //   }

  return (
    <div>
      <Grid
        lg={12}
        xs={12}
        sx={{
          overflow: "auto",
          border: "1px solid #8C9196",
          borderRadius: "7px",
        }}
      >
        <TableContainer className="mobileviewInTable w-100 examples" sx={{ height: "45vh" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="left" className="dashboardTbcell1">
                  <font className="textBlack InterMd" size={3}>
                    Tracker Name
                  </font>
                </TableCell>
                <TableCell align="left" className="dashboardTbcell1">
                  <font className="textBlack InterMd" size={3}>
                    Buyer Organization
                    <FaSort
                      color={"#000"}
                      size={20}
                      className="pl-2 pointer"
                      onClick={() => sortacrivatedby()}
                    />
                  </font>
                </TableCell>
                <TableCell align="left" className="dashboardTbcell1">
                  <font className="textBlack InterMd" size={3}>
                    Buyer Email
                  </font>
                </TableCell>
                <TableCell align="left" className="dashboardTbcell1">
                  <font className="textBlack InterMd" size={3}>
                    Battery Level
                  </font>
                </TableCell>
                <TableCell align="left" className="dashboardTbcell1">
                  <font className="textBlack InterMd" size={3}>
                    Current Location
                  </font>
                </TableCell>
                <TableCell align="left" className="dashboardTbcell1">
                  <font className="textBlack InterMd" size={3}>
                    Status
                  </font>
                </TableCell>
                <TableCell align="left" className="dashboardTbcell1">
                  <font className="textBlack InterMd" size={3}>
                    Action
                  </font>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {TrackersTable.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              ).map((row, i) => {
                return (
                  <>
                    <TableRow key={i}>
                      <TableCell align="left">
                        <Grid
                          lg={12}
                          xs={12}
                          container
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <font className="textBlue InterRg pointer textHoveringgreen">
                            {row.TrackerName}
                          </font>
                        </Grid>
                      </TableCell>
                      <TableCell align="left" className="pointer">
                        <Grid
                          lg={12}
                          xs={12}
                          container
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <font className="textBlack InterRg">
                            {row?.user[0]?.organizationName}
                          </font>
                        </Grid>
                      </TableCell>
                      <TableCell align="left">
                        <Grid
                          lg={12}
                          xs={12}
                          container
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <font className=" textBlack ">
                            {/* {timeconvertdays(row?.ActivatedOn)} */}
                            {row.BuyerEmail}
                          </font>
                        </Grid>
                      </TableCell>
                      <TableCell align="left">
                        <Grid
                          lg={12}
                          xs={12}
                          container
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <Grid lg={4} xs={4} container>
                            <BatteryGauge
                              style={{ height: "20px" }}
                              customization={
                                row?.Battery < 20
                                  ? customization
                                  : row?.Battery < 50
                                    ? customization1
                                    : row?.Battery < 100
                                      ? customization2
                                      : customization2
                              }
                              size={100}
                              value={row.Battery ? row.Battery : 50}
                            />
                          </Grid>
                          <font className="textBlack InterRg ">
                            {row?.Battery ? row.Battery : 50} %
                          </font>
                        </Grid>
                      </TableCell>
                      <TableCell align="left">
                        <Grid
                          lg={12}
                          xs={12}
                          container
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <font className=" textblack InterRg">
                            {row?.CurrentLocation
                              ? row.CurrentLocation
                              : "725 5th Ave, New York, NY 10022, USA"}
                          </font>
                        </Grid>
                      </TableCell>

                      <TableCell align="left">
                        <Grid
                          lg={12}
                          xs={12}
                          container
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <font
                            className={
                              row?.ActivationStatus == "pending"
                                ? "textGreen InterMd"
                                : "textRed InterMd"
                            }
                          >
                            {row?.ActivationStatus == "pending"
                              ? "pending"
                              : "Deactivated"}
                          </font>
                        </Grid>
                      </TableCell>

                      <TableCell align="left">
                        <Grid
                          lg={12}
                          xs={12}
                          container
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <Grid
                            lg={12}
                            xs={12}
                            container
                            justifyContent="flex-start"
                            alignItems="center"
                            onClick={() => viewDetails(row)}
                          >
                            <Button className="borderBluebtn">View</Button>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid
          lg={12}
          xs={12}
          container
          justifyContent="flex-end"
          alignItems="center"
        >
          <TablePagination
            className="globalTablePagination"
            sx={{ color: "#656873" }}
            rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
            component="div"
            count={TrackersTable.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
      <EditTracker
        open={open}
        setOpen={setOpen}
        EditTrack={trackerData}
        getMyTrackersLive={getMyTrackersLive}
        type={"admin"}
      />
    </div>
  );
};

export default PendingTrackers;
