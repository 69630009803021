import React, { useEffect, useState } from "react";
import { Grid, Button, Stack, Modal } from "@mui/material";
import LoginLogos from "../../assets/images/registerPageImages/LoginLogo.svg";
import Logo from "../../assets/images/logo.png";
import profile1 from "../../assets/images/registerPageImages/profile1.svg";
import profile2 from "../../assets/images/registerPageImages/profile2.svg";
// import Scanner from "../../assets/images/registerPageImages/scannar.svg";
import "./login.css";
import { CgClose } from "react-icons/cg";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { postMethod } from "../../apis";
import { useSelector } from "react-redux";
import Google2FA from "../modal/GoogleAuthentication";
import EnableGoogle2FA from "../modal/EnableGoogle2FA";
import { useDispatch } from "react-redux";
import { saveAs } from "file-saver";
import { getMessaging, getToken } from "firebase/messaging";

const login = () => {
  const count = useSelector((state) => state.WalletConnect)
  const messaging = getMessaging();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
  const [getLink, setGetLink] = useState(false);
  const [resetPasswordMOdal, setResetPasswordModal] = useState(false);
  const [workEmail, setWorkEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loginResult, setLoginResult] = useState("");

  const [keypin, setkeypin] = useState();
  const [QRData, setQRData] = useState("");
  const [FCMToken, setFCMToken] = useState("");
  const [registrationSuccessModal, setRegistrationSuccessModal] =
    useState(false);
  const [enableGoogleAuthentication, setEnableGoogleAuthentication] =
    useState(false);


  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255, 255, 255, 1)",
    boxShadow: "3px 2px 12px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    width: "100%",
    padding: "2%",
  };

  const [flydePassword, setFlydePassword] = useState({
    password: "",
    showPassword: false,
  });



  const handleClickShowPassword = () => {
    setFlydePassword({
      ...flydePassword,
      showPassword: !flydePassword.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop) => (event) => {
    setFlydePassword({ ...flydePassword, [prop]: event.target.value });
  };

  const [flydePassword1, setFlydePassword1] = useState({
    password: "",
    showPassword: false,
    resetPassword: "",
    showresetPassword: false,
  });

  const handleClickShowPassword1 = (val) => {
    if (val === "password") {
      setFlydePassword1({
        ...flydePassword1,
        showPassword: !flydePassword1.showPassword,
      });
    } else if (val === "resetPassword") {
      setFlydePassword1({
        ...flydePassword1,
        showresetPassword: !flydePassword1.showresetPassword,
      });
    }
  };

  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange1 = (prop) => (event) => {
    setFlydePassword1({ ...flydePassword1, [prop]: event.target.value });
  };

  const validationemails = (email) => {
    /* eslint-disable */
    const regExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regExp.test(email);
  }

  const handleValidation = () => {
    let emailValidation = /\S+@\S+\.\S+/;
    if (!workEmail) {
      toast("Please Enter Your Email Address")
      return
    }
    if (!validationemails(workEmail)) {
      toast("Please Enter Valid Email ")
      return
    } else {
      workEmail === ""
        ? toast.warn("Email can't be empty")
        : !emailValidation.test(workEmail)
          ? toast.warn("Enter valid Email")
          : flydePassword.password === ""
            ? toast.warn("Password can't be empty")
            : onhandleLogin();
    }
  };
  const requestForToken = () => {
    return getToken(messaging, { vapidKey: "BEl3KY-aQv7kfPBF-iqcaW1xrqmE-NIvpMj6vmDrvNfKSZSrsYHBzoWRW-fW3f8CBWQ-eTKo4DsKOHm5hvlfEgU" })
      .then((currentToken) => {
        if (currentToken) {
          console.log("current token for client: ", currentToken);
          setFCMToken(currentToken)
          // Perform any other neccessary action with the token
        } else {
          // Show permission request UI
          console.log("No registration token available. Request permission to generate one.");
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
      });
  };
  const onhandleLogin = async () => {
    try {
      setLoading(true);
      const params = {
        email: workEmail,
        password: flydePassword.password,
        fcmtoken: FCMToken
      };
      let url = "api/user/login";
      let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      };
      let response = await postMethod({ url, params, headers });
      if (!response.status) {
        if (response.message === "user not verified") {
          toast.warn("Account Not Activated,Please Contact Admin");
          setLoading(false);
        } else if (response.message === "user inactive") {
          toast.warn("Your Account Deactivated,Please Contact Admin");
          setLoading(false);
        } else if (response.message === "user not found") {
          toast.warn("Email Address Not Exist,Please SignUp!");
          setLoading(false);
        } else if (response.message === "password invalid") {
          toast.warn("Invalid Password");
          setLoading(false);
        } else if (response.message === "enter the valid mail") {
          toast.warn("Invalid Email Address");
          setLoading(false);
        }
      } else if (response.errors) {
        toast.warn(response?.errors[0]);
        setLoading(false);
      } else if (response.status === true) {
        if (response?.result?.TowFAstatus === "Disabled") {
          setLoading(false);
          setLoginResult(response.result);
          setWorkEmail("");
          setFlydePassword({
            password: "",
            showPassword: false,
          });
          if (response?.result?.PrivateKeystatus && response?.result?.PrivateKeystatus == "Enabled") {
            onhandle2FARegister(response?.result.id);
          } else {
            setOpen(true);
          }
        } else {
          setLoading(false);
          setLoginResult(response.result);
          setWorkEmail("");
          setFlydePassword({
            password: "",
            showPassword: false,
          });
          setEnableGoogleAuthentication(true);
        }
      }
    } catch (err) {
      console.log("Error in login", err);
    }
  };

  const [forgotPasswordEmail, setForgotPassWordEmail] = useState("");

  const handleForgotPasswordValidation = () => {
    let emailValidation = /\S+@\S+\.\S+/;
    if (!forgotPasswordEmail) {
      toast("Please Enter Your Email Address")
      return
    }
    if (!validationemails(forgotPasswordEmail)) {
      toast("Please Enter Valid Email")
      return
    } else {
      forgotPasswordEmail === ""
        ? toast.warn("Email can't be empty")
        : !emailValidation.test(forgotPasswordEmail)
          ? toast.warn("Enter valid Email")
          : onHandleforgotPassword();
    }
  };

  const onHandleforgotPassword = async () => {
    try {
      setLoading1(true);
      const params = {
        email: forgotPasswordEmail,
      };
      let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      };
      let url = "api/reset/sendlink";
      let response = await postMethod({ url, params, headers });
      if (response.errors) {
        toast.warn("Email Address Not Exist, PleaseSignUp!");
        setLoading1(false);
      } else if (response.status === false) {
        if (response.message === "user not verified") {
          toast.warn("Account Not Activated,Please Contact Admin");
          setLoading1(false);
        } else if (response.message === "user not activate") {
          toast.warn("Your Account Deactivated,Please Contact Admin");
          setLoading1(false);
        } else if (response.message === "User not Found") {
          toast.warn("Email Address Not Exist,Please SignUp!");
          setLoading1(false);
        } else {
          toast.warn("Invalid Email Address");
          setLoading1(false);
        }
      } else if (response.status === true) {
        setLoading1(false);
        dispatch({
          type: "USER_ID",
          payload: response?.link?.slice(36, response?.link?.length),
        });
        setGetLink(true);
      }
    } catch (err) {
      console.log("Error in ForgotPassword", err);
    }
  };

  const [flydePassword2, setFlydePassword2] = useState({
    password: "",
    showPassword: false,
    resetPassword: "",
    showresetPassword: false,
  });

  const handleClickShowPassword2 = (val) => {
    if (val === "password") {
      setFlydePassword2({
        ...flydePassword2,
        showPassword: !flydePassword2.showPassword,
      });
    } else if (val === "resetPassword") {
      setFlydePassword2({
        ...flydePassword2,
        showresetPassword: !flydePassword2.showresetPassword,
      });
    }
  };

  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange2 = (prop) => (event) => {
    setFlydePassword2({ ...flydePassword2, [prop]: event.target.value });
  };

  const handleValidationResetPassword = () => {
    var passwordValidation =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    {
      flydePassword2.password === ""
        ? toast.warn("Temporary Password Can't Be Empty")
        : flydePassword2.resetPassword === ""
          ? toast.warn("New Password Can't Be Empty")
          : !passwordValidation.test(flydePassword2.resetPassword)
            ? toast.warn(
              "New Password Shall Be Minimum 8 Characters With One Uppercase, One Number And One Special Character"
            )
            : onhandleResetPassword();
    }
  };

  const onhandleResetPassword = async () => {
    try {
      setLoading2(true);
      const params = {
        email: loginResult.email,
        password: flydePassword2.password,
        NewPassword: flydePassword2.resetPassword,
        passwordStatus: true,
      };
      let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      };
      let url = "api/reset/changepassword";
      let response = await postMethod({ url, params, headers });
      if (response.status === false) {
        if (response.message === "no user found") {
          toast.warn("Email Address Not Exist,Please SignUp!");
          setLoading2(false);
        } else if (response.message === "password invalid") {
          toast.warn("Invalid Temporary Password");
          setLoading2(false);
        } else if (response.message === "password change failed") {
          toast.warn("Try Again");
          setLoading2(false);
        }
      } else if (response.status === true) {
        setLoading2(false);
        setOpen(false);

        // if (loginResult.PrivateKeystatus == "Enabled") {
        //   setOpen(false);
        //   setFlydePassword1({
        //     password: "",
        //     showPassword: false,
        //     resetPassword: "",
        //     showresetPassword: false,
        //   });
        //   onhandle2FARegister(loginResult.id);
        // } else {
        //   setOpen(false);
        //   setFlydePassword1({
        //     password: "",
        //     showPassword: false,
        //     resetPassword: "",
        //     showresetPassword: false,
        //   });
        //   setRegistrationSuccessModal(true);
        // }


        setFlydePassword1({
          password: "",
          showPassword: false,
          resetPassword: "",
          showresetPassword: false,
        });
        onhandle2FARegister(loginResult.id);
      }
    } catch (err) {
      console.log("Error in login", err);
    }
  };

  const onButtonClick = async () => {
    // navigate("/homepage")
    if (!loginResult.email) {
      toast("Enter User Email Address");
      return;
    }
    if (!keypin) {
      toast("Enter valid PIN");
      return;
    }
    if (keypin.length < 6) {
      toast("Enter valid 6-digit PIN");
      return;
    }
    // if (checked1 && checked2 && checked3) {
    try {
      var response = await fetch(`${process.env.REACT_APP_BACKEND_URL}api/user/regesteruser`, {
        method: "POST",
        body: JSON.stringify({
          useremail: loginResult.email,
          password: keypin
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        }
      })
      response = await response.json()
      if (response.result.status) {
        const blob = new Blob([response.result.result], { type: "text/plain;charset=utf-8" });
        saveAs(blob, `${response.result.filename}.txt`);
        toast(response.result.message);
        setRegistrationSuccessModal(false);
        onhandle2FARegister(loginResult.id);
      } else {
        toast("username already exists")
      }
    } catch (error) {
      console.log("Error", error)
      toast("something went wrong")
    }
    // } else {
    //   toast.warn("Select All Checkboxes")
    // }
  };

  const onhandle2FARegister = async (data) => {
    try {
      const params = {
        id: data,
      };
      let url = "api/2FA/register";
      let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      };
      let response = await postMethod({ url, params, headers });
      if (response.status === true) {
        setQRData(response.secret);
        // setOpen(true);
        setEnableGoogleAuthentication(true);
      }
    } catch (err) {
      console.log("Error in 2FARegistration", err);
    }
  };
  useEffect(() => {
    requestForToken()
  }, [count])
  const handleEncryptPin = (Value) => {
    if (Value.trim().length > 0) {
      setkeypin(Value)
    }
  }
  return (
    <>
      <Grid lg={12} md={12} sm={12} xs={12} container>
        <Grid
          lg={6}
          md={6}
          sm={6}
          xs={12}
          container
          sx={{ position: "relative" }}
        >
          {window.innerWidth > 600 ? (
            <>
              <img
                src={profile1}
                className="img-fluid LoginimgPosition"
                alt="logo"
              />
              <img
                src={profile2}
                className="img-fluid LoginimgPosition1"
                alt="logo"
              />
            </>
          ) : (
            <></>
          )}
          <Grid
            lg={12}
            md={12}
            sm={12}
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              lg={6.6}
              md={10}
              sm={10}
              xs={8}
              container
              justifyContent="center"
              alignItems="center"
              sx={{ marginTop: { lg: "0", md: "0", sm: "0", xs: "10%" } }}
            >
              <Stack direction="column" spacing={2} alignItems="center">
                <Grid lg={4} md={5} sm={6} xs={6}>
                  <img src={Logo} className="img-fluid app-logo" alt="logo" />
                </Grid>
                <h3 className="InterSm textBlack m-0 pt-3">Sign In</h3>
                <Grid lg={12} md={12} sm={12} xs={12} container>
                  <Stack direction="column" spacing={2} className="w-100">
                    <font className="InterMd textBlack">Work email</font>
                    <input
                      className="inputBox px-2 py-2"
                      value={workEmail}
                      onChange={(e) => setWorkEmail(e.target.value)}
                      placeholder="example@work.com"
                    />
                    <font className="InterMd textBlack">Password</font>
                    <div className="inputBox px-2 py-2 spacebetween">
                      <input
                        type={flydePassword.showPassword ? "text" : "password"}
                        className="passwordInput"
                        placeholder="Password"
                        value={flydePassword.password}
                        onChange={handlePasswordChange("password")}
                      />
                      {flydePassword.showPassword ? (
                        <BsEyeFill
                          className="eyeIcons pointer"
                          onClick={() => handleClickShowPassword("password")}
                          onMouseDown={handleMouseDownPassword}
                        />
                      ) : (
                        <BsEyeSlashFill
                          className="eyeIcons pointer"
                          onClick={() => handleClickShowPassword("password")}
                          onMouseDown={handleMouseDownPassword}
                        />
                      )}
                    </div>
                    <Button className="btn py-2 mt-4" onClick={handleValidation}>
                      {loading ? (
                        <>
                          <Spinner animation="border" variant="light" />
                        </>
                      ) : (
                        <>Log in</>
                      )}
                    </Button>
                    <Grid
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      className=""
                    >
                      <font
                        className="textBlue InterSm pointer  textHovering"
                        role="presentation"
                        onClick={() => navigate("/register")}
                      >
                        Request to Join
                      </font>
                      <font
                        className="textBlack InterSm pointer  textHovering"
                        role="presentation"
                        onClick={() => setForgotPasswordModal(true)}
                      >
                        Forget password?
                      </font>
                    </Grid>
                  </Stack>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          lg={6}
          md={6}
          sm={6}
          xs={12}
          container
          sx={{ display: { lg: "flex", md: "flex", sm: "flex", xs: "none" } }}
        >
          <div className="loginImgBox">
            <img
              src={LoginLogos}
              className="img-fluid loginLogoimg"
              alt="loginlogo"
            />
          </div>
        </Grid>
      </Grid>
      <Modal
        open={forgotPasswordModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="center">
          <Grid lg={4} md={6} sm={8} xs={11} container sx={style}>
            <Grid lg={12} xs={12} container>
              <Grid
                lg={12}
                md={12}
                sm={12}
                xs={12}
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <h4 className="textBlack InterBd m-0">Forget Password</h4>
                <CgClose
                  size={32}
                  color={"rgba(0, 0, 0, 1)"}
                  className="pointer"
                  onClick={() => {
                    setForgotPasswordModal(false);
                    setGetLink(false);
                    setForgotPassWordEmail("")
                  }}
                />
              </Grid>
              {getLink === false ? (
                <>
                  <Grid
                    lg={12}
                    xs={12}
                    container
                    alignItems="flex-start"
                    direction="column"
                    className="pt-2"
                  >
                    <font className="InterSm textBlack mt-3">Work email</font>
                    <input
                      className="inputBox px-2 py-2 mt-1"
                      placeholder="example@work.com"
                      value={forgotPasswordEmail}
                      onChange={(e) => setForgotPassWordEmail(e.target.value)}
                    />
                    <Button
                      className="btn  py-2 mt-3"
                      onClick={handleForgotPasswordValidation}
                    >
                      {loading1 ? (
                        <>
                          <Spinner animation="border" variant="light" />
                        </>
                      ) : (
                        <>Get Link</>
                      )}
                    </Button>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid
                    lg={12}
                    xs={12}
                    container
                    alignItems="flex-start"
                    direction="column"
                    className="pt-2"
                  >
                    <font className="InterRg textBlack mt-3">
                      We will send you a link to reset your password. If you
                      don&apos;t receive the email within a few minutes, please
                      check your spam folder. Thank you!
                    </font>
                    <Button
                      className="btn w-50 py-2 mt-3"
                      onClick={() => {
                        setGetLink(false);
                        setForgotPasswordModal(false);
                      }}
                    >
                      OK
                    </Button>
                  </Grid>
                </>
              )}
              <Grid
                lg={12}
                xs={12}
                container
                justifyContent="flex-end"
                className="pt-4"
              >
                <font className="textBlack InterRg">
                  Return to{" "}
                  <font
                    className="textBlue InterRg pointer textHovering"
                    role="presentation"
                    onClick={() => {
                      setForgotPasswordModal(false);
                      setGetLink(false);
                    }}
                  >
                    Sign in
                  </font>
                </font>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <Modal
        open={resetPasswordMOdal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="center">
          <Grid lg={4} md={6} sm={8} xs={11} container sx={style}>
            <Grid lg={12} xs={12} container>
              <Grid
                lg={12}
                md={12}
                sm={12}
                xs={12}
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <h4 className="textBlack InterBd m-0">Reset Password</h4>
                <CgClose
                  size={32}
                  color={"rgba(0, 0, 0, 1)"}
                  className="pointer"
                  onClick={() => {
                    setResetPasswordModal(false);
                  }}
                />
              </Grid>
              <Grid
                lg={12}
                xs={12}
                container
                alignItems="flex-start"
                direction="column"
                className="pt-2"
              >
                <font className="InterSm textBlack mt-3">New Password</font>
                <div className="inputBox px-2 py-2 spacebetween mt-1">
                  <input
                    type={flydePassword1.showPassword ? "text" : "password"}
                    className="passwordInput"
                    placeholder="New Password"
                    onChange={handlePasswordChange1("password")}
                  />
                  {flydePassword1.showPassword ? (
                    <BsEyeFill
                      className="eyeIcons pointer"
                      onClick={() => handleClickShowPassword1("password")}
                      onMouseDown={handleMouseDownPassword1}
                    />
                  ) : (
                    <BsEyeSlashFill
                      className="eyeIcons pointer"
                      onClick={() => handleClickShowPassword1("password")}
                      onMouseDown={handleMouseDownPassword1}
                    />
                  )}
                </div>
                <font className="InterSm textBlack mt-3">Confirm password</font>
                <div className="inputBox px-2 py-2 spacebetween mt-1">
                  <input
                    type={
                      flydePassword1.showresetPassword ? "text" : "password"
                    }
                    className="passwordInput"
                    placeholder="Confirm password"
                    onChange={handlePasswordChange1("resetPassword")}
                  />
                  {flydePassword1.showresetPassword ? (
                    <BsEyeFill
                      className="eyeIcons pointer"
                      onClick={() => handleClickShowPassword1("resetPassword")}
                      onMouseDown={handleMouseDownPassword1}
                    />
                  ) : (
                    <BsEyeSlashFill
                      className="eyeIcons pointer"
                      onClick={() => handleClickShowPassword1("resetPassword")}
                      onMouseDown={handleMouseDownPassword1}
                    />
                  )}
                </div>
              </Grid>
              <Grid lg={12} xs={12} container className="pt-4">
                <h6 className="textRed InterLt m-0">
                  Your password should be at least 8 characters, including one
                  upper case letter, one lower case letter, and one number.
                </h6>
              </Grid>
              <Grid
                lg={12}
                xs={12}
                container
                justifyContent=""
                className="pt-4"
              >
                <Button
                  className="btn py-2"
                  onClick={() => {
                    setResetPasswordModal(false);
                  }}
                >
                  Reset Password
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="center">
          <Grid lg={4} md={6} sm={8} xs={11} container sx={style}>
            <Grid lg={12} xs={12} container>
              <Grid
                lg={12}
                md={12}
                sm={12}
                xs={12}
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <h4 className="textBlack InterBd m-0">Change Password</h4>
              </Grid>
              <Grid
                lg={12}
                xs={12}
                container
                alignItems="flex-start"
                direction="column"
                className="pt-2"
              >
                <font className="InterSm textBlack mt-3">
                  Temporary Password
                </font>
                <div className="inputBox px-2 py-2 spacebetween mt-1">
                  <input
                    type={flydePassword2.showPassword ? "text" : "password"}
                    className="passwordInput"
                    placeholder="Enter temporary password"
                    onChange={handlePasswordChange2("password")}
                  />
                  {flydePassword2.showPassword ? (
                    <BsEyeFill
                      className="eyeIcons pointer"
                      onClick={() => handleClickShowPassword2("password")}
                      onMouseDown={handleMouseDownPassword2}
                    />
                  ) : (
                    <BsEyeSlashFill
                      className="eyeIcons pointer"
                      onClick={() => handleClickShowPassword2("password")}
                      onMouseDown={handleMouseDownPassword2}
                    />
                  )}
                </div>
                <font className="InterSm textBlack mt-3">New password</font>
                <div className="inputBox px-2 py-2 spacebetween mt-1">
                  <input
                    type={
                      flydePassword2.showresetPassword ? "text" : "password"
                    }
                    className="passwordInput"
                    placeholder="Enter new password"
                    onChange={handlePasswordChange2("resetPassword")}
                  />
                  {flydePassword2.showresetPassword ? (
                    <BsEyeFill
                      className="eyeIcons pointer"
                      onClick={() => handleClickShowPassword2("resetPassword")}
                      onMouseDown={handleMouseDownPassword2}
                    />
                  ) : (
                    <BsEyeSlashFill
                      className="eyeIcons pointer"
                      onClick={() => handleClickShowPassword2("resetPassword")}
                      onMouseDown={handleMouseDownPassword2}
                    />
                  )}
                </div>
              </Grid>
              <Grid lg={12} xs={12} container className="pt-4">
                <h6 className="textRed InterLt m-0">
                  Your password should be at least 8 characters, including one
                  upper case letter, one lower case letter, and one number.
                </h6>
              </Grid>
              <Grid
                lg={12}
                xs={12}
                container
                justifyContent=""
                className="pt-4"
              >
                <Button
                  className="btn py-2"
                  onClick={handleValidationResetPassword}
                >
                  {loading2 ? (
                    <>
                      <Spinner animation="border" variant="light" />
                    </>
                  ) : (
                    <>Change Password</>
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <Modal
        open={registrationSuccessModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="center">
          <Grid lg={4} md={6} sm={8} xs={11} container sx={style}>
            <Grid lg={12} xs={12} container>
              <Grid
                lg={12}
                md={12}
                sm={12}
                xs={12}
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <h4 className="textBlack InterBd m-0">
                  Welcome onboard with Flyde!
                </h4>
              </Grid>
              <Grid
                lg={12}
                md={12}
                sm={12}
                xs={12}
                container
                justifyContent="flex-start"
                className="pt-3"
              >
                <font className="InterRg textBlack">
                  Congratulations on creating your account!
                  You will need to enter PIN number to generate your secret key

                </font>
                <br />
                <Grid
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  container
                  justifyContent="space-between"
                  className="mt-3"
                >
                  <input
                    type={"password"}
                    className="inputBox px-2 py-2"
                    placeholder="Enter Your Pin"
                    onChange={(e) => handleEncryptPin(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid
                lg={12}
                md={12}
                sm={12}
                xs={12}
                container
                justifyContent="center"
                className="pt-3 mb-3"
              >
                <Button
                  className="btn w-50 py-2"
                  onClick={() => {
                    onButtonClick(workEmail);
                  }}
                >
                  Download Secret Key
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      {loginResult?.TowFAstatus === "Disabled" ? (
        <>
          <EnableGoogle2FA
            enableGoogleAuthentication={enableGoogleAuthentication}
            setEnableGoogleAuthentication={setEnableGoogleAuthentication}
            QRData={QRData}
            loginResult={loginResult}
          />
        </>
      ) : (
        <>
          <Google2FA
            enableGoogleAuthentication={enableGoogleAuthentication}
            setEnableGoogleAuthentication={setEnableGoogleAuthentication}
            QRData={QRData}
            loginResult={loginResult}
          />
        </>
      )}
    </>
  );
};

export default login;
