import React, { useState, useEffect, useMemo } from "react";
import { Grid, Button, Stack } from "@mui/material";
import { BsFillArrowLeftSquareFill } from "react-icons/bs";
// import { TrackersTable } from "../../helpers/data";
// import TrackersData from "../../components/table/trackerTable/trackerTable.js";
// import Map from "../../components/sidebar/map.js";
import BatteryGauge from "react-battery-gauge";
import { useNavigate } from "react-router-dom";
// import Select from "react-select";
import "./tracker.css";
// import { chartsdetails } from "../../helpers/data";
import Linecharts from "../../components/charts/LineCharts";
// import { Link } from "react-router-dom";
// import ShipmentsTimeline from "../../helpers/timeline";
import EditTrackers from "../../components/modal/EditTracker.js";
import { SlArrowRight } from "react-icons/sl";
import ChartsViewsModal from "../../components/modal/ChartViewModal";
import { useSelector } from "react-redux";
import { postMethod } from "../../apis/index.js";
import TrackerMap from "./WrapedMap.js";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  timelineItemClasses,
} from "@mui/lab";
import { RiRecordCircleFill } from "react-icons/ri";
import { IoLocationSharp } from "react-icons/io5";
// import { onMessage, getMessaging } from "firebase/messaging";
// import axios from "axios"

const TrackerDetails = ({
  trackerDetailData,
  setViewPage,
  LiveData,
  getMyTrackersLive,
}) => {
  const count = useSelector((state) => state.WalletConnect);
  let UsersInfo = count?.userData;
  const navigate = useNavigate();
  const [progress, setProgress] = useState(false);
  const [origin, setOrigin] = useState({ lat: "", lng: "" });
  const [currentlocation, setCurrentlocation] = useState({ lat: "", lng: "" });
  const [destination, setDestination] = useState({ lat: "", lng: "" });
  const [ShipmentData, setShipmentData] = useState([])
  const [EditTrack, setEditTrack] = useState(false);
  const [sensorsType, setSensorsType] = useState("");
  const [openChart, setOpenChart] = useState(false);
  const [ChartData, setChartData] = useState("");
  const [trips, setTrips] = useState([]);
  const [deviceInfo, setDeviceInfo] = useState();
  const [currentLocation, setCurrentLocation] = useState("");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setProgress(false);
    getMyTrips();
    getShipment();
    getTrackerDetails();
    handleChange("sensors");
  }, []);

  const getTrackerDetails = async () => {
    setLoader(true)
    let url = "api/iot/getTrackerDetails"
    let params = {
      unique_id: trackerDetailData.TrackerSerial
    }
    let headers = {
      "Content-Type": "application/json",
      "x-access-token": count?.userData?.accessToken,
      "Access-Control-Allow-Origin": "*",
    };
    const response = await postMethod({ url, params, headers })
    if (response) {
      setLoader(false)
      if (response.status) {
        let details = []

        // Live location
        getLiveLocation(response.result.DevicePositionInfo[0])

        // Device Info
        setDeviceInfo(response.result.DeviceSystemInfo)

        // Battery Info
        trackerDetailData.Battery = response.result.DeviceBatteryInf.battery_level

        // Temperature Info
        let Temperature = response.result.SensorTemphumiInfo.sort((a, b) => a.server_time - b.server_time)
        details.push({
          type: "Temperature",
          value: Temperature.map(d => +(d.temperature * 0.01).toFixed(2)),
          Time: Temperature.map(d => d.server_time)
        })

        // Humidity Info
        let Humidity = response.result.SensorTemphumiInfo.sort((a, b) => a.server_time - b.server_time)
        details.push({
          type: "Humidity",
          value: Humidity.map(d => +(d.humidity * 0.01).toFixed(2)),
          Time: Humidity.map(d => d.server_time)
        })

        // Light Info
        let Light = response.result.SensorLightInfo.sort((a, b) => a.server_time - b.server_time)
        details.push({
          type: "Light",
          value: Light.map(d => +(d.illuminance * 0.01).toFixed(2)),
          Time: Light.map(d => d.server_time)
        })

        details = details.map(d => {
          return {
            ...d,
            min: Math.min(...d.value),
            max: Math.max(...d.value),
            avg: (d.value.reduce((a, b) => +a + +b, 0) / d.value.length),
            current: d.value[0],
            tracker: []
          }
        })

        setCurrent(details)
      }
    }
  }

  const formatlatLong = (pos) => {
    let decimalIndex = pos.indexOf(".")
    let result = pos.replace(".", "")
    let fResult = result.slice(0, decimalIndex - 2) + "." + result.slice(decimalIndex - 2, result.length - 1)
    return fResult
  }

  const getLiveLocation = async (pos) => {
    if (pos) {
      let loc = {
        lat: formatlatLong(pos.latitude),
        lng: formatlatLong(pos.longitude)
      }

      // setCurrentlocation(loc)

      let url = "api/iot/getTrackerLiveLocation"
      let params = {
        latitude: loc.lat,
        longitude: loc.lng
      }
      let headers = {
        "Content-Type": "application/json",
        "x-access-token": count?.userData?.accessToken,
        "Access-Control-Allow-Origin": "*",
      };
      const response = await postMethod({ url, params, headers })
      if (response && response.status) {
        setCurrentLocation(response.result.location)
      }
    }
  }

  const handleChange = (val) => {
    if (val === "sensors") {
      setSensorsType("sensors");
    } else if (val === "notifications") {
      setSensorsType("notifications");
    } else if (val === "trackers") {
      setSensorsType("trackers");
    } else if (val === "details") {
      setSensorsType("details");
    }
  };
  const customization = {
    batteryMeter: {
      fill: "#F91818",
      outerGap: 1,
      noOfCells: 1, // more than 1, will create cell battery
      interCellsGap: 1,
    },
    readingText: {
      display: "none",
    },
  };

  const customization1 = {
    batteryMeter: {
      fill: "#F3F82B",
      outerGap: 1,
      noOfCells: 1, // more than 1, will create cell battery
      interCellsGap: 1,
    },
    readingText: {
      display: "none",
    },
  };

  const customization2 = {
    batteryMeter: {
      fill: "#2BB372",
      outerGap: 1,
      noOfCells: 1, // more than 1, will create cell battery
      interCellsGap: 1,
    },
    readingText: {
      display: "none",
    },
  };

  const [current, setCurrent] = useState([]);
  const getShipment = async () => {
    const params = {
      userId: UsersInfo.id,
    };
    let headers = {
      "Content-Type": "application/json",
      "x-access-token": count?.userData?.accessToken,
      "Access-Control-Allow-Origin": "*",
    };
    try {
      let url = "api/shipment/getshipmentByUserid";
      let response = await postMethod({ url, params, headers });
      var ShipmentID = ""
      let shipmentData = []
      if (response.status) {
        response.result.map((row) => {
          row.Trackers.map((tracker) => {
            if (tracker.TrackerSerial == trackerDetailData.TrackerSerial) {
              ShipmentID = row.ID
              shipmentData.push(tracker)
            }
          })
        })
      }
      if (shipmentData && shipmentData.length) {
        setShipmentData(shipmentData)
        if (shipmentData[0].trip[0]) {
          var geocoder = new window.google.maps.Geocoder();
          var address = shipmentData[0].trip[0].endAddress;
          var startaddress = shipmentData[0].trip[0].startAddress;
          geocoder.geocode({ "address": address }, function (results, status) {
            if (status == window.google.maps.GeocoderStatus.OK && !true) {
              var latitude = results[0].geometry.location.lat();
              var longitude = results[0].geometry.location.lng();
              // alert(latitude);
              setDestination({
                lat: latitude,
                lng: longitude,
              });
            }
          });
          geocoder.geocode({ "address": startaddress }, function (results, status) {
            if (status == window.google.maps.GeocoderStatus.OK && !true) {
              var latitude = results[0].geometry.location.lat();
              var longitude = results[0].geometry.location.lng();
              // alert(latitude);
              setOrigin({
                lat: latitude,
                lng: longitude,
              });
            }
          });
        }
      }

      if (ShipmentID) {
        const params = {
          shipmentId: ShipmentID,
        };
        let url = "api/shipment/getTrackerdetails";
        try {
          let response = await postMethod({ url, params, headers });
          // getSensorData(response.result, shipmentData);
          console.log("response.result", response.result)
          if (response.result) {
            // let initial = response.result[].Geofence
            let last = response.result[response.result.length - 1].Geofence

            setCurrentlocation({
              lat: last.split(",")[0],
              lng: last.split(",")[1],
            });

          }

        } catch (error) {
          console.log(" Error", error)
        }
      }
    } catch (err) {
      console.log("Error", err)
    }

  };

  // const getSensorData = (shipments, shipmentDatas) => {
  //   let Temp = [];
  //   let Humi = [];
  //   let Light = [];
  //   let Shock = [];
  //   let Timestamp = [];
  //   // let TrackerData=[];
  //   let shipmentid = [];
  //   // var ShipmentID = ""
  //   shipments.map((row) => {
  //     // if (row.TrackerId == trackerDetailData.TrackerSerial) {
  //     Temp.push(row.Temperature);
  //     Humi.push(row.Humidity);
  //     Light.push(row.Light);
  //     Shock.push(row.Shock);
  //     Timestamp.push(row.Time);
  //     // ShipmentID = row.ShipmentID
  //     // }
  //     shipmentid.push(row.shipmentid);
  //     // Trackerid.push(row.TrackerId)
  //   });
  //   let ShipmentData = [];
  //   let filtr;
  //   let newarrs = []
  //   shipments.map((arr) => {
  //     // arrs.Trackers.map((arr) => {
  //     // if (arr.TrackerId === trackerDetailData.TrackerSerial) {
  //     newarrs.push(arr)
  //     // }
  //     // });
  //   })
  //   if (filtr !== undefined) {
  //     ShipmentData.push(filtr[0]);
  //   }
  //   let minVal = Math.min(...Temp);
  //   let maxVal = Math.max(...Temp);
  //   let sum = Temp.reduce((acc, curr) => Number(acc) + Number(curr), 0);
  //   let randomIndex = Math.floor(Math.random() * Temp.length);
  //   let currenttemp = Temp[randomIndex];
  //   let avgVal = sum / Temp.length;
  //   let minValHumi = Math.min(...Humi);
  //   let maxValHumi = Math.max(...Humi);
  //   let sumHumi = Humi.reduce((acc, curr) => Number(acc) + Number(curr), 0);
  //   let avgValHumi = sumHumi / Humi.length;
  //   let randomHumi = Math.floor(Math.random() * Humi.length);
  //   let currentHumi = Humi[randomHumi];
  //   let minValLight = Math.min(...Light);
  //   let maxValLight = Math.max(...Light);
  //   let sumLight = Light.reduce((acc, curr) => Number(acc) + Number(curr), 0);
  //   let avgValLight = sumLight / Light.length;
  //   let randomLight = Math.floor(Math.random() * Light.length);
  //   let currentLight = Light[randomLight];
  //   let minValshock = Math.min(...Shock);
  //   let maxValshock = Math.max(...Shock);
  //   let sumshock = Shock.reduce((acc, curr) => Number(acc) + Number(curr), 0);
  //   let avgValshock = sumshock / Shock.length;
  //   let randomShock = Math.floor(Math.random() * Shock.length);
  //   let currentShock = Shock[randomShock];
  //   const minData = [];
  //   minData.push({ type: "Temperature", min: minVal, max: maxVal, avg: avgVal, current: currenttemp, value: Temp, Time: Timestamp, tracker: shipmentDatas }, { type: "Humidity", min: minValHumi, max: maxValHumi, avg: avgValHumi, current: currentHumi, value: Humi, Time: Timestamp, tracker: shipmentDatas },
  //     { type: "Light", current: currentLight, min: minValLight, max: maxValLight, avg: avgValLight, value: Light, Time: Timestamp, tracker: shipmentDatas }, { type: "Shock", min: minValshock, max: maxValshock, avg: avgValshock, current: currentShock, value: Shock, Time: Timestamp, tracker: shipmentDatas })
  //   setCurrent(minData);
  // };

  const getMyTrips = async () => {
    let headers = {
      "Content-Type": "application/json",
      "x-access-token": count?.userData?.accessToken,
      "Access-Control-Allow-Origin": "*",
    };
    let url = "api/tracker/getTrackerHistory";
    let response = await postMethod({ url, headers });
    let newArr = [];
    if (response.status === true) {
      newArr = response.result.trips.map((e) => ({ ...e, isSelected: false }));
      setTrips(newArr.reverse());
      // setOrigin({
      //   lat: newArr[0].startCoordinates.latitude,
      //   lng: newArr[0].startCoordinates.longitude,
      // });
      // setDestination({
      //   lat: newArr[newArr.length - 1].endCoordinates.latitude,
      //   lng: newArr[newArr.length - 1].endCoordinates.longitude,
      // });
    }
  };
  const [timelineSelections, setTimelineSelections] = useState("");
  const handleChangetripIcons = (val) => {
    if (val === "1") {
      setTimelineSelections("1");
    } else if (val === "3") {
      setTimelineSelections("3");
    }
  };

  useEffect(() => {
    handleChangetripIcons("3");
  }, []);

  function getTime(date) {
    const dateObj = new Date(Number(date));
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const seconds = dateObj.getSeconds();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // convert to 12-hour format and handle midnight (0
    // const formattedDate = `${day}/${month}/${year}`;
    const formattedTime = `${formattedHours}:${minutes}:${seconds} ${ampm}`;
    return formattedTime;
  }

  function getDate(date) {
    const dateObj = new Date(date);
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth() + 1; // add 1 since getMonth() returns 0-based month
    const day = dateObj.getDate();
    const formattedDate = `${month}/${day}/${year}`;
    return formattedDate;
  }
  const Notification = () => {
    return (
      <Grid
        lg={12}
        xs={12}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        {
          current?.length > 0 && trackerDetailData.TrackerName == current[0]?.tracker[0]?.TrackerName ?
            <Grid lg={12} xs={12} container>
              <h4 className="textBlack InterSm">Notification List</h4>
            </Grid>
            :
            <font className="textGray1 InterRg">
              No Notifications Data Available
            </font>
        }

        {current !== undefined ? (
          <>
            {
              // current.map((val) => {
              //   return 
              current[0]?.tracker.map((row, i) => {
                return trackerDetailData.TrackerName === row.TrackerName ? (
                  <Grid
                    lg={12}
                    xs={12}
                    key={i}
                    container
                    direction="column"
                    className="borderBox w-100 mt-3 px-3 py-3"
                  >
                    <Grid
                      lg={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                    >
                      <Stack direction="column" className="w-100" spacing={2}>
                        <h6 className="textBlack InterRg m-0">
                          {row.Notification[0]?.alertName}
                        </h6>
                        {/* <font className="textGray1 InterRg">
                          {val.updatedOn}
                        </font> */}
                        {
                          row.Notification[0]?.sensor.map((sensor, p) => {
                            return (
                              <Grid lg={12} xs={12} container key={p}>
                                <font className="textGray1 InterRg">
                                  Event {p + 1} -
                                  <small className="textBlack InterRg"> {sensor.type == "temp" ? "Temperature" : sensor.type} {sensor.tempNotify}  {sensor.temperatures}
                                  </small>
                                </font>
                              </Grid>
                            )
                          })
                        }

                        <Grid lg={12} xs={12} container>
                          <font className="textGray1 InterRg">
                            Notified via Email -{" "}
                            <small className="textBlack InterRg">
                              {row.Notification[0]?.mail}
                            </small>
                          </font>
                        </Grid>
                        <Grid lg={12} xs={12} container>
                          <font className="textGray1 InterRg pt-3">
                            Notified via Phone -{" "}
                            <small className="textBlack InterRg">
                              {row.Notification[0]?.phone}
                            </small>
                          </font>
                        </Grid>
                      </Stack>
                    </Grid>
                  </Grid>
                ) : (
                  ""
                );
              })
              // })
            }
          </>
        ) : (
          <>
            <Grid
              lg={12}
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
              sx={{ padding: "8%" }}
            >
              <font className="textGray1 InterRg">
                No Notifications Data Available
              </font>
            </Grid>
          </>
        )}
      </Grid>
    );
  };

  const getTripHistory = (row, index) => {
    // setOrigin({
    //   lat: row.startCoordinates.latitude,
    //   lng: row.startCoordinates.longitude,
    // });
    // setDestination({
    //   lat: row.endCoordinates.latitude,
    //   lng: row.endCoordinates.longitude,
    // });
    trips.map((e, i) => {
      if (i === index) {
        trips[i].isSelected = true;
      } else {
        trips[i].isSelected = false;
      }
    });
    setTrips(trips);
  };
  const DeliveryDetails = () => {
    return (
      <Grid
        lg={12}
        xs={12}
        container
        justifyContent="space-between"
        alignItems="center"
        className="mb-3"
      >
        <Grid lg={12} xs={12} container>
          <h4 className="textBlack InterSm">Delivery Details</h4>
        </Grid>
        {ShipmentData !== undefined ? (
          <>
            {ShipmentData?.map((row, i) => {
              return (
                <>
                  <Grid lg={12} xs={12} key={i} container className="pt-3">
                    <font className="textBlack InterSm">{ }</font>
                  </Grid>
                  <>

                    {row.trip?.map((trip, i) => {
                      return (
                        <Grid
                          lg={12}
                          xs={12}
                          key={i}
                          container
                          className={`borderBox px-3  mt-1 ${row.isSelected === true ? "colorChange" : ""} `}
                          direction="column"
                          sx={{ cursor: "pointer" }}
                          onClick={() => getTripHistory(row, i)}
                        >
                          <Stack direction="column" className="w-100 mt-3">
                            <Grid
                              lg={12}
                              xs={12}
                              container
                              justifyContent="space-between"
                            >
                              <small className="textBlack InterRg m-0">
                                Trip {i + 1}
                              </small>
                              {/* <small className="textBlack InterRg">
                              {formatter.format(
                                row.distanceMeters * 0.000621371
                              )}{" "}
                              mi
                            </small> */}
                            </Grid>
                            <Timeline
                              sx={{
                                [`& .${timelineItemClasses.root}:before`]: {
                                  flex: 0,
                                  padding: 0,
                                },
                              }}
                            >
                              <TimelineItem style={{ minHeight: "40px" }}>
                                <TimelineSeparator>
                                  <RiRecordCircleFill
                                    size={20}
                                    color={
                                      timelineSelections === "1"
                                        ? "rgba(66, 112, 235, 1)"
                                        : "rgba(153, 153, 153, 1)"
                                    }
                                    className="pointer"
                                    onClick={() => handleChangetripIcons("1")}
                                  />
                                  <TimelineConnector
                                    sx={{ backgroundColor: "rgba(0, 0, 0, 1)" }}
                                  />
                                </TimelineSeparator>
                                <Grid
                                  lg={12}
                                  xs={12}
                                  container
                                  direction="column"
                                  className="pl-2"
                                >
                                  <small className="textBlack InterSm">
                                    {trip.startAddress}
                                  </small>
                                  <small className="textGray1 InterRg">
                                    Start Date {getDate(trip.startDate)} (
                                    {getTime(trip.startDate)})
                                  </small>
                                </Grid>
                              </TimelineItem>
                              <TimelineItem style={{ minHeight: "10px" }}>
                                <TimelineSeparator>
                                  <IoLocationSharp
                                    size={22}
                                    color={
                                      timelineSelections === "3"
                                        ? "rgba(66, 112, 235, 1)"
                                        : "rgba(153, 153, 153, 1)"
                                    }
                                    className="pointer"
                                    onClick={() => handleChangetripIcons("3")}
                                  />
                                </TimelineSeparator>
                                <Grid
                                  lg={12}
                                  xs={12}
                                  container
                                  direction="column"
                                  className="pl-2"
                                >
                                  <small className="textBlack InterSm">
                                    {trip.endAddress}
                                  </small>
                                  <small className="textGray1 InterRg">
                                    End Date {getDate(trip.endDate)} (
                                    {getTime(trip.endDate)})
                                  </small>
                                </Grid>
                              </TimelineItem>
                            </Timeline>
                            {/* <ShipmentsTimeline shipmentDetailsData={row} /> */}
                          </Stack>
                        </Grid>
                      )
                    })}
                  </>
                </>
              );
            })}
          </>
        ) : (
          <>
            <Grid
              lg={12}
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
              sx={{ padding: "8%" }}
            >
              <font className="textGray1 InterRg">
                No Delivery Details Available
              </font>
            </Grid>
          </>
        )}
      </Grid>
    );
  };
  const processedData = useMemo(() => {
    return <TrackerMap
      open={true}
      setOpen={setProgress}
      width={"50%"}
      arrow={false}
      currentlocation={currentlocation}
      // LiveData={LiveData}
      origin={origin}
      destination={destination}
      travelMode="DRIVING"
      wraped={"true"}
    />;
  }, [destination, currentlocation, origin, LiveData]);
  return (
    <>
      {progress === false ? (
        <Grid
          lg={6}
          xs={6}
          container
          sx={{ padding: "2%", width: progress === true ? "50%" : "100%" }}
        >
          <Stack direction="column" className="w-100">
            <Grid
              lg={12}
              xs={12}
              container
              justifyContent="space-between"
              alignItems="center"
              flexDirection="row"
            >
              <Grid
                lg={12}
                xs={12}
                container
                justifyContent="flex-start"
                alignItems="center"
              >
                <BsFillArrowLeftSquareFill
                  size={30}
                  color={"#AE059E"}
                  className="pointer"
                  onClick={() => setViewPage(false)}
                />
              </Grid>
              <Grid lg={6} flexDirection="column" className="mt-3">
                <h3 className="textBlack InterSm m-0">
                  {trackerDetailData.TrackerName}
                </h3>
                <font className="text-muted" style={{ fontSize: "12px" }}>
                  Tracker Serial : {trackerDetailData.TrackerSerial}
                </font>
              </Grid>
              <Grid lg={4} xs={4}>
                <Button
                  onClick={() => setEditTrack(true)}
                  disabled={trackerDetailData.ShipmentAssign}
                  className="btn  py-2"
                >
                  Tracker settings
                </Button>
              </Grid>
              <Grid lg={12} xs={12} className="w-100 py-3">
                {loader ? (
                  <Grid
                    lg={12}
                    xs={12}
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    padding={4}
                    sx={{
                      overflow: "auto",
                      border: "1px solid #8C9196",
                      borderRadius: "7px",
                    }}
                  >
                    <Grid lg={5} container justifyContent="center" alignItems="center">
                      <h3 className="textBlue mt-3"> Loading...</h3>
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    <Grid
                      lg={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid lg={6} xs={6} container direction="column" className="mb-4">
                        <font className="textGray1 InterRg">Device Unique id</font>
                        <small className="text-dark InterMd mt-2">
                          {deviceInfo?.device_unique_id}
                        </small>
                      </Grid>
                      <Grid lg={6} xs={6} container direction="column" className="mb-4">
                        <font className="textGray1 InterRg">Device Type</font>
                        <small className="text-dark InterMd mt-2">
                          {deviceInfo?.device_type}
                        </small>
                      </Grid>
                      <Grid lg={6} xs={6} container direction="column" className="mb-4">
                        <font className="textGray1 InterRg">Firmware Version</font>
                        <small className="text-dark InterMd mt-2">
                          {deviceInfo?.firmware_version}
                        </small>
                      </Grid>
                      {/* <Grid lg={6} xs={6} container direction="column" className="mb-4">
                    <font className="textGray1 InterRg">Ip Address</font>
                    <small className="text-dark InterMd mt-2">
                      {deviceInfo?.ip_address}
                    </small>
                  </Grid> */}
                      <Grid lg={6} xs={6} container direction="column" className="mb-4">
                        <font className="textGray1 InterRg">Mac Address</font>
                        <small className="text-dark InterMd mt-2">
                          {deviceInfo?.mac_address}
                        </small>
                      </Grid>
                      <Grid lg={5} xs={5} container direction="column">
                        <font className="textGray1 InterRg">Current Location</font>
                        <small className="text-dark InterMd mt-2">
                          {" "}
                          {/* {trackerDetailData.location} */}
                          {/* {LiveData} */}
                          {currentLocation}
                        </small>
                      </Grid>
                      <Grid
                        lg={6}
                        xs={6}
                        container
                        direction="column"
                        justifyContent="space-between"
                      >
                        <font className="textGray1 InterRg">Battery</font>
                        {
                          trackerDetailData.Battery && (
                            <div className="d-flex p-0 mt-2">
                              <div className="btry_pos ">
                                <BatteryGauge
                                  style={{ height: "20px" }}
                                  customization={
                                    trackerDetailData.Battery < 20
                                      ? customization
                                      : trackerDetailData.Battery < 50
                                        ? customization1
                                        : trackerDetailData.Battery < 100
                                          ? customization2
                                          : customization2
                                  }
                                  size={100}
                                  // value={50}
                                  value={trackerDetailData.Battery}
                                />
                              </div>
                              <small className="text-dark InterMd">
                                {/* 50 % (23 days, 13 hours left) */}
                                {/* {trackerDetailData.Battery} % (23 days, 13 hours left) */}
                                {trackerDetailData.Battery} %
                              </small>
                            </div>
                          )
                        }
                      </Grid>
                    </Grid>
                    <Grid
                      lg={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      className="mt-4"
                    >
                      <Grid lg={8} xs={8} container direction="column">
                        <font className="textGray1 InterRg">Update Interval</font>
                        <small className="text-dark InterMd mt-2">
                          {""}
                          30 Minutes</small>
                      </Grid>
                    </Grid>
                    <Grid
                      lg={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      className="mt-3"
                    >
                      <Grid lg={5} xs={5} container direction="column">
                        <font className="textGray1 InterRg">Tracker Status</font>
                        <small className="textBlue InterMd">
                          {" "}
                          {trackerDetailData.ShipmentAssign ? "Assigned" : "Unassigned"}
                        </small>
                      </Grid>
                      {
                        trackerDetailData.ShipmentAssign ?
                          <Grid
                            lg={6}
                            xs={6}
                            container
                            direction="column"
                            justifyContent="space-between"
                          >
                            <font className="textGray1 InterRg">Shipment Assigned</font>
                            <div className="d-flex p-0 ">
                              <small className="textBlue InterMd" role="presentation" onClick={() => navigate("/shipments")}>
                                {trackerDetailData.TrackerData.length > 1 ? (
                                  <u> {trackerDetailData.TrackerData[1].shipmentid} </u>
                                ) : (
                                  ""
                                )}
                              </small>
                            </div>
                          </Grid>
                          :
                          null
                      }

                    </Grid>
                    <Grid
                      lg={12}
                      xs={12}
                      container
                      justifyContent="flex-start"
                      alignItems="center"
                      className="mt-4"
                    >
                      <Grid
                        lg={8}
                        xs={10}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Button
                          className={
                            sensorsType === "sensors"
                              ? "linkBgcolorinshipments"
                              : "linkBgcolorinshipments1"
                          }
                          onClick={() => handleChange("sensors")}
                        >
                          Sensors
                        </Button>
                        <Button
                          className={
                            sensorsType === "notifications"
                              ? "linkBgcolorinshipments"
                              : "linkBgcolorinshipments1"
                          }
                          onClick={() => handleChange("notifications")}
                        >
                          Notifications
                        </Button>
                        <Button
                          className={
                            sensorsType === "details"
                              ? "linkBgcolorinshipments"
                              : "linkBgcolorinshipments1"
                          }
                          onClick={() => handleChange("details")}
                        >
                          Details
                        </Button>
                      </Grid>
                      <Grid className="py-5"></Grid>
                      {sensorsType === "sensors" ? (
                        <>
                          <Grid
                            lg={12}
                            xs={12}
                            container
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid lg={12} xs={12} container>
                              <h4 className="textBlack InterSm">Sensors</h4>
                            </Grid>
                            {current.length === 0 ? (
                              <>
                                <Grid
                                  lg={12}
                                  xs={12}
                                  container
                                  justifyContent="center"
                                  alignItems="center"
                                  sx={{ padding: "8%" }}
                                >
                                  <font className="textGray1 InterRg">
                                    No Sensor Data Available
                                  </font>
                                </Grid>
                              </>
                            ) : (
                              current.map((val, i) => {
                                return (
                                  <>
                                    <Grid
                                      lg={5.6}
                                      xs={5.6}
                                      key={i}
                                      container
                                      direction="column"
                                      className="mt-3"
                                    >
                                      <Grid
                                        lg={12}
                                        xs={12}
                                        container
                                        justifyContent="space-between"
                                        alignItems="center"
                                      >
                                        <font className="textGray1 InterRg">
                                          <font className="textGray1 InterRg">{val.type} ({val.type === "Temperature" ? "°F" : val.type === "Humidity" ? "%" : val.type === "Light" ? " lux " : val.type === "Shock" ? " G " : ""}) </font>
                                        </font>
                                        <SlArrowRight
                                          color={"#000"}
                                          className="pointer"
                                          onClick={() => {
                                            setOpenChart(true);
                                            setChartData(val);
                                          }}
                                        />
                                      </Grid>
                                      {current.length !== 0 ? (
                                        <>
                                          <h6 className="textBlack InterSm m-0 pt-3">
                                            <h6 className="textBlack InterSm m-0 ">{val.current}{val.type === "Temperature" ? "°F" : val.type === "Humidity" ? "%" : val.type === "Light" ? " lux " : val.type === "Shock" ? " G " : ""} {val.updateTemperature} <small className="textGray1 InterRg pl-2"> (Last update {val.Time[val.Time.length - 1] ? getDate(val.Time[val.Time.length - 1]) : ""} {val.Time[val.Time.length - 1] ? getTime(val.Time[val.Time.length - 1]) : ""})</small></h6>
                                            <small className="textGray1 InterRg pl-2">
                                              {/* ({val.updateTemperature}) */}
                                            </small>
                                          </h6>
                                          <Grid
                                            lg={12}
                                            xs={12}
                                            container
                                            justifyContent="space-between"
                                            alignItems="center"
                                            className=""
                                          >
                                            <Grid lg={4} xs={4} container>
                                              <font className="textGray1 InterRg">
                                                Min
                                              </font>
                                              <font className="textBlack InterRg pl-2">
                                                {val.min}
                                              </font>
                                            </Grid>
                                            <Grid
                                              lg={4}
                                              xs={4}
                                              container
                                              justifyContent="center"
                                            >
                                              <font className="textGray1 InterRg">
                                                Max
                                              </font>
                                              <font className="textBlack InterRg pl-2">
                                                {val.max}
                                              </font>
                                            </Grid>
                                            <Grid
                                              lg={4}
                                              xs={4}
                                              container
                                              justifyContent="flex-end"
                                            >
                                              <font className="textGray1 InterRg">
                                                Avg
                                              </font>
                                              <font className="textBlack InterRg pl-2">
                                                {String(val.avg).slice(0, 4)}
                                              </font>
                                            </Grid>
                                          </Grid>
                                          <div className="borderBox shipmentsBorderBoxincharts mt-3">
                                            <Linecharts value={val.value} Mesurements={val} />
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <Grid
                                            lg={12}
                                            xs={12}
                                            container
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{ padding: "8%" }}
                                          >
                                            <font className="textGray1 InterRg">
                                              No Sensor Data Available
                                            </font>
                                          </Grid>
                                        </>
                                      )}
                                    </Grid>
                                  </>
                                );
                              })
                            )}
                          </Grid>
                        </>
                      ) : sensorsType === "notifications" ? (
                        <>
                          <Notification />
                        </>
                      ) : sensorsType === "trackers" ? (
                        <></>
                      ) : sensorsType === "details" ? (
                        <>
                          <DeliveryDetails />
                        </>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid lg={6} md={6} xs={6}>
              {
                processedData
              }
            </Grid>
          </Stack>
        </Grid>
      ) : (
        <Grid lg={12} md={12} xs={12}>
          {
            processedData
          }
        </Grid>
      )}
      <EditTrackers
        setOpen={setEditTrack}
        open={EditTrack}
        EditTrack={trackerDetailData}
        getMyTrackersLive={getMyTrackersLive}
      />
      <ChartsViewsModal
        openChart={openChart}
        setOpenChart={setOpenChart}
        ChartData={ChartData}
      />
    </>
  );
};

export default TrackerDetails;
