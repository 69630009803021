import { applyMiddleware,createStore } from "redux";
import { combineReducers } from "redux";
import thunk from "redux-thunk";
import WalletConnect from "./Reducer";
import { persistStore , persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"

const persistconfig = {
  key:"persist-Key-flyde",
  storage
}
const rootReducer = combineReducers({
  WalletConnect
});

const persistedReducer =  persistReducer (persistconfig ,rootReducer);
const store=createStore(persistedReducer ,applyMiddleware(thunk));
const persistor = persistStore(store)

// export default store;
export default store ;
export {persistor};

// const middleware = [thunk];
// const composeEnhancers = compose(applyMiddleware(...middleware));

// const configureStore = () => {
//   return createStore(rootReducer, composeEnhancers);
// };

// const store = configureStore();

