import React, { useState, useEffect } from "react";
import { Grid, Button, Stack } from "@mui/material";
import { CgSearch } from "react-icons/cg";
import TrackersData from "../../components/table/trackerTable/trackerTable.js";
import Map from "../../components/sidebar/map.js";
import TrackerDetails from "./trackerDetails";
import ActivateTracker from "../../components/modal/ActivateTracker";
import { useSelector } from "react-redux";
import { getMethod, postMethod } from "../../apis";
import getHeaders from "../../services/index.js";
const Trackers = () => {
  const userData = useSelector((state) => state.WalletConnect.userData);
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(false);
  const [trackerDetailData, settrackerDetailData] = useState("");
  const [viewPage, setViewPage] = useState(false);
  const [myTrackers, setMyTrackers] = useState([]);
  const [TrackersTable, setTrackersTable] = useState([]);
  const [trackerstable, settrackerstable] = useState([]);
  const [NoDatas, setNoDatas] = useState(false);
  const [loader, setLoader] = useState(true);
  const [LiveData, setLiveData] = useState("");
  const [currentLocation, setcurrentLocation] = useState([])
  const headers = getHeaders();
  useEffect(() => {
    getLiveGps();
    setProgress(false);
    getMyTrackers();
    getMyTrackersLive();
    setTimeout(() => {
      setNoDatas(true);
    }, 3000);
  }, []);
  const getMyTrackers = async () => {
    let params = {
      email: userData.email,
    };
    let url = "api/tracker/viewmypurchaseTracker";
    const response = await postMethod({ url, params, headers });
    if (response.status === true) {
      const PendingTracker = response.result.filter((e) => e.ActivationStatus === "pending" && e.BuyerEmail === userData.email);
      setMyTrackers(PendingTracker);
    }
  };
  const getMyTrackersLive = async () => {
    let url = "api/tracker/liveTrackers";
    let params = {
      email: userData.email,
    };
    const response = await postMethod({ url, params, headers });
    if (response.status === true) {
      const liveTracker = response.result.filter((e) => e.ActivationStatus === "Activated" && e.BuyerEmail === userData.email);
      console.log("location",liveTracker)
      let newTrackers = []
      liveTracker.map((location) => {
        if (location.currentLocation) {
          var data = location.currentLocation
          data = String(data).split(",")
          newTrackers.push({ lat: data[0], lng: data[1],Name: location.TrackerName,TrackerSerial:location.TrackerSerial})
        }
      })
      if (newTrackers.length > 0) {
        setcurrentLocation(newTrackers)
      }
      // 
      setTrackersTable(liveTracker);
      settrackerstable(liveTracker)
      setLoader(false);
    }
  };

  const getLiveGps = async () => {
    let url = "api/traacker/getLiveGps";
    const response = await getMethod({ url, headers });
    if (response.status === true) {
      setLiveData(response.result.data[0]?.location?.reverseGeo?.formattedLocation)
    }
  };
  const searchtracker = async (e) => {
    if (e != "") {
      let result = trackerstable.filter(item => item.TrackerName.toLowerCase().includes(e.toLowerCase()))
      setTrackersTable(result)
    } else {
      setTrackersTable(trackerstable)
    }
  }
  return (
    <>
      {viewPage === false ? (
        <>
          {progress === false ? (
            <Grid
              lg={6}
              md={6}
              xs={12}
              container
              sx={{ padding: "2%", width: progress === true ? "50%" : "100%" }}
            >
              <Stack direction="column" className="w-100" spacing={4}>
                <Grid
                  lg={12}
                  md={12}
                  xs={6}
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <h5 className="textBlack InterSm mt-2">Trackers</h5>
                  <Grid lg={4} md={6} xs={12} container>
                    {open === false ? (
                      <>
                        <Button
                          className="btn  py-2"
                          onClick={() => setOpen(true)}
                        >
                          Activate New Tracker
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button className="btn py-2 mt-2">
                          Activate New Tracker
                        </Button>
                      </>
                    )}
                  </Grid>
                  <Grid
                    lg={12}
                    md={12}
                    xs={12}
                    container
                    justifyContent="space-between"
                  >
                    <div className="inputBox1  mt-2 px-2 py-1 h-75  d-flex align-items-center">
                      {open === false ? (<>
                        <input
                          className="shipmentsSearchbar w-100 "
                          placeholder="Search"
                          onChange={(e) => searchtracker(e.target.value)}
                        />
                      </>) : (
                        <>
                          <input
                            className="shipmentsSearchbar w-100 "
                            placeholder="Search"
                            onChange={(e) => searchtracker(e.target.value)}
                          />
                        </>)
                      }

                      <CgSearch size={22} color={"#999999"} />
                    </div>
                  </Grid>
                  <Grid lg={12} md={12} xs={12} className=" w-100 mt-2">
                    <TrackersData
                      TrackersTable={TrackersTable}
                      setViewPage={setViewPage}
                      settrackerDetailData={settrackerDetailData}
                      userData={userData}
                      NoDatas={NoDatas}
                      loader={loader}
                      LiveData={LiveData}
                    />
                  </Grid>
                </Grid>
                <Grid lg={6} md={6} xs={12}>
                  <Map
                    open={true}
                    setOpen={setProgress}
                    width={"50%"}
                    arrow={false}
                    LiveData={LiveData}
                    lats={currentLocation}
                  // lans={currentLan}
                  />
                </Grid>
              </Stack>
            </Grid>
          ) : (
            <Grid lg={12} md={12} xs={12}>
              <Map
                open={true}
                setOpen={setProgress}
                width={"100%"}
                arrow={true}
                LiveData={LiveData}
                lats={currentLocation}
              // lans={currentLan}
              />
            </Grid>
          )}
        </>
      ) : (
        <TrackerDetails
          setViewPage={setViewPage}
          trackerDetailData={trackerDetailData}
          LiveData={LiveData}
          getMyTrackersLive={getMyTrackersLive}
        />
      )}
      <ActivateTracker
        setOpen={setOpen}
        open={open}
        myTrackers={myTrackers}
        userData={userData}
        getMyTrackers={getMyTrackers}
        getMyTrackersLive={getMyTrackersLive}
      />
    </>
  );
};

export default Trackers;
