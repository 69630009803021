import React, { useState, useEffect } from "react";
import { Grid, Modal, Stack, Button } from "@mui/material";
import { CgClose } from "react-icons/cg";
import "./modal.css";
import { TbDownload } from "react-icons/tb";
import { RiFileUploadLine } from "react-icons/ri";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { getMethod, postMethod } from "../../apis";
// import TrackerTemplate from "../../assets/images/registerPageImages/TrackerWhitelistTemplate.xlsx";
import { useSelector } from "react-redux";
import readXlsxFile from "read-excel-file";
import Select from "react-select";
const WhitelistTrackers = ({ open, setOpen, getWhitelistedTrackers, getMyTrackersLive }) => {
  const count = useSelector((state) => state.WalletConnect);

  const [AllDevice, setAllDevice] = useState([]);

  const [uploadBtn, setUploadBtn] = React.useState(false);
  const [openSuccessfully, setOpenSuccessFully] = useState(false);
  const [openFailedModal, setOpenFailedModal] = useState(false);
  const [trackerName, setTrackerName] = useState("");
  const [trackerModel, setTrackerModel] = useState("");
  const [trackerSerial, setTrackerSerial] = useState("");
  const [buyerEmail, setBuyerEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [changeBtns, setChangeBtns] = useState(false);
  const [EmailData, setEmailData] = useState([]);
  const [getUserCalled, setgGetUserCalled] = useState(false)

  const trackerModelList = [
    { label: "Air Fright Device", value: "Air Fright Device" },
    { label: "Strip Device", value: "Strip Device" },
    { label: "Flexible Strip Device", value: "Flexible Strip Device" },
  ]

  const hiddenFileInput = React.useRef(null);
  useEffect(() => {
    if (!getUserCalled) {
      getAvailableDevice();
      getAllUser();
      setgGetUserCalled(true);
    }
  })

  const getAvailableDevice = async () => {
    let url = "api/iot/getAvailableDevice"
    let headers = {
      "Content-Type": "application/json",
      "x-access-token": count?.userData?.accessToken,
      "Access-Control-Allow-Origin": "*",
    };
    const response = await getMethod({ url, headers })
    if (response && response.status) {
      let newArr = [];
      response.result.map((e) => {
        newArr.push({ label: e.unique_id, value: e.device_unique_id })
      })
      setAllDevice(newArr)
    }
  }

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const [csvFileData, setCSVfileData] = useState([]);

  const handleChange = async () => {
    const fileUploaded = event.target.files[0];
    setUploadBtn(true);
    readXlsxFile(fileUploaded).then((rows) => {
      tablefileRead(rows);
    });
    setChangeBtns(true);
  };

  const getAllUser = async () => {
    let url = "api/user/viewAll"
    let headers = {
      "Content-Type": "application/json",
      "x-access-token": count?.userData?.accessToken,
      "Access-Control-Allow-Origin": "*",
    };
    const response = await getMethod({ url, headers })
    let newArr = [];
    response.result.map((e) => {
      newArr.push({ label: e.email, value: e.email })
    })
    setEmailData(newArr)
  }

  const tablefileRead = (data) => {
    let arr = [];
    for (let i = 1; i <= data.length - 1; i++) {
      let val = {
        TrackerModel: data[i][0],
        TrackerSerial: data[i][1],
        BuyerEmail: data[i][2],
        ActivationStatus: "pending",
        ActivatedOn: "",
        status: true,
      };
      arr.push(val);
    }
    setCSVfileData(arr);
  };

  const addWhitelistCsv = async () => {
    try {
      setLoading(true);
      const params = csvFileData;
      let headers = {
        "Content-Type": "application/json",
        "x-access-token": count?.userData?.accessToken,
        "Access-Control-Allow-Origin": "*",
      };
      let url = "api/tracker/uploadMultiple";
      let response = await postMethod({ url, params, headers });
      if (response.status === false) {
        setOpenFailedModal(true);
        setLoading(false);
      } else {
        getWhitelistedTrackers();
        setLoading(false);
        setOpen(false);
        setCSVfileData([])
        setOpenSuccessFully(true);
        getMyTrackersLive();
        getAvailableDevice();
      }
    } catch (err) {
      console.log("Error in whitelisted", err);
    }
  };

  const style1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255, 255, 255, 1)",
    boxShadow: "0px 1px 13px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    width: "100%",
    padding: "2%",
  };

  const checkValidation = () => {
    let emailValidation = /\S+@\S+\.\S+/;
    {
      trackerModel === ""
        ? toast.warn("Tracker Model Can't Be Empty")
        : trackerSerial === ""
          ? toast.warn("Tracker Serial Can't Be Empty")
          : buyerEmail === ""
            ? toast.warn("Buyer Email Can't Be Empty")
            : !emailValidation.test(buyerEmail)
              ? toast.warn("Enter valid Email")
              : addwhitlisted();
    }
  };
  const addwhitlisted = async () => {
    try {
      setLoading(true);
      const params = {
        TrackerName: trackerName,
        TrackerModel: trackerModel,
        TrackerSerial: trackerSerial,
        BuyerEmail: buyerEmail
      };
      let headers = {
        "Content-Type": "application/json",
        "x-access-token": count?.userData?.accessToken,
        "Access-Control-Allow-Origin": "*",
      };
      let url = "api/tracker/whitelist";
      let response = await postMethod({ url, params, headers });
      if (response.message === "existing serial number") {
        toast.warn("Existing Serial Number");
        setLoading(false);
      } else if (response.message === "tracker whitelist failed") {
        toast.warn("Try Again");
        setLoading(false);
      } else if (response.message === "tracker whielisted") {
        setLoading(false);
        setOpen(false);
        setUploadBtn(false);
        setTrackerName("");
        setTrackerModel("");
        setTrackerSerial("");
        setBuyerEmail("");
        getWhitelistedTrackers();
        setOpenSuccessFully(true);
        getMyTrackersLive();
        getAvailableDevice();
      }
    } catch (err) {
      console.log("Error in whitelisted", err);
    }
  };

  // const onButtonClick = () => {
  //   fetch(TrackerTemplate).then((response) => {
  //     response.blob().then((blob) => {
  //       const fileURL = window.URL.createObjectURL(blob);
  //       let alink = document.createElement("a");
  //       alink.href = fileURL;
  //       alink.download = "Tracker Template.xlsx";
  //       alink.click();
  //     });
  //   });
  // };

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="center">
          <Grid lg={6} md={6} sm={8} xs={11} container sx={style1}>
            <Grid
              lg={12}
              md={12}
              sm={12}
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Grid lg={11} md={11} xs={11}>
                <h4 className="textBlack InterBd m-0">Whitelist Tracker</h4>
              </Grid>
              <Grid lg={1} md={1} xs={1}>
                <CgClose
                  size={32}
                  color={"rgba(0, 0, 0, 1)"}
                  className="pointer"
                  onClick={() => {
                    setOpen(false);
                    setUploadBtn(false);
                  }}
                />
              </Grid>
            </Grid>
            <Grid lg={12} md={12} sm={12} xs={12} container>
              <Stack direction="row" spacing={2} className="w-100 mt-4">
                <Grid lg={6} xs={12} container>
                  <font className="textBlack InterMd pt-2">Tracker Name</font>
                  <input
                    className="inputBox1 py-2"
                    placeholder="Enter Tracker Name"
                    onChange={(e) => setTrackerName(e.target.value)}
                  />
                </Grid>
                <Grid lg={6} xs={12} container>
                  <font className="textBlack InterMd pt-2">Tracker Model</font>
                  <Select
                    className="w-100 py-2"
                    isSearchable={true}
                    onChange={(e) => setTrackerModel(e.value)}
                    options={trackerModelList}
                  />
                </Grid>
              </Stack>
              <Stack direction="row" spacing={2} className="w-100 mt-4">
                <Grid lg={6} xs={12} container>
                  <font className="textBlack InterMd">Tracker Serial</font>
                  <Select
                    className="w-100 py-2"
                    isSearchable={true}
                    onChange={(e) => setTrackerSerial(e.value)}
                    options={AllDevice}
                  />
                </Grid>
                <Grid lg={6} xs={12} container>
                  <font className="textBlack InterMd">Buyer Email</font>
                  <Select
                    className="w-100 py-2"
                    // isLoading={true}
                    isSearchable={true}
                    onChange={(e) => setBuyerEmail(e.value)}
                    options={EmailData}
                  />
                </Grid>
              </Stack>
              <Stack direction="column" spacing={2} className="w-100 mt-4">
                <Grid lg={12} xs={12} container justifyContent="center">
                  <font className="textBlack InterMd">
                    or Upload More Trackers in CSV Format
                  </font>
                </Grid>
                <Grid
                  lg={12}
                  xs={12}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <div
                    className="pointer"
                    role="presentation"
                  // onClick={() => onButtonClick()}
                  >
                    <a
                      href="https://fitmintwear.s3-ap-southeast-2.amazonaws.com/profile/WhitelistTemplate_1683521301324.xlsx"
                      download
                    >
                      <TbDownload size={24} color={"#AE059E"} />
                      <font className="textBlue InterMd pl-1">
                        Download Template
                      </font>
                    </a>
                  </div>
                </Grid>
                <Grid
                  lg={12}
                  xs={12}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  {uploadBtn === false ? (
                    <>
                      <button
                        className="btnTransparent d-flex justify-content-center align-items-center w-50 py-2"
                        onClick={handleClick}
                      >
                        <RiFileUploadLine
                          size={22}
                          color={"#000"}
                          className="ml-1"
                        />
                        <small>Upload Trackers List</small>
                      </button>
                      <input
                        type="file"
                        ref={hiddenFileInput}
                        onChange={handleChange}
                        accept={".csv,.pdf,.xlsx"}
                        style={{ display: "none" }}
                      />
                    </>
                  ) : (
                    <>
                      <button
                        className="btnTransparent d-flex justify-content-center align-items-center w-50 py-2"
                        onClick={handleClick}
                      >
                        <RiFileUploadLine
                          size={22}
                          color={"#AE059E"}
                          className="ml-1"
                        />
                        <small
                          className="textBlue"
                          style={{ borderBottom: "1px solid #AE059E" }}
                        >
                          Tracker Template.CSV
                        </small>
                      </button>
                      <input
                        type="file"
                        ref={hiddenFileInput}
                        onChange={handleChange}
                        accept={".csv,.pdf,.xlsx"}
                        style={{ display: "none" }}
                      />
                    </>
                  )}
                </Grid>
                <Grid
                  lg={12}
                  xs={12}
                  container
                  justifyContent="center"
                  alignItems="center"
                  className="mt-3"
                >
                  <Button
                    className="btn shipmentsbtnspadding"
                    onClick={
                      changeBtns === false ? checkValidation : addWhitelistCsv
                    }
                  >
                    {loading ? (
                      <>
                        <Spinner animation="border" variant="light" />
                      </>
                    ) : (
                      <>Whitelist</>
                    )}
                  </Button>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Modal >
      <Modal
        open={openSuccessfully}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="center">
          <Grid lg={4} md={6} sm={8} xs={11} container sx={style1}>
            <Grid lg={12} xs={12} container>
              <Grid
                lg={11}
                md={11}
                sm={11}
                xs={11}
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <h4 className="textBlack InterBd m-0">
                  Trackers Whitelisted Successfully!
                </h4>
              </Grid>
              <Grid lg={1} md={1} xs={1}>
                <CgClose
                  size={32}
                  color={"rgba(0, 0, 0, 1)"}
                  className="pointer"
                  onClick={() => {
                    setOpenSuccessFully(false);
                  }}
                />
              </Grid>
            </Grid>
            <Grid lg={12} xs={12} container className="pt-3">
              <font className="textBlack InterMd">
                The trackers have been successfully whitelisted, and now they
                are available for activation by the buyers.
              </font>
            </Grid>
            <Grid
              lg={12}
              xs={12}
              container
              justifyContent="flex-end"
              alignItems="center"
              className="pt-3"
            >
              <Grid lg={4} xs={4} container>
                <Button
                  className="btn shipmentsbtnspadding"
                  onClick={() => {
                    setOpenSuccessFully(false);
                  }}
                >
                  OK
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <Modal
        open={openFailedModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="center">
          <Grid lg={4} md={6} sm={8} xs={11} container sx={style1}>
            <Grid
              lg={12}
              md={12}
              sm={12}
              xs={12}
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <h4 className="textBlack InterBd m-0">
                Trackers Whitelisting Failed!
              </h4>
              <CgClose
                size={32}
                color={"rgba(0, 0, 0, 1)"}
                className="pointer"
                onClick={() => {
                  setOpenFailedModal(false);
                }}
              />
            </Grid>
            <Grid lg={12} xs={12} container className="pt-3">
              <font className="textBlack InterMd">
                The trackers whitelisting has failed. Please check the uploaded
                CSV file and try uploading it again.
              </font>
            </Grid>
            <Grid
              lg={12}
              xs={12}
              container
              justifyContent="flex-end"
              alignItems="center"
              className="pt-3"
            >
              <Grid lg={4} xs={4} container>
                <Button
                  className="btn shipmentsbtnspadding"
                  onClick={() => {
                    setOpenFailedModal(false);
                  }}
                >
                  OK
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};
export default WhitelistTrackers;
