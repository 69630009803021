import React, { useState, useEffect } from "react";
import { Grid, Button, Stack, Badge } from "@mui/material";
import { CgSearch } from "react-icons/cg";
import OrganizationTable from "../../components/table/organizationTable/OrganizationTable";
import ApprovalRequestTable from "../../components/table/organizationTable/ApprovalRequestTable";
import CreateReportModal from "../../components/modal/GenerateNewReport";
// import { OrganizationData } from "../../helpers/data1";
import { getMethod } from "../../apis";
import { useSelector } from "react-redux";
import { FormatDate1 } from "../../helpers/index";
import { CSVLink } from "react-csv";
// import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
// import ReactToPdf from "react-to-pdf"
import { jsPDF } from "jspdf";
import "jspdf-autotable";

const Organization = () => {

  const count = useSelector((state) => state.WalletConnect)
  const ref = React.createRef();
  const [organizationsData, setOrganizationsData] = useState([]);
  const [organizationsData1, setOrganizationsData1] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [changeBtn, setChangeBtn] = useState("");
  const [organizationLoading, setOrganizationLoading] = useState(false);
  const [organizationssearchData, setOrganizationssearchData] = useState([]);
  const [organizationssearchData1, setOrganizationssearchData1] = useState([]);

  const handleSections = (check) => {
    if (check === "organizations") {
      setChangeBtn("organizations");
      setOrganizationLoading(true);
      getAdminDashBoardDetails();
    }
    else if (check === "request") {
      setChangeBtn("request");
      getApproveUsers();
    }
  }

  const getApproveUsers = async () => {
    try {
      let headers = {
        "Content-Type": "application/json",
        "x-access-token": count?.userData?.accessToken,
        "Access-Control-Allow-Origin": "*"
      };
      let url = "api/admin/organization/view";
      let response = await getMethod({ url, headers });
      setOrganizationsData1(response.message);
      setOrganizationssearchData1(response.message);
      setLoading(true);
    }
    catch (err) {
      console.log("Error in gerUsers", err);
    }
  }

  const getAdminDashBoardDetails = async () => {
    try {
      let headers = {
        "Content-Type": "application/json",
        "x-access-token": count?.userData?.accessToken,
        "Access-Control-Allow-Origin": "*"
      };
      let url = "api/admin/dashboard";
      let response = await getMethod({ url, headers });
      let result = response.result;
      if (result) {
        let newarr = [...new Set(result?.Organization)];
        let newarr1 = [...new Set(result?.Shipments)];
        let newarr2 = [...new Set(result?.Trackers)];
        let newOrg = [];
        newarr.map((items) => {
          var arr = [];
          var arr1 = [];
          newarr1.map((value) => {
            if (items._id === value.CompanyID) {
              arr.push(value);
            }
          })
          newarr2.map((value) => {
            if (items.email === value.BuyerEmail) {
              arr1.push(value);
            }
          })
          newOrg.push({ organization: items, shipments: arr, trackers: arr1 });
        });
        setOrganizationsData(newOrg);
        setOrganizationssearchData(newOrg);
        setOrganizationLoading(false);
      }
    }
    catch (err) {
      console.log("err in getUserDetails", err);
    }
  }

  const handleSearch = (e) => {
    var data = organizationssearchData.filter((res) => {
      return ((res.organization.organizationName).toLowerCase()).match((e).toLowerCase()) || ((res.organization.email).toLowerCase()).match((e).toLowerCase()) || ((res.organization.userName).toLowerCase()).match((e).toLowerCase()) || ((res.organization.status === false ? "Inactive" : "Active").toLowerCase()).match((e).toLowerCase()) || ((FormatDate1(res.organization.createdAt)).toLowerCase()).match((e).toLowerCase());
    });
    setOrganizationsData(data);
  }

  const handleSearch1 = (e) => {
    var data = organizationssearchData1.filter((res) => {
      return ((res.organizationName).toLowerCase()).match((e).toLowerCase()) || ((res.email).toLowerCase()).match((e).toLowerCase()) || ((res.organizationType).toLowerCase()).match((e).toLowerCase())  || ((FormatDate1(res.createdAt)).toLowerCase()).match((e).toLowerCase());
    });
    setOrganizationsData1(data);
  }

  const DownloadData = organizationsData.map((row) => ({
    OrganizationName: row.organization.organizationName,
    CurrentPlan: !row?.organization?.Plan[0]?.PlanName ? "-" : row?.organization?.Plan[0]?.PlanName,
    JoinedOn: row.organization.createdAt,
    ActiveTrackers: row?.trackers?.length,
    ActiveShipments: row?.shipments?.length,
    Status: row?.organization?.status === false ? "Inactive" : "Active",
  }));

  const columns = [
    { label: "Organization Name", key: "OrganizationName" },
    { label: "Current Plan", key: "CurrentPlan" },
    { label: "Joined On", key: "JoinedOn" },
    { label: "Active Trackers", key: "ActiveTrackers" },
    { label: "Active Shipments", key: "ActiveShipments" },
    { label: "Status", key: "Status" },
  ];

  const DownloadData1 = organizationsData1.map((row) => ({
    OrganizationName: row.organizationName,
    OrganizationType: row.organizationType,
    WorkEmail:row.email,
    RequestedOn: row.createdAt,
  }));

  const columns1 = [
    { label: "Organization Name", key: "OrganizationName" },
    { label: "Organization Type", key: "OrganizationType" },
    { label: "Work Email", key: "WorkEmail" },
    { label: "Requested On", key: "Requested On" },
  ];


  const createPdfdata1 =async(datas)=>{
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);
    const title = "Requested Organization";
    const headers = [["OrganizationName","OrganizationType","RequestedOn","WorkEmail"]];
    const data = datas.map((elt) => [elt.OrganizationName, elt.OrganizationType,elt.RequestedOn,elt.WorkEmail]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Requested Organization.pdf");

  }

  const createPdf = async (datas) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);
    const title = "Approved Organization";
    const headers = [["ActiveShipments", "ActiveTrackers","CurrentPlan","JoinedOn","OrganizationName","Status"]];

    const data = datas.map((elt) => [elt.ActiveShipments, elt.ActiveTrackers,elt.CurrentPlan,elt.JoinedOn,elt.OrganizationName,elt.Status]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Approved Organization.pdf");
  }

  const approvehandleAZsort=async(value)=>{
    if(value){
      const strAscending = [...organizationsData1].sort((a, b) =>
        a.organizationName.toLowerCase() > b.organizationName.toLowerCase() ? 1 : -1,
      );
      setOrganizationsData1(strAscending)
    }else{
      const strAscending = [...organizationsData1].sort((a, b) =>
        a.organizationName.toLowerCase() > b.organizationName.toLowerCase() ? -1 : 1,
      );
      setOrganizationsData1(strAscending)
    }
  }

  const handleAZsort=async(value)=>{
    if(value){
      const strAscending = [...organizationsData].sort((a, b) =>
        a.organization?.organizationName.toLowerCase() > b.organization?.organizationName.toLowerCase() ? 1 : -1,
      );
      setOrganizationsData(strAscending)
    }else{
      const strAscending = [...organizationsData].sort((a, b) =>
        a.organization?.organizationName.toLowerCase() > b.organization?.organizationName.toLowerCase() ? -1 : 1,
      );
      setOrganizationsData(strAscending)
    }
  }


  useEffect(() => {
    handleSections("organizations");
    getApproveUsers();
  }, []);

  return (
    <>
      <Grid lg={12} md={12} sm={12} xs={12} container sx={{ padding: "0% 4%", marginTop: "4%" }}>
        <Grid lg={12} md={12} sm={12} xs={12}>
          <Stack direction="column" spacing={3} className="w-100">
            <h5 className="textBlack InterBd m-0">Organizations</h5>
            <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="flex-start" alignItems="center">
              <Grid lg={5} md={8} sm={8} xs={12} container justifyContent={{ lg: "flex-start", md: "flex-start", sm: "flex-start", xs: "center" }} alignItems="center">
                <Grid lg={5} md={5} sm={5} xs={12} className="mr-3 topalignmentinTickets">
                  <Button className={changeBtn === "organizations" ? "reportsbtnBlueKeyMetrices shipmentsbtnspadding" : "reportsbtnKeyMetrices shipmentsbtnspadding"} onClick={() => handleSections("organizations")}>Approved Organizations</Button>
                </Grid>
                <Grid lg={5} md={5} sm={5} xs={12} className="mr-3 topalignmentinTickets">
                  <Badge badgeContent={organizationsData1.length} color="success" sx={{
                    "& .MuiBadge-badge": {
                      color: "#fff",
                      backgroundColor: "#C92828",
                      fontFamily: "InterRegular"
                    },
                    width: "100%"
                  }}>
                    <Button className={changeBtn === "request" ? "reportsbtnBlueKeyMetrices shipmentsbtnspadding" : "reportsbtnKeyMetrices shipmentsbtnspadding"} onClick={() => handleSections("request")}>Approval Requests</Button>
                  </Badge>
                </Grid>
              </Grid>
            </Grid>
            <div className="FlydeCard3 h-100 mb-5" style={{ overflow: "hidden" }}>
              <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="space-between" alignItems="center" sx={{ padding: "2%" }}>
                <h6 className="textGray1 InterMd m-0">{changeBtn === "organizations" ? organizationsData.length : organizationsData1.length} Results</h6>
                <Grid lg={6} md={8} sm={10} xs={12} container justifyContent="space-between">
                  {changeBtn === "organizations" ? (<>
                    <Grid lg={6.2} md={5} sm={5} xs={12} container className="topalignmentinTickets">
                      <div className="inputBox1 px-2 py-2 d-flex align-items-center">
                        <input
                          className="shipmentsSearchbar "
                          placeholder="Search Customers Name"
                          onChange={(e) => handleSearch(e.target.value)}
                        />
                        <CgSearch size={22} color={"#999999"} />
                      </div>
                    </Grid>
                    <Grid lg={2.5} md={3} sm={3} xs={12} container className="topalignmentinTickets">
                      <Button className="btn py-2 w-100">
                        <CSVLink className="text-light" filename={"Approved Organizations.csv"} data={DownloadData} headers={columns}>
                          Export as CSV
                        </CSVLink>
                      </Button>
                    </Grid>
                    <Grid lg={2.5} md={3} sm={3} xs={12} container className="topalignmentinTickets w-100">
                      <Button className="btn py-2" onClick={()=>
                        createPdf(DownloadData)
                        // toPdf()
                      }>Export as PDF</Button>
                    </Grid>
                  </>) : (<>
                    <Grid lg={6.2} md={5} sm={5} xs={12} container className="topalignmentinTickets">
                      <div className="inputBox1 px-2 py-2 d-flex align-items-center">
                        <input
                          className="shipmentsSearchbar "
                          placeholder="Search Customers Name"
                          onChange={(e) => handleSearch1(e.target.value)}
                        />
                        <CgSearch size={22} color={"#999999"} />
                      </div>
                    </Grid>
                    <Grid lg={2.5} md={3} sm={3} xs={12} container className="topalignmentinTickets">
                      <CSVLink filename={"Approval Requests.csv"} data={DownloadData1} headers={columns1}>
                        <Button className="btn py-2">Export as CSV</Button>
                      </CSVLink>
                    </Grid>
                    <Grid lg={2.5} md={3} sm={3} xs={12} container className="topalignmentinTickets">
                      <Button className="btn py-2" onClick={()=>{createPdfdata1(DownloadData1)}}>Export as PDF</Button>
                    </Grid>
                  </>)}
                </Grid>
              </Grid>
              {changeBtn === "organizations" ? (<>
                <OrganizationTable organizationLoading={organizationLoading} organizationsData={organizationsData} getAdminDashBoardDetails={getAdminDashBoardDetails} handleAZsort={handleAZsort} ref={ref} />
              </>) : (<>
                <ApprovalRequestTable loading={loading} organizationsData={organizationsData1} changeBtn={changeBtn} getApproveUsers={getApproveUsers} approvehandleAZsort={approvehandleAZsort} />
              </>)}
            </div>
          </Stack>
        </Grid>
      </Grid>
      <CreateReportModal open={open} setOpen={setOpen} />
    </>
  )
};

export default Organization;