import React, { useState, useEffect } from "react";
import { Avatar, Grid, Button, Stack, Modal } from "@mui/material";
// import { FaShippingFast } from "react-icons/fa";
// import { VscCompassActive } from "react-icons/vsc";
import { CgTrack } from "react-icons/cg";
import { MdSpatialTracking } from "react-icons/md";
import { BsPlus } from "react-icons/bs";
import Plan from "../../assets/images/dashboardImages/Vector.svg";
import "./dashboard.css";
import AlertsTable from "../../components/table/dashboardTable/AlertsTable";
// import RecentCommunicationTable from "../../components/table/dashboardTable/RecentCommunicationTable";
import TrackerTable from "../../components/table/dashboardTable/TrackerTable";
// import ActiveShipmentsTable from "../../components/table/dashboardTable/ActiveShipmentTable";
import { SlOrganization } from "react-icons/sl";
import { FiRadio } from "react-icons/fi";
// import { MdCheckCircle } from "react-icons/md";
import Select from "react-select";
import BarChart from "../../components/charts/barchart";
import PieChart from "../../components/charts/PieChart";
import RecentOrganization from "../../components/table/dashboardTable/RecentOrganization";
import { useSelector } from "react-redux";
import { CgClose } from "react-icons/cg";
// import { RecentAlerts } from "../../helpers/data";
import { useNavigate } from "react-router-dom";
import CreateShipmentsModal from "../../components/modal/CreateShipmentModal";
import ActivateTracker from "../../components/modal/ActivateTracker";
import { getMethod, postMethod } from "../../apis/index";
import { NavHashLink as NavLink } from "react-router-hash-link";
import getHeaders from "../../services";

const dashboard = () => {

  const headers = getHeaders();
  const navigate = useNavigate();
  const [create, setcreate] = useState(false);
  const [tracker, setTracker] = useState(false);
  const [dashboardData, setDashBoardData] = useState("");
  const [userdashboardData, setUserDashBoardData] = useState("");
  const [userdashboardPlan, setUserDashBoardPlan] = useState([]);
  const [
    // shipmentData
    , setShimentData
  ] = useState([]);
  const [TrackersTable, setTrackersTable] = useState([]);
  const [
    // mailInboxData
    , setMailInboxData
  ] = useState([]);
  const [alertdata, setalertdata] = useState([]);
  const [myTrackers, setMyTrackers] = useState([]);
  const [Userinfo, setUserinfo] = useState([]);
  const [Openmodal, setOpenmodal] = useState(false)
  const [
    // comCount
    , setcomCount
  ] = useState("0");
  const count = useSelector((state) => state.WalletConnect);
  const userType =
    count?.userData?.length === 0 ? "" : count?.userData?.roles[0];
  let email = count?.userData?.length === 0 ? "" : count?.userData?.roles[0];


  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255, 255, 255, 1)",
    boxShadow: "3px 2px 12px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    width: "100%",
    padding: "2%",
  };

  const customStylesReactselect = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#fff",
      color: "#AE059E",
      border: "1px solid #AE059E",
      outline: "0px",
      boxShadow: "0px",
      borderRadius: "20px",
      fontFamily: "InterMedium",
      cursor: "pointer",
      padding: "0.5%",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#AE059E",
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: "#AE059E", // Custom colour
    }),
    placeholder: (styles) => ({
      ...styles,
      color: "#AE059E",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 100,
      fontFamily: "InterMedium",
      color: "#AE059E",
    }),
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#AE059E",
        color: "#AE059E",
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: "#AE059E",
    }),
  };
  const datas = [
    { label: "last 7 Days", value: "last 7 Days" },
    { label: "Last 30 Days", value: "Last 30 Days" },
    { label: "last 60 Days", value: "Last 60 Days" },
    { label: "Last 90 Days", value: "Last 90 Days" },
  ];

  const nFormatterDashboard = (num) => {
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "B";
    }
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
    }
    return num;
  };

  const getAdminDashBoardDetails = async () => {
    try {
      let headers = {
        "Content-Type": "application/json",
        "x-access-token": count?.userData?.accessToken,
        "Access-Control-Allow-Origin": "*",
      };
      let url = "api/admin/dashboard";
      let response = await getMethod({ url, headers });
      setDashBoardData(response.result);
      let oncompletedData = [];
      response.result.Shipments.map((val) => {
        let stred =
          val.Trackers[val.Trackers.length - 1]?.trip[
            val.Trackers[val.Trackers.length - 1].trip.length - 1
          ].endDate;
        let ed = Date.parse(stred) / 1000;
        var DateNow = Math.floor(Date.now() / 1000);
        if (DateNow >= ed) {
          oncompletedData.push(val);
        }
      });
      setcomCount(oncompletedData.length);
    } catch (err) {
      console.log("ERROR in getUserDetails", err);
    }
  };

  const getMyTrackers = async () => {
    let params = {
      email: count?.userData.email,
    };
    let url = "api/tracker/viewmypurchaseTracker";
    const response = await postMethod({ url, params, headers });
    if (response.status === true) {
      const PendingTracker = response.result.filter((e) => e.ActivationStatus === "pending" && e.BuyerEmail === count?.userData.email);
      setMyTrackers(PendingTracker);
    }
  };

  const getUserDashBoardDetails = async () => {
    try {
      let params = {
        email: count?.userData?.email,
      };
      let headers = {
        "Content-Type": "application/json",
        "x-access-token": count?.userData?.accessToken,
        "Access-Control-Allow-Origin": "*",
      };
      let url = "api/user/dashboard";
      let response = await postMethod({ url, headers, params });
      setUserDashBoardData(response.result);
      setUserDashBoardPlan(response?.result?.Plan[0]);
      getUsersDetails1();
      getshipment();
      getMyTrackersLive();
      getMailsOneInfo();
      getMyTrackers();
    } catch (err) {
      console.log("ERROR in getUserDetails", err);
    }
  };

  const getUsersDetails1 = async () => {
    try {
      var response = await fetch(`${process.env.REACT_APP_BACKEND_URL}api/organization/viewMyprofile`, {
        method: "POST",
        body: JSON.stringify({
          email: count?.userData?.email,
        }),
        headers: {
          "Content-Type": "application/json",
          "x-access-token": count?.userData?.accessToken,
          "Access-Control-Allow-Origin": "*"
        }
      })
      response = await response.json()
      if (response.status === true) {
        setUserinfo(response.result);
      }
      else {
        setUserinfo([]);
      }
    }
    catch (err) {
      console.log("ERROR in getUserDetails", err);
    }
  }
  const getusershipmentalert = async (datas) => {
    let shipmentsids = []
    if (datas.length > 0) {
      shipmentsids = await datas.map(eve => { return eve.ID });
    }
    try {
      const params = {
        ShipmentID: shipmentsids,
        email: count?.userData?.email,
      };

      let url = "api/notification/ListMultipleNotifications";
      let response = await postMethod({ url, params, headers });
      if (response.status) {
        setalertdata(response.result1)
      }
    } catch (error) {
      console.log("ERROR", error)
    }

  }
  const getshipment = async () => {
    try {
      const params = {
        // CompanyID: count?.userData?.id,
        userId: count?.userData?.id,
      };
      let headers = {
        "Content-Type": "application/json",
        "x-access-token": count?.userData?.accessToken,
        "Access-Control-Allow-Origin": "*",
      };
      // let url = "api/shipment/viewMyallshipments";
      let url = "api/shipment/getshipmentByUserid";
      let response = await postMethod({ url, params, headers });
      let Datas = response.result;
      let onGoingData = [];
      if (Datas.length > 0) {
        Datas.map((val) => {
          let stred = val.Trackers[val.Trackers.length - 1]?.trip[val.Trackers[val.Trackers.length - 1].trip.length - 1].endDate
          let ed = Date.parse(stred) / 1000
          let strsd = val.Trackers[0].trip[0].startDate
          var sd = Date.parse(strsd) / 1000
          var DateNow = Math.floor(Date.now() / 1000);
          if (DateNow >= sd && DateNow <= ed) {
            onGoingData.push(val);
          }
        });
        onGoingData.reverse()
        // setShipmentsData(onGoingData);
        setShimentData(onGoingData);
        getusershipmentalert(onGoingData);
      }

    } catch (err) {
      console.log("ERROR in getShipments", err);
    }
  };
  const getMyTrackersLive = async () => {
    let headers = {
      "Content-Type": "application/json",
      "x-access-token": count?.userData?.accessToken,
      "Access-Control-Allow-Origin": "*",
    };
    let url = "api/tracker/liveTrackers";
    let params = {
      email: count?.userData?.email,
    };
    try {
      const response = await postMethod({ url, params, headers });
      if (response.result) {
        const liveTracker = response.result.filter(
          (e) => e.ActivationStatus === "Activated" && e.BuyerEmail === count?.userData?.email
        );
        if (response.status === true) {
          setTrackersTable(liveTracker);
        }
      }
    } catch (error) {
      console.log("Error", error)
    }


  };

  const getUsersDetails = async (index) => {
    try {
      let headers = {
        "Content-Type": "application/json",
        "x-access-token": count?.userData?.accessToken,
        "Access-Control-Allow-Origin": "*",
      };
      let url = "api/user/viewAll";
      let response = await getMethod({ url, headers });
      if (response.status === true) {
        let result = response?.result.filter((val) => val.userType === "Admin");
        let composeEmail = [];
        result.map((val) => {
          let email = { id: val?._id, label: val?.email, value: val?.email };
          composeEmail.push(email);
        });
        getMailsInfo(result, index ? index : 0);
      } else {
        getMailsInfo([]);
      }
    } catch (err) {
      console.log("ERROR in getUserDetails", err);
    }
  };

  const getMailsInfo = async (userData1, index) => {
    try {
      let params = {
        id: count?.userData?.id,
      };
      let headers = {
        "Content-Type": "application/json",
        "x-access-token": count?.userData?.accessToken,
        "Access-Control-Allow-Origin": "*",
      };
      let url = "api/mail/viewMail";
      let response = await postMethod({ url, headers, params });
      let result = response.result;
      if (response.status === true) {
        let arr = [];
        for (let i = 0; i <= result.length - 1; i++) {
          let newData = userData1.filter(
            (val) => val._id === result[i]?.RecieverID
          );
          arr.push({ ...newData[0], ...result[i] });
        }
        let mailIn = arr

        setMailInboxData(mailIn);
        mapping(mailIn[0], index);
      } else {
        setMailInboxData([]);
        mapping([]);
      }
    } catch (err) {
      console.log("ERROR in getMails", err);
    }
  };

  const handleDirection = () => {
    if (email === "ROLE_SUPERADMIN") {
      getUsersDetails();
    } else {
      getMailsOneInfo();
    }
  };

  useEffect(() => {
    handleDirection();
  }, []);

  const getMailsOneInfo = async (index) => {
    try {
      let params = {
        id: count?.userData?.id,
      };
      let headers = {
        "Content-Type": "application/json",
        "x-access-token": count?.userData?.accessToken,
        "Access-Control-Allow-Origin": "*",
      };
      let url = "api/mail/viewMail";
      let response = await postMethod({ url, headers, params });
      let result = response.result;
      if (response.status === true) {
        let senderId = [];
        result.map((val) => {
          senderId.push(val?.SenderID);
        });
        let sender = [...new Set(senderId)];
        if (sender?.length !== 0) {
          getadminInfo(sender[0], result, index ? index : 0);
        }
      } else {
        getadminInfo([]);
      }
    } catch (err) {
      console.log("ERROR in getMails", err);
    }
  };

  const mapping = (val, i) => {
    handleMessageInbox(val, i);
  };

  const handleMessageInbox = (data) => {
    if (data) {
      let timeDivision = data?.Mails;
      let arr = [];
      let uniq = [];
      timeDivision.map((mail) => {
        let dname = timeSince(new Date(mail?.createdAt));
        let d = { dname, mail };
        uniq.push(dname);
        arr.push(d);
      });
      let arr1 = [...new Set(uniq)];
      let newarr = [];
      arr1.map((val) => {
        let newarr1 = [];
        arr.map((item) => {
          if (item.dname === val) {
            newarr1.push(item);
          }
        });
        newarr.push({ name: val, maildata: newarr1 });
      });
    }
  };
  const timeSince = (date) => {
    var seconds = Math.floor((new Date() - date) / 1000);
    var interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + " years ago";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months ago";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days ago";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return "Today";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return "Today";
    }
    return "Today";
  };

  const getadminInfo = async (id, mails, index) => {
    try {
      let headers = {
        "Content-Type": "application/json",
        "x-access-token": count?.userData?.accessToken,
        "Access-Control-Allow-Origin": "*",
      };
      let params = {
        userid: id,
      };
      let url = "api/user/id/viewmyProfile";
      let response = await postMethod({ url, headers, params });
      let result = response?.result;
      if (response.status === true) {
        let newMails = [];
        mails.map((val) => {
          newMails.push({ ...result, ...val });
        });
        // let mailIn = newMails.reverse();
        let mailIn = newMails
        setMailInboxData(mailIn);
        mapping(mailIn[0], index);
      } else {
        setMailInboxData([]);
      }
    } catch (err) {
      console.log("ERROR in getUserDetails", err);
    }
  };

  const navfunction = () => {
    navigate("/tracker")
  }

  useEffect(() => {
    if (userType === "ROLE_SUPERADMIN") {
      getAdminDashBoardDetails();
    } else {
      getUserDashBoardDetails();
    }
  }, []);

  const AvailableTrackers = (data) => {
    const values = data.filter(track => track.ActivationStatus == "Activated")
    return values.length
  }

  return (
    <>
      {userType === "ROLE_ADMIN" ? (
        <Grid
          lg={12}
          xs={12}
          container
          sx={{ backgroundColor: "#F5F5F5", paddingBottom: "4%" }}
        >
          <Stack direction="column" className="w-100" spacing={4}>
            <Grid
              lg={12}
              xs={12}
              container
              sx={{ padding: "0% 4%", marginTop: "4%" }}
            >
              <Grid lg={12} xs={12} container justifyContent="space-between">
                {/* <Grid
                  lg={2.8}
                  md={6}
                  xs={12}
                  sx={{ padding: "0.5% 0.5%" }}
                  container
                  onClick={() => {Userinfo && Userinfo?.Plan[0] =="no plan" ? setOpenmodal(true) : navigate("/shipments")}}
                >
                  <div className="FlydeCard pointer">
                    <Grid
                      lg={12}
                      xs={12}
                      container
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid
                        lg={4}
                        xs={4}
                        container
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Avatar className="dashBoardAvatars IconsZoomStyles">
                          <VscCompassActive size={35} color={"#AE059E"} />
                        </Avatar>
                      </Grid>
                      <Grid lg={8} xs={8} container direction="column">
                        <font size={4} className="textBlack InterMd">
                          Active Shipment
                        </font>
                        <h3 className="textBlack InterBd m-0">
                          {nFormatterDashboard(
                            shipmentData?.length > 0 ? shipmentData?.length : 0
                          )}
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Grid> */}
                <Grid
                  lg={2.8}
                  md={6}
                  xs={12}
                  sx={{ padding: "0.5% 0.5%" }}
                  container
                  onClick={() => { Userinfo && Userinfo?.Plan[0] == "no plan" ? setOpenmodal(true) : navfunction() }}
                >
                  <div className="FlydeCard pointer">
                    <Grid
                      lg={12}
                      xs={12}
                      container
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid
                        lg={4}
                        xs={4}
                        container
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Avatar className="dashBoardAvatars IconsZoomStyles">
                          <CgTrack size={40} color={"#AE059E"} />
                        </Avatar>
                      </Grid>
                      <Grid lg={8} xs={8} container direction="column">
                        <font size={4} className="textBlack InterMd">
                          No of Trackers
                        </font>
                        <h3 className="textBlack InterBd m-0">
                          {nFormatterDashboard(
                            userdashboardData?.Trackers?.length > 0 ? AvailableTrackers(userdashboardData?.Trackers) : 0
                          )}
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid
                  lg={2.8}
                  xs={12}
                  md={6}
                  sx={{ padding: "0.5% 0.5%" }}
                  container
                >
                  <div className="FlydeCard pointer">
                    <Grid
                      lg={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      {/* <Grid
                        lg={5}
                        xs={5}
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <FaShippingFast
                          size={35}
                          color={"#AE059E"}
                          className="IconsZoomStyles"
                        />
                        <Button
                          className="dashboardcardBtn mt-2"
                          onClick={() => {Userinfo && Userinfo?.Plan[0] =="no plan" ? setOpenmodal(true) : setcreate(true)}}
                        >
                          <BsPlus size={18} color={"#AE059E"} />
                          <small className="InterSm">Add Shipment</small>
                        </Button>
                      </Grid>
                      <span
                        style={{
                          border: "1px solid rgba(234, 234, 234, 1)",
                          minHeight: "70px",
                        }}
                      ></span> */}
                      <Grid
                        lg={12}
                        xs={12}
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <MdSpatialTracking
                          size={35}
                          color={"#2BB372"}
                          className="IconsZoomStyles"
                        />
                        <Button
                          className="dashboardcardBtn1 mt-2"
                          onClick={() => { Userinfo && Userinfo?.Plan[0] == "no plan" ? setOpenmodal(true) : setTracker(true) }}
                        >
                          <BsPlus size={18} color={"#2BB372"} />
                          <small className="InterSm">Add Tracker</small>
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid
                  lg={2.8}
                  xs={12}
                  container
                  md={6}
                  sx={{ padding: "0.5% 0.5%" }}
                >
                  <div className="FlydeCard pointer">
                    <Grid
                      lg={12}
                      xs={12}
                      container
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid
                        lg={4}
                        xs={4}
                        container
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Avatar className="dashBoardAvatars IconsZoomStyles">
                          <img src={Plan} alt="Plan" />
                        </Avatar>
                      </Grid>
                      <Grid lg={8} xs={8} container direction="column">
                        {userdashboardPlan?.Amount ?
                          <>
                            <font size={4} className="textBlack InterMd">
                              Basic Plan
                            </font>
                            <h3 className="textBlack InterBd m-0">
                              ${userdashboardPlan?.Amount}
                              <font size={2} className="textGray1 InterSm pl-1">
                                per month
                              </font>
                            </h3>
                          </>
                          :
                          <NavLink to="/Profile#plans" smooth={true} spy="true" duration={2000} className="btn py-2 mt-3" onClick={() => setOpenmodal(false)}>Purchase Plan</NavLink>
                        }
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              lg={12}
              xs={12}
              container
              justifyContent="space-between"
              alignItems="center"
              sx={{ padding: "0% 4%" }}
            >
              <Grid lg={5.8} xs={12} container className="mt-2">
                <div className="FlydeCard1 example" style={{ overflowY: "auto" }}>
                  <Grid
                    lg={12}
                    xs={12}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ padding: "2%" }}
                  >
                    <h5 className="textBlack InterBd m-0">
                      Available Trackers
                    </h5>
                    <small
                      className="textBlue InterBd pointer textHovering"
                      role="presentation"
                      onClick={() => { Userinfo && Userinfo?.Plan[0] == "no plan" ? setOpenmodal(true) : navigate("/tracker") }}
                    >
                      View All
                    </small>
                  </Grid>
                  <TrackerTable AvailableTrackers={TrackersTable} />
                </div>
              </Grid>
              <Grid lg={5.8} xs={12} container className="mt-2">
                <div className="FlydeCard1 example" style={{ overflowY: "auto" }}>
                  <Grid
                    lg={12}
                    xs={12}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ padding: "2%" }}
                  >
                    <h5 className="textBlack InterBd m-0">
                      Recent Alerts & Notifications
                    </h5>
                    {/* <small className="textBlue InterBd pointer textHovering">View All</small> */}
                  </Grid>
                  <AlertsTable RecentAlerts={alertdata} />
                </div>
              </Grid>
              {/* <Grid lg={5.8} xs={12} container className="mt-2">
                <div className="FlydeCard1 example" style={{ overflowY: "auto" }}>
                  <Grid
                    lg={12}
                    xs={12}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ padding: "2%" }}
                  >
                    <h5 className="textBlack InterBd m-0">
                      Recent Communications
                    </h5>
                    <small
                      className="textBlue InterBd pointer textHovering"
                      role="presentation"
                      onClick={() => navigate("/mails")}
                    >
                      View All
                    </small>
                  </Grid>
                  <RecentCommunicationTable
                    RecentCommunications={mailInboxData}
                  />
                </div>
              </Grid> */}
            </Grid>
            {/* <Grid
              lg={12}
              xs={12}
              container
              justifyContent="space-between"
              alignItems="center"
              sx={{ padding: "0% 4%" }}
            >
              <Grid lg={5.8} xs={12} container className="mt-2">
                <div className="FlydeCard1 example" style={{ overflowY: "auto" }}>
                  <Grid
                    lg={12}
                    xs={12}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ padding: "2%" }}
                  >
                    <h5 className="textBlack InterBd m-0">
                      Available Trackers
                    </h5>
                    <small
                      className="textBlue InterBd pointer textHovering"
                      role="presentation"
                      onClick={() => { Userinfo && Userinfo?.Plan[0] == "no plan" ? setOpenmodal(true) : navigate("/tracker") }}
                    >
                      View All
                    </small>
                  </Grid>
                  <TrackerTable AvailableTrackers={TrackersTable} />
                </div>
              </Grid>
              <Grid lg={5.8} xs={12} container className="mt-2">
                <div className="FlydeCard1 example" style={{overflowY:"auto" }}>
                  <Grid
                    lg={12}
                    xs={12}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ padding: "2%" }}
                  >
                    <h5 className="textBlack InterBd m-0">Active Shipments</h5>
                    <small
                      className="textBlue InterBd pointer textHovering"
                      role="presentation"
                      onClick={() => {Userinfo && Userinfo?.Plan[0] =="no plan" ? setOpenmodal(true) : navigate("/shipments")}}
                    >
                      View All
                    </small>
                  </Grid>
                  <ActiveShipmentsTable ActiveShipments={shipmentData} />
                </div>
              </Grid>
            </Grid> */}
          </Stack>
          <Modal
            open={Openmodal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="center">
              <Grid lg={4} md={6} sm={8} xs={11} container sx={style}>
                <Grid lg={12} xs={12} container>
                  <Grid
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <h4 className="textBlack InterBd m-0">Access Restricted</h4>
                    <CgClose
                      size={32}
                      color={"rgba(0, 0, 0, 1)"}
                      className="pointer"
                      onClick={() => {
                        setOpenmodal(false);
                      }}
                    />
                  </Grid>
                  <Grid
                    lg={12}
                    xs={12}
                    container
                    alignItems="flex-start"
                    direction="column"
                    className="pt-2"
                  >
                    <font className="InterSm textBlack mt-3">Please purchase one of our plans to enjoy our services.</font>
                    <NavLink
                      to="/Profile#plans"
                      smooth={true}
                      spy="true"
                      duration={2000}
                      className="btn py-2 mt-3"
                      onClick={() => setOpenmodal(false)}
                    >
                      View Plans
                    </NavLink>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Modal>
          <CreateShipmentsModal open={create} setOpen={setcreate} />
          <ActivateTracker setOpen={setTracker} open={tracker} userData={count?.userData} myTrackers={myTrackers} />
        </Grid>
      ) : (
        <Grid
          lg={12}
          xs={12}
          container
          sx={{ backgroundColor: "#F5F5F5", paddingBottom: "4%" }}
        >
          <Stack direction="column" className="w-100" spacing={4}>
            <Grid
              lg={12}
              xs={12}
              container
              sx={{ padding: "0% 4%", marginTop: "4%" }}
            >
              <Grid lg={12} xs={12} container justifyContent="space-between">
                <Grid
                  lg={2.8}
                  md={6}
                  xs={12}
                  sx={{ padding: "0.5% 0.5%" }}
                  container
                  onClick={() => navigate("/organizations")}
                >
                  <div className="FlydeCard pointer">
                    <Grid
                      lg={12}
                      xs={12}
                      container
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid
                        lg={4}
                        xs={4}
                        container
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Avatar className="dashBoardAvatars IconsZoomStyles">
                          <SlOrganization size={35} color={"#AE059E"} />
                        </Avatar>
                      </Grid>
                      <Grid lg={8} xs={8} container direction="column">
                        <font size={3} className="textBlack InterMd">
                          No of Organizations
                        </font>
                        <h3 className="textBlack InterBd m-0">
                          {nFormatterDashboard(
                            dashboardData?.Organization?.length
                          )}
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid
                  lg={2.8}
                  md={6}
                  xs={12}
                  sx={{ padding: "0.5% 0.5%" }}
                  container
                  onClick={() => navigate("/tracker")}
                >
                  <div className="FlydeCard pointer">
                    <Grid
                      lg={12}
                      xs={12}
                      container
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid
                        lg={4}
                        xs={4}
                        container
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Avatar className="dashBoardAvatars IconsZoomStyles">
                          <FiRadio size={40} color={"#AE059E"} />
                        </Avatar>
                      </Grid>
                      <Grid lg={8} xs={8} container direction="column">
                        <font size={3} className="textBlack InterMd">
                          No of Active Trackers
                        </font>
                        <h3 className="textBlack InterBd m-0">
                          {nFormatterDashboard(dashboardData?.Trackers?.length)}
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                {/* <Grid
                  lg={2.8}
                  md={6}
                  xs={12}
                  sx={{ padding: "0.5% 0.5%" }}
                  container
                >
                  <div className="FlydeCard pointer">
                    <Grid
                      lg={12}
                      xs={12}
                      container
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid
                        lg={4}
                        xs={4}
                        container
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Avatar className="dashBoardAvatars IconsZoomStyles">
                          <FaShippingFast size={40} color={"#AE059E"} />
                        </Avatar>
                      </Grid>
                      <Grid lg={8} xs={8} container direction="column">
                        <font size={3} className="textBlack InterMd">
                          Active Shipments
                        </font>
                        <h3 className="textBlack InterBd m-0">
                          {nFormatterDashboard(
                            dashboardData?.Shipments?.length
                          )}
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid
                  lg={2.8}
                  md={6}
                  xs={12}
                  sx={{ padding: "0.5% 0.5%" }}
                  container
                >
                  <div className="FlydeCard pointer">
                    <Grid
                      lg={12}
                      xs={12}
                      container
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid
                        lg={4}
                        xs={4}
                        container
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Avatar className="dashBoardAvatars IconsZoomStyles">
                          <MdCheckCircle size={40} color={"#AE059E"} />
                        </Avatar>
                      </Grid>
                      <Grid lg={8} xs={8} container direction="column">
                        <font size={3} className="textBlack InterMd">
                          Completed Shipments
                        </font>
                        <h3 className="textBlack InterBd m-0">
                          {" "}
                          {nFormatterDashboard(comCount)}
                        </h3>
                      </Grid>
                    </Grid>
                  </div>
                </Grid> */}
              </Grid>

              <Grid container>
                <Grid lg={6} md={12} xs={12} sx={{ padding: "1%" }}>
                  <div className="FlydeCard2" style={{ overflow: "hidden" }}>
                    <Grid
                      lg={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ padding: "2% 2%" }}
                    >
                      <h5 className="textBlack InterBd m-0 font-weight-bold">
                        Total Billing Amount ($)
                      </h5>
                      <Grid lg={6} xs={6} container justifyContent="flex-end">
                        <Grid lg={6} xs={12} container>
                          <Select
                            styles={customStylesReactselect}
                            isSearchable={false}
                            className="w-100 mt-1"
                            placeholder="Select Days"
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                            options={datas}
                          //   onChange={(e) => setGeofenceNotify(e)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <BarChart chartData={dashboardData.Payment} />
                  </div>
                </Grid>
                <Grid lg={6} md={12} xs={12} sx={{ padding: "1%" }}>
                  <div className="FlydeCard2" style={{ overflow: "hidden" }}>
                    <Grid
                      lg={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ padding: "2% 2%" }}
                    >
                      <h5 className="textBlack InterBd m-0 font-weight-bold">
                        New Users
                      </h5>
                      <Grid lg={6} xs={6} container justifyContent="flex-end">
                        <Grid lg={6} xs={12} container>
                          <Select
                            styles={customStylesReactselect}
                            isSearchable={false}
                            className="w-100 mt-1"
                            placeholder="Select Days"
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                            options={datas}
                          //   onChange={(e) => setGeofenceNotify(e)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <PieChart chartData={dashboardData.Organization} />
                  </div>
                </Grid>
              </Grid>
              <Grid
                lg={12}
                xs={12}
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid
                  lg={6}
                  md={12}
                  xs={12}
                  sx={{ padding: "1%", }}
                  className="mt-2"
                >
                  <div className="FlydeCard1 example" style={{ overflowY: "auto" }}>
                    <Grid
                      lg={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ padding: "2%" }}
                    >
                      <h5 className="textBlack InterBd m-0">
                        Recently Joined Organizations
                      </h5>
                      <small
                        className="textBlue InterBd pointer textHovering"
                        role="presentation"
                        onClick={() => navigate("/organizations")}
                      >
                        View All
                      </small>
                    </Grid>

                    <RecentOrganization Organization={dashboardData} />
                  </div>
                </Grid>
                {/* <Grid
                  lg={6}
                  md={12}
                  xs={12}
                  sx={{ padding: "1%" }}
                  className="mt-2"
                >
                  <div className="FlydeCard1 example" style={{ overflowY: "auto" }}>
                    <Grid
                      lg={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ padding: "2%" }}
                    >
                      <h5 className="textBlack InterBd m-0">
                        Recent Communications
                      </h5>
                      <small
                        className="textBlue InterBd pointer textHovering"
                        role="presentation"
                        onClick={() => navigate("/mails")}
                      >
                        View All
                      </small>
                    </Grid>
                    <RecentCommunicationTable
                      RecentCommunications={mailInboxData}
                    />
                  </div>
                </Grid> */}
              </Grid>
            </Grid>
          </Stack>

        </Grid>
      )}
    </>
  );
};

export default dashboard;
