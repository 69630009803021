import React, { useEffect } from "react";
import Approutes from "./routes/Approutes";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
// import firebase from "firebase/compat/app";
// import { getAnalytics } from "firebase/analytics";
// import { getMessaging, getToken, Messaging} from "firebase/messaging";
const App = () => {

  var firebaseConfig = {
    apiKey: "AIzaSyAeDHapYTJZkCBtoGjybQDat7xl-Epm94k",
    authDomain: "flyde-fd866.firebaseapp.com",
    projectId: "flyde-fd866",
    storageBucket: "flyde-fd866.appspot.com",
    messagingSenderId: "461427160294",
    appId: "1:461427160294:web:85fca3c19c7c447cc17727",
    measurementId: "G-TVK9YERR2W"
  };
  // const app = initializeApp(firebaseConfig);
  initializeApp(firebaseConfig);
  const messaging = getMessaging();
  // const onMessageListener = () =>
  //   new Promise((resolve) => {
  //     messaging.onMessage((payload) => {
  //       resolve(payload);
  //     });
  //   });
  const requestForToken = () => {
    return getToken(messaging, { vapidKey: "BEl3KY-aQv7kfPBF-iqcaW1xrqmE-NIvpMj6vmDrvNfKSZSrsYHBzoWRW-fW3f8CBWQ-eTKo4DsKOHm5hvlfEgU" })
      .then((currentToken) => {
        if (currentToken) {
          console.log("current token for client: ", currentToken);
          // Perform any other neccessary action with the token
        } else {
          // Show permission request UI
          console.log("No registration token available. Request permission to generate one.");
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
      });
  };
  // const showNotification = () => {
  //   new Notification("Hello World")
  // }
  useEffect(() => {
    // showNotification()
    // sendData()
    // getAnalytics(app);
    requestForToken()
    // onMessageListener()
    // getMessaging(app);
    // getTokens()
  }, [])
  return (
    <div className="App">
      <Approutes />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        // theme="dark"
        limit={1}
      />
    </div>
  );
}

export default App;
