import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Button, Stack, Modal, Box } from "@mui/material";
import { CgClose } from "react-icons/cg";
import "./modal.css";
import { toast } from "react-toastify";
import { postMethod, putMethod } from "../../apis";
import getHeaders from "../../services";
import { getMethod } from "../../apis";
import Select from "react-select";

const EditTracker = ({ open, setOpen, type, EditTrack, getMyTrackersLive }) => {
  const count = useSelector((state) => state.WalletConnect);

  const headers = getHeaders();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255, 255, 255, 1)",
    boxShadow: "0px 1px 13px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    width: "100%",
    height: "calc(80% - 50px)",
    padding: "2%",
  };

  const [EmailData, setEmailData] = useState([])

  const [trackername, settrackername] = useState()
  const [BuyerEmail, setBuyerEmail] = useState()
  const [description, setdescription] = useState()

  useEffect(() => {
    if (open) {
      settrackername(EditTrack?.TrackerName)
      setdescription(EditTrack.Description)
      if (type === "admin") {
        getAllUser()
      }
    }
  }, [open])

  const getAllUser = async () => {
    let url = "api/user/viewAll"
    let headers = {
      "Content-Type": "application/json",
      "x-access-token": count?.userData?.accessToken,
      "Access-Control-Allow-Origin": "*",
    };
    const response = await getMethod({ url, headers })
    let newArr = [];
    response.result.map((e) => {
      newArr.push({ label: e.email, value: e.email })
    })
    setEmailData(newArr)
    setBuyerEmail(newArr.find(a => a.value == EditTrack.BuyerEmail))
  }

  const edittrackerdetails = async (trakerdetails) => {
    if (trackername !== "") {
      if (trakerdetails._id) {
        const params = {
          id: trakerdetails._id,
          trackername: trackername,
          BuyerEmail: BuyerEmail?.value ? BuyerEmail?.value : EditTrack.BuyerEmail,
          Description: description
        };
        let url = "api/trackerdetails/edittracker";
        let headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        };
        const response = await postMethod({ url, params, headers });
        if (response.status) {
          toast.success(response.message)
          getMyTrackersLive();
          setOpen(false)
        } else {
          toast.success(response.message)
        }
      } else {
        toast.warning("Tracker Id Missing")
      }
    } else {
      toast.warn("Enter Tracker Name")
    }

  }

  const ActivateTracker = async (trakerdetails) => {
    if (trackername.trim().length === 0) {
      toast.warn("Tracker name can't be empty");
      return
    }
    let url = "api/tracker/activatetracker"
    let params = {
      _id: trakerdetails._id,
      TrackerName: trackername,
      BuyerEmail: BuyerEmail?.value ? BuyerEmail?.value : EditTrack.BuyerEmail,
      Description: description
    }
    const response = await putMethod({ url, params, headers })
    if (response.status === true) {
      setOpen(false);
      toast.success("Tracker Activated")
      getMyTrackersLive();
    } else {
      toast.error("Tracker Activation Failed Try Again Later")
    }
  }


  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="center">
          <Grid lg={6} md={6} sm={8} xs={11} container sx={style}>
            <Grid
              lg={12}
              md={12}
              sm={12}
              xs={12}
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid lg={11} md={11} xs={11}>
                <h4 className="textBlack InterBd m-0"> Edit Tracker</h4>
              </Grid>
              <Grid lg={1} md={1} xs={1}>
                <CgClose
                  size={32}
                  color={"rgba(0, 0, 0, 1)"}
                  className="pointer"
                  onClick={() => setOpen(false)}
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                width: "100%",
                height: "calc(100% - 100px)",
                overflowY: "scroll",
                scrollbarWidth: "thin",
                "&::-webkit-scrollbar": {
                  width: "1px",
                  borderRadius: "1px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#fff",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#fff",
                },
              }}
            >
              <Grid lg={12} md={12} sm={12} xs={12} container>
                <Stack direction="row" spacing={2} className="w-100 mt-4">
                  <Grid lg={6} xs={12} container>
                    <font className="textBlack InterRg py-2">
                      Tracker Name
                    </font>
                    <input
                      className="inputBox1 w-100  px-2 py-2"
                      placeholder="Enter Tracker Name"
                      value={trackername}
                      disabled={type !== "admin"}
                      onChange={(e) => settrackername(e.target.value)}
                    />
                  </Grid>
                  <Grid lg={6} xs={12} container>
                    <font className="textBlack InterRg py-2">
                      Tracker Model
                    </font>
                    <input
                      className="inputBox1 w-100  px-2 py-2 newshipmentsinputbox"
                      value={EditTrack?.TrackerModel}
                      disabled
                    />
                  </Grid>
                </Stack>
                <Stack direction="row" spacing={2} className="w-100 mt-2">
                  <Grid lg={6} xs={12} container>
                    <font className="textBlack InterRg py-2">
                      Tracker Serial
                    </font>
                    <input
                      className="inputBox1 w-100  px-2 py-2 newshipmentsinputbox"
                      value={EditTrack?.TrackerSerial}
                      disabled
                    />
                  </Grid>
                  {
                    type === "admin" && (
                      <Grid lg={6} xs={12} container>
                        <font className="textBlack InterRg py-2">
                          Buyer Email
                        </font>
                        <Select
                          className="w-100 py-2"
                          isSearchable={true}
                          options={EmailData}
                          value={BuyerEmail}
                          onChange={setBuyerEmail}
                        />
                      </Grid>
                    )
                  }
                </Stack>
                <Stack direction="row" spacing={2} className="w-100 mt-2">
                  <Grid lg={12} xs={12} container>
                    <font className="textBlack InterRg py-2">
                      Description (Optional)
                    </font>
                    <textarea
                      className="inputBox1 px-2 py-2"
                      placeholder="Description"
                      name="description"
                      value={description}
                      rows={3}
                      onChange={(e) => setdescription(e.target.value)}
                    />
                  </Grid>
                </Stack>
              </Grid>
              {/* <Grid lg={12} md={12} sm={12} xs={12} container>
                <Grid
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  container
                  className="mb-5"
                >
                  <Stack direction="column" spacing={2} className="w-100">
                    <Grid
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      {type === "admin" ? (
                        <>
                          <font className="textBlack InterRg py-2">
                            Organization Email
                          </font>
                          <input
                            className="inputBox1 w-100  px-2 py-2"
                            placeholder="Enter Tracker Name"
                            value={EditTrack?.BuyerEmail}
                            onChange={(e) => setBuyerEmail(e.target.value)}
                          />
                        </>
                      ) : (
                        ""
                      )}
                      <font className="textBlack InterRg py-2">
                        Tracker Name
                      </font>
                      <input
                        className="inputBox1 w-100  px-2 py-2"
                        placeholder="Enter Tracker Name"
                        value={trackername}
                        onChange={(e) => settrackername(e.target.value)}
                      />

                      <font className="textBlack InterRg py-2">
                        Tracker model
                      </font>
                      <input
                        className="inputBox1 w-100  px-2 py-2 newshipmentsinputbox"
                        defaultValue={EditTrack?.TrackerModel}
                        disabled
                      />
                      <font className="textBlack InterRg py-2">
                        Tracker Serial
                      </font>
                      <input
                        className="inputBox1 w-100  px-2 py-2 newshipmentsinputbox"
                        defaultValue={EditTrack?.TrackerSerial}
                        disabled
                      />
                      <font className="textBlack InterRg py-3">
                        Description (Optional)
                      </font>
                      <textarea
                        className="inputBox1 px-2 py-2"
                        placeholder="Description"
                        name="description"
                        value={description}
                        onChange={(e) => setdescription(e.target.value)}
                      />
                    </Grid>
                  </Stack>
                </Grid>
              </Grid> */}
            </Box>
            <Grid
              lg={12}
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
              className="mt-3"
            >
              <Grid
                lg={10}
                xs={10}
                container
                justifyContent="space-around"
                alignItems="center"
              >
                {type === "admin" ? (
                  <>
                    <Grid lg={5} xs={5}>
                      <Button
                        className="btngray shipmentsbtnspadding"
                        onClick={() => edittrackerdetails(EditTrack)}
                      >
                        Edit
                      </Button>
                    </Grid>
                    <Grid lg={5} xs={5}>
                      <Button
                        className="btn shipmentsbtnspadding"
                        onClick={() => ActivateTracker(EditTrack)}
                      >
                        Activate
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid lg={5} xs={5}>
                      <Button
                        className="btngray shipmentsbtnspadding"
                        onClick={() => setOpen(false)}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid lg={5} xs={5}>
                      <Button
                        className="btn shipmentsbtnspadding"
                        onClick={() => edittrackerdetails(EditTrack)}
                      >
                        {type === "edit" ? "Save" : "Save"}
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default EditTracker;
