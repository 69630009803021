import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
// import "./Styles.css";
import { Grid, CssBaseline, Box } from "@mui/material";
// import { CgClose } from "react-icons/cg";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import {
  GoogleMap,
  withGoogleMap,
  withScriptjs,
  DirectionsRenderer, Marker
} from "react-google-maps";
function TrackerMap({
  open,
  setOpen,
  width,
  arrow,
  wraped,
  origin,
  destination,
  travelMode,
  currentlocation
}) {
  const drawerWidth = width;
  const openedMixin = (theme) => ({
    width: drawerWidth,
    backgroundColor: "#fff",
    zIndex: 5,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    overflowY: "hidden",
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    display: "none",
  });
  const [markerPos, setMarkerPos] = useState("")

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

  const containerStyle = {
    width: "100%",
    height: "100%",
  };
  const [directions, setDirections] = useState(null);
  useEffect(() => {
    setMarkerPos(new window.google.maps.LatLng(
      currentlocation?.lat, currentlocation?.lng))
    const directionsService = new window.google.maps.DirectionsService();
    directionsService.route(
      {
        origin: new window.google.maps.LatLng(
          origin?.lat, origin?.lng),
        destination: new window.google.maps.LatLng(
          destination?.lat,
          destination?.lng
        ),
        travelMode: travelMode ? travelMode : "",
        // waypoints: [
        //   {
        //     location: new window.google.maps.LatLng(
        //       currentlocation?.lat, currentlocation?.lng)
        //   },
        // ],
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirections(result);
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
    console.log("wraped", wraped)
  }, [origin, destination, travelMode, currentlocation]);
  const MyMapComponent = withScriptjs(
    withGoogleMap(() => (
      <GoogleMap
        defaultZoom={8}
        defaultCenter={new window.google.maps.LatLng(origin.lat, origin.lng)}
      >
        <Marker position={markerPos}
          defaultIcon={window.google.maps.VehicleType.BUS}
          // icon={window.google.maps.VehicleType.BUS}
          icon={"http://maps.google.com/mapfiles/ms/icons/blue-dot.png"}
        />
        <DirectionsRenderer directions={directions} />
        {directions && <DirectionsRenderer directions={directions} />}
      </GoogleMap>
    ))
  );
  const googleMapURL="https://maps.googleapis.com/maps/api/js?key="+process.env.REACT_APP_GOOGE_API_KEY+"&v=3.exp&libraries=geometry,drawing,places"
 
  return (
    <>
      <Box sx={{ display: "flex" }} className="sidebarShipmentsBoxContainer">
        <CssBaseline />
        <Drawer variant="permanent" open={open} anchor="right">
          <Box
            sx={{
              scrollbarWidth: "thin",
              "&::-webkit-scrollbar": {
                width: "8px",
                borderRadius: "8px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#fff",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#D9D9D9",
              },
            }}
          >
            <Grid lg={12} xs={12}>
              {arrow === false ? (
                <Grid
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  container
                  className="justify-content-center"
                  style={{
                    position: "fixed",
                    right: "47.5%",
                    bottom: "50%",
                    zIndex: "9999",
                    width: "5%",
                  }}
                >
                  <div className="iconopenposition">
                    <MdKeyboardArrowLeft
                      size={30}
                      color={"rgba(0, 0, 0, 1)"}
                      className="pointer"
                      onClick={() => setOpen(true)}
                    />
                  </div>
                </Grid>
              ) : (
                ""
              )}
              <Grid
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={containerStyle}
                className="p-0 m-0"
                flexDirection={"column"}
                sx={{ height: "100vh" }}
              >
                {arrow === true ? (
                  <Grid
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="iconopenposition justify-content-center "
                  >
                    <MdKeyboardArrowRight
                      size={30}
                      color={"rgba(0, 0, 0, 1)"}
                      className="pointer "
                      onClick={() => setOpen(false)}
                    />
                  </Grid>
                ) : (
                  ""
                )}
                {/* <Grid lg={4} sx={4} flexDirection="row">
                  <div className="bg-light selectView mx-3 d-flex px-2 ">
                    <button
                      onClick={() => selected("roadmap")}
                      style={{ width: "70px" }}
                      className={
                        isselected === "roadmap"
                          ? "linkBgcolorinshipments"
                          : " linkBgcolorinshipments1"
                      }
                    >
                      Map
                    </button>
                    <button
                      onClick={() => selected("hybrid")}
                      style={{ width: "100px" }}
                      className={
                        isselected === "hybrid"
                          ? "linkBgcolorinshipments   px-4 mx-1"
                          : " linkBgcolorinshipments1    px-4 mx-1"
                      }
                    >
                      Satellite
                    </button>
                  </div>
                </Grid> */}
                <MyMapComponent
                  isMarkerShown
                  googleMapURL={googleMapURL}
                  loadingElement={<div style={{ height: "100%" }} />}
                  containerElement={
                    <div
                      style={{
                        height: "100vh",
                        overflowY: "hidden",
                        paddingTop:
                          arrow === undefined
                            ? ""
                            : arrow === false
                              ? "9%"
                              : "5%",
                      }}
                    />
                  }
                  mapElement={<div style={{ height: "100%" }} />}
                />
              </Grid>
            </Grid>
          </Box>
        </Drawer>
      </Box>
    </>
  );
}

export default TrackerMap;
