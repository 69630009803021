import React,{useEffect , useState} from "react";
import { Grid, Button, Stack } from "@mui/material";
import { CgSearch } from "react-icons/cg";
import BillingTables from "../../components/table/BillingTable/BillingTable";
import { useSelector } from "react-redux";
import { getMethod } from "../../apis";
import { CSVLink } from "react-csv";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

const Billing = () => {
  const count = useSelector((state) => state.WalletConnect)
  const [ payData  , setPayData] = useState([]);
  const [payload,setpayload]= useState([]);
  useEffect(()=>{
    getPayments();
  },[])
  const getPayments = async ()=>{
    let url ="api/payment/viewPayments";
    let headers = {
      "Content-Type": "application/json",
      "x-access-token": count?.userData?.accessToken,
      "Access-Control-Allow-Origin": "*"
    };
    const response = await getMethod({url , headers})
    setPayData(response.result)
    setpayload(response.result)
  }

  const DownloadData = payData.map((row) => 
    ({
      TransactionID : row.paymentID?row.paymentID:row._id,
      CustomerName :row.CustomerName,
      SubscriptionPlan:row.plan[0]?.PlanName,
      AmountCharged:row.plan[0]?.Amount,
      PaymentDate:row.createdAt,
      PaymentStatus:row.PaymentStatus === true?"Paid":row.status,
    })
  );

  const columns = [
    { label: "Transaction ID", key: "TransactionID" },
    { label: "Customer Name", key: "CustomerName" },
    { label: "Subscription Plan", key: "SubscriptionPlan" },
    { label: "Amount Charged", key: "AmountCharged" },
    { label: "Payment Date", key: "PaymentDate" },
    { label: "Payment Status", key: "PaymentStatus" },
  ]
  const searchbilling =async(value)=>{
    if(value !=""){
      let result = payload.filter(item=> item.CustomerName.toLowerCase().includes(value.toLowerCase()))
      setPayData(result)
    }else{
      setPayData(payload)
    }
  }

  const orgnamehandleAZsort=async(value)=>{
    if(value){
      const strAscending = [...payData].sort((a, b) =>
        a.CustomerName.toLowerCase() > b.CustomerName.toLowerCase() ? 1 : -1,
      );
      setPayData(strAscending)
    }else{
      const strAscending = [...payData].sort((a, b) =>
        a.CustomerName.toLowerCase() > b.CustomerName.toLowerCase() ? -1 : 1,
      );
      setPayData(strAscending)
    }
  }

  const planhandleAZsort=async(value)=>{
    if(value){
      const strAscending = [...payData].sort((a, b) =>
        a.plan[0]?.PlanName.toLowerCase() > b.plan[0]?.PlanName.toLowerCase() ? 1 : -1,
      );
      setPayData(strAscending)
    }else{
      const strAscending = [...payData].sort((a, b) =>
        a.plan[0]?.PlanName.toLowerCase() > b.plan[0]?.PlanName.toLowerCase() ? -1 : 1,
      );
      setPayData(strAscending)
    }
  }
  const createPdfdata1 =async(datas)=>{
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);
    const title = "Recent Payment";
    const headers = [["Transaction ID","Customer Name","Subscription Plan","Amount Charged","Payment Date","Payment Status"]];
    const data = datas.map((elt) => [elt.TransactionID, elt.CustomerName,elt.SubscriptionPlan,elt.AmountCharged,elt.PaymentDate,elt.PaymentStatus]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Recent Payment.pdf");

  }


  return (
    <>
      <Grid lg={12} md={12} sm={12} xs={12} container sx={{ padding: "0% 4%", marginTop: "4%" }}>
        <Grid lg={12} md={12} sm={12} xs={12}>
          <Stack direction="column" spacing={3} className="w-100">
            <h5 className="textBlack InterBd m-0">Recent Payments</h5>
            <div className="FlydeCard3 h-100 mb-5" style={{ overflow: "hidden" }}>
              <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="space-between" alignItems="center" sx={{ padding: "2%" }}>
                <h6 className="textGray1 InterMd m-0">{payData.length} Results</h6>
                <Grid lg={6} md={8} sm={10} xs={12} container justifyContent="space-between">
                  <Grid lg={6.2} md={5} sm={5} xs={12} container className="topalignmentinTickets">
                    <div className="inputBox1 px-2 py-2 d-flex align-items-center">
                      <input
                        className="shipmentsSearchbar "
                        placeholder="Search Customers Name"
                        onChange={(e)=>searchbilling(e.target.value)}
                      />
                      <CgSearch size={22} color={"#999999"} />
                    </div>
                  </Grid>
                  <Grid lg={2.5} md={3} sm={3} xs={12} container className="topalignmentinTickets">
                    <Button className="btn py-2">
                      <CSVLink className="text-light" filename={"Recent Payment.csv"} data={DownloadData} headers={columns}>
                        Export as CSV
                      </CSVLink>
                    </Button>
                  </Grid>
                  <Grid lg={2.5} md={3} sm={3} xs={12} container className="topalignmentinTickets">
                    <Button className="btn py-2" onClick={()=>{createPdfdata1(DownloadData)}}>Export as PDF</Button>
                  </Grid>
                </Grid>
              </Grid>
              <BillingTables BillingData={payData} orgnamehandleAZsort={orgnamehandleAZsort} planhandleAZsort={planhandleAZsort} />
            </div>
          </Stack>
        </Grid>
      </Grid>
    </>
  )
};

export default Billing;