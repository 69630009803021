import React, { useEffect, useState } from "react";
import { Grid, Button, Stack, Modal } from "@mui/material";
import OTPInput from "otp-input-react";
import { CgClose } from "react-icons/cg";
// import QRCodeGenerater from "../../helpers/QRCode";
import { useNavigate } from "react-router-dom";
import { MdOutlineUploadFile } from "react-icons/md";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { postMethod } from "../../apis";
import { useDispatch } from "react-redux";
import { saveAs } from "file-saver";
const Google2FA = ({ enableGoogleAuthentication, setEnableGoogleAuthentication, loginResult }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [privateKeyModal, setPrivateKeyModal] = useState(false);
  const [forgetpinModal, setForgetpinModal] = useState(false);
  const [confirmOTP, setConfirmOTP] = useState(false);
  // const [sendOTPLoad, setsendOTPLoad] = useState(false);
  const [sendOTPLoad1, setsendOTPLoad1] = useState(false);
  const [filedata, setfiledata] = useState("")
  const [filename, setfilename] = useState("")
  // const [forgotPasswordEmail, setforgotPasswordEmail] = useState("")
  const [password, setpassword] = useState()
  const [confirmpassword, setconfirmpassword] = useState()
  const [OTPvalue, setOTPvalue] = useState()
  const [KeyPIN, setKeyPIN] = useState()
  const [keyStatus, setKeyStatus] = useState(false)
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255, 255, 255, 1)",
    boxShadow: "3px 2px 12px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    width: "100%",
    padding: "2%",
  };
  const OtpInputBox = {
    width: "60px",
    height: "60px",
    textAlign: "center",
    marginRight: window.innerWidth > 600 ? 0 : "4%",
    border: "none",
    outline: "none",
    backgroundColor: "#D9D9D9",
    borderRadius: "40px",
    color: "#000",
    fontSize: "20px",
    fontFamily: "InterSemiBold"
  }
  const localData = localStorage.getItem("email")
  const onHanleOtpValidation = () => {
    { otp.length !== 6 ? (toast.warn("Code can't be empty")) : (onhandle2FAValidate()) }

  };
  const onhandle2FAValidate = async () => {
    try {
      const params = {
        token: otp,
        userId: loginResult.id,
      };
      let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
      };
      let url = "api/2FA/validate";
      let response = await postMethod({ url, params, headers });
      if (response.validated === true) {

        // if (localData == loginResult.email) {
        //   let file = localStorage.getItem("userkey")
        //   if (file) {
        //     setKeyStatus(true)
        //     setfiledata(file)
        //   }
        // }
        // setEnableGoogleAuthentication(false);
        // setPrivateKeyModal(true);
        // setOtp()

        setEnableGoogleAuthentication(false);
        setKeyStatus(false)
        localStorage.setItem("email", loginResult.email)
        toast("User Verified Successfully")
        dispatch({ type: "USERDATA_UPDATE", payload: loginResult });
        navigate("/dashboard");
      }
      else if (response.message === "error") {
        toast.warn("Try Again");
        navigate("/");
      }
      else if (response.validated === false) {
        toast.warn("Otp invalid");
        navigate("/");
      }
    }
    catch (err) {
      console.log("Error in 2FAValidate", err);
    }
  }
  const hiddenFileInput = React.useRef(null);
  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  const uploadPrivateKey = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const text = event.target.result;
      setfiledata(text)
      setfilename(file.name)
    };
    reader.readAsText(file);
  }
  const uploadFileValidation = async () => {

    if (!loginResult.email) {
      toast("please enter your email address")
      return
    }
    if (!KeyPIN) {
      toast("please enter Valid PIN number")
      return
    }
    if (KeyPIN.length < 6) {
      toast("please enter Valid PIN number")
      return
    }
    if (filedata) {
      try {
        var response = await fetch(`${process.env.REACT_APP_BACKEND_URL}api/user/verifyprivatekey`, {
          method: "POST",
          body: JSON.stringify({
            privateKey: filedata,
            useremail: loginResult.email,
            password: KeyPIN
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          }
        })
        response = await response.json()
        if (response.status) {
          localStorage.setItem("userkey", filedata)
          localStorage.setItem("email", loginResult.email)
          toast("User Verified Successfully")
          dispatch({ type: "USERKEY_UPDATE", payload: filedata });
          dispatch({ type: "USERDATA_UPDATE", payload: loginResult });
          setPrivateKeyModal(false);
          navigate("/dashboard");
        } else {
          toast("Invalid User secret Key")
        }
      } catch (err) {
        console.log("Error", err)
      }
    } else {
      toast("upload secret key")
    }
  };
  const SendOTP = async () => {
    try {
      var response = await fetch(`${process.env.REACT_APP_BACKEND_URL}api/user/resenduserotpforpin`, {
        method: "POST",
        body: JSON.stringify({
          email: loginResult.email
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        }
      })
      response = await response.json()
      if (response.status) {
        setConfirmOTP(true)
        toast("OTP Send Successfully, Check your Email",)
      } else {
        toast("Invalid User secret Key")
      }
    } catch (err) {
      console.log("Error", err)
    }
  }
  const handleConfirmOTP = async () => {
    if (!OTPvalue || OTPvalue.length < 6) {
      toast("Please Enter valid OTP")
      return
    }
    if (password < 6) {
      toast("Enter valid 6-digit PIN");
      return
    }
    if (password != confirmpassword) {
      toast("Both PIN Should Be Same")
      return
    }
    try {
      setsendOTPLoad1(true)
      var response = await fetch(`${process.env.REACT_APP_BACKEND_URL}api/user/validuserotppin`, {
        method: "POST",
        body: JSON.stringify({
          otp: OTPvalue,
          email: loginResult.email,
          password: password,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        }
      })
      response = await response.json()
      if (response.result.status) {
        const blob = new Blob([response.result.result], { type: "text/plain;charset=utf-8" });
        saveAs(blob, `${response.result.filename}.txt`);
        toast(response.result.message);
        localStorage.clear()
        setConfirmOTP(false)
        setsendOTPLoad1(false)
        setForgetpinModal(false)
      } else {
        setsendOTPLoad1(false)
        toast("Invalid User secret Key")
      }
    } catch (err) {
      setsendOTPLoad1(false)
      console.log("Error", err)
    }
  }
  const validate_OTP = (value) => {
    if (value) {
      if (OTPvalue) {
        if (OTPvalue.length <= 6) {
          setOTPvalue(value)
        }
      } else {
        setOTPvalue(value)
      }

    }
  }
  useEffect(() => {

  }, [localData])
  return (<>
    <Modal
      open={enableGoogleAuthentication}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="center">
        <Grid lg={4} md={6} sm={8} xs={11} container sx={style} >
          <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="space-between" alignItems="center">
            <h4 className="textBlack InterBd m-0">Google 2FA Authentication</h4>
            {/* <CgClose size={32} color={"rgba(0, 0, 0, 1)"} className="pointer" onClick={() => setEnableGoogleAuthentication(false)} /> */}
          </Grid>
          <Grid lg={12} md={12} sm={12} xs={12} container>
            <Stack direction="column" spacing={2} className="w-100">
              <font className="textGray1 InterRg pt-2">You will need a Google Authenticator to complete this process.</font>
              <Grid lg={12} md={12} sm={12} xs={12} container>
                <h6 className="textBlack InterSm m-0">Enter the Code</h6>
                <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="space-between" alignItems="center" className="pt-4" sx={{ overflow: "hidden" }}>
                  <OTPInput value={otp} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }} inputStyles={OtpInputBox} onChange={setOtp} OTPLength={6} otpType="number" disabled={false} />
                </Grid>
              </Grid>
              <Button className="btn py-2" onClick={() => { onHanleOtpValidation(); }}>Submit</Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
    <Modal
      open={forgetpinModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="center">
        <Grid lg={4} md={6} sm={8} xs={11} container sx={style}>
          <Grid lg={12} xs={12} container>
            <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="space-between" alignItems="center">
              <h4 className="textBlack InterBd m-0">Forget PIN</h4>
              <CgClose size={32} color={"rgba(0, 0, 0, 1)"} className="pointer" onClick={() => setForgetpinModal(false)} />
            </Grid>
            <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="space-between" alignItems="center">

              <font className="InterRg textBlack mt-3"> OTP send to this Email<font className="InterSm textBlack mt-3"> {loginResult.email}  </font> </font>
            </Grid>
            {
              !confirmOTP &&
              <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="center" alignItems="center">
                <Spinner animation="border" variant="warning" />
              </Grid>

            }
            {
              confirmOTP &&
              <>
                <Grid lg={12} xs={12} container alignItems="flex-start" direction="column" className="pt-2">
                  <font className="InterSm textBlack mt-3">Enter OTP</font>
                  <input
                    className="inputBox px-2 py-2 mt-1"
                    placeholder="Enter Valid OTP"
                    type="number"
                    max={999999}
                    onChange={(e) => { validate_OTP(e.target.value) }}
                  />
                </Grid>
                <Grid lg={12} xs={12} container alignItems="flex-start" direction="column" className="pt-2">
                  <font className="InterSm textBlack mt-3">New PIN</font>
                  <input
                    className="inputBox px-2 py-2 mt-1"
                    placeholder="Enter Pin"
                    onChange={(e) => setpassword(e.target.value)}
                  />
                </Grid>
                <Grid lg={12} xs={12} container alignItems="flex-start" direction="column" className="pt-2">
                  <font className="InterSm textBlack mt-3">Confirm PIN</font>
                  <input
                    className="inputBox px-2 py-2 mt-1"
                    placeholder="Confirm Pin"
                    // value={forgotPasswordEmail}
                    onChange={(e) => setconfirmpassword(e.target.value)}
                  />
                </Grid>
                <Button className="btn py-2 mt-3" onClick={() => { handleConfirmOTP() }}>
                  {
                    sendOTPLoad1 ?
                      <Spinner animation="border" variant="light" />
                      :
                      "Submit"
                  }
                </Button>
              </>
            }
          </Grid>
        </Grid>
      </Grid>
    </Modal>
    <Modal
      open={privateKeyModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="center">
        <Grid lg={4} md={6} sm={8} xs={11} container sx={style}>
          <Grid lg={12} xs={12} container>
            <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="space-between" alignItems="center">
              {!keyStatus ? <h4 className="textBlack InterBd m-0">Upload Your Secret Key</h4>
                : <h4 className="textBlack InterBd m-0">Enter Your PIN</h4>
              }
              <CgClose size={32} color={"rgba(0, 0, 0, 1)"} className="pointer" onClick={() => setPrivateKeyModal(false)} />
            </Grid>
            <Grid lg={12} xs={12} container alignItems="center" direction="column" className="pt-2">
              {
                !keyStatus &&
                <>
                  <Button className="btnTransparent w-75 py-2 mt-3 center" onClick={handleClick}><MdOutlineUploadFile color={"#000"} size={26} />{filename ? filename : "Upload Secret Key"}</Button>
                  <input type="file"
                    ref={hiddenFileInput}
                    onChange={(e) => uploadPrivateKey(e)}
                    accept={".txt"}
                    style={{ display: "none" }}
                  />
                </>
              }

              <input type="text"
                className="inputBox w-75 px-2 py-2 mt-3"
                placeholder="Enter Pin"
                onChange={(e) => setKeyPIN(e.target.value)}
              // accept={".txt"}
              // style={{ display: "none" }}
              />
              <Button className="btn w-75 py-2 mt-3" onClick={() => { uploadFileValidation(); }}>Continue</Button>
            </Grid>
            <Grid lg={12} xs={12} container justifyContent="space-between" className="pt-4">
              <font className="textBlack InterRg"><font className="textBlack InterSm pointer  textHovering" role="presentation" onClick={() => { setPrivateKeyModal(false); SendOTP(); setForgetpinModal(true) }}>Forget Pin ?</font></font>
              <font className="textBlack InterRg">Return to <font className="textBlue InterRg pointer textHovering" role="presentation" onClick={() => { setPrivateKeyModal(false); }}>Sign in</font></font>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  </>)
};

export default Google2FA;