import React, { useEffect, useState } from "react";
import { Grid, Modal, Stack, Box, Button } from "@mui/material";
import { CgClose } from "react-icons/cg";
import Linecharts from "../charts/LineCharts";
import { toast } from "react-toastify";

const ChartsViewsModal = ({ openChart, setOpenChart, ChartData }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255, 255, 255, 1)",
    boxShadow: "0px 1px 13px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    width: "100%",
    padding: "2%",
  };

  const [StartDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");
  const [ChatsData, setChatsData] = useState([]);
  const newChartDatas = ChartData;
  const ApplyDatefilter = () => {
    let newChartData = newChartDatas;
    var startmyArray = StartDate.split("-");
    startmyArray =
      "" + startmyArray[1] + "-" + startmyArray[2] + "-" + startmyArray[0];
    var endmyArray = EndDate.split("-");
    endmyArray = "" + endmyArray[1] + "-" + endmyArray[2] + "-" + endmyArray[0];
    let startDatetime = new Date(startmyArray).getTime();
    let endDatetime = new Date(endmyArray).getTime();
    if (newChartData.Time.length > 0 && newChartData.value.length > 0) {
      let newdata = [];
      let Time = [];
      let value = [];
      newChartData.Time.map((data, i) => {
        if (data > startDatetime && data < endDatetime) {
          newdata.push({ date: data, value: newChartData.value[i] });
          Time.push(data);
          value.push(newChartData.value[i]);
        }
      });
      if (Time.length > 0) {
        newChartData.Time = Time;
        newChartData.value = value;
        setChatsData(newChartData);
        setStartDate("");
      } else {
        toast.warning("No data Found");
        setChatsData(ChartData);
      }
    }
  };
  const modalclose = () => {
    if (ChartData) {
      // setChatsData(ChartData)
      setOpenChart(false);
    } else {
      setOpenChart(false);
    }
  };
  useEffect(() => {
    if (ChartData) {
      setChatsData(ChartData);
    }
  }, [ChartData]);
  useEffect(() => {
  }, [ChatsData]);
  const disableDate = () => {
    const today = new Date();
    const dd = String(today.getDate() + 0).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };
  return (
    <>
      <Modal
        open={openChart}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="center">
          <Grid lg={5.5} md={6} sm={8} xs={11} container sx={style}>
            <Grid
              lg={12}
              md={12}
              sm={12}
              xs={12}
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <h4 className="textBlack InterBd m-0">
                {ChartData.type}{" "}
                {ChartData.type === "Temperature"
                  ? " ° F"
                  : ChartData.type === "Humidity"
                    ? " % "
                    : ChartData.type === "Light"
                      ? " lux "
                      : ChartData.type === "Shock"
                        ? " G "
                        : ""}{" "}
                {ChatsData?.length}
              </h4>
              <CgClose
                size={32}
                color={"rgba(0, 0, 0, 1)"}
                className="pointer"
                onClick={() => modalclose()}
              />
              <Grid lg={12} xs={12} container>
                <font className="textGray1 InterSm pt-1"></font>
              </Grid>
            </Grid>
            <Grid lg={12} md={12} sm={12} xs={12} container>
              <Stack direction="column" spacing={2} className="w-100">
                <Grid
                  lg={12}
                  xs={12}
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  className="pt-3"
                >
                  <Grid lg={4} xs={6} container>
                    <Grid lg={4} xs={4} container>
                      <small className="textGray1 InterRg">Min</small>
                      <small className="textBlack InterMd pl-1">
                        {" "}
                        {ChartData.min}
                      </small>
                    </Grid>
                    <Grid lg={4} xs={4} container justifyContent="center">
                      <small className="textGray1 InterRg">Max</small>
                      {/* <small className="textBlack InterMd pl-1"> {data}</small> */}
                      <small className="textBlack InterMd pl-1">
                        {" "}
                        {ChartData.max}
                      </small>
                    </Grid>
                    <Grid lg={4} xs={4} container justifyContent="flex-end">
                      <small className="textGray1 InterRg">Avg</small>
                      <small className="textBlack InterMd pl-1">
                        {" "}
                        {String(ChartData.avg).slice(0, 4)}
                      </small>
                    </Grid>
                  </Grid>
                  <Grid
                    lg={6}
                    xs={6}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    className="pt-4"
                    flexDirection="row"
                    display="flex"
                  >
                    <div className="chartModalDateinput1 overflowsBox">
                      <input
                        type="text"
                        onFocus={(e) => (e.target.type = "datetime-local")}
                        onBlur={(e) => (e.target.type = "text")}
                        className="DateInputsfocus"
                        placeholder="DD/MM/YYYY"
                        // value={StartDate}
                        max={disableDate()}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </div>
                    <div className="chartModalDateinput1 overflowsBox">
                      {/* <font className="textBlack">to</font> */}
                      <input
                        type="text"
                        onFocus={(e) => (e.target.type = "datetime-local")}
                        onBlur={(e) => (e.target.type = "text")}
                        className="DateInputsfocus"
                        placeholder="DD/MM/YYYY"
                        // value={EndDate}
                        max={disableDate()}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </div>
                    <Button
                      className="btn  mt-3"
                      // disabled={IndexLoad}
                      onClick={() => {
                        ApplyDatefilter();
                      }}
                    >
                      Apply
                    </Button>
                  </Grid>
                </Grid>

                <Grid lg={12} xs={12} container>
                  <Box className="chartModalViewBox">
                    {ChatsData && (
                      <Linecharts type="modal" Mesurements={ChatsData} />
                    )}
                  </Box>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default ChartsViewsModal;
