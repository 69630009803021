export const FormatDate = (dateString) => {
  let newFormat = new Date(dateString).toString().substring(4, 15).split(" ");
  return newFormat[1] + " " + newFormat[0] + " " + newFormat[2];
};

export const FormatDate1 = (unix_timestamp) => {
  var a = new Date(unix_timestamp);
  var year = a.getFullYear();
  var month = a.getMonth() + 1;
  var date = a.getDate();
  var time = date + "/" + month + "/" + year;
  return time;
};

export const FormatTimeAndDate = (dateString) => {
  let a = new Date(dateString);
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();
  var AmOrPm = hour >= 12 ? "PM" : "AM";
  hour = hour % 12 || 12;
  var year = a.getFullYear();
  var month = a.getMonth() + 1;
  var date = a.getDate();
  var time = date+"/"+month+"/"+year+"  "+hour+":" +min+":"+sec+" "+AmOrPm;
  return time;
};

export const FormatTime = (dateString) => {
  let a = new Date(dateString);
  var hour = a.getHours();
  var min = a.getMinutes();
  var AmOrPm = hour >= 12 ? "PM" : "AM";
  hour = hour % 12 || 12;
  var time = hour+":" +min+" "+AmOrPm;
  return time;
};

export const FormatPhoneNo = (data) => {
  let p = data.toString().replace(/\D+/g, "").replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "+$1 ($2) $3-$4");
  return p;
};