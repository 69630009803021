import React, { useState } from "react";
import {
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TablePagination,
} from "@mui/material";
import "../table.css";
import { FaSort } from "react-icons/fa";

const BillingTables = ({ BillingData,orgnamehandleAZsort,planhandleAZsort }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function timeconvertdays(date) {
    var a = new Date(date);
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var dates = a.getDate();
    // var time = a.toLocaleTimeString();
    var hour = a.getHours();
    var min = a.getMinutes();
    var AmOrPm = hour >= 12 ? "PM" : "AM";
    hour = hour % 12 || 12;
    var result =
      dates +
      " " +
      month +
      " " +
      year +
      "   " +
      hour +
      ":" +
      min + " " +

      AmOrPm 
    return result;
  }

  const [sortByorganization,setSortByOrganization] = useState(false);

  const sortorgnamefunction=async()=>{
    if(sortByorganization){
      orgnamehandleAZsort(true)
      setSortByOrganization(false)
    }else{
      orgnamehandleAZsort(false)
      setSortByOrganization(true)
    }
  }

  const [sortByplan,setSortByPlan] = useState(false);

  const sortsubplan=async()=>{
    if(sortByplan){
      planhandleAZsort(true)
      setSortByPlan(false)
    }else{
      planhandleAZsort(false)
      setSortByPlan(true)
    }
  }

  

  return (
    <div>
      {BillingData.length !== 0 ? (
        <>
          <Grid lg={12} xs={12} sx={{ overflow: "auto" }}>
            <TableContainer
              className="mobileviewInTable"
              sx={{
                height: "55vh",
                "&::-webkit-scrollbar": {
                  width: 5,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#FFFF",
                },
                "&::-webkit-scrollbar-thumb": {                  
                  backgroundColor: "#AE059E",
                  borderRadius: 2,
                },
              }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" className="reportTbcell">
                      <font className="textBlack InterMd" size={3}>
                        Transaction ID{" "}
                      </font>
                    </TableCell>
                    <TableCell align="left" className="reportTbcell">
                      <font className="textBlack InterMd" size={3}>
                        Customer Name
                        <FaSort
                          color={"#000"}
                          size={20}
                          className="pl-2 pointer"
                          onClick={()=>sortorgnamefunction()}
                        />
                      </font>
                    </TableCell>
                    <TableCell align="left" className="reportTbcell">
                      <font className="textBlack InterMd" size={3}>
                        Subscription Plan
                        <FaSort
                          color={"#000"}
                          size={20}
                          className="pl-2 pointer"
                          onClick={()=>sortsubplan()}
                        />
                      </font>
                    </TableCell>
                    <TableCell align="left" className="reportTbcell">
                      <font className="textBlack InterMd" size={3}>
                        Amount Charged
                      </font>{" "}
                    </TableCell>

                    <TableCell align="left" className="reportTbcell">
                      <font className="textBlack InterMd" size={3}>
                        Payment Date
                      </font>
                    </TableCell>
                    <TableCell align="center" className="reportTbcell">
                      <font className="textBlack InterMd" size={3}>
                        Payment Status
                      </font>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {BillingData.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  ).map((row, i) => {
                    return (
                      <>
                        <TableRow
                          key={i}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center">
                            <Grid lg={12} xs={12} container direction="column">
                              {row.paymentID?row.paymentID:row._id}
                            </Grid>
                          </TableCell>
                          <TableCell align="left">
                            <Grid
                              lg={12}
                              xs={12}
                              container
                              justifyContent="flex-start"
                              alignItems="center"
                            >
                              <font className="textBlack InterMd">
                                {row.CustomerName}
                              </font>
                            </Grid>
                          </TableCell>
                          <TableCell align="center">
                            <Grid
                              lg={12}
                              xs={12}
                              container
                              justifyContent="flex-start"
                              alignItems="center"
                            >
                              <font className="textBlack InterMd">
                                {row.plan[0]?.PlanName}
                              </font>
                            </Grid>
                          </TableCell>
                          <TableCell align="center">
                            <Grid
                              lg={12}
                              xs={12}
                              container
                              justifyContent="flex-start"
                              alignItems="center"
                            >
                              <font className="textBlack InterMd">
                                $ {row.plan[0]?.Amount}
                              </font>
                            </Grid>
                          </TableCell>
                          <TableCell align="left" className="pointer">
                            <Grid lg={12} xs={12} container>
                              <font className="textBlack  pointer textHoveringgreen">
                                {timeconvertdays(row.createdAt)}
                              </font>
                            </Grid>
                          </TableCell>
                          <TableCell align="center">
                            <Grid lg={12} xs={12}>
                              {row.PaymentStatus === true ? (
                                <font className="textGreen InterMd pointer ">
                                  Paid
                                </font>
                              ) : (
                                <font className="textRed InterMd pointer">
                                  {row.status}
                                </font>
                              )}
                            </Grid>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid
              lg={12}
              xs={12}
              container
              justifyContent="flex-end"
              alignItems="center"
              sx={{ borderTop: "1px solid rgba(224, 224, 224, 1)" }}
            >
              <TablePagination
                className="globalTablePagination"
                sx={{ color: "#656873" }}
                rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
                component="div"
                count={BillingData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid
            lg={12}
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            className=""
          >
            {/* <Grid lg={10} xs={12} container justifyContent="center" alignItems="center" className="">
            <img src={Noreports} className="img-fluid" alt="NoDataImage" />
          </Grid> */}
            <Grid
              lg={10}
              xs={12}
              container
              justifyContent="center"
              className=""
            >
              <h4 className="textBlack InterBd">
                No Organizations Available to Display
              </h4>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default BillingTables;
