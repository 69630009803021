import React from "react";
import QRCode from "react-qr-code";

const QRCodeGenerater = ({QRData}) =>{

  return(
    <>
      <QRCode value={QRData?.otpauth_url} style={{ height: "20vh", maxWidth: "100%", width: "100%" }}/>
    </>
  )
};
export default QRCodeGenerater;