import React, { useState,useEffect } from "react";
import ReactECharts from "echarts-for-react";


function BarChart({chartData}) {

   
  // const [chartdata,setchardata]=useState()
  useEffect(()=>{
    if(chartData){
      chartdatas(chartData)
    }
  },[chartData])

  const [optiondates,setoptiondates]=useState([])
  const [optionvalues,setoptionvalues]=useState([])

  const options = {
    grid: { top: 20, right: 40, bottom: 20, left: 40 },
    xAxis: {
      type: "category",
      // data: ["21/3", "21/3",  "23/3", "24/3", "25/3","26/3",],
      data: optiondates,
    },
  
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: function (value) {
          return `${value / 1000}k`;
        },
      },
    },
    series: [
      {
        // data: [10000, 20000, 30000, 40000, 50000, 60000],
        data:optionvalues,
        type: "bar",
        smooth: true,
        barWidth: 15,
        itemStyle: {
          // Set the color of the bar
          color: "#AE059E",
          borderRadius: [3, 3, 0, 0],
        },
      },
    ],
    tooltip: {
      trigger: "axis",
    },
  };

  const timeconvertdays = (unix_timestamp) => {
    var a = new Date(unix_timestamp);
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    // var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    // var hour = a.getHours();
    // var min = a.getMinutes();
    // var sec = a.getSeconds();
    var time = date + "-" + month; //+ ' ' + year + ' ' + hour + ':' + min + ':' + sec;
    return time;
  };

  const chartdatas = async(Data)=>{
    let charinfo = []
    await Data.map((item)=>{
      const unixTimestamp = new Date(item.createdAt).getTime()
      charinfo.push({...item,time:unixTimestamp})
    })
    const currentdatetime = Date.now()
    const lasweektime = currentdatetime-604800000
    const lastweekinfo = charinfo.filter(eve => eve.time > lasweektime)
    const days = 7
    let weektimestaps =[]
    for (let i = 0; i <= days; i++){
      const nowtime = currentdatetime - 86400000 * i
      weektimestaps.push(nowtime)
    }
    const weekfinaldatadate = []
    const weekfinaldatavalue = []
    var data = 0
    Promise.all(weektimestaps.map(async(day)=>{
      const datas = lastweekinfo.filter(itm => itm.time >day && itm.time < data)
      data = day
      const dateformate = timeconvertdays(day+86400000)
      let totalScores = 0 ;
      if(datas.length > 0){
        totalScores =  datas.reduce( (accumulator, currentValue) => Number(accumulator) + Number(currentValue.Amount ? currentValue.Amount : 0),0,);
      }else{
        totalScores = 0
      }
      weekfinaldatadate.push(dateformate)
      weekfinaldatavalue.push(totalScores)
    }))
    setoptiondates(weekfinaldatadate.slice(1,6))
    setoptionvalues(weekfinaldatavalue.slice(1,6))
  }

  return (
    <ReactECharts
      option={options}
      //   style={{ width: "600px", height: "300px" }}
    ></ReactECharts>
  );
}

export default BarChart;
