import React, { useState, useRef, useEffect } from "react";
import { Grid, TableBody, TableCell, TableContainer, TableHead, TableRow, Table, TablePagination, Button } from "@mui/material";
import "../table.css";
import Noreports from "../../../assets/images/dashboardImages/noreports.svg";
import { MdDownload } from "react-icons/md";
import { FaSort } from "react-icons/fa";
import { useSelector } from "react-redux";
import { postMethod } from "../../../apis";
import { useDownloadExcel } from "react-export-table-to-excel";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
const ReportsTable = ({ reportsData }) => {

  const UserData = useSelector((state) => state.WalletConnect.userData)
  let email = UserData.roles[0]
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(email === "ROLE_SUPERADMIN" ? 7 : 5);
  const [Exportdata, setExportdata] = useState("")
  const tableRef = useRef(null);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  function capitalize(s) {
    return s && s[0].toUpperCase() + s.slice(1);
  }
  const getdata = (value) => {
    var str = ""
    if (value) {
      if (value.length > 0) {
        value.map((data) => {
          if (str.length > 0) {
            let dat = capitalize(data)
            if (data == "temp") {
              str = str + " , " + "Temperature"
            } else {
              str = str + " , " + dat
            }
          } else {
            if (data == "temp") {
              str = "Temperature"
            } else {
              str = capitalize(data)
            }
          }
        })
      }
    }
    return str
  }

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "reports ",
    sheet: "reports "
  })
  const generatedPDF = async (data) => {
    const params = {
      shipmentId: data.ShipmentName,
      DateRangeFrom: data.DateRangeFrom,
      DateRangeTo: data.DateRangeTo
    }
    let headers = {
      "Content-Type": "application/json",
      "x-access-token": UserData?.accessToken,
      "Access-Control-Allow-Origin": "*"
    };
    let url = "api/report/GenerateReportdata";
    try {
      let response = await postMethod({ url, params, headers });
      if (response.status) {
        let result = response.result;
        setExportdata(result)
        if (data.fileformat == "pdf") {
          createPdf(result)
        } else {
          onDownload()
        }
      } else {
        console.log("Error")
      }
    } catch (err) {
      console.log("Error")
    }

  }
  const createPdf = async (datas) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);
    const title = "Shipment Reports";
    const headers = [["Time", "Temperature", "Humidity", "Light", "Shock", "Battery"]];
    const data = datas.map((elt) => [elt.Time, elt.Temperature, elt.Humidity, elt.Light, elt.Shock, elt.Battery]);
    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Shipment.pdf");
  }
  useEffect(() => {

  }, [reportsData])

  const getdate = (date) => {
    if (date) {
      let newDate = new Date(date)
      return String(newDate).slice(0, 15)
    } else {
      return ""
    }
  }
  return (
    <div>
      {reportsData.length !== 0 ? (<>
        <Grid lg={12} xs={12} sx={{ overflow: "auto" }}>
          <TableContainer className={email === "ROLE_SUPERADMIN" ? "mobileviewInTable tablefixedHeightinAllpages1 hide_scroll" : "mobileviewInTable tablefixedHeightinAllpages hide_scroll"}>
            <Table stickyHeader aria-label="sticky table ">
              <TableHead>
                <TableRow>
                  <TableCell align="left" className="reportTbcell">
                    <font className="textBlack InterMd" size={3}>{email === "ROLE_SUPERADMIN" ? (<>Organization Name <FaSort color={"#000"} size={20} className="pl-2 pointer" /></>) : "Report Name"}</font>
                  </TableCell>
                  <TableCell align="left" className="reportTbcell">
                    <font className="textBlack InterMd" size={3}>Shipment Id</font>
                  </TableCell>
                  <TableCell align="left" className="reportTbcell">
                    <font className="textBlack InterMd" size={3}>Created on</font>
                  </TableCell>
                  <TableCell align="left" className="reportTbcell">
                    <font className="textBlack InterMd" size={3}>Date Range from</font>
                  </TableCell>
                  <TableCell align="left" className="reportTbcell">
                    <font className="textBlack InterMd" size={3}>Date Range to</font>
                  </TableCell>
                  <TableCell align="left" className="reportTbcell">
                    <font className="textBlack InterMd" size={3}>Type</font>
                  </TableCell>
                  <TableCell align="left" className="reportTbcell">
                    <font className="textBlack InterMd" size={3}>Action</font>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reportsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                  return (<>
                    <TableRow key={i} sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}>
                      <TableCell align="left">
                        <Grid lg={12} xs={12} container justifyContent="flex-start" alignItems="center">
                          {email === "ROLE_SUPERADMIN" ? (<>
                            <font className="textBlack InterMd">{row?.OrganizationName}</font>
                          </>) : (<>
                            <small className="textBlack InterMd">{row?.ReportName}</small>
                          </>)}
                        </Grid>
                      </TableCell>
                      <TableCell align="left" className="pointer">
                        <Grid lg={12} xs={12} container justifyContent="flex-start" alignItems="center">
                          {email === "ROLE_SUPERADMIN" ? (<>
                            <font className="textBlack InterMd">{row?.ShipmentName}</font>
                          </>) : (<>
                            <small className="textBlack InterMd pointer textHoveringgreen">{row?.ShipmentName}</small>
                          </>)}
                        </Grid>
                      </TableCell>
                      <TableCell align="left">
                        <Grid lg={12} xs={12} container justifyContent="flex-start" alignItems="center">
                          {email === "ROLE_SUPERADMIN" ? (<>
                            <font className="textBlack InterMd">{row?.createdAt.slice(0, 15)}</font>
                          </>) : (<>
                            <small className="textBlack InterMd">{row?.createdAt.slice(0, 15)}</small>
                          </>)}
                        </Grid>
                      </TableCell>
                      <TableCell align="left">
                        <Grid lg={12} xs={12} container justifyContent="flex-start" alignItems="center">
                          {email === "ROLE_SUPERADMIN" ? (<>
                            <font className="textBlack InterMd">{row ? getdate(row.DateRangeFrom) : ""}</font>
                          </>) : (<>
                            <small className="textBlack InterMd">{row ? getdate(row.DateRangeFrom) : ""}</small>
                          </>)}
                        </Grid>
                      </TableCell>
                      <TableCell align="left">
                        <Grid lg={12} xs={12} container justifyContent="flex-start" alignItems="center">
                          {email === "ROLE_SUPERADMIN" ? (<>
                            <font className="textBlack InterMd">{row ? getdate(row.DateRangeTo) : ""}</font>
                          </>) : (<>
                            <small className="textBlack InterMd">{row ? getdate(row.DateRangeTo) : ""}</small>
                          </>)}
                        </Grid>
                      </TableCell>
                      <TableCell align="left">
                        <Grid lg={12} xs={12} container justifyContent="flex-start" alignItems="center">
                          {email === "ROLE_SUPERADMIN" ? (<>
                            <font className="textBlack InterMd">{getdata(row?.KeyMetrics)}</font>
                          </>) : (<>
                            <small className="textBlack InterMd">{getdata((row?.KeyMetrics))}</small>
                          </>)}
                        </Grid>
                      </TableCell>
                      <TableCell align="left">
                        <Grid lg={12} xs={12} container justifyContent="flex-start" alignItems="center">
                          <Button className="btn w-75" onClick={() => generatedPDF(row)} >
                            {email === "ROLE_SUPERADMIN" ? (<>
                              <MdDownload size={20} />
                              <font className="InterMd pl-1">{String(row.fileformat).toUpperCase()}</font>
                            </>) : (<>
                              <MdDownload />
                              <small className="InterMd pl-1">{String(row.fileformat).toUpperCase()}</small>
                            </>)}
                          </Button>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  </>)
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid lg={12} xs={12} container justifyContent="flex-end" alignItems="center" sx={{ borderTop: "1px solid rgba(224, 224, 224, 1)" }}>
            <TablePagination className="globalTablePagination"
              sx={{ color: "#656873" }}
              rowsPerPageOptions={email === "ROLE_SUPERADMIN" ? [7, 14, 21, 28, 35, 42, 60, 80, 100] : [5, 10, 15, 20, 25, 50, 100]}
              component="div"
              count={reportsData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </>) : (<>
        <Grid lg={12} xs={12} container justifyContent="center" alignItems="center" alignContent="center" className="">
          <Grid lg={10} xs={12} container justifyContent="center" alignItems="center" className="">
            <img src={Noreports} className="img-fluid" alt="NoDataImage" />
          </Grid>
          <Grid lg={10} xs={12} container justifyContent="center" className="">
            <h4 className="textBlack InterBd">No Reports Available to Display</h4>
          </Grid>
        </Grid>
      </>)}
      <table ref={tableRef} style={{ display: "none" }} >
        <tbody>
          <tr>
            <tr>
              <th>Time</th>
              <th>Temperature F</th>
              <th>Humidity</th>
              <th>Light</th>
              <th>Shock</th>
              <th>Battery</th>
            </tr>
            {Exportdata && Exportdata.map((userdata, i) => {
              return (
                <tr key={i}>
                  <td>{userdata.Time}</td>
                  <td>{userdata.Temperature}</td>
                  <td>{userdata.Humidity}</td>
                  <td>{userdata.Light}</td>
                  <td>{userdata.Shock}</td>
                  <td>{userdata.Battery}</td>
                </tr>
              )
            })
            }
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ReportsTable;
