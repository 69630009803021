import React, { useState, useEffect } from "react";
import ReactECharts from "echarts-for-react";

const Linecharts = ({ type, Mesurements, value }) => {
  useEffect(() => {
    getTime();
  }, [Mesurements]);
  const [newTime, setTime] = useState([]);
  function timeconvertdays(date) {
    let a = new Date(Number(date));
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var AmOrPm = hour >= 12 ? "PM" : "AM";
    hour = hour % 12 || 12;
    var result = a.toLocaleDateString() + ", " + hour + ":" + min + ":" + sec + " " + AmOrPm;
    return result;
  }
  const getTime = () => {
    let newArr = [];
    // timeconvertdays(Mesurements?.Time);
    Mesurements?.Time?.map((row) => {
      newArr.push(timeconvertdays(row));
    });
    setTime(newArr);
  };
  const option = {
    tooltip: {
      trigger: "axis",
    },
    scales: {
      y: {
        display: false,
      },
    },
    legend: {},
    xAxis: {
      type: "category",
      boundaryGap: false, 
      data: newTime,
    },
    yAxis: {
      show: false,
    },
    series: [
      {
        type: "line",
        data: value,
        // markPoint: {
        //   data: [{ type: "max", name: "Max" }],
        // },
        // markLine: {
        //   data: [{ type: "average", name: "Avg" }]
        // }
      },
    ],
  };

  const option1 = {
    tooltip: {
      trigger: "axis",
    },
    legend: {},
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: newTime,
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: "{value}",
      },
    },
    series: [
      {
        type: "line",
        data: Mesurements?.value,
        // markPoint: {
        //   data: [{ type: "max", name: "Max" }],
        // },
        // markLine: {
        //   data: [{ type: "average", name: "Avg" }]
        // }
      },
    ],
  };

  return (
    <div className="w-100">
      <ReactECharts option={type === "modal" ? option1 : option} />
    </div>
  );
};
export default Linecharts;