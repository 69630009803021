import React, { useEffect, useState } from "react";
import {
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TablePagination,
} from "@mui/material";
import "../table.css";
// import { TableToolTip } from "../../../helpers/toolbar";
import NoData from "../../../assets/images/dashboardImages/loadingData.svg";
import LinkExpired from "../../../assets/images/dashboardImages/linkExpired.svg";
import BatteryGauge from "react-battery-gauge";
const TrackerTable = ({
  TrackersTable,
  setViewPage,
  settrackerDetailData,
  NoDatas,
  loader,
  LiveData
}) => {


  const customization = {
    batteryMeter: {
      fill: "#F91818",
      outerGap: 1,
      noOfCells: 1, // more than 1, will create cell battery
      interCellsGap: 1,
    },
    readingText: {
      display: "none",
    },
  };
  const customization1 = {
    batteryMeter: {
      fill: "#F3F82B",
      outerGap: 1,
      noOfCells: 1, // more than 1, will create cell battery
      interCellsGap: 1,
    },
    readingText: {
      display: "none",
    },
  };

  const customization2 = {
    batteryMeter: {
      fill: "#2BB372",
      outerGap: 1,
      noOfCells: 1, // more than 1, will create cell battery
      interCellsGap: 1,
    },
    readingText: {
      display: "none",
    },
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const getLoaction = (data) => {
    if (data.gps) {
      return data.gps
      // let newdata = data.currentLocation.split(",")
    }else{
      return "-----"
    }
   
  }
  useEffect(() => {

  }, [LiveData])
  return (
    <div>
      {loader === true ? (
        <Grid
          lg={12}
          xs={12}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            overflow: "auto",
            border: "1px solid #8C9196",
            borderRadius: "7px",
          }}
        >
          <Grid lg={5} container justifyContent="center" alignItems="center">
            {NoDatas === true ? (
              <h3 className="textBlue mt-3"> No Trackers.....</h3>
            ) : (
              <h3 className="textBlue mt-3"> Loading...</h3>
            )}
          </Grid>
          {NoDatas === true ? (
            <Grid lg={1}>
              <img
                src={LinkExpired}
                className="img-fluid rounded py-2"
                alt="NoDataImage"
              />
            </Grid>
          ) : (
            <Grid lg={1}>
              <img
                src={NoData}
                className="img-fluid rounded py-2"
                alt="NoDataImage"
              />
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid
          lg={12}
          xs={12}
          sx={{
            overflow: "auto",
            border: "1px solid #8C9196",
            borderRadius: "7px",
          }}
        >
          <TableContainer className="mobileviewInTable w-100 example" sx={{ height: "60vh" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" className="dashboardTbcell1">
                    <font className="textBlack InterMd" size={3}>
                      Tracker Name
                    </font>
                  </TableCell>
                  <TableCell align="left" className="dashboardTbcell1">
                    <font className="textBlack InterMd" size={3}>
                      Location
                    </font>
                  </TableCell>
                  <TableCell align="left" className="dashboardTbcell1">
                    <font className="textBlack InterMd" size={3}>
                      Battery
                    </font>
                  </TableCell>
                  <TableCell align="left" className="dashboardTbcell1">
                    <font className="textBlack InterMd" size={3}>
                      Status
                    </font>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {TrackersTable?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                  return (
                    <>
                      <TableRow key={i}>
                        <TableCell
                          align="left"
                          onClick={() => {
                            setViewPage(true);
                            settrackerDetailData(row);
                          }}
                        >
                          <Grid
                            lg={12}
                            xs={12}
                            container
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <font className="textBlue InterRg pointer textHoveringgreen">
                              {row?.TrackerName?.length > 20
                                ? row?.TrackerName?.slice(0, 20) + ".."
                                : row?.TrackerName}
                            </font>
                          </Grid>
                        </TableCell>
                        <TableCell align="left" className="pointer">
                          <Grid
                            lg={12}
                            xs={12}
                            container
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <font className="textBlack InterRg">
                              {getLoaction(row)}
                            </font>
                          </Grid>
                        </TableCell>
                        <TableCell align="left">
                          <Grid
                            lg={12}
                            xs={12}
                            container
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <Grid lg={4} xs={4} container>
                              <BatteryGauge
                                style={{ height: "20px" }}
                                customization={
                                  row.Battery < 20
                                    ? customization
                                    : row.Battery < 50
                                      ? customization1
                                      : row.Battery < 100
                                        ? customization2
                                        : customization2
                                }
                                size={100}
                                value={row.Battery ? row.Battery : 60}
                              />
                            </Grid>
                            <font className="textBlack InterRg">
                              {row.Battery ? row.Battery : 60}%
                            </font>
                          </Grid>
                        </TableCell>
                        <TableCell align="left">
                          <Grid
                            lg={12}
                            xs={12}
                            container
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            {row?.ShipmentAssign === true ? (
                              <font className="text-success InterRg">
                                Assigned
                              </font>
                            ) : (
                              <font className=" text-danger InterRg">
                                Unassigned
                              </font>
                            )}
                          </Grid>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid
            lg={12}
            xs={12}
            container
            justifyContent="flex-end"
            alignItems="center"
          >
            <TablePagination
              className="globalTablePagination"
              sx={{ color: "#656873" }}
              rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
              component="div"
              count={TrackersTable.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default TrackerTable;
