import S3 from "react-aws-s3";
import {useSelector} from "react-redux";
export default function getHeaders() {
  let headers = {};
  const count = useSelector((state) => state.WalletConnect)
  const usertoken = count?.userData;
  const token = usertoken ? usertoken.accessToken : ""
  headers["Content-Type"] = "application/json";
  headers["x-access-token"] = token;
  headers["Access-Control-Allow-Origin"] = "*";
  headers = { ...headers };
  return headers;
}

const config = {
  bucketName: process.env.REACT_APP_S3_BACKETNAME,
  dirName: "profile",
  region: process.env.REACT_APP_S3_REGION,
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
};
export const ReactS3Client = new S3(config);