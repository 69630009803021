import React, { useState, useEffect } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Container,
  Grid,
  Menu,
  Avatar,
  Modal, Badge

} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Logo from "../../assets/images/logo.png";
import { TiHome } from "react-icons/ti";
import {
  // FaTruckMoving,
  FaFileAlt,
  // FaTicketAlt
} from "react-icons/fa";
import {
  // IoPersonCircle, 
  IoNotificationsSharp
} from "react-icons/io5";
import { CgTrack } from "react-icons/cg";
import { CgClose } from "react-icons/cg";
import "./navbar.css";
import { FiChevronDown, FiRadio } from "react-icons/fi";
import { AiOutlineLogout } from "react-icons/ai";
import LogoutModal from "../modal/logout";
import {
  MdDashboard,
  // MdContactSupport
} from "react-icons/md";
import { SlOrganization } from "react-icons/sl";
import { RiBillFill, RiSettings5Fill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { NavHashLink as NavLink } from "react-router-hash-link";
import { onMessage, getMessaging } from "firebase/messaging";
// import firebase from "firebase"
// import AnchorLink from "react-anchor-link-smooth-scroll"
// import { postMethod } from "../../apis";
function NavBar() {

  const location = useLocation();
  // const result = location.pathname;
  // const currentLocation = result.split("/").pop();
  const [logmodal, setLogmodal] = useState(false);
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);

  const [Openmodal, setOpenmodal] = useState(false)

  const count = useSelector((state) => state.WalletConnect);
  let email = count?.userData?.length === 0 ? "" : count?.userData?.roles[0];


  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255, 255, 255, 1)",
    boxShadow: "3px 2px 12px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    width: "100%",
    padding: "2%",
  };
  const messaging = getMessaging();
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };


  onMessage(messaging, (payload) => {
    const notificationTitle = payload.notification.title;
    var title = notificationTitle;
    var body = payload.notification.body;
    var icon = "./logo192.png";
    var tag = "Flyde";
    var data = {
      doge: {
        wow: "Flyde"
      }
    };
    new Notification(title, {
      body: body,
      icon: icon,
      tag: tag,
      data: data
    })
    getNotifications()
    // resolve(payload);
  });
  const [userData, setUserData] = useState("");
  const [Notifications, SetNotifications] = useState([])

  const getUsersDetails = async () => {
    try {
      var response = await fetch(`${process.env.REACT_APP_BACKEND_URL}api/organization/viewMyprofile`, {
        method: "POST",
        body: JSON.stringify({
          email: count?.userData?.email,
        }),
        headers: {
          "Content-Type": "application/json",
          "x-access-token": count?.userData?.accessToken,
          "Access-Control-Allow-Origin": "*"
        }
      })
      response = await response.json()


      // const params = {
      //   email: count?.userData?.email,
      // };
      // let headers = {
      //   "Content-Type": "application/json",
      //   "x-access-token": count?.userData?.accessToken,
      //   "Access-Control-Allow-Origin": "*"
      // };
      // let url = "api/organization/viewMyprofile";
      // let response = await postMethod({ url, params,headers });
      if (response.status === true) {
        setUserData(response.result);
      }
      else {
        setUserData([]);
      }
    }
    catch (err) {
      console.log("Error in getUserDetails", err);
    }
  }
  const getNotifications = async () => {
    var userEmail = count?.userData?.email
    if (email === "ROLE_SUPERADMIN") {
      userEmail = "Admin"
    }
    try {
      var response = await fetch(`${process.env.REACT_APP_BACKEND_URL}api/dnotification/ListDnotifications`, {
        method: "POST",
        body: JSON.stringify({
          useremail: userEmail,
        }),
        headers: {
          "Content-Type": "application/json",
          "x-access-token": count?.userData?.accessToken,
          "Access-Control-Allow-Origin": "*"
        }
      })
      response = await response.json()
      if (response.status) {
        SetNotifications(response.result)
      }
    } catch (err) {
      console.log("Error", err)
    }

  }
  useEffect(() => {
    // onMessageListener()
    getUsersDetails();
    getNotifications()
  }, []);
  const NavigatePage = (data) => {
    if (data.notificationsCategory == "Approval Request") {
      navigate("/organizations")
    } else if (data.notificationsCategory == "Payment Received") {
      navigate("/billings")
    }
  }
  const NavigatePageUser = (data) => {
    if (data.notificationsCategory == "New Tracker Whitelisted") {
      navigate("/tracker")
    } else if (data.notificationsCategory == "Payment Received") {
      navigate("/billings")
    }
  }
  return (
    <AppBar
      position="sticky"
      sx={{
        background: "#fff",
        boxShadow: "none",
        border: "1px solid rgba(140, 145, 150, 1)", zIndex: 10
      }}
    >
      <Container maxWidth="xxl">
        <Toolbar disableGutters>
          {email === "ROLE_SUPERADMIN" ? (<>
            <Grid
              lg={12}
              md={12}
              sm={12}
              xs={12}
              container
              justifyConent="space-between"
              alignItems="center"
            >
              <Grid md={1} sm={6} xs={6} container alignItems="center" className="pointer" onClick={() => navigate("/dashboard")}>
                <img src={Logo} alt="Tlogo" style={{ height: "50px" }} className="app-logo" />
              </Grid>
              <Grid md={8.5} sm={6} xs={6} container>
                <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                  <Grid
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid
                      lg={1.5}
                      md={2}
                      sm={2}
                      xs={2}
                      container
                      justifyContent="center"
                      alignItems="center"
                      className={
                        location.pathname === "/dashboard"
                          ? "linkBgcolorinNavbar pointer"
                          : "linkBgcolorinNavbar1 pointer"
                      }
                      onClick={() => navigate("/dashboard")}
                    >
                      <MdDashboard size={20} className="mr-1" />
                      <small className="InterMd">Dashboard</small>
                    </Grid>
                    <Grid
                      lg={1.5}
                      md={2}
                      sm={2}
                      xs={2}
                      container
                      justifyContent="center"
                      alignItems="center"
                      className={
                        location.pathname === "/organizations"
                          ? "linkBgcolorinNavbar pointer"
                          : "linkBgcolorinNavbar1 pointer"
                      }
                      onClick={() => navigate("/organizations")}
                    >
                      <SlOrganization size={17} className="mr-1" />
                      <small className="InterMd">Organizations</small>
                    </Grid>
                    <Grid
                      lg={1.5}
                      md={2}
                      sm={2}
                      xs={2}
                      container
                      justifyContent="center"
                      alignItems="center"
                      className={
                        location.pathname === "/billings"
                          ? "linkBgcolorinNavbar pointer"
                          : "linkBgcolorinNavbar1 pointer"
                      }
                      onClick={() => navigate("/billings")}
                    >
                      <RiBillFill size={20} className="mr-1" />
                      <small className="InterMd">Billings</small>
                    </Grid>
                    <Grid
                      lg={1.5}
                      md={2}
                      sm={2}
                      xs={2}
                      container
                      justifyContent="center"
                      alignItems="center"
                      className={
                        location.pathname === "/tracker"
                          ? "linkBgcolorinNavbar pointer"
                          : "linkBgcolorinNavbar1 pointer"
                      }
                      onClick={() => navigate("/tracker")}
                    >
                      <FiRadio size={20} className="mr-1" />
                      <small className="InterMd">Trackers</small>
                    </Grid>
                    <Grid
                      lg={1.5}
                      md={2}
                      sm={2}
                      xs={2}
                      container
                      justifyContent="center"
                      alignItems="center"
                      className={
                        location.pathname === "/reports"
                          ? "linkBgcolorinNavbar pointer"
                          : "linkBgcolorinNavbar1 pointer"
                      }
                      onClick={() => navigate("/reports")}
                    >
                      <FaFileAlt size={19} className="mr-1" />
                      <small className="InterMd">Reports</small>
                    </Grid>
                    {/* <Grid
                      lg={1.5}
                      md={2}
                      sm={2}
                      xs={2}
                      container
                      justifyContent="center"
                      alignItems="center"
                      className={
                        location.pathname === "/mails"
                          ? "linkBgcolorinNavbar pointer"
                          : "linkBgcolorinNavbar1 pointer"
                      }
                      onClick={() => navigate("/mails")}
                    >
                      <MdContactSupport size={24} className="mr-1" />
                      <small className="InterMd">Support</small>
                    </Grid> */}
                    {/* <Grid
                      lg={1.5}
                      md={2}
                      sm={2}
                      xs={2}
                      container
                      justifyContent="center"
                      alignItems="center"
                      className={
                        location.pathname === "/profile"
                          ? "linkBgcolorinNavbar pointer"
                          : "linkBgcolorinNavbar1 pointer"
                      }
                      onClick={() => navigate("/profile")}
                    >
                      <IoPersonCircle size={24} className="mr-1" />
                      <small className="InterMd">Profile</small>
                    </Grid> */}
                    <Grid
                      lg={1.5}
                      md={2}
                      sm={2}
                      xs={2}
                      container
                      justifyContent="center"
                      alignItems="center"
                      className={
                        location.pathname === "/settings"
                          ? "linkBgcolorinNavbar pointer"
                          : "linkBgcolorinNavbar1 pointer"
                      }
                      onClick={() => navigate("/settings")}
                    >
                      <RiSettings5Fill size={20} className="mr-1" />
                      <small className="InterMd">Settings</small>
                    </Grid>

                  </Grid>
                </Box>
                <Box
                  sx={{
                    flexGrow: 1,
                    display: { xs: "flex", md: "none" },
                    justifyContent: "flex-end",
                  }}
                >
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                  >
                    <MenuIcon sx={{ color: "#000" }} />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                      display: { xs: "block", md: "none" },
                    }}
                  >
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Grid
                        lg={12}
                        xs={12}
                        container
                        direction="column"
                        alignItems="center"
                      >
                        <div
                          className={
                            location.pathname === "/dashboard"
                              ? "linkBgcolorinNavbarDropdown pointer"
                              : "linkBgcolorinNavbarDropdown1 pointer"
                          }
                          role="presentation"
                          onClick={() => navigate("/dashboard")}
                        >
                          <MdDashboard size={20} className="mr-1" />
                          <small className="InterMd">DashBoard</small>
                        </div>
                        <div
                          className={
                            location.pathname === "/organizations"
                              ? "linkBgcolorinNavbarDropdown mt-3 pointer"
                              : "linkBgcolorinNavbarDropdown1 mt-3 pointer"
                          }
                          role="presentation"
                          onClick={() => navigate("/organizations")}
                        >
                          <SlOrganization size={20} className="mr-1" />
                          <small className="InterMd">Organizations</small>
                        </div>
                        <div
                          className={
                            location.pathname === "/billings"
                              ? "linkBgcolorinNavbarDropdown mt-3 pointer"
                              : "linkBgcolorinNavbarDropdown1 mt-3 pointer"
                          }
                          role="presentation"
                          onClick={() => navigate("/billings")}
                        >
                          <RiBillFill size={20} className="mr-1" />
                          <small className="InterMd">Billings</small>
                        </div>
                        <div
                          className={
                            location.pathname === "/trackers"
                              ? "linkBgcolorinNavbarDropdown mt-3 pointer"
                              : "linkBgcolorinNavbarDropdown1 mt-3 pointer"
                          }
                          role="presentation"
                          onClick={() => navigate("/trackers")}
                        >
                          <FiRadio size={20} className="mr-1" />
                          <small className="InterMd">Trackers</small>
                        </div>
                        <div
                          className={
                            location.pathname === "/reports"
                              ? "linkBgcolorinNavbarDropdown mt-3 pointer"
                              : "linkBgcolorinNavbarDropdown1 mt-3 pointer"
                          }
                          role="presentation"
                          onClick={() => navigate("/reports")}
                        >
                          <FaFileAlt size={20} className="mr-1" />
                          <small className="InterMd">Reports</small>
                        </div>
                        {/* <div
                          className={
                            location.pathname === "/mails"
                              ? "linkBgcolorinNavbarDropdown mt-3 pointer"
                              : "linkBgcolorinNavbarDropdown1 mt-3 pointer"
                          }
                          role="presentation"
                          onClick={() => navigate("/mails")}
                        >
                          <MdContactSupport size={20} className="mr-1" />
                          <small className="InterMd">Support</small>
                        </div> */}
                        {/* <div
                          className={
                            location.pathname === "/profile"
                              ? "linkBgcolorinNavbarDropdown mt-3 pointer"
                              : "linkBgcolorinNavbarDropdown1 mt-3 pointer"
                          }
                          role="presentation"
                          onClick={() => navigate("/profile")}
                        >
                          <IoPersonCircle size={24} className="mr-1" />
                          <small className="InterMd">Profile</small>
                        </div> */}

                        <div
                          className={
                            location.pathname === "/settings"
                              ? "linkBgcolorinNavbarDropdown mt-3 pointer"
                              : "linkBgcolorinNavbarDropdown1 mt-3 pointer"
                          }
                          role="presentation"
                          onClick={() => navigate("/settings")}
                        >
                          <RiSettings5Fill size={20} className="mr-1" />
                          <small className="InterMd">Settings </small>
                        </div>
                      </Grid>
                    </MenuItem>
                  </Menu>
                </Box>
              </Grid>
              <Grid md={0.5}>
                <Grid
                  className="linkBgcolorinNavbar1 pointer dropdown-toggle"
                  // display="grid"
                  direction="column"
                  type="button"
                  // id="dropdownMenu3"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  lg={1}
                  md={1}
                  sm={1}
                  xs={1}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <IoNotificationsSharp size={24} className="mr-1" />
                  <Badge
                    badgeContent={Notifications.length}
                    color="success"
                    sx={{
                      "& .MuiBadge-badge": {
                        color: "#fff",
                        backgroundColor: "#C92828",
                        fontFamily: "InterRegular",
                      },
                      width: "100%",
                    }}
                  >

                  </Badge>
                  {/* <small className="InterMd">Notification</small> */}
                </Grid>
                <div
                  className="dropdown-menu "
                  aria-labelledby="dropdownMenu3"
                  style={{ width: "30%", maxHeight: "300px", minHeight: "200px", overflow: "scroll" }}
                >
                  {Notifications.length > 0 ?
                    Notifications.map((data, i) => {
                      return (
                        <div className="dropdown-item " aria-hidden="true"
                          key={i}
                          onClick={() => NavigatePage(data)}
                          style={{
                            width: "98%", whiteSpace: "unset", marginBottom: "3px",
                            backgroundColor: "#eaeef2", color: "#020202", fontFamily: "InterMedium", borderRadius: "3px", cursor: "pointer"
                          }}>
                          <p className="InterMd" style={{ marginBottom: "0px" }}>{data.notificationsName} </p><b />
                          <div style={{ flexDirection: "row", justifyContent: "space-between", display: "flex" }}>
                            <p className="InterRg" style={{ marginBottom: "0px" }}> {data.notificationsDescription} </p>
                            <small className="InterLt" style={{ marginBottom: "0px", textAlign: "end" }}> {data.createdAt.slice(0, 15)}</small>
                          </div>
                        </div>
                      )
                    })
                    :
                    <>
                      <div className="dropdown-item " aria-hidden="true"
                        style={{
                          width: "98%", whiteSpace: "unset", marginBottom: "3px",
                          backgroundColor: "#eaeef2", color: "#020202", fontFamily: "InterMedium", borderRadius: "3px", cursor: "pointer"
                        }}>
                        <p className="InterMd" style={{ marginBottom: "0px" }}> No data Found </p><b />
                        <div style={{ flexDirection: "row", justifyContent: "space-between", display: "flex" }}>
                          <p className="InterRg" style={{ marginBottom: "0px" }}> No data Found </p>
                        </div>
                      </div>
                    </>
                  }
                </div>
              </Grid>
              <Grid md={2} sm={2} xs={2} container>
                <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                  <Grid
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                  >

                    <Grid lg={4} xs={3} container justifyContent="flex-end">
                      <Avatar src={`${process.env.REACT_APP_S3_LINK}profile/${userData?.profilePic}`} height="100%" width="100%" />
                    </Grid>
                    <Grid lg={8} xs={9} container>
                      <Grid
                        display="grid"
                        direction="column"
                        className=" dropdown-toggle"
                        type="button"
                        id="dropdownMenu2"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <font className="textBlack InterMd pl-1">
                          {email === "ROLE_SUPERADMIN" ? (<>GTAI Admin <FiChevronDown /></>) : (<>{userData?.organizationName?.slice(0, 18) + ".."}<FiChevronDown /></>)}
                        </font>
                        <small className="textGray1 InterMd pl-1">
                          {email === "ROLE_SUPERADMIN" ? (<>{count?.userData?.email?.slice(0, 18) + ".."}</>) : (<>{count?.userData?.email?.slice(0, 18) + ".."}</>)}
                        </small>
                      </Grid>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenu2"
                      >
                        <button
                          className="dropdown-item btn  mt-3 d-flex align-items-center"
                          type="button"
                          onClick={() => navigate("/profile")}
                          style={{ padding: "4%" }}
                        >
                          <Avatar src={userData?.profilePic} sx={{ width: 20, height: 20 }} className="mr-2" /> View Profile
                        </button>
                        <button
                          className="dropdown-item btn  mt-3 d-flex align-items-center"
                          type="button"
                          onClick={() => setLogmodal(true)}
                          style={{ padding: "4%" }}
                        >
                          <AiOutlineLogout size={20} className="mr-2" /> Logout
                        </button>
                      </div>
                    </Grid>
                  </Grid>

                </Box>
              </Grid>
            </Grid>
          </>) : (<>
            <Grid
              lg={12}
              md={12}
              sm={12}
              xs={12}
              container
              justifyConent="space-between"
              alignItems="center"
            >
              <Grid md={1} sm={6} xs={6} container alignItems="center" className="pointer" onClick={() => navigate("/dashboard")}>
                <img src={Logo} alt="Tlogo" style={{ height: "50px" }} className="app-logo" />
              </Grid>
              <Grid md={8.5} sm={6} xs={6} container>
                <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                  <Grid
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid
                      lg={2}
                      md={2}
                      sm={2}
                      xs={2}
                      container
                      justifyContent="center"
                      alignItems="center"
                      className={
                        location.pathname === "/dashboard"
                          ? "linkBgcolorinNavbar pointer"
                          : "linkBgcolorinNavbar1 pointer"
                      }
                      onClick={() => navigate("/dashboard")}
                    >
                      <TiHome size={20} className="mr-1" />
                      <small className="InterMd">Dashboard</small>
                    </Grid>
                    {/* <Grid
                      lg={2}
                      md={2}
                      sm={2}
                      xs={2}
                      container
                      justifyContent="center"
                      alignItems="center"
                      className={
                        location.pathname === "/shipments"
                          ? "linkBgcolorinNavbar pointer"
                          : "linkBgcolorinNavbar1 pointer"
                      }
                      onClick={() => {
                        userData && userData?.Plan[0] == "no plan" ? setOpenmodal(true) : navigate("/shipments")
                      }
                      }
                    >
                      <FaTruckMoving size={20} className="mr-1" />
                      <small className="InterMd">Shipment</small>
                    </Grid> */}
                    <Grid
                      lg={2}
                      md={2}
                      sm={2}
                      xs={2}
                      container
                      justifyContent="center"
                      alignItems="center"
                      className={
                        location.pathname === "/tracker"
                          ? "linkBgcolorinNavbar pointer"
                          : "linkBgcolorinNavbar1 pointer"
                      }
                      onClick={() => {
                        // navigate("/tracker")
                        userData && userData?.Plan[0] == "no plan" ? setOpenmodal(true) : navigate("/tracker")
                      }
                      }
                    >
                      <CgTrack size={20} className="mr-1" />
                      <small className="InterMd">Trackers</small>
                    </Grid>
                    <Grid
                      lg={2}
                      md={2}
                      sm={2}
                      xs={2}
                      container
                      justifyContent="center"
                      alignItems="center"
                      className={
                        location.pathname === "/reports"
                          ? "linkBgcolorinNavbar pointer"
                          : "linkBgcolorinNavbar1 pointer"
                      }
                      onClick={() => navigate("/reports")}
                    >
                      <FaFileAlt size={20} className="mr-1" />
                      <small className="InterMd">Reports</small>
                    </Grid>
                    {/* <Grid
                      lg={2}
                      md={2}
                      sm={2}
                      xs={2}
                      container
                      justifyContent="center"
                      alignItems="center"
                      className={
                        location.pathname === "/mails"
                          ? "linkBgcolorinNavbar pointer"
                          : "linkBgcolorinNavbar1 pointer"
                      }
                      onClick={() => navigate("/mails")}
                    >
                      <FaTicketAlt size={20} className="mr-1" />
                      <small className="InterMd">Mails & Tickets</small>
                    </Grid> */}
                    {/* <Grid
                      lg={2}
                      md={2}
                      sm={2}
                      xs={2}
                      container
                      justifyContent="center"
                      alignItems="center"
                      className={
                        location.pathname === "/profile"
                          ? "linkBgcolorinNavbar pointer"
                          : "linkBgcolorinNavbar1 pointer"
                      }
                      onClick={() => navigate("/profile")}
                    >
                      <IoPersonCircle size={24} className="mr-1" />
                      <small className="InterMd">Profile</small>
                    </Grid> */}
                  </Grid>
                </Box>
                <Box
                  sx={{
                    flexGrow: 1,
                    display: { xs: "flex", md: "none" },
                    justifyContent: "flex-end",
                  }}
                >
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                  >
                    <MenuIcon sx={{ color: "#000" }} />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                      display: { xs: "block", md: "none" },
                    }}
                  >
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Grid
                        lg={12}
                        xs={12}
                        container
                        direction="column"
                        alignItems="center"
                      >
                        <div
                          className={
                            location.pathname === "/dashboard"
                              ? "linkBgcolorinNavbarDropdown pointer"
                              : "linkBgcolorinNavbarDropdown1 pointer"
                          }
                          role="presentation"
                          onClick={() => navigate("/dashboard")}
                        >
                          <TiHome size={20} className="mr-1" />
                          <small className="InterMd">DashBoard</small>
                        </div>
                        {/* <div
                          className={
                            location.pathname === "/shipments"
                              ? "linkBgcolorinNavbarDropdown mt-3 pointer"
                              : "linkBgcolorinNavbarDropdown1 mt-3 pointer"
                          }
                          role="presentation"
                          onClick={() => navigate("/shipments")}
                        >
                          <FaTruckMoving size={20} className="mr-1" />
                          <small className="InterMd">Shipments</small>
                        </div> */}
                        <div
                          className={
                            location.pathname === "/tracker"
                              ? "linkBgcolorinNavbarDropdown mt-3 pointer"
                              : "linkBgcolorinNavbarDropdown1 mt-3 pointer"
                          }
                          role="presentation"
                          onClick={() => navigate("/tracker")}
                        >
                          <CgTrack size={20} className="mr-1" />
                          <small className="InterMd">Trackers</small>
                        </div>
                        <div
                          className={
                            location.pathname === "/reports"
                              ? "linkBgcolorinNavbarDropdown mt-3 pointer"
                              : "linkBgcolorinNavbarDropdown1 mt-3 pointer"
                          }
                          role="presentation"
                          onClick={() => navigate("/reports")}
                        >
                          <FaFileAlt size={20} className="mr-1" />
                          <small className="InterMd">Reports</small>
                        </div>
                        {/* <div
                          className={
                            location.pathname === "/mails"
                              ? "linkBgcolorinNavbarDropdown mt-3 pointer"
                              : "linkBgcolorinNavbarDropdown1 mt-3 pointer"
                          }
                          role="presentation"
                          onClick={() => navigate("/mails")}
                        >
                          <FaTicketAlt size={20} className="mr-1" />
                          <small className="InterMd">Mails & Tickets</small>
                        </div> */}
                        {/* <div
                          className={
                            location.pathname === "/profile"
                              ? "linkBgcolorinNavbarDropdown mt-3 pointer"
                              : "linkBgcolorinNavbarDropdown1 mt-3 pointer"
                          }
                          role="presentation"
                          onClick={() => navigate("/profile")}
                        >
                          <small className="InterMd">Profile</small>
                        </div> */}
                      </Grid>
                    </MenuItem>
                  </Menu>
                </Box>
              </Grid>
              <Grid md={0.5}>
                <Grid
                  className="linkBgcolorinNavbar1 pointer dropdown-toggle"
                  // display="grid"
                  direction="column"
                  type="button"
                  // id="dropdownMenu3"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  lg={1}
                  md={1}
                  sm={1}
                  xs={1}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <IoNotificationsSharp size={24} className="mr-1" />
                  <Badge
                    badgeContent={Notifications.length}
                    color="success"
                    sx={{
                      "& .MuiBadge-badge": {
                        color: "#fff",
                        backgroundColor: "#C92828",
                        fontFamily: "InterRegular",
                      },
                      width: "100%",
                    }}
                  >


                  </Badge>

                  {/* <small className="InterMd">Notification</small> */}
                </Grid>
                <div
                  className="dropdown-menu "
                  aria-labelledby="dropdownMenu3"
                  style={{ width: "30%", maxHeight: "300px", minHeight: "200px", overflow: "scroll" }}
                >
                  {
                    Notifications.length > 0 ?
                      Notifications.map((data, i) => {
                        return (
                          <div className="dropdown-item "
                            aria-hidden="true"
                            onClick={() => NavigatePageUser(data)}
                            key={i} style={{
                              width: "98%", whiteSpace: "unset", marginBottom: "3px",
                              backgroundColor: "#eaeef2", color: "#020202", fontFamily: "InterMedium", borderRadius: "3px", cursor: "pointer"
                            }}>
                            <p className="InterMd" style={{ marginBottom: "0px" }}>{data.notificationsName} </p><b />
                            <div style={{ flexDirection: "row", justifyContent: "space-between", display: "flex" }}>
                              <p className="InterRg" style={{ marginBottom: "0px" }}> {data.notificationsDescription} </p>
                              <small className="InterLt" style={{ marginBottom: "0px", textAlign: "end" }}> {data.createdAt.slice(0, 15)}</small>
                            </div>
                          </div>
                        )
                      })
                      :
                      <>
                        <div className="dropdown-item " aria-hidden="true"
                          style={{
                            width: "98%", whiteSpace: "unset", marginBottom: "3px",
                            backgroundColor: "#eaeef2", color: "#020202", fontFamily: "InterMedium", borderRadius: "3px", cursor: "pointer"
                          }}>
                          <p className="InterMd" style={{ marginBottom: "0px" }}> No data Found </p><b />
                          <div style={{ flexDirection: "row", justifyContent: "space-between", display: "flex" }}>
                            {/* <p className="InterRg" style={{ marginBottom: "0px" }}> No data Found </p> */}
                          </div>
                        </div>
                      </>
                  }
                </div>
              </Grid>
              <Grid md={2} sm={2} xs={2} container>
                <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                  <Grid
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Grid lg={4} xs={3} container justifyContent="flex-end">
                      <Avatar src={`${process.env.REACT_APP_S3_LINK}profile/${userData?.Logo}`} height="100%" width="100%"
                      />
                    </Grid>
                    <Grid lg={8} xs={9} container>
                      <Grid
                        display="grid"
                        direction="column"
                        className=" dropdown-toggle"
                        type="button"
                        id="dropdownMenu2"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <font className="textBlack InterMd pl-1">
                          {email === "ROLE_SUPERADMIN" ? (<>GTAI Admin <FiChevronDown /></>) : (<>{userData?.organizationName?.slice(0, 18) + ".."}<FiChevronDown /></>)}
                        </font>
                        <small className="textGray1 InterMd pl-1">
                          {email === "ROLE_SUPERADMIN" ? (<>{count?.userData?.email?.slice(0, 18) + ".."}</>) : (<>{count?.userData?.email?.slice(0, 18) + ".."}</>)}
                        </small>
                      </Grid>
                      <div
                        className="dropdown-menu "
                        aria-labelledby="dropdownMenu2"
                      >
                        <button
                          className="dropdown-item btn  mt-3 d-flex align-items-center"
                          type="button"
                          onClick={() => navigate("/profile")}
                          style={{ padding: "4%" }}
                        >
                          <Avatar src={userData?.profilePic} sx={{ width: 20, height: 20 }} className="mr-2" /> View Profile
                        </button>
                        <button
                          className="dropdown-item btn  mt-3 d-flex align-items-center"
                          type="button"
                          onClick={() => setLogmodal(true)}
                          style={{ padding: "4%" }}
                        >
                          <AiOutlineLogout size={20} className="mx-2" /> Logout
                        </button>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </>)}
        </Toolbar>

        <Modal
          open={Openmodal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="center">
            <Grid lg={4} md={6} sm={8} xs={11} container sx={style}>
              <Grid lg={12} xs={12} container>
                <Grid
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <h4 className="textBlack InterBd m-0">Access Restricted</h4>
                  <CgClose
                    size={32}
                    color={"rgba(0, 0, 0, 1)"}
                    className="pointer"
                    onClick={() => {
                      setOpenmodal(false);
                    }}
                  />
                </Grid>
                <Grid
                  lg={12}
                  xs={12}
                  container
                  alignItems="flex-start"
                  direction="column"
                  className="pt-2"
                >
                  <font className="InterSm textBlack mt-3">Please purchase one of our plans to enjoy our services.</font>
                  <NavLink
                    to="/Profile#plans"
                    smooth={true}
                    spy="true"
                    duration={2000}
                    className="btn py-2 mt-3"
                    onClick={() => setOpenmodal(false)}
                  >
                    View Plans
                  </NavLink>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
        <LogoutModal open={logmodal} setOpen={setLogmodal} />
      </Container>
    </AppBar>
  );
}
export default NavBar;
