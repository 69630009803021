import React, { useState } from "react";
import { Grid, TableBody, TableCell, TableContainer, TableHead, TableRow, Table, TablePagination, Button } from "@mui/material";
import "../table.css";
import { FaSort } from "react-icons/fa";
import ApprovalRequest from "../../modal/ApprovalRequest";
import { FormatDate1 } from "../../../helpers/index";
import LoadingData from "../../../assets/images/dashboardImages/loadingData.svg";
import LinkExpired from "../../../assets/images/dashboardImages/linkExpired.svg";

const ApprovalRequestTable = ({ organizationsData, changeBtn, getApproveUsers,loading,approvehandleAZsort }) => {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = useState(false);
  const [organizationData, setOrganizationData] = useState("");
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [sortByorganization,setSortByOrganization] = useState(false);

  const sortorgnamefunction=async()=>{
    if(sortByorganization){
      approvehandleAZsort(true)
      setSortByOrganization(false)
    }else{
      approvehandleAZsort(false)
      setSortByOrganization(true)
    }
  }

  const [sortByData, setSortByData] = useState(false);

  const handleSort = (val) => {
    if (val === "sort") {
      organizationsData?.sort(function (a, b) { return ((new Date(b.createdAt)).getTime()) - ((new Date(a.createdAt)).getTime()) });
      setSortByData(true);
    }
    else {
      organizationsData?.sort(function (a, b) { return ((new Date(a.createdAt)).getTime()) - ((new Date(b.createdAt)).getTime()) });
      setSortByData(false);
    }
  }

  return (
    <div>
      {organizationsData.length !== 0 ? (<>
        <Grid lg={12} xs={12} sx={{ overflow: "auto" }}>
          <TableContainer className="mobileviewInTable hide_scroll" sx={{ height: "55vh" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" className="reportTbcell">
                    <font className="textBlack InterMd" size={3}>Organization Name <FaSort color={"#000"} size={20} className="pl-2 pointer" onClick={() => sortorgnamefunction()}/></font>
                  </TableCell>
                  <TableCell align="left" className="reportTbcell">
                    <font className="textBlack InterMd" size={3}>Organization Type</font>
                  </TableCell>
                  <TableCell align="left" className="reportTbcell">
                    <font className="textBlack InterMd" size={3}>Work Email</font>
                  </TableCell>
                  <TableCell align="left" className="reportTbcell">
                    <font className="textBlack InterMd" size={3}>Requested On <FaSort color={"#000"} size={20} className="pl-2 pointer" onClick={() => handleSort(sortByData === false ? "sort" : "sort1")}/></font>
                  </TableCell>
                  <TableCell align="left" className="reportTbcell">
                    <font className="textBlack InterMd" size={3}>Action</font>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {organizationsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                  return (<>
                    <TableRow key={i} sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}>
                      <TableCell align="left">
                        <Grid lg={12} xs={12} container direction="column">
                          <font className="textBlack InterSm">{row?.organizationName}</font>
                        </Grid>
                      </TableCell>
                      <TableCell align="left">
                        <Grid lg={12} xs={12} container justifyContent="flex-start" alignItems="center">
                          <font className="textBlack InterMd">{row?.organizationType}</font>
                        </Grid>
                      </TableCell>
                      <TableCell align="left">
                        <Grid lg={12} xs={12} container justifyContent="flex-start" alignItems="center">
                          <font className="textBlack InterMd">{row?.email}</font>
                        </Grid>
                      </TableCell>
                      <TableCell align="left">
                        <Grid lg={12} xs={12} container justifyContent="flex-start" alignItems="center">
                          <font className="textBlack InterMd">{FormatDate1(row?.createdAt)}</font>
                        </Grid>
                      </TableCell>
                      <TableCell align="left">
                        <Grid lg={12} xs={12} container justifyContent="flex-start" alignItems="center">
                          <Button className="borderBluebtn" onClick={() => { setOpen(true); setOrganizationData(row); }}>View</Button>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  </>)
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid lg={12} xs={12} container justifyContent="flex-end" alignItems="center" sx={{ borderTop: "1px solid rgba(224, 224, 224, 1)" }}>
            <TablePagination className="globalTablePagination"
              sx={{ color: "#656873" }}
              rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
              component="div"
              count={organizationsData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </>) : (<>
        <Grid lg={12} xs={12} container justifyContent="center" alignItems="center" alignContent="center" className="">
          <Grid lg={10} xs={12} container justifyContent="center" alignItems="center" className="">
            {loading === false ? (<>
              <img src={LoadingData} className="img-fluid" alt="NoDataImage" />
            </>) : (<>
              <img src={LinkExpired} className="linkExpiredImage" alt="NoDataImage" />
            </>)}
          </Grid>
          <Grid lg={10} xs={12} container justifyContent="center" className="">
            <h4 className="textBlack InterBd">{changeBtn === "organizations" ? "No Organizations Available to Display" : "No Requests Available to Display"}</h4>
          </Grid>
        </Grid>
      </>)}
      <ApprovalRequest open={open} setOpen={setOpen} organizationData={organizationData} getApproveUsers={getApproveUsers} />
    </div>
  );
};

export default ApprovalRequestTable;
