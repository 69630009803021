import React, { useState } from "react";
import {
  Grid,
  Button,
  Modal,
  Box,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
} from "@mui/material";
import "./modal.css";
import { CgClose } from "react-icons/cg";
import BatteryGauge from "react-battery-gauge";
import { CgSearch } from "react-icons/cg";
import getHeaders from "../../services";
import { putMethod } from "../../apis";
import { toast } from "react-toastify";
const AdminTrackerDetail = ({
  open,
  setOpen,
  TrackerDatas,
  getMyTrackersLive,
}) => {
  const headers = getHeaders();
  const [DeactivateConfirm, setConfirm] = useState(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255, 255, 255, 1)",
    boxShadow: "0px 1px 13px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    width: "100%",
    padding: "2%",
  };

  const customization = {
    batteryMeter: {
      fill: "#F91818",
      outerGap: 1,
      noOfCells: 1, // more than 1, will create cell battery
      interCellsGap: 1,
    },
    readingText: {
      display: "none",
    },
  };

  const customization1 = {
    batteryMeter: {
      fill: "#F3F82B",
      outerGap: 1,
      noOfCells: 1, // more than 1, will create cell battery
      interCellsGap: 1,
    },
    readingText: {
      display: "none",
    },
  };

  const customization2 = {
    batteryMeter: {
      fill: "#2BB372",
      outerGap: 1,
      noOfCells: 1, // more than 1, will create cell battery
      interCellsGap: 1,
    },
    readingText: {
      display: "none",
    },
  };
  function timeconvertdays(date) {
    var a = new Date(date);
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var dates = a.getDate();
    // var time = a.toLocaleTimeString();
    var hour = a.getHours();
    var min = a.getMinutes();
    var AmOrPm = hour >= 12 ? "PM" : "AM";
    hour = hour % 12 || 12;
    var result =
      dates +
      " " +
      month +
      " " +
      year +
      "   " +
      " ( " +
      hour +
      ":" +
      min +
      " : " +
      AmOrPm +
      " )";
    return result;
  }

  const Deactivate = async (values) => {
    if (!TrackerDatas.ShipmentAssign) {
      let url = "api/tracker/deactivate";
      let params = {
        TrackerSerial: TrackerDatas.TrackerSerial,
        status: values,
      };
      const response = await putMethod({ url, params, headers });
      if (response.status === true) {
        setConfirm(false);
        setOpen(false);
        getMyTrackersLive();
        toast.success("Deactivation Success");
      } else {
        setConfirm(false);
        setOpen(false);
        getMyTrackersLive();
        toast.warn("deactivation Failed Try Again ");
      }
    } else {
      toast.warn("Tracker Already Activated In Shipment");
    }
  };

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="center">
          <Grid lg={6} md={6} sm={8} xs={11} container sx={style}>
            <Grid
              lg={12}
              md={12}
              sm={12}
              xs={12}
              container
              justifyContent="space-between"
              alignfonts="center"
            >
              <Grid lg={11} md={11} xs={11}>
                <h4 className="textBlack InterBd m-0">Tracker Details</h4>
              </Grid>
              <Grid lg={1} md={1} xs={1}>
                <CgClose
                  size={32}
                  color={"rgba(0, 0, 0, 1)"}
                  className="pointer"
                  onClick={() => {
                    setOpen(false);
                  }}
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                width: "100%",
                height: "400px",
                overflowY: "scroll",
                scrollbarWidth: "thin",
                "&::-webkit-scrollbar": {
                  width: "1px",
                  borderRadius: "1px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#fff",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#fff",
                },
              }}
            >
              <Stack spacing={2} className="w-100">
                <Grid
                  container
                  rowSpacing={1}
                  sx={{ padding: "2% 2%" }}
                  className="border rounded mt-3"
                >
                  <Grid item lg={12} xs={12} container direction="row">
                    <Grid lg={5} md={6} xs={6}>
                      <font className="textGray InterRg">Trackers Name</font>
                    </Grid>
                    <Grid lg={7} md={6} xs={6}>
                      <font className="textBlack InterRg">
                        {TrackerDatas.TrackerName}
                      </font>
                    </Grid>
                  </Grid>
                  <Grid item lg={12} xs={12} container direction="row">
                    <Grid lg={5} md={6} xs={6}>
                      <font className="textGray InterRg">Trackers Model</font>
                    </Grid>
                    <Grid lg={7} md={6} xs={6}>
                      <font className="textBlack InterRg">
                        {TrackerDatas.TrackerModel}
                      </font>
                    </Grid>
                  </Grid>
                  <Grid item lg={12} xs={12} container direction="row">
                    <Grid lg={5} md={6} xs={6}>
                      <font className="textGray InterRg">Trackers Serial</font>
                    </Grid>
                    <Grid lg={7} md={6} xs={6}>
                      <font className="textBlack InterRg">
                        {TrackerDatas.TrackerSerial}
                      </font>
                    </Grid>
                  </Grid>
                  <Grid item lg={12} xs={12} container direction="row">
                    <Grid lg={5} md={6} xs={6}>
                      <font className="textGray InterRg">Description</font>
                    </Grid>
                    <Grid lg={7} md={6} xs={6}>
                      <font className="textBlack InterRg">
                        {TrackerDatas.Description}
                      </font>
                    </Grid>
                  </Grid>
                  <Grid item lg={12} xs={12} container direction="row">
                    <Grid lg={5} md={6} xs={6}>
                      <font className="textGray InterRg">Buyer Organization</font>
                    </Grid>
                    <Grid lg={7} md={6} xs={6}>
                      <font className="textBlack InterRg">
                        {TrackerDatas?.user ? TrackerDatas?.user[0]?.organizationName : ""}
                      </font>
                    </Grid>
                  </Grid>
                  <Grid item lg={12} xs={12} container direction="row">
                    <Grid lg={5} md={6} xs={6}>
                      <font className="textGray InterRg">Buyer Email</font>
                    </Grid>
                    <Grid lg={7} md={6} xs={6}>
                      <font className="textBlack InterRg">
                        {TrackerDatas.BuyerEmail}
                      </font>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    xs={12}
                    container
                    direction="row"
                  >
                    <Grid lg={5} md={6} xs={6}>
                      <font className="textGray InterRg">Activated By</font>
                    </Grid>
                    <Grid lg={7} md={6} xs={6}>
                      <font className="textBlack InterRg">
                        {TrackerDatas.user
                          ? TrackerDatas.user[0]?.organizationName
                          : ""}
                      </font>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    xs={12}
                    container
                    direction="row"
                  >
                    <Grid lg={5} md={6} xs={6}>
                      <font className="textGray InterRg">Activated on</font>
                    </Grid>
                    <Grid lg={7} md={6} xs={6}>
                      <font className="textBlack InterRg">
                        {timeconvertdays(TrackerDatas.ActivatedOn)}
                      </font>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    xs={12}
                    container
                    direction="row"
                  >
                    <Grid lg={5} md={6} xs={6}>
                      <font className="textGray InterRg">Battery Level</font>
                    </Grid>
                    <Grid
                      lg={7}
                      md={6}
                      xs={6}
                      container
                      direction="row"
                      alignItems="flex-start"
                    >
                      <BatteryGauge
                        style={{ height: "20px" }}
                        customization={
                          TrackerDatas.Battery < 20
                            ? customization
                            : TrackerDatas.Battery < 50
                              ? customization1
                              : TrackerDatas.Battery < 100
                                ? customization2
                                : customization2
                        }
                        size={100}
                        value={TrackerDatas.Battery}
                      />
                      <font className="textBlack InterRg  ">
                        {TrackerDatas.Battery} 50 %
                      </font>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    xs={12}
                    container
                    direction="row"
                  >
                    <Grid lg={5} md={6} xs={6}>
                      <font className="textGray InterRg">Current Location</font>
                    </Grid>
                    <Grid lg={7} md={6} xs={6}>
                      <font className="textBlack InterRg">
                        {TrackerDatas.CurrentLocation
                          ? TrackerDatas.CurrentLocation
                          : "725 5th Ave, New York, NY 10022, USA"}
                      </font>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  lg={12}
                  xs={12}
                  md={12}
                  sx={{ padding: "2% 2%" }}
                  className="py-3"
                  container
                  direction="row"
                  justifyContent="space-between"
                >
                  {TrackerDatas.TrackerData?.length <= 0 ? (
                    <h5 className="InterBd textBlack ">
                      No Notification Here :)
                    </h5>
                  ) : (
                    <>
                      <h5 className="InterBd textBlack">Notification Log</h5>
                      <Grid lg={6} md={6}>
                        <div className="inputBox1 px-2 py-2 d-flex align-items-center">
                          <input
                            className="shipmentsSearchbar "
                            placeholder="Search Notifications"
                          />
                          <CgSearch size={22} color={"#999999"} />
                        </div>
                      </Grid>

                      <Grid lg={12} xs={12}>
                        <TableContainer className="mobileviewInTable border rounded mt-4">
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="left"
                                  className="reportTbcell"
                                >
                                  <font className="textBlack InterMd" size={3}>
                                    Alert Name
                                  </font>
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className="reportTbcell"
                                >
                                  <font className="textBlack InterMd" size={3}>
                                    Date & Time
                                  </font>
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className="reportTbcell"
                                >
                                  <font className="textBlack InterMd" size={3}>
                                    Message Type
                                  </font>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {TrackerDatas.TrackerData?.map((row, i) => {
                                return (
                                  <>
                                    <TableRow
                                      key={i}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell align="left">
                                        <Grid
                                          lg={12}
                                          xs={12}
                                          container
                                          direction="column"
                                        >
                                          {row.AlertName}
                                        </Grid>
                                      </TableCell>
                                      <TableCell align="left">
                                        <Grid
                                          lg={12}
                                          xs={12}
                                          container
                                          justifyContent="flex-start"
                                          alignItems="center"
                                        >
                                          <font className="textBlack InterMd">
                                            {row.DataTime}
                                          </font>
                                        </Grid>
                                      </TableCell>
                                      <TableCell align="left">
                                        <Grid
                                          lg={12}
                                          xs={12}
                                          container
                                          justifyContent="flex-start"
                                          alignItems="center"
                                        >
                                          <font className="textBlack InterMd">
                                            {row.MessageType}
                                          </font>
                                        </Grid>
                                      </TableCell>
                                    </TableRow>
                                  </>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Stack>
            </Box>
            <Grid
              lg={12}
              xs={12}
              container
              justifyContent="center"
              alignfonts="center"
              className="mt-5"
            >
              <Grid
                lg={10}
                xs={10}
                container
                justifyContent="space-evenly"
                alignfonts="center"
              >
                <Grid lg={3} xs={3}>
                  <Button
                    className="btngray shipmentsbtnspadding"
                    onClick={() => setOpen(false)}
                  >
                    cancel
                  </Button>
                </Grid>
                <Grid lg={3} xs={5}>
                  <Button
                    className="btnRed shipmentsbtnspadding"
                    onClick={() => {
                      setConfirm(true);
                    }}
                  >
                    {TrackerDatas.ActivationStatus == "Activated"
                      ? "Deactivate"
                      : "Activate"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>

      <Modal
        open={DeactivateConfirm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="center">
          <Grid lg={4} md={6} sm={8} xs={11} container sx={style}>
            <Grid
              lg={12}
              md={12}
              sm={12}
              xs={12}
              container
              justifyContent="space-between"
              alignfonts="center"
            >
              <h4 className="textBlack InterBd m-0">
                Are you sure want to deactivate {TrackerDatas.TrackerName} ?
              </h4>
              <Grid
                lg={12}
                xs={12}
                container
                justifyContent="center"
                alignfonts="center"
                className="mt-5"
              >
                <Grid
                  lg={10}
                  xs={10}
                  container
                  justifyContent="space-around"
                  alignfonts="center"
                >
                  <Grid lg={3} xs={5}>
                    <Button
                      className="btngray shipmentsbtnspadding"
                      onClick={() => setConfirm(false)}
                    >
                      cancel
                    </Button>
                  </Grid>
                  <Grid lg={3} xs={5}>
                    <Button
                      className="btnRed shipmentsbtnspadding"
                      onClick={() =>
                        TrackerDatas.ActivationStatus == "Activated"
                          ? Deactivate("Deactivate")
                          : Deactivate("Activated")
                      }
                    >
                      {TrackerDatas.ActivationStatus == "Activated"
                        ? "Deactivate"
                        : "Activate"}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default AdminTrackerDetail;
