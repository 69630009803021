import React, { useState } from "react";
import { Grid, Modal, Avatar, Box, Button } from "@mui/material";
import { CgClose } from "react-icons/cg";
import "./modal.css";
// import logoProfile from "../../assets/images/logoProfile.png";
// import TableToolTip from "../../helpers/toolbar";
import { FormatDate1 } from "../../helpers/index";
import { useSelector } from "react-redux";
import { putMethod } from "../../apis/index";
import { toast } from "react-toastify";

const OrganizationModal = ({ open, setOpen, organizationData1,getAdminDashBoardDetails }) => {

  const count = useSelector((state) => state.WalletConnect)
  // let email = count?.userData?.length === 0 ? "" : count?.userData?.roles[0];

  const [openReject, setOpenReject] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255, 255, 255, 1)",
    boxShadow: "0px 1px 13px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    width: "100%",
    height: "calc(100% - 50px)",
    padding: "2%",
  };

  const style1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255, 255, 255, 1)",
    boxShadow: "0px 1px 13px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    width: "100%",
    padding: "1.5%"
  };

  const editOrganization = async (val) => {
    try {
      const params = {
        email: organizationData1?.organization?.email,
        status:val,
      };
      let headers = {
        "Content-Type": "application/json",
        "x-access-token": count?.userData?.accessToken,
        "Access-Control-Allow-Origin": "*"
      };
      let url = "api/organization/update";
      let response = await putMethod({ url, params, headers });
      if (response.message === "User not Found") {
        toast.warn("Email doesn't exist");
      }
      else if (response.message === "update  Failed") {
        toast.warn("Try Again");
      }
      else if (response.message === "Organization Updated") {
        if(val === true){
          toast.success("Organization Activated");
          setOpenReject(false);
          getAdminDashBoardDetails();
        }
        else{
          toast.success("Organization Deactivated");
          setOpenReject(false);
          getAdminDashBoardDetails();
        }
      }
      else{
        toast.warn("Try Again");
      }
    }
    catch (err) {
      console.log("Error in editOrganization", err);
    }
  }

  const timeconvertdays = (unix_timestamp) => {
    var a = new Date(unix_timestamp);
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    // var hour = a.getHours();
    // var min = a.getMinutes();
    // var sec = a.getSeconds();
    var time = date + "-" + month + "-" + year; //+ ' ' + hour + ':' + min + ':' + sec;
    return time;
  };

  return (<>
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="center">
        <Grid lg={5} md={6} sm={8} xs={11} container sx={style}>
          <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="space-between" alignItems="center">
            <h4 className="textBlack InterBd m-0">Organization Details</h4>
            <CgClose size={32} color={"rgba(0, 0, 0, 1)"} className="pointer" onClick={() => setOpen(false)} />
          </Grid>
          <Box sx={{
            width: "100%",
            height: "calc(100% - 100px)",
            overflowY: "scroll",
            scrollbarWidth: "thin",
            "&::-webkit-scrollbar": {
              width: "1px",
              borderRadius: "1px"
            },
            "&::-webkit-scrollbar-track": {
              background: "#fff",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#fff",
            },
          }}>
            <Grid lg={12} md={12} sm={12} xs={12} container className="pt-3 mb-5">
              <font className="textBlack InterSm">Organization Details</font>
              <div className="borderBox w-100 px-3 py-3 mt-2">
                <Grid lg={12} xs={12} container>
                  {organizationData1?.organization?.Logo ?
                    <Avatar sx={{height:"100px" , width:"100px"}}>
                      <img src={`${process.env.REACT_APP_S3_LINK}profile/${organizationData1?.organization?.Logo}`} alt="profile" className="img-fluid  " />
                    </Avatar>:
                    <Avatar sx={{height:"100px" , width:"100px"}} />}
                </Grid>
                <Grid lg={12} xs={12} container className="pt-3" justifyContent="space-between">
                  <Grid lg={5.8} xs={12} container justifyContent={{ xs: "space-between", lg: "flex-start" }}>
                    <Grid lg={6} xs={6} container>
                      <small className="InterRg textGray1">Organization Name</small>
                    </Grid>
                    <Grid lg={6} xs={6} container>
                      <small className="InterMd textBlack">{organizationData1?.organization?.organizationName}</small>
                    </Grid>
                  </Grid>
                  <Grid lg={5.8} xs={12} container justifyContent="space-between">
                    <Grid lg={6} xs={6} container>
                      <small className="InterRg textGray1">Organization Type</small>
                    </Grid>
                    <Grid lg={6} xs={6} container direction="column">
                      <small className="InterMd textBlack">{organizationData1?.organization?.organizationType}</small>
                    </Grid>
                  </Grid>
                  <Grid lg={5.8} xs={12} container justifyContent="space-between" className="pt-3">
                    <Grid lg={6} xs={6} container>
                      <small className="InterRg textGray1">Joined On</small>
                    </Grid>
                    <Grid lg={6} xs={6} container direction="column">
                      <small className="InterMd textBlack">{FormatDate1(organizationData1?.organization?.createdAt)}</small>
                    </Grid>
                  </Grid>
                  <Grid lg={5.8} xs={12} container justifyContent="space-between" className="pt-3">
                    <Grid lg={6} xs={6} container>
                      <small className="InterRg textGray1">Contact Number</small>
                    </Grid>
                    <Grid lg={6} xs={6} container className="pointer" direction="column">
                      <small className="InterMd textBlack">{organizationData1?.organization?.conatctNumber?.replace(/\D+/g, "").replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "+$1 ($2) $3-$4")}</small>
                    </Grid>
                  </Grid>
                  <Grid lg={12} xs={12} container justifyContent="" className="pt-3">
                    <Grid lg={2.9} xs={3} container>
                      <small className="InterRg textGray1">Work Email</small>
                    </Grid>
                    <Grid lg={9} xs={9} container direction="column">
                      <small className="InterMd textBlack">{organizationData1?.organization?.email}</small>
                    </Grid>
                  </Grid>
                  <Grid lg={5.8} xs={12} container justifyContent="space-between" className="pt-3">
                    <Grid lg={6} xs={6} container>
                      <small className="InterRg textGray1">Status</small>
                    </Grid>
                    <Grid lg={6} xs={6} container direction="column">
                      <small className={organizationData1?.organization?.status === false ? "textRed InterMd" : "textGreen InterMd"}>{organizationData1?.organization?.status === false ? "Inactive" : "Active"}</small>
                    </Grid>
                  </Grid>
                  <Grid lg={12} xs={12} container justifyContent="" className="pt-3">
                    <Grid lg={2.9} xs={3} container>
                      <small className="InterRg textGray1">Address</small>
                    </Grid>
                    <Grid lg={9} xs={9} container direction="column">
                      <small className="InterMd textBlack">{organizationData1?.organization?.address}</small>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid lg={12} xs={12} container justifyContent="space-between" alignItems="center" className="pt-3">
                  <Grid lg={3.8} xs={3.8} container className="borderBox px-2 py-2" direction="column">
                    <small className="InterRg textGray1">Active Trackers</small>
                    <small className="InterSm textBlack">{organizationData1?.trackers?.length}</small>
                  </Grid>
                  <Grid lg={3.8} xs={3.8} container className="borderBox px-2 py-2" direction="column">
                    <small className="InterRg textGray1">Active Shipments</small>
                    <small className="InterSm textBlack">{organizationData1?.shipments?.length}</small>
                  </Grid>
                  <Grid lg={3.8} xs={3.8} container className="borderBox px-2 py-2" direction="column">
                    <small className="InterRg textGray1">Total Shipments</small>
                    <small className="InterSm textBlack">{organizationData1?.shipments?.length}</small>
                  </Grid>
                </Grid>
              </div>
              <font className="textBlack InterSm pt-2">Contact Person</font>
              <div className="borderBox w-100 px-3 py-3 mt-2">
                <Grid lg={12} xs={12} container justifyContent="space-between">
                  <Grid lg={5.8} xs={12} container justifyContent={{ xs: "space-between", lg: "flex-start" }}>
                    <Grid lg={6} xs={6} container>
                      <small className="InterRg textGray1">Full Name</small>
                    </Grid>
                    <Grid lg={6} xs={6} container direction="column">
                      <small className="InterMd textBlack">{organizationData1?.organization?.userName}</small>
                    </Grid>
                  </Grid>
                  <Grid lg={5.8} xs={12} container justifyContent="space-between">
                    <Grid lg={6} xs={6} container>
                      <small className="InterRg textGray1">Contact Number</small>
                    </Grid>
                    <Grid lg={6} xs={6} container direction="column">
                      <small className="InterMd textBlack">{organizationData1?.organization?.conatctNumber?.replace(/\D+/g, "").replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "+$1 ($2) $3-$4")}</small>
                    </Grid>
                  </Grid>
                  <Grid lg={12} xs={12} container justifyContent="" className="pt-2">
                    <Grid lg={2.9} xs={3} container>
                      <small className="InterRg textGray1">Address</small>
                    </Grid>
                    <Grid lg={9} xs={9} container direction="column">
                      <small className="InterMd textBlack">{organizationData1?.organization?.address}</small>
                    </Grid>
                  </Grid>
                  <Grid lg={12} xs={12} container justifyContent="" className="pt-2">
                    <Grid lg={2.9} xs={3} container>
                      <small className="InterRg textGray1">Description</small>
                    </Grid>
                    <Grid lg={9} xs={9} container direction="column">
                      <small className="InterMd textBlack">{organizationData1?.organization?.Description}</small>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              <font className="textBlack InterSm pt-2">Plan Details</font>
              <div className="borderBox w-100 px-3 py-3 mt-2">
                <Grid lg={12} xs={12} container justifyContent="space-between">
                  <Grid lg={5.8} xs={12} container justifyContent={{ xs: "space-between", lg: "flex-start" }}>
                    <Grid lg={6} xs={6} container>
                      <small className="InterRg textGray1">Plan Type</small>
                    </Grid>
                    <Grid lg={6} xs={6} container className="pointer" direction="column">
                      <small className="InterMd textBlack">{!organizationData1?.organization?.Plan[0]?.PlanName ? "-" : organizationData1?.organization?.Plan[0]?.PlanName}</small>
                    </Grid>
                  </Grid>
                  <Grid lg={5.8} xs={12} container justifyContent="space-between">
                    <Grid lg={6} xs={6} container>
                      <small className="InterRg textGray1">Billing Amount</small>
                    </Grid>
                    <Grid lg={6} xs={6} container className="pointer" direction="column">
                      <small className="InterMd textBlack">{!organizationData1?.organization?.Plan[0]?.PlanName ? "-" : organizationData1?.organization?.Plan[0]?.Amount}</small>
                    </Grid>
                  </Grid>
                  <Grid lg={5.8} xs={12} container justifyContent="space-between" className="pt-3">
                    <Grid lg={6} xs={6} container>
                      <small className="InterRg textGray1">Transaction ID</small>
                    </Grid>
                    <Grid lg={6} xs={6} container className="pointer" direction="column" sx={{ overflow: "hidden" }}>
                      <small className="InterMd textBlack">{organizationData1?.organization?.Plan[0]?.paymentID ? organizationData1?.organization?.Plan[0]?.paymentID : "-" }</small>
                    </Grid>
                  </Grid>
                  <Grid lg={5.8} xs={12} container justifyContent="space-between" className="pt-3">
                    <Grid lg={6} xs={6} container>
                      <small className="InterRg textGray1">Next Payment</small>
                    </Grid>
                    <Grid lg={6} xs={6} container className="pointer" direction="column">
                      <small className="InterMd textBlack">{organizationData1?.organization?.Plan[0]?.CurrentDate ? timeconvertdays(organizationData1?.organization?.Plan[0]?.CurrentDate + (86400000 * 30)) : "- - -"}</small>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Box>
          <Grid lg={12} xs={12} container justifyContent="center" alignItems="center" className="mt-3">
            <Grid lg={8} xs={10} container justifyContent="space-around" alignItems="center">
              <Grid lg={5} xs={5}>
                <Button className="organizationCalcelbtn shipmentsbtnspadding" onClick={() => setOpen(false)}>Cancel</Button>
              </Grid>
              <Grid lg={5} xs={5}>
                {organizationData1?.organization?.status === false ? (<>
                  <Button className="organizationactivatebtn shipmentsbtnspadding" onClick={() => { setOpen(false); setOpenReject(true); }}>Activate</Button>
                </>) : (<>
                  <Button className="organizationdeactivatebtn shipmentsbtnspadding" onClick={() => { setOpen(false); setOpenReject(true); }}>Deactivate</Button>
                </>)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
    <Modal
      open={openReject}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="center">
        <Grid lg={3.5} md={6} sm={8} xs={11} container sx={style1}>
          <Grid lg={12} xs={12} container justifyContent="center" alignItems="center">
            <h5 className="textBlack text-left InterBd m-0">Are you sure you want to {organizationData1?.organization?.status === false ? "Activated" : "Deactivated"} this Organization  “{organizationData1?.organization?.organizationName}” ?</h5>
          </Grid>
          <Grid lg={12} xs={12} container justifyContent="center" alignItems="center" className="pt-4">
            <Grid lg={8} xs={10} container justifyContent="space-around" alignItems="center">
              <Grid lg={5} xs={5}>
                <Button className="organizationCalcelbtn shipmentsbtnspadding" onClick={() => setOpenReject(false)}>Cancel</Button>
              </Grid>
              <Grid lg={5} xs={5}>
                {organizationData1?.organization?.status === false ? (<>
                  <Button className="organizationactivatebtn shipmentsbtnspadding" onClick={() => { editOrganization(true); }}>Activate</Button>
                </>) : (<>
                  <Button className="organizationdeactivatebtn shipmentsbtnspadding" onClick={() => { editOrganization(false); }}>Deactivate</Button>
                </>)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  </>)
}

export default OrganizationModal;