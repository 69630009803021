import React, { useState } from "react";
import { Grid, Button, Stack, Modal } from "@mui/material";
import OTPInput from "otp-input-react";
import { useNavigate } from "react-router-dom";
import QRCodeGenerater from "../../helpers/QRCode";
import { toast } from "react-toastify";
import { postMethod } from "../../apis";
import { TableToolTip } from "../../helpers/toolbar";
// import {useDispatch} from "react-redux";

const EnableGoogle2FA = ({ enableGoogleAuthentication, setEnableGoogleAuthentication, QRData,loginResult }) => {

  // const dispatch = useDispatch();

  const navigate = useNavigate();
  const [otp, setOtp] = useState("");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255, 255, 255, 1)",
    boxShadow: "3px 2px 12px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    width: "100%",
    padding: "2%",
  };


  const OtpInputBox = {
    width: "60px",
    height: "60px",
    textAlign: "center",
    marginRight: window.innerWidth > 600 ? 0 : "4%",
    border: "none",
    outline: "none",
    backgroundColor: "#D9D9D9",
    borderRadius: "40px",
    color: "#000",
    fontSize: "20px",
    fontFamily: "InterSemiBold"
  }

  const onHanleOtpValidation = () =>{
    { otp.length !== 6 ? (toast.warn("Code can't be empty")) : (onhandle2FAVerify()) }
  };

  const onhandle2FAVerify = async () => {
    try {
      const params = {
        token:otp,
        userId: loginResult.id,
        email: loginResult.email,
      };
      let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
      };
      let url = "api/2FA/verify";
      let response = await postMethod({ url, params,headers });
      if(response.verified === true){
        setEnableGoogleAuthentication(false);
        // dispatch({type:"USERDATA_UPDATE" , payload:loginResult})
        setOtp("");
        navigate("/");
        toast.success("2FA Enabled Successfully, You Can Login Now!"); 
      }
      else if(response.message === "error"){
        toast.warn("Try Again");
      }
      else if(response.verified === false){
        toast.warn("Verified Your Code");
      }
    }
    catch(err){
      console.log("Error in 2FAVerify",err);
    }
  }

  return (<>
    <Modal
      open={enableGoogleAuthentication}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="center">
        <Grid lg={4} md={6} sm={8} xs={11} container sx={style} >
          <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="space-between" alignItems="center">
            <h4 className="textBlack InterBd m-0">Enable Google 2FA</h4>
            {/* <CgClose size={32} color={"rgba(0, 0, 0, 1)"} className="pointer" onClick={() => setEnableGoogleAuthentication(false)} /> */}
          </Grid>
          <Grid lg={12} md={12} sm={12} xs={12} container>
            <Stack direction="column" spacing={2} className="w-100">
              <font className="textGray1 InterRg pt-2">You will need a Google Authenticator to complete this process.</font>
              <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="space-between" alignItems="center">
                <Grid lg={4} md={4} sm={4} xs={12} container justifyContent={{ lg: "flex-start", md: "flex-start", sm: "flex-start", xs: "center" }}>
                  <QRCodeGenerater QRData={QRData} />
                </Grid>
                <Grid lg={7.6} md={7.6} sm={7.6} xs={12} container >
                  <small className="textBlack InterRg">1.Open the Google Authenticator app on your device.</small>
                  <small className="textBlack InterRg pt-1">2.Scan this QR code in google authenticator.</small>
                  <small className="textBlack InterRg pt-1">3.Enter the code displayed on the app.</small>
                  <small className="textBlack InterRg pt-1">4.Tap the &quot;Submit&quot; button to login.</small>
                </Grid>
              </Grid>
              <Grid lg={12} md={12} sm={12} xs={12} container direction="column" className="pointer"  >
                <h6 className="textBlack InterSm m-0">Secret Key</h6>
                <Grid container className="example" style={{overflow:"auto" , padding:"0px 0px 10px 0px"}}> 
                  <TableToolTip value={QRData?.base32} type={"2fa"}/>
                </Grid>                
              </Grid>
              <Grid lg={12} md={12} sm={12} xs={12} container>
                <h6 className="textBlack InterSm m-0">Enter the Code</h6>
                <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="space-between" alignItems="center" className="pt-4" sx={{ overflow: "hidden" }}>
                  <OTPInput value={otp} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }} inputStyles={OtpInputBox} onChange={setOtp} OTPLength={6} otpType="number" disabled={false} />
                </Grid>
              </Grid>
              <Button className="btn py-2" onClick={() => { onHanleOtpValidation(); }}>Submit</Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  </>)
};

export default EnableGoogle2FA;