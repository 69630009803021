import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import CheckoutForm from "./checkoutForm";

export default function PaymentForm({ PurchaseData ,setOpen ,getOneUserDetails ,setpayement}) {
  const stripeTestPromise = loadStripe(`${process.env.REACT_APP_PUBLISHABLE_KEY}`);
  return (
    <div className="AppWrapper">
      <Elements stripe={stripeTestPromise}>
        <CheckoutForm PurchaseData={PurchaseData} setOpen={setOpen} setpayement={setpayement} getOneUserDetails={getOneUserDetails} />
      </Elements>
    </div>
  );
}
