import React, { useState } from "react";
import {
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
} from "@mui/material";
import "../table.css";
import NoData from "../../../assets/images/dashboardImages/noData.svg";
import EditContact from "../../modal/EditContact";
import RemoveContact from "../../modal/RemoveContact";
const contactsTable = ({ ContactsData,getContactsDetails }) => {
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState("");
  const [editName,setEditName] = useState("");
  const [editPhoneNo,setEditPhoneNo] = useState("");
  const [editMail,setEditMail] = useState("");
  const [remove  , setRemove] =useState(false);
  const editContact = (row) => {
    if (row) {
      setOpen(true);
      setEditName(row?.Name);
      setEditPhoneNo(row?.phone);
      setEditMail(row?.mail);
      setEditData(row);
    }
  };
  const removeContact = (row) => {
    if (row) {
      setRemove(true);
      setEditName(row?.Name);
      setEditPhoneNo(row?.phone);
      setEditMail(row?.mail);
      setEditData(row);
    }
  };
  return (
    <div>
      {ContactsData?.length !== 0 ? (
        <>
          <Grid lg={12} xs={12} sx={{ overflow: "auto" }}>
            <TableContainer className=" mobileviewInTable">
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left" className="dashboardTbcell">
                      <font className="textBlack InterMd" size={3}>
                        Name
                      </font>
                    </TableCell>
                    <TableCell align="left" className="dashboardTbcell">
                      <font className="textBlack InterMd" size={3}>
                        Phone
                      </font>
                    </TableCell>
                    <TableCell align="left" className="dashboardTbcell">
                      <font className="textBlack InterMd" size={3}>
                        Email
                      </font>
                    </TableCell>
                    <TableCell
                      align="left"
                      className="dashboardTbcell"
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ContactsData?.map((row, i) => {
                    return (
                      <>
                        <TableRow key={i}>
                          <TableCell align="left">
                            <Grid
                              lg={12}
                              xs={12}
                              container
                              justifyContent="flex-start"
                              alignItems="center"
                            >
                              {/* <Avatar sx={{ width: "20px", height: "20px" }} /> */}
                              <font className="textBlack InterRg pl-2">
                                {row?.Name?.length > 20
                                  ? row?.Name?.slice(0, 20) + ".."
                                  : row?.Name}
                              </font>
                            </Grid>
                          </TableCell>
                          <TableCell align="left">
                            <Grid
                              lg={12}
                              xs={12}
                              container
                              justifyContent="flex-start"
                              alignItems="center"
                            >
                              <font className="textBlack InterRg">
                                {row?.phone
                                  ?.replace(/\D+/g, "")
                                  .replace(
                                    /(\d{1})(\d{3})(\d{3})(\d{4})/,
                                    "+$1 ($2) $3-$4"
                                  )}
                                {/* +
                                {row?.phone?.length > 24
                                  ? row?.phone?.slice(0, 24) + ".."
                                  : row?.phone} */}
                              </font>
                            </Grid>
                          </TableCell>
                          <TableCell align="left">
                            <Grid
                              lg={12}
                              xs={12}
                              container
                              justifyContent="flex-start"
                              alignItems="center"
                            >
                              <font className="textBlack InterRg">
                                {row?.mail}
                              </font>
                            </Grid>
                          </TableCell>
                          <TableCell>
                            <Grid
                              display="flex"
                              justifyContent="space-between"
                              direction="row"
                            >
                              <button
                                className="btnTransparent textGray1 mx-2"
                                onClick={() => editContact(row)}
                              >
                                Edit
                              </button>

                              <button className="btnTransparent textGray1 " onClick={()=>removeContact(row)}>
                                Remove
                              </button>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </>
      ) : (
        <>
          <Grid
            lg={12}
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            className=""
          >
            <Grid
              lg={10}
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
              className=""
            >
              <img src={NoData} className="img-fluid" alt="NoDataImage" />
            </Grid>
            <Grid
              lg={10}
              xs={12}
              container
              justifyContent="center"
              className=""
            >
              <h4 className="textBlack InterBd">No contacts Available</h4>
            </Grid>
          </Grid>
        </>
      )}
      <EditContact open={open} setOpen={setOpen}  editName={editName} setEditName={setEditName} editPhoneNo={editPhoneNo} setEditPhoneNo={setEditPhoneNo} editMail={editMail} setEditMail={setEditMail} EditData={editData} getContactsDetails={getContactsDetails}/>
      <RemoveContact open={remove} setOpen={setRemove} editName={editName} setEditName={setEditName} editPhoneNo={editPhoneNo} setEditPhoneNo={setEditPhoneNo} editMail={editMail} setEditMail={setEditMail} EditData={editData} getContactsDetails={getContactsDetails}/>
    </div>
  );
};

export default contactsTable;
