import React from "react";
import { Tooltip } from "@mui/material";

export const TableToolTip = ({ value, type }) => {
  const tooltipTop = {
    "& .MuiTooltip-tooltip": {
      color: "#000",
      backgroundColor: "#fff",
      fontFamily: "InterRegular",
    }
  };
  return (
    <div>
      {type === "shipments" ? (<>
        <Tooltip PopperProps={{ sx: tooltipTop }} title={value} placement="top-start">
          <font size={3} className="textBlack InterRg">{value?.slice(0, 12) + ".."}</font>
        </Tooltip>
      </>) : type === "tracker" ? (<>
        <Tooltip PopperProps={{ sx: tooltipTop }} title={value} placement="top-start">
          <font size={3} className="textBlack InterRg">{value?.slice(0, 24) + ".."}</font>
        </Tooltip>
      </>) : type === "organizations" ? (<>
        <Tooltip PopperProps={{ sx: tooltipTop }} title={value} placement="top-start">
          <small className="InterMd textBlack">{value}</small>
        </Tooltip>
      </>) : type === "2fa" ? (<>
        <Tooltip PopperProps={{ sx: tooltipTop }} title={value} placement="top">
          <font className="textBlack InterRg pt-3">{value}</font>
        </Tooltip>
      </>) :(<>
        <Tooltip PopperProps={{ sx: tooltipTop }} title={value} placement="top-start">
          <font size={3} className="textBlack InterRg">{value?.slice(0, 15) + ".."}</font>
        </Tooltip>
      </>)}
    </div>
  );
};
