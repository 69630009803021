import React from "react";
import { Routes, Route,useLocation,Navigate } from "react-router-dom";
import Navbar from "../components/navbar/Navbar";
import Login from "../components/registration/Login";
import SignUp from "../components/registration/SignUp";
import Dashboard from "../pages/dashboard/Dashboard";
// import Shipments from "../pages/shipments/Shipments";
import Trackers from "../pages/tracker/Tracker";
import Reports from "../pages/reports/Reports";
// import Tickets from "../pages/tickets/index";
import Profile from "../pages/profile/Profile";
import TrackerDetails from "../pages/tracker/trackerDetails";
import Organization from "../pages/organization/Organization";
import Billing from "../pages/Billings/billing";
import TrackersAdmin from "../pages/adminTrackers/AdminTrackers";
import Settings from "../pages/settings/Settings";
import ResetPassword from "../components/registration/ResetPassword";
import {useSelector} from "react-redux";

const Approutes = () => {
  const location = useLocation();
  const count = useSelector((state) => state.WalletConnect);
  // let PathIds = count?.userId;
  const userType = count?.userData?.length === 0 ? "" : count?.userData?.roles[0];
 
  return (<>
    {location.pathname !== "/" && location.pathname !== "/register" && !location.pathname.includes("/resetpassword/") ? <Navbar/> : null}
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/resetpassword/:id" element={<ResetPassword />} />
      <Route path="/register" element={<SignUp />} />
      <Route path="/dashboard" element={<Dashboard />} />
      {/* <Route path="/shipments" element={<Shipments />} /> */}
      <Route path="/tracker" element={userType === "ROLE_ADMIN" ?<Trackers /> : <TrackersAdmin />} />
      <Route path="/reports" element={<Reports />} />
      {/* <Route path="/mails" element={<Tickets />} /> */}
      <Route path="/profile" element={<Profile />} />
      <Route path="/TrackerDetails" element={<TrackerDetails />} />
      <Route path="/organizations" element={userType === "ROLE_ADMIN" ? null : <Organization />} />
      <Route path="/billings" element={userType === "ROLE_ADMIN" ? null : <Billing/>} />
      <Route path="/settings" element={userType === "ROLE_ADMIN" ? null : <Settings/>} />
      <Route path="*" element={<Navigate to="/" />}/>
    </Routes>
  </>)
};

export default Approutes;