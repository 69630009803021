import React, { useState, useEffect } from "react";
import { Grid, Button } from "@mui/material";
import { CgSearch } from "react-icons/cg";
import ReportsTable from "../../components/table/reportsTable/ReportsTable";
import CreateReportModal from "../../components/modal/GenerateNewReport";
// import { ReportsInfo, SuperAdminReportsInfo } from "../../helpers/data1";
import { useSelector } from "react-redux";
import { postMethod } from "../../apis";


const Reports = () => {
  const UserData = useSelector((state) => state.WalletConnect.userData)
  const [reportsData, setReportsData] = useState([]);
  const [RealreportsData, setRealreportsData] = useState([]);
  const [open, setOpen] = useState(false);
  const [Shipmentdata, setShipmentdata]=useState([])
  let email = UserData.roles[0]

  useEffect(() => {
    if (email === "ROLE_SUPERADMIN") {
      // setReportsData(SuperAdminReportsInfo);
    }
    else {
      // setReportsData(ReportsInfo);
    }
  }, []);
  const getuserShipment = async () => {
    const params = {
      userId: UserData.id,
    };
    let headers = {
      "Content-Type": "application/json",
      "x-access-token": UserData?.accessToken,
      "Access-Control-Allow-Origin": "*"
    };
    var url = "api/shipment/getshipmentByUserid";
    if(email === "ROLE_SUPERADMIN" ){
      url = "api/shipment/viewShipment"
    }else{
      
      url = "api/shipment/getshipmentByUserid";
    }
   
    let response = await postMethod({ url, params, headers });
    if (response.status === true) {
      let newShipment = []
      if (response.result.length > 0) {
        setShipmentdata(response.result)
        response.result.map((shipment) => {
          newShipment.push(shipment.Name)
        })
      }
    }
  }
  const getuserReport = async () => {
    const params = {
      creatorID: UserData.id,
    };
    let headers = {
      "Content-Type": "application/json",
      "x-access-token": UserData?.accessToken,
      "Access-Control-Allow-Origin": "*"
    };
    let url = "api/report/viewmyReports";
    let response = await postMethod({ url, params, headers });
    if (response.status === true) {
      if(response.result.length>0){
        response.result.reverse()
        setRealreportsData(response.result)
        setReportsData(response.result)
      } else{
        setReportsData([])
        setRealreportsData([])
      }
     
    }
  }
  useEffect(() => {
    getuserShipment()
    getuserReport()
  }, [])
  const searchFilter =(value)=>{
    const report = RealreportsData.filter((data)=>{
      if(data.ReportName.search(value) != -1){
        return data
      }
    })
    if(report.length>0){
      setReportsData(report)
    }else{
      setReportsData([])
    }
  }
  return (
    <>
      {email === "ROLE_SUPERADMIN" ? (<>
        <Grid lg={12} md={12} sm={12} xs={12} container sx={{ padding: "0% 4%", marginTop: "4%" }}>
          <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="space-between">
            <h5 className="textBlack InterBd m-0">Reports</h5>
            <Grid lg={2} md={4} sm={4} xs={12} container className="topalignmentinTickets">
              <Button className="btn py-2" onClick={() => setOpen(true)}>Generate New Report</Button>
            </Grid>
          </Grid>
          <div className="FlydeCard3 h-100 mb-5" style={{ overflow: "hidden" }}>
            <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="space-between" alignItems="center" sx={{ padding: "2%" }}>
              <h6 className="textGray1 InterMd m-0">{reportsData.length} Results</h6>
              <Grid lg={6} md={8} sm={10} xs={12} container justifyContent="space-between">
                <Grid lg={6.2} md={5} sm={5} xs={12} container className="topalignmentinTickets">
                  <div className="inputBox1 px-2 py-2 d-flex align-items-center">
                    <input  className="shipmentsSearchbar " placeholder="Search Reports" onChange={(e)=>searchFilter(e.target.value)}  />
                    <CgSearch size={22} color={"#999999"} />
                  </div>
                </Grid>
                {/* <Grid lg={2.5} md={3} sm={3} xs={12} container className="topalignmentinTickets">
                  <Button className="btn py-2">Export as CSV</Button>
                </Grid>
                <Grid lg={2.5} md={3} sm={3} xs={12} container className="topalignmentinTickets">
                  <Button className="btn py-2">Export as PDF</Button>
                </Grid> */}
              </Grid>
            </Grid>
            <ReportsTable reportsData={reportsData} />
          </div>
        </Grid>
      </>) : (<>
        <Grid lg={12} md={12} sm={12} xs={12} container sx={{ padding: "0% 4%", marginTop: "4%" }}>
          <div className="FlydeCard2 h-100 mt-3" style={{ overflow: "hidden" }}>
            <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="space-between" alignItems="center" sx={{ padding: "2%" }}>
              <h5 className="textBlack InterBd m-0">Reports</h5>
              <Grid lg={6} md={10} sm={10} xs={12} container justifyContent="flex-end">
                <Grid lg={5.6} md={6} sm={6} xs={12} container className="mr-3 topalignmentinTickets">
                  <div className="inputBox1  px-2 py-2 d-flex align-items-center">
                    <input
                      className="shipmentsSearchbar "
                      placeholder="Search Report"
                      onChange={(e)=>searchFilter(e.target.value)}
                    />
                    <CgSearch size={22} color={"#999999"} />
                  </div>
                </Grid>
                <Grid lg={3.6} md={4} sm={4} xs={12} container className="topalignmentinTickets">
                  <Button className="btn py-2" onClick={() => setOpen(true)}>Generate New Report</Button>
                </Grid>
              </Grid>
            </Grid>
            <ReportsTable reportsData={reportsData} />
          </div>
        </Grid>
      </>)}
      <CreateReportModal open={open} setOpen={setOpen}  Shipmentdata={Shipmentdata} getuserReport={getuserReport}  />
    </>
  )
};

export default Reports;