import React, { useState } from "react";
import { Grid, Button, Modal, Box, Stack,Checkbox } from "@mui/material";
import "./modal.css";
import { toast } from "react-toastify";
import PaymentForm from "./cardSection";
const PurchasePreview = ({ open, setOpen, PurchaseData ,getOneUserDetails}) => {
  const [Payment, setpayement] = useState(false);
  const [checked,setchecked] = useState(false)
  const handlePayments = async () => {
    if (checked){
      setpayement(true);
      setchecked(false);
    }else{
      toast.warn("Please Select the Check Box")
    }  
  };

  const handleChange=async()=>{
    if(checked){
      setchecked(false)
    }else{
      setchecked(true)
    }
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255, 255, 255, 1)",
    boxShadow: "0px 1px 13px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    width: "100%",
    padding: "1%",
  };

  const toHoursAndMinutes = (totalMinutes) => {
    if (totalMinutes >= 60) {
      const hours = Math.floor(totalMinutes / 60);
      return hours + " " + "Hour";
    } else {
      return totalMinutes + " " + "Minutes";
    }
  };

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="center">
          <Grid lg={3.5} md={6} sm={8} xs={11} container sx={style}>
            {Payment === false ? (
              <Stack direction="column" spacing={2} className="w-100">
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <Grid lg={12} md={12} xs={12}>
                    <Grid className="">
                      <Grid
                        alignItems="center"
                        display="flex"
                        direction="column"
                      >
                        <h5 className="InterBd textBlack text-bold">
                          {PurchaseData?.plan?.PlanName}
                        </h5>
                        <h5 className="InterBd textBlack text-bold">
                          ${PurchaseData?.plan?.Amount}
                        </h5>
                        <font className="textGray1 interXs ">Per Month</font>
                        <ul className="pt-3">
                          <li>
                            <font className="InterMd textBlack">
                              Maximum -
                              <span className="InterBd">
                                {PurchaseData?.plan?.Trackers} Trackers
                              </span>
                            </font>
                          </li>
                          <li className="pt-1">
                            <font className="InterMd textBlack">
                              Maximum Data Usage -
                              <span className="InterBd">
                                {PurchaseData?.plan?.DataUsage}GB/ month
                              </span>
                            </font>
                          </li>
                          <li className="pt-1">
                            <font className="InterMd textBlack">
                              Data Update Interval -
                              <span className="InterBd">
                                {toHoursAndMinutes(
                                  PurchaseData?.plan?.UpdateInterval
                                )}
                              </span>
                            </font>
                          </li>
                          <li className="pt-1">
                            <font className="InterMd textBlack">
                              App Notification -
                              <span className="InterBd">
                                {PurchaseData?.plan?.AppNotification === true
                                  ? "Yes"
                                  : "No"}
                              </span>
                            </font>
                          </li>
                          <li className="pt-1">
                            <font className="InterMd textBlack">
                              Email Notification -
                              <span className="InterBd">
                                {PurchaseData?.plan?.EmailNotification === true
                                  ? "Yes"
                                  : "No"}
                              </span>
                            </font>
                          </li>
                          <li className="pt-1">
                            <font className="InterMd textBlack">
                              SMS Notification -
                              <span className="InterBd">
                                {PurchaseData?.plan?.SMSnotification === true
                                  ? "Yes"
                                  : "No"}
                              </span>
                            </font>
                          </li>
                          <li className="pt-1">
                            <font className="InterMd textBlack">
                              Access to Mobile App -
                              <span className="InterBd">
                                {PurchaseData?.plan?.Accesstomobile === true
                                  ? "Yes"
                                  : "No"}
                              </span>
                            </font>
                          </li>
                        </ul>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>

                <Grid
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    label={"Keep me logged in"}
                    inputProps={{ "aria-label": "primary checkbox" }}
                    sx={{ padding: "0" }}
                  />
                  <font className="InterSm textBlack pl-2">
                    I agree to Term & Conditions 
                  </font>
                </Grid>

                <Grid
                  lg={12}
                  xs={12}
                  container
                  justifyContent="center"
                  alignItems="center"
                  className=""
                >
                  <Grid
                    lg={12}
                    xs={12}
                    container
                    justifyContent="space-around"
                    alignItems="center"
                  >
                    <Grid lg={5.5} xs={5}>
                      <Button
                        className="btngray"
                        onClick={() => {setOpen(false);setchecked(false);}}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid lg={5.5} xs={5}>
                      <Button className="btn" onClick={handlePayments}>
                        Complete Payment
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Stack>
            ) : (
              <PaymentForm PurchaseData={PurchaseData} setOpen={setOpen} setpayement={setpayement} getOneUserDetails={getOneUserDetails} />
            )}
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default PurchasePreview;
