import React, { useState, useEffect, useMemo } from "react";
import { Grid, Button, Stack, Modal, Box } from "@mui/material";
import { CgClose } from "react-icons/cg";
import "./modal.css";
import Select from "react-select";
import { BsPlus } from "react-icons/bs";
import { Link } from "react-router-dom";
import { RiDeleteBinLine } from "react-icons/ri";
import { FaRegEdit } from "react-icons/fa";
import AddnewNotifications from "../modal/AddnewNotifications";
import { postMethod } from "../../apis";
import getHeaders from "../../services";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import AutoComplete from "../googleAutocomplete"
// import { USAState } from "../../helpers/data1";
// eslint-disable-next-line no-use-before-define
import { GoogleMap, withGoogleMap, Marker, withScriptjs, Circle, } from "react-google-maps"; // eslint-disable-line
import Map from "../../components/sidebar/map.js";
// import LoadingData from "../../assets/images/dashboardImages/loadingData.svg";
import { Spinner } from "react-bootstrap";

const Transportation = [
  { label: "Air", value: "Air", name: "TransportationType" },
  { label: "Road", value: "Road", name: "TransportationType" },
  { label: "Ocean", value: "Ocean", name: "TransportationType" },
  { label: "Rail", value: "Rail", name: "TransportationType" },
];

const CreateShipmentsModal = ({ open, setOpen, type, getContactsDetails, EditData }) => {


  const count = useSelector((state) => state.WalletConnect);
  let UsersInfo = count?.userData;
  const headers = getHeaders();
  // var autoCompleteRef = useRef(null);
  // const inputRef = useRef();
  // const options = {
  //   componentRestrictions: { country: "us" },
  //   fields: ["address_components", "geometry"],
  //   types: ["address"],
  //   strictBounds: false,
  // };
  // const [addTrip, setAddTrip] = useState([
  //   {
  //     TransportationType: "",
  //     startAddress: "",
  //     startDate: "",
  //     endAddress: "",
  //     endDate: "",
  //     description: "",
  //   },
  // ]);
  const [openNotification, setOpenNotification] = useState(false);
  const [allTrigers, setAllTrigers] = useState([]);
  // const [selectcityoptions, setselectcityoptions] = useState([])
  const [openSuccessfully, setOpenSuccessFully] = useState(false);
  const [s_name, setS_name] = useState("");
  const [shipData, setShipData] = useState("");
  const [tracker, setTracker] = useState([]);
  const [selectedTracker, setSelectedTracker] = useState("");
  const [TrackersTable, setTrackersTable] = useState([]);
  const [RealTrackersTable, setRealTrackersTable] = useState([]);
  const [userData, setUserData] = useState("");
  const [alertName, setAlertName] = useState("");
  const [selectMail, setSelectMail] = useState([]);
  const [selectPhone, setSelectPhone] = useState([]);
  const [allNotifi, setAllNotify] = useState([]);
  const [IndexValue, setIndexValue] = useState();
  const [IndexLoad, setIndexLoad] = useState(false);
  const [modalType, setModalType] = useState(false);
  const [privateKeyModal, setPrivateKeyModal] = useState(false);
  const [SecretPIN, setSecretPIN] = useState(null);
  const mapStatus = true
  // const [isRun , setisRun] = useState(false);
  // const [types , setTypes] = useState("");
  const [addTrackers, setAddTrackers] = useState([
    {
      _id: "",
      TrackerName: "",
      TrackerModal: "",
      TrackerSerial: "",
      TrackerStatus: true,
      trip: [
        {
          TransportationType: "",
          startState: "",
          startCity: "",
          startAddress: "",
          // startAddressValid: false,
          startDate: "",
          endState: "",
          endCity: "",
          endAddress: "",
          // endAddressValid: false,
          endDate: "",
          description: "",
        },
      ],
      Notification: [],
    },
  ]);
  // const handleAdd = (val, index) => {
  //   if (val === "addtracker") {
  //     const newAddTrackers = [...addTrackers];
  //     const newTracker = {
  //       _id: "",
  //       TrackerName: "",
  //       TrackerModal: "",
  //       TrackerSerial: "",
  //       alertName: "",
  //       trip: [
  //         {
  //           TransportationType: "",
  //           startAddress: "",
  //           startDate: "",
  //           endAddress: "",
  //           endDate: "",
  //           description: "",
  //         },
  //       ],
  //       Notification: [],
  //       phone: [],
  //       mail: [],
  //     };
  //     newAddTrackers.push(newTracker);
  //     setAddTrackers(newAddTrackers);
  //   } else if (val === "addtrip") {
  //     // setAddTrip([...addTrip, {}]);
  //     const newAddTrackers = [...addTrackers];
  //     const newTrip = {
  //       TransportationType: "",
  //       startAddress: "",
  //       startDate: "",
  //       endAddress: "",
  //       endDate: "",
  //       description: "",
  //     };
  //     newAddTrackers[index].trip.push(newTrip);
  //     setAddTrackers(newAddTrackers);
  //   }
  // };
  const handleShipments = (e, index, val, tripIndex) => {

    if (val === "tracker") {
      const { name, value } = e;
      let data1 = tracker.filter((data) => data.TrackerName == e.value);
      setSelectedTracker(data1);
      const list = [...addTrackers];
      list[index]._id = data1[0]._id;
      list[index].TrackerModal = data1[0].TrackerModel;
      list[index].TrackerSerial = data1[0].TrackerSerial
      list[index][name] = value;
      const newTrackersTable = RealTrackersTable.filter((data) => data.value != e.value)
      setTrackersTable(newTrackersTable)
      setAddTrackers(list);
    } else if (val === "trip") {
      const { name, value } = e;
      const newAddTrackers = [...addTrackers];
      newAddTrackers[index].trip[tripIndex] = {
        ...newAddTrackers[index].trip[tripIndex],
        [name]: value,
      };
      setAddTrackers(newAddTrackers);
    }
  };

  // const handleClose = (i, value, j) => {
  //   if (value == "removetrackers") {
  //     const values = [...addTrackers];
  //     values.splice(i, 1);
  //     setAddTrackers(values);
  //   } else if (value == "removetrip") {
  //     const newAddTrackers = [...addTrackers];
  //     newAddTrackers[i].trip.splice(j, 1);
  //     setAddTrackers(newAddTrackers);
  //   }
  // };
  const getUsersDetails = async () => {
    try {
      const params = {
        email: UsersInfo.email,
      };
      let url = "api/organization/viewMyprofile";
      let response = await postMethod({ url, params, headers });
      if (response.status === true) {
        getShipment(response.result);
        setUserData(response.result);
      }
    } catch (err) {
      console.log("Error in getUserDetails", err);
    }
  };
  const getShipment = async (user) => {
    if (user) {
      try {
        const params = {
          userId: UsersInfo.id,
        };
        let url = "api/shipment/getshipmentByUserid";
        let response = await postMethod({ url, params, headers });
        if (response.status === true) {
          let Datas = response.result;
          if (Datas.length > 0) {
            getSHipmentID(Datas.length);
          } else {
            getSHipmentID(0);
          }
        } else {
          getSHipmentID(0);
        }
      } catch (err) {
        console.log("Error in getUserDetails", err);
      }
    }

  };
  const getSHipmentID = (response) => {
    const min = 10000; // minimum value
    const max = 99999; // maximum value
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    // const orgID = user.organizationName.slice(0, 4).toUpperCase();
    setShipData("FYD" + randomNumber + "-" + "000" + (response + 1));
    // setShipData(orgID + randomNumber + "-" + "000" + (response + 1));
  };
  const getMyTrackersLive = async () => {
    let url = "api/tracker/liveTrackers";
    let params = {
      email: UsersInfo.email,
    };
    try {
      const response = await postMethod({ url, params, headers });
      if (response.result) {
        const liveTracker = response.result.filter(
          (e) => !e.ShipmentAssign //e.ActivationStatus === "Activated"
        );
        if (response.status === true) {
          setTracker(liveTracker);
          let newArr = [];
          liveTracker.map((e) => {
            if (e.BuyerEmail == UsersInfo.email) {
              newArr.push({
                label: e.TrackerName,
                value: e.TrackerName,
                name: "TrackerName",
              });
            }
          });
          setTrackersTable(newArr);
          setRealTrackersTable(newArr);
        }
      }
    } catch (error) {
      console.log("Error", error)
    }


  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255, 255, 255, 1)",
    boxShadow: "0px 1px 13px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    width: "100%",
    height: "calc(100% - 50px)",
    padding: "2%",
  };

  const style1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255, 255, 255, 1)",
    boxShadow: "0px 1px 13px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    width: "100%",
    padding: "2%",
  };

  const customStylesReactselect = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#fff",
      color: "#000",
      border: "1px solid rgba(153, 153, 153, 1)",
      outline: "0px",
      boxShadow: "0px",
      borderRadius: "4px",
      fontFamily: "InterMedium",
      cursor: "pointer",
      padding: "0.5%",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 100,
      fontFamily: "InterMedium",
    }),
  };

  const addNotification = (i) => {
    setModalType("new")
    setIndexValue(i);
    setOpenNotification(true)
  }
  const createShipment = async () => {
    console.log("createShipment")
    if (s_name === "") {
      toast.warn("Shipment name can't be empty");
      return;
    }
    // if (SecretPIN.length < 6) {
    //   toast.warn("Enter Your 6 digit PIN");
    //   return;
    // }
    if (addTrackers[0].TrackerName === "") {
      toast.warn("select your tracker for shipment");
      return;
    }
    if (
      addTrackers[0].trip[0].TransportationType == "" &&
      addTrackers[0].trip[0].startAddress == "" &&
      addTrackers[0].trip[0].startDate == "" &&
      addTrackers[0].trip[0].endAddress == "" &&
      addTrackers[0].trip[0].endDate === ""
    ) {
      toast.warn("complete your trip details");
      return;
    }
    if (addTrackers.length > 0) {
      let data = []
      addTrackers.map((trackrer) => {
        if (trackrer.trip.length > 0) {
          trackrer.trip.map((trip) => {
            if (trip.TransportationType == "") {
              data.push(true)
            } else if (trip.startAddress == "") {
              data.push(true)
            } else if (trip.startDate == "") {
              data.push(true)
            } else if (trip.endAddress == "") {
              data.push(true)
            } else if (trip.endDate == "") {
              data.push(true)
            }
            // else if (addTrackers[0].trip[0].startAddressValid === false) {
            //   data.push(true)
            // }
            // else if (addTrackers[0].trip[0].endAddressValid === false) {
            //   data.push(true)
            // }
            // else if (trip.startCity == "") {
            //   data.push(true)
            // } else if (trip.startState == "") {
            //   data.push(true)
            // } else if (trip.endCity == "") {
            //   data.push(true)
            // } else if (trip.endState == "") {
            //   data.push(true)
            // }
          })
        }
      })
      if (data.includes(true)) {
        toast.warn("complete your trip details");
        return;
      }
    }
    // if (!count.userKey) {
    //   toast.warn("Try Again");
    //   return;
    // }
    setIndexLoad(true)
    const url = "api/shipment/create"
    const params = {
      CompanyID: userData._id,
      ShipmentID: shipData,
      ShipmentName: s_name,
      trackerDatas: addTrackers,
      privateKey: count.userKey,
      status: true,
      password: SecretPIN
    };
    try {
      const response = await postMethod({ url, params, headers })
      if (response.status) {
        if (response.message == "shipment created success") {
          setPrivateKeyModal(false)
          getContactsDetails();
          setOpen(false);
          setOpenSuccessFully(true);
          setIndexLoad(false)
        } else {
          setIndexLoad(false)
          toast.error("shipment creation failed Try again")
        }
      } else {
        setIndexLoad(false)
        toast.error("shipment creation failed Try again")
      }

    } catch {
      setIndexLoad(false)
      toast.error("shipment creation failed Try again")
    }

  };
  const disablePastDate = (index, index2, type) => {
    if (index == 0 && index2 == 0 && type == "Start") {
      const today = new Date();
      const dd = String(today.getDate() + 0).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = today.getFullYear();
      const hh = today.getHours();
      const MM = today.getMinutes();
      return yyyy + "-" + mm + "-" + dd + "T" + hh + ":" + MM;
    } else if (type == "Start" && index2 != 0) {
      if (addTrackers[0]?.trip[index2 - 1]?.endDate) {
        if (index == 0) {
          return addTrackers[0]?.trip[index2 - 1]?.endDate
        } else {
          return addTrackers[index - 1]?.trip[index2 - 1]?.endDate
        }
      } else {
        const today = new Date();
        const dd = String(today.getDate() + 0).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        const hh = today.getHours();
        const MM = today.getMinutes();
        return yyyy + "-" + mm + "-" + dd + "T" + hh + ":" + MM;
      }
    } else if (type == "Start" && index != 0) {
      if (addTrackers[index]?.trip[index2 - 1]?.endDate) {
        return addTrackers[index]?.trip[index2 - 1]?.endDate
      } else {
        const today = new Date();
        const dd = String(today.getDate() + 0).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        const hh = today.getHours();
        const MM = today.getMinutes();
        return yyyy + "-" + mm + "-" + dd + "T" + hh + ":" + MM;
      }
    }
    else if (type == "end" && index == 0 && index2 == 0) {

      if (addTrackers[index]?.trip[index]?.startDate) {
        return addTrackers[index]?.trip[index]?.startDate
      } else {
        const today = new Date();
        const dd = String(today.getDate() + 0).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        const hh = today.getHours();
        const MM = today.getMinutes();
        return yyyy + "-" + mm + "-" + dd + "T" + hh + ":" + MM;
      }
    } else if (type == "end" && index != 0) {
      if (addTrackers[index]?.trip[index2]?.startDate) {
        return addTrackers[index]?.trip[index2]?.startDate
      } else {
        const today = new Date();
        const dd = String(today.getDate() + 0).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        const hh = today.getHours();
        const MM = today.getMinutes();
        return yyyy + "-" + mm + "-" + dd + "T" + hh + ":" + MM;
      }
    } else if (type == "end") {
      if (addTrackers[0]?.trip[index2]?.startDate) {
        return addTrackers[index]?.trip[index2]?.startDate
      } else {
        const today = new Date();
        const dd = String(today.getDate() + 0).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        const hh = today.getHours();
        const MM = today.getMinutes();
        return yyyy + "-" + mm + "-" + dd + "T" + hh + ":" + MM;
      }
    }

  };

  const RemoveNOtification = (i, j) => {
    const newAddTrackers = [...addTrackers];
    newAddTrackers[i].Notification.splice(j, 1);
    setAddTrackers(newAddTrackers);
  }
  let newarr = [];
  const EditNotifications = (i, row) => {
    setModalType("edit")
    newarr = row.Notification[i]
    setOpenNotification(true)
  }

  function capitalize(s) {
    return s && s[0].toUpperCase() + s.slice(1);
  }
  const cancelData = () => {
    setAddTrackers([
      {
        _id: "",
        TrackerName: "",
        TrackerModal: "",
        TrackerSerial: "",
        trip: [
          {
            TransportationType: "",
            startAddress: "",
            startDate: "",
            endAddress: "",
            endDate: "",
            description: "",
          },
        ],
        Notification: [],
      },
    ])
    setOpen(false)
  }

  const processedData = useMemo(() => {
    return <Map open={mapStatus} setOpen={mapStatus} width={"50%"} />;
  }, [open]);


  useEffect(() => {
    getUsersDetails();
    getMyTrackersLive();
    if (allNotifi && EditData) {
      console.log("allNotifi")
    }
  }, [])
  const confirmPIN = () => {
    console.log("confirmPIN")
    if (s_name === "") {
      toast.warn("Shipment name can't be empty");
      return;
    }
    if (addTrackers[0].TrackerName === "") {
      toast.warn("select your tracker for shipment");
      return;
    }
    if (
      addTrackers[0].trip[0].TransportationType == "" &&
      addTrackers[0].trip[0].startAddress == "" &&
      addTrackers[0].trip[0].startDate == "" &&
      addTrackers[0].trip[0].endAddress == "" &&
      addTrackers[0].trip[0].endDate === ""
    ) {
      toast.warn("complete your trip details");
      return;
    }
    if (addTrackers.length > 0) {
      let data = []
      addTrackers.map((trackrer) => {
        if (trackrer.trip.length > 0) {
          trackrer.trip.map((trip) => {
            if (trip.TransportationType == "") {
              data.push(true)
            } else if (trip.startAddress == "") {
              data.push(true)
            } else if (trip.startDate == "") {
              data.push(true)
            } else if (trip.endAddress == "") {
              data.push(true)
            } else if (trip.endDate == "") {
              data.push(true)
            }
          })
        }
      })
      if (data.includes(true)) {
        toast.warn("complete your trip details");
        return;
      }
    }

    // if (!count.userKey) {
    //   toast.warn("Try Again");
    //   return;
    // }
    // setPrivateKeyModal(true)

    createShipment()
  }
  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ backgroundColor: "rgba(152, 152, 152, 0.34)", zIndex: 1000 }}
      >
        <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="center">
          <Grid lg={10} md={10} sm={11} xs={11} container sx={style}>
            <Grid
              lg={6}
              md={6}
              sm={12}
              xs={12}
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <h4 className="textBlack InterBd m-0">
                {type === "edit" ? "Edit Shipment" : "Create New Shipment"}
              </h4>
              <CgClose
                size={32}
                color={"rgba(0, 0, 0, 1)"}
                className="pointer"
                onClick={() => cancelData()}
                style={{ position: "absolute", left: "45%" }}
              />
            </Grid>
            <Box
              sx={{
                width: "100%",
                height: "calc(100% - 100px)",
                overflowY: "scroll",
                scrollbarWidth: "thin",
                "&::-webkit-scrollbar": {
                  width: "1px",
                  borderRadius: "1px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#fff",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#fff",
                },
              }}
            >
              <Grid lg={12} xs={12} container>
                <Grid
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  container
                  className="mb-5 mt-3"
                  sx={{ padding: "2%" }}
                >
                  <Stack direction="column" spacing={2} className="w-100">
                    <Grid
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid lg={5.5} xs={5} container>
                        <font className="textBlack InterRg">Shipment ID</font>
                        <input
                          className="inputBox1 newshipmentsinputbox px-2 py-2 mt-2"
                          defaultValue={shipData}
                          disabled
                        />
                      </Grid>
                      <Grid lg={5.5} xs={5} container>
                        <font className="textBlack InterRg">Shipment Name</font>
                        <input
                          className="inputBox1 px-2 py-2 mt-2"
                          placeholder="Enter Shipment Name"
                          // value={s_name}
                          onChange={(e) => setS_name(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                    {addTrackers.map((val, i) => {
                      return (
                        <>
                          <Grid
                            key={i}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            container
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <font className="textBlack InterSm">
                              Tracker
                            </font>
                            {/* <font className="textBlack InterSm">
                              Tracker - {i + 1}
                            </font>
                            {i === addTrackers.length - 1 ? (
                              <>
                                {i === 0 ? (
                                  <>
                                    <Button
                                      className="btn w-25"
                                      onClick={() => handleAdd("addtracker")}
                                    >
                                      Add Tracker
                                    </Button>
                                  </>
                                ) : (
                                  <>
                                    <Grid
                                      lg={6}
                                      xs={6}
                                      container
                                      justifyContent="flex-end"
                                      alignItems="center"
                                    >
                                      <Button
                                        className="btn w-50 mr-3"
                                        onClick={() => handleAdd("addtracker")}
                                      >
                                        Add Tracker
                                      </Button>
                                      <RiDeleteBinLine
                                        size={20}
                                        color={"#FF4C21"}
                                        className="pointer"
                                        onClick={() =>
                                          handleClose(i, "removetrackers")
                                        }
                                      />
                                    </Grid>
                                  </>
                                )}
                              </>
                            ) : (
                              <></>
                            )} */}
                          </Grid>
                          <Grid
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            container
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid lg={5.5} xs={5} container>
                              <font className="textBlack InterRg">
                                Tracker Name
                              </font>
                              <Select
                                styles={customStylesReactselect}
                                isSearchable={false}
                                className="w-100 mt-2"
                                placeholder="Select Tracker"
                                components={{
                                  IndicatorSeparator: () => null,
                                }}
                                options={TrackersTable}
                                onChange={(e) =>
                                  handleShipments(e, i, "tracker")
                                }
                              />
                            </Grid>
                            <Grid lg={5.5} xs={5} container>
                              <font className="textBlack InterRg">
                                Tracker Model
                              </font>
                              <input
                                className="inputBox1 px-2 py-2 mt-2"
                                placeholder="Tracker Model"
                                name="TrackerModal"
                                value={selectedTracker[0]?.TrackerModel}
                                disabled
                                onChange={(e) =>
                                  handleShipments(e, i, "tracker")
                                }
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            container
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <font className="textBlack InterRg">
                              Notifications
                            </font>
                            <Link
                              className="textBlue InterRg pointer"
                              onClick={() => addNotification(i)}
                            >
                              <BsPlus />
                              Add New Notifications
                            </Link>
                          </Grid>
                          <Grid
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            container
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <div className="notificationBoxinshipments">
                              <Grid
                                lg={12}
                                xs={12}
                                container
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                {addTrackers[i].Notification.length === 0 && type !== "edit" ? (
                                  <>
                                    <Grid
                                      lg={12}
                                      xs={12}
                                      container
                                      justifyContent="center"
                                      alignItems="center"
                                    >
                                      <p className="textGray InterRg m-0">
                                        No Notifications Added
                                      </p>
                                    </Grid>
                                  </>
                                ) : (
                                  <>
                                    <Grid lg={6} xs={6} container>
                                      <font
                                        size={3}
                                        className="textBlack InterMd"
                                      >
                                        {/* Tracker {addTrackers.length + 1}{" "} */}
                                        Notification
                                      </font>
                                    </Grid>
                                    <Grid
                                      lg={6}
                                      xs={6}
                                      container
                                      justifyContent="flex-end"
                                    >
                                      <RiDeleteBinLine
                                        size={20}
                                        color={"#FF4C21"}
                                        className="pointer mr-3"
                                        onClick={() =>
                                          RemoveNOtification(i, 0)
                                        }
                                      />
                                      <FaRegEdit
                                        size={20}
                                        color={"#AE059E"}
                                        className="pointer"
                                        onClick={() => { EditNotifications(i, val) }
                                        }
                                      />
                                    </Grid>
                                    <Grid lg={12} xs={12} container>
                                      <p className="textGray InterRg">
                                        {/* Temperature, Light, Humidity, Shock,
                                        Geofence */}
                                        {/* Added  */}
                                        {addTrackers[i].Notification[0]?.sensor[0] ? addTrackers[i].Notification[0]?.sensor[0].type == "temp" ? "Temperature" : addTrackers[i].Notification[0]?.sensor[0].type == "geo" ? "Geofence" : capitalize(addTrackers[i].Notification[0]?.sensor[0].type) : ""}
                                        {addTrackers[i].Notification[0]?.sensor[1] ? addTrackers[i].Notification[0]?.sensor[1].type == "temp" ? " , Temperature" : addTrackers[i].Notification[0]?.sensor[0].type == "geo" ? " , Geofence" : " , " + capitalize(addTrackers[i].Notification[0]?.sensor[1].type) : ""}
                                        {addTrackers[i].Notification[0]?.sensor[2] ? addTrackers[i].Notification[0]?.sensor[2].type == "temp" ? " , Temperature" : addTrackers[i].Notification[0]?.sensor[0].type == "geo" ? " , Geofence" : " , " + capitalize(addTrackers[i].Notification[0]?.sensor[2].type) : ""}
                                        {addTrackers[i].Notification[0]?.sensor[3] ? addTrackers[i].Notification[0]?.sensor[3].type == "temp" ? " , Temperature" : addTrackers[i].Notification[0]?.sensor[0].type == "geo" ? " , Geofence" : " , " + capitalize(addTrackers[i].Notification[0]?.sensor[3].type) : ""}
                                      </p>
                                    </Grid>
                                  </>
                                )}
                              </Grid>
                            </div>
                          </Grid>
                          {val.trip.map((row, j) => {
                            return (
                              <>
                                <Grid
                                  key={j}
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  container
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <font className="textBlack InterSm">
                                    Trip
                                  </font>
                                  {/* <font className="textBlack InterSm">
                                    Trip - {j + 1}
                                  </font>
                                  {j === val.trip.length - 1 ? (
                                    <>
                                      {j === 0 ? (
                                        <>
                                          <Button
                                            className="btn w-50 mr-3"
                                            onClick={() =>
                                              handleAdd("addtrip", i)
                                            }
                                          >
                                            Add Trip
                                          </Button>
                                        </>
                                      ) : (
                                        <>
                                          <Grid
                                            lg={6}
                                            xs={6}
                                            container
                                            justifyContent="flex-end"
                                            alignItems="center"
                                          >
                                            <Button
                                              className="btn w-50 mr-3"
                                              onClick={() =>
                                                handleAdd("addtrip", i)
                                              }
                                            >
                                              Add Trip
                                            </Button>
                                            <RiDeleteBinLine
                                              size={20}
                                              color={"#FF4C21"}
                                              className="pointer"
                                              onClick={() =>
                                                handleClose(i, "removetrip", j)
                                              }
                                            />
                                          </Grid>
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )} */}
                                </Grid>
                                <Grid
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  container
                                  justifyContent="flex-start"
                                  alignItems="center"
                                >
                                  <font className="textBlack InterRg">
                                    Mode of Transportation
                                  </font>
                                  <Select
                                    styles={customStylesReactselect}
                                    isSearchable={false}
                                    className="w-100 mt-2"
                                    placeholder="Mode of Transportation"
                                    components={{
                                      IndicatorSeparator: () => null,
                                    }}
                                    options={Transportation}
                                    onChange={(e) =>
                                      handleShipments(e, i, "trip", j)
                                    }
                                  />
                                </Grid>
                                <Grid
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  container
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  {/* <Grid lg={5.5} xs={5} container>
                                    <font className="textBlack InterRg">
                                      Shipment Start state
                                    </font>
                                    <Select
                                      styles={customStylesReactselect}
                                      isSearchable={false}
                                      className="w-100"
                                      placeholder="Select a State"
                                      components={{
                                        IndicatorSeparator: () => null,
                                      }}
                                      options={USAState}
                                      // value={usaState}
                                      onChange={(e) => countryselect(e, i, "trip", j)}
                                    />
                                  </Grid>
                                  <Grid lg={5.5} xs={5} container>
                                    <font className="textBlack InterRg">
                                      Shipment Start City
                                    </font>
                                    <Select
                                      styles={customStylesReactselect}
                                      isSearchable={false}
                                      className="w-100"
                                      placeholder="Select a city"
                                      components={{
                                        IndicatorSeparator: () => null,
                                      }}
                                      options={selectcityoptions}
                                      // value={usaCity}
                                      onChange={(e) => countryTab(e, i, "trip", j)}
                                    />
                                  </Grid> */}
                                  <Grid lg={5.5} xs={5} container>
                                    <font className="textBlack InterRg">
                                      Shipment Start Address
                                    </font>
                                    {/* <input ref={inputRef} /> */}
                                    <AutoComplete type={"startAddress"} index={i} name={"trip"} index2={j} handleShipments={handleShipments} />
                                    {/* <input
                                      className="inputBox1 px-2 py-2 mt-2"
                                      placeholder="Enter Shipment Start Address"
                                      name="startAddress"
                                      // ref={inputRef}
                                      value={row?.startAddress}
                                      // onFocus={() => { dataFocused() }}
                                      onChange={(e) =>
                                        handleShipments(e.target, i, "trip", j)
                                      }
                                    /> */}
                                  </Grid>
                                  {/* <Grid lg={5.5} xs={5} container>
                                    
                                    <Button
                                      className="btn shipmentsbtnspadding px-2 py-2 mt-2"
                                      onClick={() => {
                                        confirmAddress("startAddress", i, "trip", j);
                                      }}
                                    >
                                      Validate
                                    </Button>
                                  </Grid> */}
                                  <Grid lg={5.5} xs={5} container>
                                    <font className="textBlack InterRg">
                                      Shipment Start Date
                                    </font>
                                    <div className="inputBox1 overflowsBox px-2 py-2 mt-2">
                                      <input
                                        type="text"
                                        onFocus={(e) =>
                                          (e.target.type = "datetime-local")
                                        }
                                        onBlur={(e) => (e.target.type = "text")}
                                        className="DateInputsfocus"
                                        placeholder="MM/DD/YYYY"
                                        min={disablePastDate(i, j, "Start")}
                                        // min={"2023-05-09T10:04"}
                                        name="startDate"
                                        value={row?.startDate}
                                        onChange={(e) => {
                                          handleShipments(
                                            e.target,
                                            i,
                                            "trip",
                                            j
                                          );
                                        }
                                        }
                                      />
                                    </div>
                                  </Grid>
                                </Grid>
                                <Grid
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  container
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Grid lg={5.5} xs={5} container>
                                    <font className="textBlack InterRg">
                                      Shipment End Address
                                    </font>
                                    {/* <input
                                      className="inputBox1 px-2 py-2 mt-2"
                                      placeholder="Enter Shipment End  Address"
                                      name="endAddress"
                                      value={row.endAddress}
                                      onChange={(e) =>
                                        handleShipments(e.target, i, "trip", j)
                                      }
                                    /> */}
                                    <AutoComplete type={"endAddress"} index={i} name={"trip"} index2={j} handleShipments={handleShipments} />
                                  </Grid>
                                  {/* <Grid lg={5.5} xs={5} container>
                                    <Button
                                      className="btn shipmentsbtnspadding px-2 py-2 mt-2"
                                      onClick={() => {
                                        confirmAddress("endAddress", i, "trip", j);
                                      }}
                                    >
                                      Validate
                                    </Button>
                                  </Grid> */}
                                  <Grid lg={5.5} xs={5} container>
                                    <font className="textBlack InterRg">
                                      Shipment End Date
                                    </font>
                                    <div className="inputBox1 overflowsBox px-2 py-2 mt-2">
                                      <input
                                        type="text"
                                        onFocus={(e) =>
                                          (e.target.type = "datetime-local")
                                        }
                                        onBlur={(e) => (e.target.type = "text")}
                                        className="DateInputsfocus"
                                        placeholder="MM/DD/YYYY"
                                        min={disablePastDate(i, j, "end")}
                                        name="endDate"
                                        value={row.endDate}
                                        onChange={(e) =>
                                          handleShipments(
                                            e.target,
                                            i,
                                            "trip",
                                            j
                                          )
                                        }
                                      />
                                    </div>
                                  </Grid>
                                </Grid>
                                {/* <Grid
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  container
                                  justifyContent="space-between"
                                  alignItems="center"
                                > */}
                                {/* <Grid lg={5.5} xs={5} container>
                                    <font className="textBlack InterRg">

                                      Shipment End State
                                    </font>
                                    <Select
                                      styles={customStylesReactselect}
                                      isSearchable={false}
                                      className="w-100"
                                      placeholder="Select a State"
                                      components={{
                                        IndicatorSeparator: () => null,
                                      }}
                                      options={USAState}
                                      // value={usaState}
                                      onChange={(e) => countryENDselect(e, i, "trip", j)}
                                    />
                                  </Grid>
                                  <Grid lg={5.5} xs={5} container>
                                    <font className="textBlack InterRg">
                                      Shipment End city
                                    </font>
                                    <Select
                                      styles={customStylesReactselect}
                                      isSearchable={false}
                                      className="w-100"
                                      placeholder="Select a city"
                                      components={{
                                        IndicatorSeparator: () => null,
                                      }}
                                      options={selectcityoptions}
                                      // value={usaCity}
                                      onChange={(e) => countrEndTab(e, i, "trip", j)}
                                    />
                                  </Grid> */}


                                {/* </Grid> */}
                                <Grid
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  container
                                  justifyContent="flex-start"
                                  alignItems="center"
                                >
                                  <font className="textBlack InterRg">
                                    Description (Optional)
                                  </font>
                                  <textarea
                                    className="inputBox1 px-2 py-2 mt-2"
                                    placeholder="Description"
                                    name="description"
                                    value={row.description}
                                    onChange={(e) =>
                                      handleShipments(e.target, i, "trip", j)
                                    }
                                  />
                                </Grid>
                              </>
                            );
                          })}
                        </>
                      );
                    })}
                  </Stack>
                </Grid>
                <Grid lg={6} md={6} sm={12} xs={12} container>
                  {
                    processedData
                  }

                </Grid>
              </Grid>
            </Box>
            <Grid
              lg={6}
              xs={6}
              container
              justifyContent="center"
              alignItems="center"
              className="mt-3"
            >
              <Grid
                lg={10}
                xs={10}
                container
                justifyContent="space-around"
                alignItems="center"
              >
                <Grid lg={5} xs={5}>
                  <Button
                    className="btngray shipmentsbtnspadding"
                    onClick={() => cancelData()}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid lg={5} xs={5}>
                  {type === "edit" ? (
                    <>
                      <Button
                        className="btn shipmentsbtnspadding"
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        Save
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        className="btn shipmentsbtnspadding"
                        disabled={IndexLoad}
                        onClick={() => confirmPIN()}
                      >

                        {
                          IndexLoad ?
                            <Spinner animation="border" variant="light" />
                            : "Create"
                        }
                      </Button>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <AddnewNotifications
        type={modalType}
        setModalType={setModalType}
        openNotification={openNotification}
        setOpenNotification={setOpenNotification}
        allTrigers={allTrigers}
        setAllTrigers={setAllTrigers}
        setSelectMail={setSelectMail}
        selectMail={selectMail}
        setSelectPhone={setSelectPhone}
        selectPhone={selectPhone}
        alertName={alertName}
        setAlertName={setAlertName}
        userData={userData}
        setAllNotify={setAllNotify}
        IndexValue={IndexValue}
        addTrackers={addTrackers}
        setAddTrackers={setAddTrackers}
        newarr={newarr}
      />
      <Modal
        open={openSuccessfully}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="center">
          <Grid lg={4} md={6} sm={8} xs={11} container sx={style1}>
            <Grid
              lg={12}
              md={12}
              sm={12}
              xs={12}
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <h4 className="textBlack InterBd m-0">
                Shipment Created Successfully
              </h4>
              <CgClose
                size={32}
                color={"rgba(0, 0, 0, 1)"}
                className="pointer"
                onClick={() => setOpenSuccessFully(false)}
              />
            </Grid>
            <Grid lg={12} xs={12} container className="pt-3">
              <font className="textBlack InterRg">
                Shipment ID{" "}
                <font className="textBlue InterRg">{shipData}</font> has
                been created and tracker{" "}
                <font className="textBlue InterRg">{addTrackers[0]?.TrackerName}</font> is being
                utilized for this shipment.
              </font>
            </Grid>
            <Grid
              lg={12}
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
              className="pt-3"
            >
              <Grid lg={5} xs={5}>
                <Button
                  className="btn shipmentsbtnspadding"
                  onClick={() => setOpenSuccessFully(false)}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <Modal
        open={privateKeyModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="center">
          <Grid lg={4} md={6} sm={8} xs={11} container sx={style1}>
            <Grid lg={12} xs={12} container>
              <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="space-between" alignItems="center">
                <h4 className="textBlack InterBd m-0">Enter Your PIN</h4>
                <CgClose size={32} color={"rgba(0, 0, 0, 1)"} className="pointer" onClick={() => setPrivateKeyModal(false)} />
              </Grid>
              <Grid lg={12} xs={12} container alignItems="center" direction="column" className="pt-2">
                <input type="number"
                  className="inputBox w-75 px-2 py-2 mt-3"
                  placeholder="Enter Pin"
                  onChange={(e) => setSecretPIN(e.target.value)}
                />
                <Button className="btn w-75 py-2 mt-3" onClick={() => { createShipment(); }}>

                  {
                    IndexLoad ?
                      <Spinner animation="border" variant="light" />
                      : "Continue"
                  }
                </Button>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default CreateShipmentsModal;
