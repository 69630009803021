import React, { useState, useEffect } from "react";
import { Grid, Button, Stack } from "@mui/material";
import LoginLogos from "../../assets/images/registerPageImages/LoginLogo.png";
import Logo from "../../assets/images/logo.png";
import profile1 from "../../assets/images/registerPageImages/profile1.svg";
import profile2 from "../../assets/images/registerPageImages/profile2.svg";
import "./login.css";
import {
  BsEyeFill,
  BsEyeSlashFill,
  BsFillArrowLeftCircleFill,
} from "react-icons/bs";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { postMethod } from "../../apis";
import LinkExpired from "../../assets/images/dashboardImages/linkExpired.svg";
import { useSelector } from "react-redux";

const ResetPassword = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const count = useSelector((state) => state.WalletConnect);

  const [flydePassword1, setFlydePassword1] = useState({
    password: "",
    showPassword: false,
    resetPassword: "",
    showresetPassword: false,
  });

  const handleClickShowPassword1 = (val) => {
    if (val === "password") {
      setFlydePassword1({
        ...flydePassword1,
        showPassword: !flydePassword1.showPassword,
      });
    } else if (val === "resetPassword") {
      setFlydePassword1({
        ...flydePassword1,
        showresetPassword: !flydePassword1.showresetPassword,
      });
    }
  };

  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange1 = (prop) => (event) => {
    setFlydePassword1({ ...flydePassword1, [prop]: event.target.value });
  };

  const handleValidation = () => {
    var passwordValidation =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/
    {
      flydePassword1.password === ""
        ? toast.warn("New Password Can't Be Empty")
        : !passwordValidation.test(flydePassword1.password)
          ? toast.warn(
            "New Password Shall Be Minimum 8 Characters With One Uppercase, One Number And One Special Character"
          )
          : flydePassword1.resetPassword === ""
            ? toast.warn("Confirm Password Can't Be Empty")
            : !passwordValidation.test(flydePassword1.resetPassword)
              ? toast.warn(
                "Confirm Password Shall Be Minimum 8 Characters With One Uppercase, One Number And One Special Character"
              )
              : flydePassword1.password !== flydePassword1.resetPassword
                ? toast.warn("Both Password Should Be Same")
                : onhandleResetPassword();
    }
  };

  const onhandleResetPassword = async () => {
    try {
      setLoading(true);
      const params = {
        id: id,
        password: flydePassword1.password,
      };
      let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      };
      let url = "api/reset/updatethepassword";
      let response = await postMethod({ url, params, headers });
      if (response.status === false) {
        if (response.message === "user not verified") {
          toast.warn("Please Contact Admin");
          setLoading(false);
        } else if (response.message === "user not activate") {
          toast.warn("Your Account Deactivated,Please Contact Admin");
          setLoading(false);
        } else if (response.message === "user not found") {
          toast.warn("Email Address Not Exist,Please SignUp!");
          setLoading(false);
        } else if (response.message === "OTP Expired") {
          toast.warn("Link has expired!");
          setLoading(false);
          setValidationPage(true);
        } else if (response.message === "enter the valid mail") {
          toast.warn("Invalid Email Address");
          setLoading(false);
        }
      } else if (response.status === true) {
        toast.success("Password Updated");
        setLoading(false);
        // localStorage.clear();
        navigate("/");
      }
    } catch (err) {
      console.log("Error in login", err);
    }
  };

  const [validationPage, setValidationPage] = useState(false);

  const handlelinkValidation = async () => {
    try {
      // if(PathIds === id){
      var response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}api/reset/linkvalidation`,
        {
          method: "POST",
          body: JSON.stringify({
            id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      response = await response.json();
      if (!response.status) {
        if (response.message === "user not verified") {
          toast.warn("Email is doesn't Exist!Please SignUp");
          navigate("/");
          // localStorage.clear();
        } else if (response.message === "OTP Expired") {
          setValidationPage(true);
        } else {
          // navigate("/");
          // localStorage.clear();
        }
      } 
    } catch (err) {
      console.log("Error in linkValidation", err);
    }
  };

  useEffect(() => {
    handlelinkValidation();
  }, [count]);

  return (
    <>
      {validationPage === false ? (
        <>
          <Grid lg={12} md={12} sm={12} xs={12} container>
            <Grid
              lg={6}
              md={6}
              sm={6}
              xs={12}
              container
              sx={{ position: "relative" }}
            >
              {window.innerWidth > 600 ? (
                <>
                  <img
                    src={profile1}
                    className="img-fluid LoginimgPosition"
                    alt="logo"
                  />
                  <img
                    src={profile2}
                    className="img-fluid LoginimgPosition1"
                    alt="logo"
                  />
                </>
              ) : (
                <></>
              )}
              <Grid
                lg={12}
                md={12}
                sm={12}
                xs={12}
                container
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  lg={6.6}
                  md={10}
                  sm={10}
                  xs={8}
                  container
                  justifyContent="center"
                  alignItems="center"
                  sx={{ marginTop: { lg: "0", md: "0", sm: "0", xs: "10%" } }}
                >
                  <Stack direction="column" spacing={2} alignItems="center">
                    <Grid lg={4} md={5} sm={6} xs={6}>
                      <img src={Logo} className="img-fluid app-logo" alt="logo" />
                    </Grid>
                    <h3 className="InterSm textBlack m-0 pt-3">
                      Reset Password
                    </h3>
                    <Grid lg={12} md={12} sm={12} xs={12} container>
                      <Stack direction="column" spacing={2} className="w-100">
                        <font className="InterSm textBlack">New Password</font>
                        <div className="inputBox px-2 py-2 spacebetween mt-1">
                          <input
                            type={
                              flydePassword1.showPassword ? "text" : "password"
                            }
                            className="passwordInput"
                            placeholder="New Password"
                            onChange={handlePasswordChange1("password")}
                          />
                          {flydePassword1.showPassword ? (
                            <BsEyeFill
                              className="eyeIcons pointer"
                              onClick={() =>
                                handleClickShowPassword1("password")
                              }
                              onMouseDown={handleMouseDownPassword1}
                            />
                          ) : (
                            <BsEyeSlashFill
                              className="eyeIcons pointer"
                              onClick={() =>
                                handleClickShowPassword1("password")
                              }
                              onMouseDown={handleMouseDownPassword1}
                            />
                          )}
                        </div>
                        <font className="InterSm textBlack mt-3">
                          Confirm password
                        </font>
                        <div className="inputBox px-2 py-2 spacebetween mt-1">
                          <input
                            type={
                              flydePassword1.showresetPassword
                                ? "text"
                                : "password"
                            }
                            className="passwordInput"
                            placeholder="Confirm password"
                            onChange={handlePasswordChange1("resetPassword")}
                          />
                          {flydePassword1.showresetPassword ? (
                            <BsEyeFill
                              className="eyeIcons pointer"
                              onClick={() =>
                                handleClickShowPassword1("resetPassword")
                              }
                              onMouseDown={handleMouseDownPassword1}
                            />
                          ) : (
                            <BsEyeSlashFill
                              className="eyeIcons pointer"
                              onClick={() =>
                                handleClickShowPassword1("resetPassword")
                              }
                              onMouseDown={handleMouseDownPassword1}
                            />
                          )}
                        </div>
                        <Grid lg={12} xs={12} container className="">
                          <h6 className="textRed InterLt m-0">
                            Your password should be at least 8 characters,
                            including one upper case letter, one lower case
                            letter, and one number.
                          </h6>
                        </Grid>
                        <Grid
                          lg={12}
                          xs={12}
                          container
                          justifyContent=""
                          className=""
                        >
                          <Button
                            className="btn py-2"
                            onClick={() => handleValidation()}
                          >
                            {loading ? (
                              <>
                                <Spinner animation="border" variant="light" />
                              </>
                            ) : (
                              <>Reset Password</>
                            )}
                          </Button>
                        </Grid>
                      </Stack>
                    </Grid>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              lg={6}
              md={6}
              sm={6}
              xs={12}
              container
              sx={{
                display: { lg: "flex", md: "flex", sm: "flex", xs: "none" },
              }}
            >
              <div className="loginImgBox">
                <img
                  src={LoginLogos}
                  className="img-fluid loginLogoimg"
                  alt="loginlogo"
                />
              </div>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid
            lg={12}
            md={12}
            sm={12}
            xs={12}
            container
            justifycontent="center"
            alignItems="center"
            sx={{ padding: "4%", height: "100vh" }}
          >
            <div className="FlydeCard3" style={{ overflow: "hidden" }}>
              <Grid
                lg={12}
                md={12}
                sm={12}
                xs={12}
                container
                justifyContent="center"
                alignItems="center"
                sx={{ padding: "2%" }}
              >
                <Grid
                  lg={10}
                  xs={12}
                  container
                  justifyContent="center"
                  alignItems="center"
                  className=""
                >
                  <img
                    src={LinkExpired}
                    className="linkExpiredImage"
                    alt="NoDataImage"
                  />
                </Grid>
                <Grid
                  lg={12}
                  xs={12}
                  container
                  justifyContent="center"
                  alignItems="center"
                  className="pt-3"
                >
                  <h3 className="textBlack InterBd">This link has expired</h3>
                </Grid>
                <Grid
                  lg={12}
                  xs={12}
                  container
                  justifyContent="center"
                  alignItems="center"
                  className="pt-3 pointer"
                >
                  <BsFillArrowLeftCircleFill
                    size={30}
                    color={"#AE059E"}
                    className="pointer"
                    onClick={() => navigate("/")}
                  />
                  <h5 className="textBlack InterSm m-0 pl-3">SignIn</h5>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </>
      )}
    </>
  );
};

export default ResetPassword;
