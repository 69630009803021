import React from "react";
import { Grid, TableBody, TableCell, TableContainer, TableHead, TableRow, Table } from "@mui/material";
import "../table.css";
import BatteryGauge from "react-battery-gauge";
import { TableToolTip } from "../../../helpers/toolbar";
import NoData from "../../../assets/images/dashboardImages/noData.svg";
import { useEffect,useState } from "react";
import { getMethod } from "../../../apis";
import { useNavigate } from "react-router-dom";
import getHeaders from "../../../services";

const TrackersTable = ({ AvailableTrackers }) => {
  const headers = getHeaders();
  const navigate = useNavigate();
  const [LiveData , setLiveData] = useState("");
  const customization = {
    batteryMeter: {
      fill: "#F91818",
      outerGap: 1,
      noOfCells: 1, // more than 1, will create cell battery
      interCellsGap: 1
    },
    readingText: {
      display: "none"
    },
  };

  const customization1 = {
    batteryMeter: {
      fill: "#F3F82B",
      outerGap: 1,
      noOfCells: 1, // more than 1, will create cell battery
      interCellsGap: 1
    },
    readingText: {
      display: "none"
    },
  };

  const customization2 = {
    batteryMeter: {
      fill: "#2BB372",
      outerGap: 1,
      noOfCells: 1, // more than 1, will create cell battery
      interCellsGap: 1
    },
    readingText: {
      display: "none"
    },
  };

  useEffect (()=>{
    getLiveGps();
  },[])


  const getLiveGps = async () => {
    let url = "api/traacker/getLiveGps";
    const response = await getMethod({ url, headers });
    if(response.status === true){
      setLiveData(response.result.data[0])
    }
  };

  return (
    <div>
      {AvailableTrackers.length !== 0 ? (<>
        <Grid lg={12} xs={12} sx={{ overflow: "auto" }}>
          <TableContainer className="pt-2 mobileviewInTable">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" className="dashboardTbcell">
                    <font className="textBlack InterMd" size={3}>Tracker Name</font>
                  </TableCell>
                  <TableCell align="left" className="dashboardTbcell">
                    <font className="textBlack InterMd" size={3}>Location</font>
                  </TableCell>
                  <TableCell align="left" className="dashboardTbcell">
                    <font className="textBlack InterMd" size={3}>Battery Level</font>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {AvailableTrackers.map((row, i) => {
                  return (<>
                    <TableRow key={i} >
                      <TableCell align="left">
                        <Grid lg={12} xs={12} onClick={() => navigate("/tracker")} container justifyContent="flex-start" alignItems="center" >
                          <font className="textBlue InterRg pointer textHoveringgreen">{row?.TrackerName?.length > 20 ? row?.TrackerName?.slice(0, 20) + ".." : row?.TrackerName}</font>
                        </Grid>
                      </TableCell>
                      <TableCell align="left">
                        { LiveData && LiveData?.name && LiveData?.name.length > 24 ? (<>
                          <Grid lg={12} xs={12} container justifyContent="flex-start" alignItems="center" className="pointer">
                            <TableToolTip value={LiveData?.location?.reverseGeo?.formattedLocation} type={"tracker"} />
                          </Grid>
                        </>) : (<>
                          <Grid lg={12} xs={12} container justifyContent="flex-start" alignItems="center">
                            <font className="textBlack InterRg">{LiveData?.location?.reverseGeo?.formattedLocation}</font>
                          </Grid>
                        </>)}
                      </TableCell>
                      <TableCell align="left">
                        <Grid lg={12} xs={12} container justifyContent="flex-start" alignItems="center">
                          <Grid lg={4} xs={4} container>
                            <BatteryGauge
                              style={{ height: "20px" }}
                              customization={
                                row.Battery < 20
                                  ? customization
                                  : row.Battery < 50
                                    ? customization1
                                    : row.Battery < 100
                                      ? customization2
                                      : customization2
                              }
                              size={100}
                              value={row.Battery ? row.Battery : 60}
                            />
                            {/* <BatteryGauge style={{ height: "20px" }} customization={row.Battery < 20 ? customization : row.Battery < 50 ? customization1 : row.Battery < 100 ? customization2 : ""} size={100} value={row.Battery} /> */}
                          </Grid>
                          <font className="textBlack InterRg "> 
                            {row.Battery ? row.Battery : 60}%
                            {/* {row.Battery} % */}
                          </font>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  </>)
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </>) : (<>
        <Grid lg={12} xs={12} container justifyContent="center" alignItems="center" alignContent="center" className="">
          <Grid lg={10} xs={12} container justifyContent="center" alignItems="center" className="">
            <img src={NoData} className="img-fluid" alt="NoDataImage" />
          </Grid>
          <Grid lg={10} xs={12} container justifyContent="center" className="">
            <h4 className="textBlack InterBd">No Trackers Available</h4>
          </Grid>
        </Grid>
      </>)}
    </div>
  );
};

export default TrackersTable;