import React, { useEffect, useState } from "react";
import { Grid, Button, Stack, Modal, Box } from "@mui/material";
import { CgClose } from "react-icons/cg";
import "./modal.css";
import Select from "react-select";
import TrackerSuccess from "./trackerSuccess";
import TrackerFailed from "./TrackerFailed";
import { toast } from "react-toastify";
import { putMethod } from "../../apis";

import getHeaders from "../../services/index";

const ActivateTracker = ({ open, setOpen, myTrackers, userData, getMyTrackersLive, getMyTrackers }) => {

  let headers = getHeaders();
  const [open2, setOpen2] = useState(false);
  const [open1, setopen1] = useState(false);

  const [TrackerList, setTrackerList] = useState([]);
  const [TrackerId, setTrackerId] = useState();
  const [BuyerEmail, setBuyerEmail] = useState();
  const [TrackerModal, setTrackerModal] = useState();
  const [TrackerSerial, setTrackerSerial] = useState();
  const [TrackerDescription, setTrackerDescription] = useState();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255, 255, 255, 1)",
    boxShadow: "0px 1px 13px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    width: "100%",
    height: "calc(80% - 50px)",
    padding: "2%",
  };

  const customStylesReactselect = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#fff",
      color: "#000",
      border: "1px solid rgba(153, 153, 153, 1)",
      outline: "0px",
      boxShadow: "0px",
      borderRadius: "4px",
      fontFamily: "InterMedium",
      cursor: "pointer",
      padding: "0.5%",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 100,
      fontFamily: "InterMedium",
    }),
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#AE059E",
        color: "#fff",
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: "#fff",
    }),
  };

  useEffect(() => {
    if (open) {
      setTrackerId()
      setBuyerEmail()
      setTrackerModal()
      setTrackerSerial()
      setTrackerDescription()

      getTrackerList()
    }
  }, [open])

  const getTrackerList = () => {
    let newArr = [];
    myTrackers.map((e) => {
      if (e.BuyerEmail == userData.email) {
        newArr.push({ label: e.TrackerName, value: e._id })
      }
    });
    setTrackerList(newArr)
  };

  const selectTracker = (e) => {
    let tracker = myTrackers.find(t => t._id === e.value)
    setTrackerId(tracker._id)
    setBuyerEmail(tracker.BuyerEmail)
    setTrackerModal(tracker.TrackerModel)
    setTrackerSerial(tracker.TrackerSerial)
    setTrackerDescription(tracker.Description)
  }


  const activateTracker = async () => {
    if (!TrackerId) {
      toast.warn("Please sleect Tracker name");
      return
    }
    let url = "api/tracker/activatetracker"
    let params = {
      _id: TrackerId,
      BuyerEmail: BuyerEmail,
      Description: TrackerDescription
    }
    const response = await putMethod({ url, params, headers })
    if (response.status === true) {
      setOpen(false);
      setOpen2(true);
      toast.success("Tracker Activated")
      getMyTrackersLive();
      getMyTrackers();

      setTrackerId()
      setBuyerEmail()
      setTrackerModal()
      setTrackerSerial()
      setTrackerDescription()
    } else {
      toast.error("Tracker Activation Failed Try Again Later")
      setopen1(true);
    }
  }

  const closemodal = () => {
    setTrackerSerial("")
    setOpen(false)
  }

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-TrackerDescription"
      >
        <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="center">
          <Grid lg={4} md={6} sm={8} xs={11} container sx={style}>
            <Grid
              lg={12}
              md={12}
              sm={12}
              xs={12}
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid lg={11} md={11} xs={11}>
                <h4 className="textBlack InterBd m-0"> Activate New Tracker</h4>
              </Grid>
              <Grid lg={1} md={1} xs={1}>
                <CgClose
                  size={32}
                  color={"rgba(0, 0, 0, 1)"}
                  className="pointer"
                  onClick={() => closemodal()}
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                width: "100%",
                height: "calc(100% - 100px)",
                overflowY: "scroll",
                scrollbarWidth: "thin",
                "&::-webkit-scrollbar": {
                  width: "1px",
                  borderRadius: "1px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#fff",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#fff",
                },
              }}
            >
              <Grid lg={12} xs={12}>
                <Grid
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  container
                  className="mb-5"
                >
                  <Stack direction="column" spacing={2} className="w-100">
                    <Grid
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <font className="textBlack InterRg py-2">
                        Tracker Name
                      </font>
                      <Select
                        styles={customStylesReactselect}
                        isSearchable={false}
                        className="w-100 mt-1"
                        placeholder="Select Tracker"
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        options={TrackerList}
                        onChange={(e) => selectTracker(e)}
                      />

                      <font className="textBlack InterRg py-2">
                        Tracker Model
                      </font>
                      <input
                        className="inputBox1 w-100  px-2 py-2 "
                        placeholder="Tracker Model"
                        value={TrackerModal}
                        disabled
                      />
                      <font className="textBlack InterRg py-2">
                        Tracker Serial
                      </font>
                      <input
                        className="inputBox1 w-100  px-2 py-2 "
                        placeholder="Tracker Serial"
                        value={TrackerSerial}
                        disabled
                      />
                      <font className="textBlack InterRg py-3">
                        Description (Optional)
                      </font>
                      <textarea
                        className="inputBox1 px-2 py-2"
                        placeholder="Enter Tracker Description"
                        name="TrackerDescription"
                        value={TrackerDescription}
                        onChange={(e) => setTrackerDescription(e.target.value)}
                      />
                    </Grid>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            <Grid
              lg={12}
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
              className="mt-3"
            >
              <Grid
                lg={10}
                xs={10}
                container
                justifyContent="space-around"
                alignItems="center"
              >
                <Grid lg={5} xs={5}>
                  <Button
                    className="btngray shipmentsbtnspadding"
                    onClick={() => {
                      closemodal()
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid lg={5} xs={5}>
                  <Button
                    className="btn shipmentsbtnspadding"
                    onClick={() => activateTracker()}
                  >
                    Activate
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <TrackerSuccess open={open2} setOpen={setOpen2} />
      <TrackerFailed open={open1} setOpen={setopen1} />
    </>
  );
};

export default ActivateTracker;
