import React from "react";
import { Grid, Button, Stack, Modal, Box } from "@mui/material";
// import { CgClose } from "react-icons/cg";
import "./modal.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { putMethod } from "../../apis";
import {useSelector} from "react-redux";

const RemoveContact = ({ open, setOpen, editName, setEditName, editPhoneNo, setEditPhoneNo, editMail, setEditMail, getContactsDetails }) => {

  const [loading, setLoading] = React.useState(false);
  const count = useSelector((state) => state.WalletConnect)

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255, 255, 255, 1)",
    boxShadow: "0px 1px 13px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    width: "100%",
    padding: "2%",
  };

  let UsersInfo = count?.userData;

  const Removecontact = async () => {
    try {
      setLoading(true);
      const params = {
        email: UsersInfo?.email,
        contact: {
          mail: editMail,
          status: false
        }
      };
      let headers = {
        "Content-Type": "application/json",
        "x-access-token": count?.userData?.accessToken,
        "Access-Control-Allow-Origin": "*"
      };
      let url = "api/organization/Removecontact";
      let response = await putMethod({ url, params,headers });
      if (response.message === "User not Found") {
        toast.warn("Email doesn't exist");
        setLoading(false);
      }
      else if (response.message === "contatct updated failed") {
        toast.warn("Try Again");
        setLoading(false);
      }
      else if (response.message === "contact Updated") {
        toast.success("Contact Removed");
        getContactsDetails();
        setLoading(false);
        setOpen(false);
      }
    }
    catch (err) {
      console.log("Error in addContacts", err);
    }
  }

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="center">
          <Grid lg={3} md={5} sm={8} xs={11} container sx={style}>
            <Grid
              lg={12}
              md={12}
              sm={12}
              xs={12}
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <h5 className="textBlack InterBd m-0 ">Are you sure want to remove Contact ?</h5>
            </Grid>

            <Box sx={{ width: "100%" }}>
              <Grid lg={12} xs={12}>
                <Grid
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  container
                  className=""
                >
                  <Stack direction="column" spacing={2} className="w-100">
                    <Grid
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <font className="textBlack InterRg py-2">
                        Contact Name
                      </font>
                      <input
                        className="inputBox1 w-100  px-2 py-2 LoginTextCapitalize"
                        placeholder="Enter Contact Name"
                        value={editName}
                        onChange={(e) => setEditName(e.target.value)}
                        disabled
                      />

                      <font className="textBlack InterRg py-2">Phone</font>
                      <PhoneInput
                        className="phoneinput_modal textBlack"
                        country={"us"}
                        containerStyle={{ width: "100%" }}
                        style={{ zIndex: 0 }}
                        countryCodeEditable={false}
                        disableSearchIcon={true}
                        disableDropdown={true}
                        placeholder="Enter Contact Number"
                        value={editPhoneNo}
                        onChange={(e) => setEditPhoneNo(e)}
                        disabled
                      />
                      <font className="textBlack InterRg py-2">Email</font>
                      <input
                        className="inputBox1 w-100  px-2 py-2 "
                        placeholder="Enter Email"
                        value={editMail}
                        onChange={(e) => setEditMail(e.target.value)}
                        disabled
                      />
                    </Grid>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            <Grid
              lg={12}
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
              className="pt-4"
            >
              <Grid
                lg={10}
                xs={10}
                container
                justifyContent="space-around"
                alignItems="center"
              >
                <Grid lg={5} xs={5}>
                  <Button
                    className="btngray shipmentsbtnspadding"
                    onClick={() => setOpen(false)}
                  >
                    No
                  </Button>
                </Grid>
                <Grid lg={5} xs={5}>
                  <Button className="btn shipmentsbtnspadding" onClick={Removecontact}>
                    {loading ? (<>
                      <Spinner animation="border" variant="light" />
                    </>) : (<>
                      Yes
                    </>)}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default RemoveContact;
