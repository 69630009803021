import React from "react";
import { Grid, Button,  Modal,  } from "@mui/material";
import "./modal.css";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";

const LogoutModal = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255, 255, 255, 1)",
    boxShadow: "0px 1px 13px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    width: "100%",
    padding: "2%",
  };
  const confirm =  () =>{
    navigate("/");
    // localStorage.clear();
    dispatch( {type:"CLEAR_SESSION"});
  }
  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="center">
          <Grid lg={3} md={6} sm={8} xs={11} container sx={style}>
            <Grid
              lg={12}
              md={12}
              sm={12}
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
            >
              <h6 className="textBlack InterBd m-0"> Are you Sure want to Logout ?</h6>
            </Grid>
            <Grid
              lg={12}
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
              className="mt-3"
            >
              <Grid
                lg={10}
                xs={10}
                container
                justifyContent="space-around"
                alignItems="center"
              >
                <Grid lg={5} xs={5}>
                  <Button
                    className="btngray shipmentsbtnspadding"
                    onClick={() => setOpen(false)}
                  >
                    No
                  </Button>
                </Grid>
                <Grid lg={5} xs={5}>
                  <Button className="btn shipmentsbtnspadding" onClick={()=>confirm()} >Yes</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default LogoutModal;
