import React, { useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Button, Spinner } from "react-bootstrap";
import "./style.css";
import { useSelector } from "react-redux";
import { postMethod } from "../../apis";
import { toast } from "react-toastify";
import { Grid } from "@mui/material";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "black",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#fce883",
      },
      "::placeholder": {
        color: "black",
      },
    },
    invalid: {
      iconColor: "#black",
      color: "#red",
    },
  },
};
const Field = ({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange,
}) => (
  <div className="FormRow">
    <label htmlFor={id} className="FormRowLabel">
      {label}
    </label>
    <input
      className="FormRowInput"
      id={id}
      type={type}
      placeholder={placeholder}
      required={required}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
    />
  </div>
);

const SubmitButton = ({ processing, error, children, disabled }) => (
  <button
    className={`SubmitButton ${error ? "SubmitButton--error" : ""}`}
    type="submit"
    disabled={processing || disabled}
  >
    {processing ? "Processing..." : children}
  </button>
);

const ErrorMessage = ({ children }) => (
  <div className="ErrorMessage" role="alert">
    <svg width="16" height="16" viewBox="0 0 17 17">
      <path
        fill="#FFF"
        d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
      />
      <path
        fill="#6772e5"
        d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
      />
    </svg>
    {children}
  </div>
);

export default function CheckoutForm({ PurchaseData ,setOpen ,getOneUserDetails ,setpayement }) {
  const count = useSelector((state) => state.WalletConnect)
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const amount = PurchaseData?.plan?.Amount;
  const user_sub = PurchaseData?.user?.organizationName;
  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });
    if (!error) {
      try {
        const { id } = paymentMethod;
        const params = {
          amount: amount,
          id,
          user: user_sub,
        };
        let url = "api/checkout";
        let headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        };
        const response = await postMethod({ url, params, headers });
        if (response.status === true) {
          updateUserBill(response.paymentID)         
        } else {
          console.log("Error in payment");
        }
      } catch (error) {
        console.log("Error", error);
      }
    } else {
      console.log("Error", error.message);
    }
    setLoader(false);
  };

  const [loader, setLoader] = useState(false);
  const updateUserBill = async (paymentID)=> {
    try {
      const params = {
        email: PurchaseData?.user?.email,
        planName:PurchaseData?.plan?.PlanName,
        plan:[{...PurchaseData?.plan,paymentID , CurrentDate:Date.now()}],
        Amount:PurchaseData?.plan?.Amount,
        paymentID:paymentID,
        createdAt:new Date(),
      };
      let url = "api/payment/subscription";
      let headers = {
        "Content-Type": "application/json",
        "x-access-token": count?.userData?.accessToken,
        "Access-Control-Allow-Origin": "*"
      };
      let response = await postMethod({ url,headers,params });
      if (response.status === true) {
        toast.success("Subscription Success");
        setpayement(false)
        getOneUserDetails();
        setOpen(false);
        window.location.reload(true)
      }
      else {
        setOpen(false);
      }
    }
    catch (err) {
      console.log("Error in getUserDetails", err);
    }
  }
  return (
    <>
      <form className="Form" onSubmit={handleSubmit}>
        <fieldset className="FormGroup">
          <Field
            label="Name"
            id="name"
            type="text"
            placeholder="john"
            required
            autoComplete="name"
            value={user_sub}
          />
          <Field
            label="Email"
            id="email"
            type="email"
            placeholder="example@gmail.com"
            required
            autoComplete="email"
            value={PurchaseData?.user?.email}
          />
        </fieldset>
        <fieldset className="FormGroup FormRowInput mt-3  border ">
          <CardElement
            options={CARD_OPTIONS}
            onChange={(e) => {
              setError(e.error);
            }}
          />
        </fieldset>
        {error && <ErrorMessage>{error.message}</ErrorMessage>}
        {loader ? (
          <SubmitButton
            // processing={processing}
            error={error}
            disabled={!stripe}
          >
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            Processing...
          </SubmitButton>
        ) : (
          <SubmitButton
            // processing={processing}
            error={error}
            disabled={!stripe}
          >
            Pay $ {amount}
          </SubmitButton>
        )}
        < Grid  className="w-50 mt-4 mx-auto">
          <Button className="btnRed" onClick={()=>{setOpen(false);setpayement(false)}} >Cancel</Button>
        </Grid>
     
      </form>
    </>
  );
}
