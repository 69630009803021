import React from "react";
import { Grid, Button, Stack, Modal, Box, Avatar } from "@mui/material";
import { CgClose } from "react-icons/cg";
import "./modal.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { putMethod } from "../../apis";
import S3 from "react-aws-s3";
import AutoComplete from "../googleAutocomplete";
// import { ReactS3Client } from "../../services/index";
import { useSelector } from "react-redux";
import Select from "react-select";
import { OrgType } from "../../helpers/data1"
import { GoogleMap, withGoogleMap, Marker, withScriptjs, Circle, } from "react-google-maps"; // eslint-disable-line

const EditOrganization = ({ open, setOpen, userName, setUserName, profilePic, setProfilePic, organizationType, setOrganizationType, organizationName, oragnizationemail, contactNo, setContactNo, contactAddress, setContactAddress, getUsersDetails, organizationLogo, setOrganizationLogo, setDescription, description }) => {
  const count = useSelector((state) => state.WalletConnect)
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255, 255, 255, 1)",
    boxShadow: "0px 1px 13px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    width: "100%",
    height: "calc(80% - 50px)",
    padding: "2%",
  };

  const customStylesReactselect = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#fff",
      color: "#000",
      border: "1px solid rgba(140, 145, 150, 1)",
      outline: "0px",
      boxShadow: "0px",
      borderRadius: "4px",
      fontFamily: "InterMedium",
      cursor: "pointer",
      padding: "0.5%",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 100,
      fontFamily: "InterMedium",
    }),
  };

  let email = count?.userData?.roles[0];
  // const str = email === "SuperAdmin" ? OrganizationData.profilePic : OrganizationData.OrganizationLogo str.split("/").pop();
  // const fileName = OrganizationData?.profilePic;

  const hiddenFileInput = React.useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };


  const handleChange = async (file) => {
    let extension = "." + file.name.split(".").pop(); //jpg,jpeg,png
    let filename = "profilepic" + "_" + "recruiter" + "_" + Date.now() + extension;
    try {
      const { REACT_APP_S3_BUCKETNAME, REACT_APP_S3_REGION, REACT_APP_S3_ACCESS_KEY, REACT_APP_S3_SECRET_KEY } = process.env;
      const config = {
        bucketName: REACT_APP_S3_BUCKETNAME,
        dirName: "profile",
        region: REACT_APP_S3_REGION,
        accessKeyId: REACT_APP_S3_ACCESS_KEY,
        secretAccessKey: REACT_APP_S3_SECRET_KEY,
      };
      const ReactS3Client = new S3(config);
      const data = await ReactS3Client.uploadFile(file, filename);
      if (data.status === 204) {
        setOrganizationLogo(filename);
        // setRecruiter({ ...recruiters, profilePic: filename });
      } else {
        console.log("Error in profile image upload");
      }
    } catch (err) {
      console.log("Error image uploading", err);
    }
  };


  const [loading, setLoading] = React.useState(false);

  const handleValidation = () => {
    { organizationName === "" ? (toast.warn("Organization Name Can't Be Empty")) : contactNo?.length !== 11 ? (toast.warn("Contact Number Can't Be Empty")) : oragnizationemail === "" ? (toast.warn("Email Address Can't Be Empty")) : contactAddress === "" ? (toast.warn("Contact Address Can't Be Empty")) : editOrganization() }
  };

  const editOrganization = async () => {
    try {
      setLoading(true);
      const params = {
        email: oragnizationemail,
        organizationName: organizationName,
        conatctNumber: contactNo,
        address: contactAddress,
        organizationType: organizationType?.value,
        Logo: organizationLogo,
      };
      let headers = {
        "Content-Type": "application/json",
        "x-access-token": count?.userData?.accessToken,
        "Access-Control-Allow-Origin": "*"
      };
      let url = "api/organization/update";
      let response = await putMethod({ url, params, headers });
      if (response.message === "User not Found") {
        toast.warn("Email doesn't exist");
        setLoading(false);
      }
      else if (response.message === "update  Failed") {
        toast.warn("Try Again");
        setLoading(false);
      }
      else if (response.message === "Organization Updated") {
        toast.success("Organization Details Updated");
        getUsersDetails();
        setLoading(false);
        setOpen(false);
      }
    }
    catch (err) {
      console.log("Error in editOrganization", err);
    }
  }

  const hiddenFileInput1 = React.useRef(null);

  const handleClick1 = () => {
    hiddenFileInput1.current.click();
  };
  const handleChange1 = async (event) => {
    try {
      const file = event.target.files[0];
      let name = file.name;
      let extension = "." + name?.split(".")?.pop(); //jpg,jpeg,png
      let filename = "profilePic" + "_" + Date.now() + extension;
      const config = {
        bucketName: process.env.REACT_APP_S3_BUCKETNAME,
        dirName: "profile",
        region: process.env.REACT_APP_S3_REGION,
        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
      };
      const ReactS3Client = new S3(config);
      await ReactS3Client.uploadFile(file, filename);
      setProfilePic(filename);
    }
    catch (err) {
      console.log("Error in ProfilePicUpload", err);
    }
  };

  const handleValidation1 = () => {
    { profilePic === "" ? (toast.warn("Choose Your Profilepic")) : userName === "" ? (toast.warn("User Name Can't Be Empty")) : contactNo?.length !== 11 ? (toast.warn("Contact Number Can't Be Empty")) : oragnizationemail === "" ? (toast.warn("Email Address Can't Be Empty")) : contactAddress === "" ? (toast.warn("Contact Address Can't Be Empty")) : editProfile() }
  };

  const editProfile = async () => {
    try {
      setLoading(true);
      const params = {
        profilePic: profilePic,
        email: oragnizationemail,
        userName: userName,
        conatctNumber: contactNo,
        address: contactAddress,
        Description: description,
      };
      let headers = {
        "Content-Type": "application/json",
        "x-access-token": count?.userData?.accessToken,
        "Access-Control-Allow-Origin": "*"
      };
      let url = "api/organization/update";
      let response = await putMethod({ url, params, headers });
      if (response.message === "User not Found") {
        toast.warn("Email doesn't exist");
        setLoading(false);
      }
      else if (response.message === "update  Failed") {
        toast.warn("Try Again");
        setLoading(false);
      }
      else if (response.message === "Organization Updated") {
        toast.success("Profile Updated");
        getUsersDetails();
        setLoading(false);
        setOpen(false);
      }
    }
    catch (err) {
      console.log("Error in editOrganization", err);
    }
  }
  const handleStreetData =(target,index, name, index2)=>{
    console.log("index, name, index2",index, name, index2)
    if (target.value) {
      setContactAddress(target.value)
    }
  }
  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ zIndex: 1000 }}
      >
        <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="center">
          <Grid lg={4} md={6} sm={8} xs={11} container sx={style}>
            <Grid
              lg={12}
              md={12}
              sm={12}
              xs={12}
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid lg={11} md={11} xs={11}>
                <h4 className="textBlack InterBd m-0">
                  {email === "ROLE_SUPERADMIN"
                    ? "Edit Profile Details"
                    : "Edit Organization Details"}
                </h4>
              </Grid>
              <Grid lg={1} md={1} xs={1}>
                <CgClose
                  size={32}
                  color={"rgba(0, 0, 0, 1)"}
                  className="pointer"
                  onClick={() => { setOpen(false), getUsersDetails() }}
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                width: "100%",
                height: "calc(100% - 100px)",
                overflowY: "scroll",
                scrollbarWidth: "thin",
                "&::-webkit-scrollbar": {
                  width: "1px",
                  borderRadius: "1px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#fff",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#fff",
                },
              }}
            >
              <Grid lg={12} xs={12}>
                <Grid
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  container
                  className="mb-5"
                >
                  <Stack direction="column" spacing={2} className="w-100">
                    <Grid
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      {email === "ROLE_SUPERADMIN" ? (
                        <>
                          <Stack
                            direction="column"
                            spacing={2.8}
                            className="w-100"
                          >
                            <Grid container direction="column" className="pt-2">
                              <font className="textBlack InterRg">
                                {email === "ROLE_SUPERADMIN"
                                  ? "Profile Picture"
                                  : "Organization Logo"}
                              </font>
                              <Grid
                                display="flex"
                                alignItems="center"
                                className="editOrganationLogo mt-1"
                                justifyContent="space-between"
                                sx={{ padding: "1%" }}
                              >
                                <Grid
                                  lg={6}
                                  xs={6}
                                  container
                                  alignItems="center"
                                >
                                  {profilePic ? (
                                    <Avatar
                                      src={`${process.env.REACT_APP_S3_LINK}/profile/${profilePic}`}
                                    />
                                  ) : (
                                    <Avatar src={profilePic} />
                                  )}
                                  {/* <img alt="profile" src={profilePic} /> */}
                                  <small className="textBlack InterMd pl-2">
                                    {profilePic?.split("_")?.pop()} {/* 7KB */}
                                  </small>
                                </Grid>
                                <Grid
                                  lg={6}
                                  xs={6}
                                  container
                                  justifyContent="flex-end"
                                  alignItems="center"
                                >
                                  {profilePic ? (
                                    <>
                                      <button
                                        className="btnTransparent w-50 btn"
                                        onClick={handleClick1}
                                      >
                                        Change
                                      </button>
                                      <input
                                        type="file"
                                        ref={hiddenFileInput1}
                                        onChange={handleChange1}
                                        accept={"image/*"}
                                        style={{ display: "none" }}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        className="btnTransparent w-75"
                                        onClick={handleClick1}
                                      >
                                        Upload
                                      </button>
                                      <input
                                        type="file"
                                        ref={hiddenFileInput1}
                                        onChange={handleChange1}
                                        accept={"image/*"}
                                        style={{ display: "none" }}
                                      />{" "}
                                    </>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container direction="column">
                              <font className="textBlack InterRg">
                                User Name
                              </font>
                              <input
                                className="inputBox1 w-100  px-2 py-2 mt-1 LoginTextCapitalize"
                                placeholder="Enter User Name"
                                value={userName}
                                onChange={(e) => setUserName(e.target.value)}
                              />
                            </Grid>
                            <Grid container direction="column">
                              <font className="textBlack InterRg">
                                Contact Number
                              </font>
                              <PhoneInput
                                className="phoneinput_modal textBlack"
                                country={"us"}
                                containerStyle={{ width: "100%" }}
                                style={{ zIndex: 0 }}
                                countryCodeEditable={false}
                                disableSearchIcon={true}
                                disableDropdown={true}
                                placeholder="Enter Contact Number"
                                value={contactNo}
                                onChange={(e) => setContactNo(e)}
                              />
                            </Grid>
                            <Grid container direction="column">
                              <font className="textBlack InterRg">Email</font>
                              <input
                                className="inputBox1 w-100  px-2 py-2 mt-1"
                                placeholder="Enter Email"
                                value={oragnizationemail}
                              // onChange={(e) => setEmail(e.target.value)}
                              />
                            </Grid>
                            <Grid container direction="column">
                              <font className="textBlack InterRg">
                                Contact Address
                              </font>
                              <input
                                className="inputBox1 w-100  px-2 py-2 mt-1 LoginTextCapitalize"
                                placeholder="Enter Contact Address"
                                value={contactAddress}
                                onChange={(e) =>
                                  setContactAddress(e.target.value)
                                }
                              />
                            </Grid>
                            <Grid container direction="column">
                              <font className="textBlack InterRg">
                                Description{" "}
                                <small className="InterMd textRed">
                                  (Max 300 characters)
                                </small>
                              </font>
                              <div
                                className="inputBox1 mt-1"
                                style={{ height: "18vh" }}
                              >
                                <Grid
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  container
                                  sx={{ padding: "1%" }}
                                >
                                  <Grid
                                    lg={11}
                                    md={11}
                                    sm={11}
                                    xs={11}
                                    container
                                  >
                                    <textarea
                                      className="ticketTbTextAreaInputs LoginTextCapitalize"
                                      maxLength="300"
                                      placeholder="Enter Your Description"
                                      value={description}
                                      onChange={(e) =>
                                        setDescription(e.target.value)
                                      }
                                    />
                                  </Grid>
                                  <Grid
                                    lg={1}
                                    md={1}
                                    sm={1}
                                    xs={1}
                                    container
                                    direction="column"
                                    sx={{ position: "relative" }}
                                  >
                                    <small
                                      className="textGray1 InterSm"
                                      style={{
                                        position: "absolute",
                                        top: "5%",
                                        right: "3%",
                                      }}
                                    >
                                      {description?.length}
                                    </small>
                                  </Grid>
                                </Grid>
                              </div>
                            </Grid>
                          </Stack>
                        </>
                      ) : (
                        <>
                          <Grid
                            display="flex"
                            alignItems="center"
                            className="editOrganationLogo"
                            justifyContent="space-between"
                            sx={{ padding: "1%", marginTop: "3%" }}
                          >
                            <Grid lg={8} xs={8} container alignItems="center">
                              {organizationLogo ? (
                                <Avatar
                                  src={`${process.env.REACT_APP_S3_LINK}profile/${organizationLogo}`}
                                />
                              ) : (
                                <Avatar src={organizationLogo} />
                              )}
                              <small className="textBlack InterMd pl-2">
                                {organizationLogo?.split("_")?.pop()}{" "}
                              </small>
                            </Grid>
                            <Grid
                              lg={4}
                              xs={4}
                              container
                              justifyContent="flex-end"
                              alignItems="center"
                            >
                              {organizationLogo ? (
                                <>
                                  <button
                                    className="btnTransparent w-55 mr-3"
                                    onClick={handleClick}
                                  >
                                    Change
                                  </button>
                                  <input
                                    type="file"
                                    ref={hiddenFileInput}
                                    onChange={(e) =>
                                      handleChange(e.target.files[0])
                                    }
                                    accept={"image/*"}
                                    style={{ display: "none" }}
                                  />
                                </>
                              ) : (
                                <>
                                  <button
                                    className="btnTransparent w-55 mr-3"
                                    onClick={handleClick}
                                  >
                                    Upload
                                  </button>
                                  <input
                                    type="file"
                                    ref={hiddenFileInput}
                                    onChange={(e) =>
                                      handleChange(e.target.files[0])
                                    }
                                    accept={"image/*"}
                                    style={{ display: "none" }}
                                  />
                                </>
                              )}
                            </Grid>
                          </Grid>
                          <font className="textBlack InterRg py-2">
                            Organization Name
                          </font>
                          <input
                            className="inputBox1 w-100  px-2 py-2"
                            placeholder="Enter Organization Name"
                            value={organizationName}
                          // onChange={(e) =>
                          //   setOrganizationName(e.target.value)
                          // }
                          />
                          <font className="textBlack InterRg py-2">
                            Organization Type
                          </font>
                          <Select
                            styles={customStylesReactselect}
                            isSearchable={false}
                            className="w-100"
                            placeholder="Enter the Organization Type"
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                            options={OrgType}
                            value={organizationType}
                            onChange={(e) => setOrganizationType(e)}
                          />
                          <font className="textBlack InterRg py-2">
                            Contact Number
                          </font>
                          <PhoneInput
                            className="phoneinput_modal textBlack"
                            country={"us"}
                            containerStyle={{ width: "100%" }}
                            style={{ zIndex: 0 }}
                            countryCodeEditable={false}
                            disableSearchIcon={true}
                            disableDropdown={true}
                            placeholder="Enter Contact Number"
                            value={contactNo}
                            onChange={(e) => setContactNo(e)}
                          />
                          <font className="textBlack InterRg py-2">Email</font>
                          <input
                            className="inputBox1 w-100  px-2 py-2"
                            placeholder="Enter Email"
                            value={oragnizationemail}
                          // onChange={(e) => setEmail(e.target.value)}
                          />
                          <font className="textBlack InterRg py-2">
                            Contact Address
                          </font>
                          <AutoComplete type={"startAddresss"} index={2} name={"trip"} index2={2} handleShipments={handleStreetData} />
                          {/* <textarea
                            className="inputBox1 w-100  px-2 py-2 LoginTextCapitalize"
                            placeholder="Enter Email"
                            value={`${contactAddress}`}
                          // onChange={(e) => setContactAddress(e.target.value)}
                          /> */}
                          {/* <font className="textBlack InterRg py-2">
                            Select your state
                          </font>
                          <Select
                            styles={customStylesReactselect}
                            isSearchable={false}
                            className="w-100"
                            placeholder="Select a State"
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                            // options={USAState}
                            // value={usaState}
                            // onChange={(e) => countryselect(e)}
                          /> */}

                        </>
                      )}
                    </Grid>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            <Grid
              lg={12}
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
              className="mt-3"
            >
              <Grid
                lg={10}
                xs={10}
                container
                justifyContent="space-around"
                alignItems="center"
              >
                <Grid lg={5} xs={5}>
                  <Button
                    className="btngray shipmentsbtnspadding"
                    onClick={() => { setOpen(false), getUsersDetails() }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid lg={5} xs={5}>
                  <Button
                    className="btn shipmentsbtnspadding"
                    onClick={
                      email === "ROLE_SUPERADMIN"
                        ? handleValidation1
                        : handleValidation
                    }
                  >
                    {loading ? (
                      <>
                        <Spinner animation="border" variant="light" />
                      </>
                    ) : (
                      <>Save</>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default EditOrganization;
