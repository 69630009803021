import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import "./Styles.css";
import { Grid, CssBaseline, Box } from "@mui/material";
import { getMethod } from "../../apis";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import getHeaders from "../../services";
import {
  GoogleMap,
  withGoogleMap,
  Marker,
  withScriptjs, OverlayView
} from "react-google-maps";
import { InfoWindow } from "@react-google-maps/api";
const Map = ({ open, setOpen, width, arrow, lats, lans }) => {

  const drawerWidth = width;
  const headers = getHeaders();
  const [currentPosition, setCurrentPosition] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  useEffect(() => {
    getLiveGps();
    if (lats && lats.length > 0) {
      setCurrentPosition({
        lat: Number(lats[0].lat),
        lng: Number(lats[0].lng),
      });
    }else{
      setCurrentPosition({
        lat: Number(40.66849469),
        lng: Number(-96.12186939),
      });
    }
  }, [lats]);

  const openedMixin = (theme) => ({
    width: drawerWidth,
    backgroundColor: "#fff",
    zIndex: 5,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    overflowY: "hidden",
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    display: "none",
  });

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

  const containerStyle = {
    width: "100%",
    height: "100%",
  };
  const getLiveGps = async () => {
    if (!lats && !lans) {
      let url = "api/traacker/getLiveGps";
      const response = await getMethod({ url, headers });
      if (response.status === true) {
        setCurrentPosition({
          lat: Number(response.result.data[0]?.location.latitude),
          lng: Number(response.result.data[0]?.location.longitude),
        });
      }
    }
  };
  const handleMarkerClick = (e) => {
    console.log("e.latLng", e.latLng)
    setSelectedPosition([]);
    setSelectedAddress(null);
    setSelectedCity(null);
    if (selectedCity) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: e.latLng }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            setSelectedAddress(results[0].formatted_address);
            for (let component of results[0].address_components) {
              if (component.types.includes("locality")) {
                setSelectedCity(component.long_name);
                break;
              }
            }
          }
        }
      });
    }

  };
  const getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2)
  });
  const RenderData = () => {
    if (!lats) {
      return
    }
    let data = lats //[{ lat: 40.69386268, lng: -96.28065353 }, { lat: 41.21784524, lng: -103.65131031 }]
    const Render = data.map((localData, i) => {
      let newdata = new window.google.maps.LatLng(
        Number(localData.lat), Number(localData.lng))
      return (
        <>
          <Marker position={newdata} key={i} title={"newdata"} />
          <OverlayView
            position={newdata}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={getPixelPositionOffset}
          >
            <div>
              <div className="popup-bubble">
                {/* <p>{localData.Name}</p> */}
                <h6>{localData.Name}</h6>
                <p>{localData.TrackerSerial}</p>
              </div>
              <div className="popup-bubble-anchor" />
            </div>
          </OverlayView>
        </>
      )
    })
    return Render

  }
  const MyMapComponent = withScriptjs(
    withGoogleMap(() => (
      <GoogleMap
        defaultZoom={8}
        defaultCenter={currentPosition}
        onClick={handleMarkerClick}
      >
        {currentPosition && <RenderData />}
        {selectedAddress && (
          <InfoWindow position={selectedPosition}>
            <div>
              <h2>{selectedAddress}</h2>
              <p>{selectedCity}</p>
              <p>
                Latitude: {selectedPosition.lat()}, Longitude:{" "}
                {selectedPosition.lng()}
              </p>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    )))
  //   ))
  // );
  const googleMapURL="https://maps.googleapis.com/maps/api/js?key="+process.env.REACT_APP_GOOGE_API_KEY+"&v=3.exp&libraries=geometry,drawing,places"
 
  return (
    <>
      <Box sx={{ display: "flex" }} className="sidebarShipmentsBoxContainer">
        <CssBaseline />
        <Drawer variant="permanent" open={open} anchor="right">
          <Box
            sx={{
              scrollbarWidth: "thin",
              "&::-webkit-scrollbar": {
                width: "8px",
                borderRadius: "8px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#fff",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#D9D9D9",
              },
            }}
          >
            <Grid lg={12} xs={12}>
              {arrow === false ? (
                <Grid
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  container
                  className="justify-content-center"
                  style={{
                    position: "fixed",
                    right: "47.5%",
                    bottom: "50%",
                    zIndex: "9999",
                    width: "5%",
                  }}
                >
                  <div className="iconopenposition">
                    <MdKeyboardArrowLeft
                      size={30}
                      color={"rgba(0, 0, 0, 1)"}
                      className="pointer"
                      onClick={() => setOpen(true)}
                    />
                  </div>
                </Grid>
              ) : (
                ""
              )}
              <Grid
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={containerStyle}
                className="p-0 m-0"
                flexDirection={"column"}
                sx={{ height: "100vh" }}
              >
                {arrow === true ? (
                  <Grid
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="iconopenposition justify-content-center "
                  >
                    <MdKeyboardArrowRight
                      size={30}
                      color={"rgba(0, 0, 0, 1)"}
                      className="pointer "
                      onClick={() => setOpen(false)}
                    />
                  </Grid>
                ) : (
                  ""
                )}
                <MyMapComponent
                  isMarkerShown
                  googleMapURL={googleMapURL}
                  loadingElement={<div style={{ height: "100%" }} />}
                  containerElement={
                    <div
                      style={{
                        height: "100vh",
                        overflowY: "hidden",
                        paddingTop:
                          arrow === undefined
                            ? ""
                            : arrow === false
                              ? "9%"
                              : "5%",
                      }}
                    />
                  }
                  mapElement={<div style={{ height: "100%" }} />}
                />
              </Grid>
            </Grid>
          </Box>
        </Drawer>
      </Box>
    </>
  );
}

export default Map;
