import React from "react";
import { Grid, Button, Modal } from "@mui/material";
import "./modal.css";
import { CgClose } from "react-icons/cg";

const TrackerFailed = ({ open, setOpen }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255, 255, 255, 1)",
    boxShadow: "0px 1px 13px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    width: "100%",
    padding: "1%",
  };

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="center">
          <Grid lg={3} md={6} sm={8} xs={11} container sx={style}>
            <Grid
              lg={12}
              md={12}
              sm={12}
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Grid lg={11} md={11} xs={11}>
                <h5 className="textBlack InterBd m-0">
                  Tracker Activation Failed
                </h5>
              </Grid>
              <Grid lg={1} md={1} xs={1}>
                <CgClose
                  size={25}
                  color={"rgba(0, 0, 0, 1)"}
                  className="pointer"
                  onClick={() => setOpen(false)}
                />
              </Grid>
            </Grid>
            <Grid
              lg={12}
              md={12}
              sm={12}
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
            >
              <p className="textBlack InterMd mt-3">
                Tracker Tracker Serial N8W7-4LH-JK53-VB19 is already activated.
                Try again to select a Different Tracker.
              </p>
              <p className="textBlack InterMd mt-3">
                If you are experiencing this issue repeatedly, please contact
                support admin@flyde.com at or call{" "}
                <span className="textBlue">+1-555-555-5555</span> for
                assistance.
              </p>
            </Grid>
            <Grid
              lg={12}
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
              className="mt-3"
            >
              <Grid
                lg={10}
                xs={10}
                container
                justifyContent="center"
                alignItems="center"
              >
                <Grid lg={5} xs={5}>
                  <Button
                    className="btn shipmentsbtnspadding"
                    onClick={() => setOpen(false)}
                  >
                    Close
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default TrackerFailed;
