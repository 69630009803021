import React, { useEffect, useState } from "react";
import { Grid, Button, Modal, Stack, Box, FormControl, FormControlLabel, RadioGroup, Radio } from "@mui/material";
import { CgClose } from "react-icons/cg";
import "./modal.css";
import Select from "react-select";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { postMethod } from "../../apis";
import { Spinner } from "react-bootstrap";
// const Trackers = [
//   { label: "My South Tracker", value: "My South Tracker", name: "TrackerName" },
//   { label: "Tracker 4", value: "Tracker 4", name: "TrackerName" },
//   { label: "Tracker 6", value: "Tracker 6", name: "TrackerName" },
// ];

// const NotifiedEmails = [
//   { label: "john@gmail.com", value: "john@gmail.com" },
//   { label: "fleming@gmail.com", value: "fleming@gmail.com" },
//   { label: "cladia@gmail.com", value: "cladia@gmail.com" },
// ];

const OrganizationNames = [
  { label: "Coastal Fleet Services", value: "Coastal Fleet Services" },
  { label: "Fleetstar Logistics", value: "Fleetstar Logistics" },
  { label: "Fleet Solutions Inc", value: "Fleet Solutions Inc" },
  { label: "FleetMasters LLC", value: "FleetMasters LLC" },
  { label: "Fleet Response LLC", value: "Fleet Response LLC" },
  { label: "Fleet Dynamics", value: "Fleet Dynamics" },
];



const CreateReportModal = ({ open, setOpen, Shipmentdata, getuserReport }) => {
  const UserData = useSelector((state) => state.WalletConnect.userData)
  let email = UserData.roles[0]
  const [sensorsBtn, setSensorsBtn] = useState("");
  const [fileFormat, setfileFormat] = useState("");
  const [sensorsBtn1, setSensorsBtn1] = useState("");
  const [sensorsBtn2, setSensorsBtn2] = useState("");
  const [sensorsBtn3, setSensorsBtn3] = useState("");
  const [sensorsBtn4, setSensorsBtn4] = useState("");
  const [startDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");
  const [reportName, setReportName] = useState("");
  const [SeletedEmail, setSeletedEmail] = useState("");
  const [ShipmentNames, setShipmentNames] = useState([]);
  const [Organization_Name, setOrganizationName] = useState();
  const [Loader, setLoader] = useState(false);
  const [Trackers, setTrackers] = useState([]);
  const [openSuccessfully, setOpenSuccessFully] = useState(false);
  const [SelectedShipment, setSelectedShipment] = useState("");
  const [AssignedTracker, setAssignedTracker] = useState("");
  const [ShipmentData, setShipmentData] = useState([]);
  const [NotifiedEmails, setNotifiedEmails] = useState([]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255, 255, 255, 1)",
    boxShadow: "0px 1px 13px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    width: "100%",
    height: "calc(100% - 50px)",
    padding: "2%",
  };

  const customStylesReactselect = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#fff",
      color: "#000",
      border: "1px solid rgba(153, 153, 153, 1)",
      outline: "0px",
      boxShadow: "0px",
      borderRadius: "4px",
      fontFamily: "InterMedium",
      cursor: "pointer",
      padding: "0.5%"
    }),
    menu: (base) => ({
      ...base,
      zIndex: 100,
      fontFamily: "InterMedium",
    }),
  };

  const customStylesReactselect1 = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#fff",
      color: "#000",
      border: "1px solid rgba(153, 153, 153, 1)",
      outline: "0px",
      boxShadow: "0px",
      borderRadius: "4px",
      fontFamily: "InterMedium",
      cursor: "pointer",
      padding: "0.5%"
    }),
    menu: (base) => ({
      ...base,
      zIndex: 100,
      fontFamily: "InterMedium",
    }),
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#AE059E",
        color: "#fff",
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: "#fff",
    }),
  };

  const disablePastDate = (val) => {
    if (startDate !== "") {
      if (val === "endDate") {
        const today = new Date(startDate);
        const dd = String(today.getDate() + 0).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
      }
      else {
        const today = new Date();
        const dd = String(today.getDate() + 0).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
      }
    }
    else {
      const today = new Date();
      const dd = String(today.getDate() + 0).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = today.getFullYear();
      return yyyy + "-" + mm + "-" + dd;
    }
  };

  const handlesensorBtn = (val) => {
    if (val === "temp") {
      setSensorsBtn("temp");
    }
    else if (val === "humidity") {
      setSensorsBtn1("humidity");
    }
    else if (val === "shock") {
      setSensorsBtn2("shock");
    }
    else if (val === "light") {
      setSensorsBtn3("light");
    }
    else if (val === "geofence") {
      setSensorsBtn4("geofence");
    }
  }

  const handlesensorBtn1 = (val) => {
    if (val === "temp1") {
      setSensorsBtn("temp1");
    }
    else if (val === "humidity1") {
      setSensorsBtn1("humidity1");
    }
    else if (val === "shock1") {
      setSensorsBtn2("shock1");
    }
    else if (val === "light1") {
      setSensorsBtn3("light1");
    }
    else if (val === "geofence1") {
      setSensorsBtn4("geofence1");
    }
  }

  const style1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255, 255, 255, 1)",
    boxShadow: "0px 1px 13px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    width: "100%",
    padding: "2%",
  };
  // const showNotification= () =>{
  //   new Notification("Hello World")
  // }
  const CreateReport = async () => {
    // showNotification()
    var OrganizationName = "Flyde"
    if (email === "ROLE_SUPERADMIN") {
      setReportName("Created by Admin")
      OrganizationName = Organization_Name
    }
    if (reportName.trim().length == 0) {
      toast.error("Enter valid Report Name")
      return;
    }
    if (SelectedShipment == "") {
      toast.error("Select Shipment Name")
      return
    }
    if (AssignedTracker == "") {
      toast.error("Select Assigned Tracker")
      return
    }
    if (!AssignedTracker) {
      toast.error("Select Assigned Tracker")
      return
    }
    if (SeletedEmail == "") {
      toast.error("Select Email")
      return
    }
    if (!SeletedEmail) {
      toast.error("Select Email")
      return
    }

    const params = {
      creatorID: UserData.id,
      ReportName: reportName.trim(),
      ShipmentName: SelectedShipment,
      AssignedTarcker: AssignedTracker,
      KeyMetrics: [sensorsBtn, sensorsBtn1, sensorsBtn2, sensorsBtn3, sensorsBtn4],
      fileformat: fileFormat,
      ParticularDate: "",
      DateRangeFrom: startDate,
      DateRangeTo: EndDate,
      OrganizationName: OrganizationName,
      // TimeInterval:"",
      SendTo: [SeletedEmail],
      Status: true,
      ShipmentData: ShipmentData
    };
    let headers = {
      "Content-Type": "application/json",
      "x-access-token": UserData?.accessToken,
      "Access-Control-Allow-Origin": "*"
    };
    setLoader(true)
    let url = "api/report/createReport";
    try {
      let response = await postMethod({ url, params, headers });
      if (response.status === true) {
        setReportName(""); setSelectedShipment(""); setAssignedTracker("")
        getuserReport()
        setSensorsBtn("")
        setSensorsBtn1("")
        setSensorsBtn2("")
        setSensorsBtn3("")
        setSensorsBtn4("")
        setOpen(false);
        setOpenSuccessFully(true);
        setLoader(false)
      } else {
        toast.error("Data not available")
        setLoader(false)
      }
    } catch (error) {
      toast.error("Try Again")
      setLoader(false)
    }

  }
  const onSensorsupload = (e) => {
    setSelectedShipment(e.value)
    let newShipment = []
    Shipmentdata.map((shipment) => {
      if (shipment.ID == e.value) {
        setShipmentData(shipment)
        if (shipment.Trackers.length > 0) {
          shipment.Trackers.map((Trackers) => {
            newShipment.push({ label: Trackers.TrackerName, value: shipment.TrackerSerial, name: Trackers.TrackerName })
          })
        }
      }
    })
    if (newShipment.length > 0) {
      setTrackers(newShipment)
    }
  }
  const onSensorsuploadOrg = (e) => {
    setOrganizationName(e.value)
  }
  const getUsersDetails = async () => {
    let headers = {
      "Content-Type": "application/json",
      "x-access-token": UserData?.accessToken,
      "Access-Control-Allow-Origin": "*"
    };
    try {
      const params = {
        email: UserData.email,
      };
      let url = "api/organization/viewMyprofile";
      let response = await postMethod({ url, params, headers });
      if (response.status === true) {
        let emails = []
        response.result?.organizationContacts?.map((e) => {
          if (e.status === true) {
            emails.push({
              label: e.mail,
              value: e.mail,
              name: "mail",
            });
          }
        });
        setNotifiedEmails(emails)
      }
    } catch (err) {
      console.log("Error in getUserDetails", err);
    }
  };
  useEffect(() => {
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    } else {
      Notification.requestPermission()
        .then(function (permission) {
          console.log(permission)
        });
      console.log("Notifications are supported");
    }
    getUsersDetails()
    if (Shipmentdata && Shipmentdata?.length > 0) {
      let newShipment = []
      Shipmentdata.map((shipment) => {
        newShipment.push({ label: shipment.Name, value: shipment.ID, name: shipment.Name })
      })
      if (newShipment.length > 0) {
        setShipmentNames(newShipment)
      }
    }

  }, [Shipmentdata])

  const setUserEmail = (value) => {
    if (value[0]) {
      setSeletedEmail(value[0]?.label)
    }

  }
  const setUserAssignedTracker = (value) => {
    if (value) {
      setAssignedTracker(value.label)
    }

  }
  const SetUpdteFileFormat = (val) => {
    if (val == "pdf") {
      setfileFormat("pdf")
    } else {
      setfileFormat("csv")
    }

  }
  const closeModal = () => {
    setfileFormat("")
    setSensorsBtn("")
    setSensorsBtn1("")
    setSensorsBtn2("")
    setSensorsBtn3("")
    setSensorsBtn4("")
    setOpen(false)
  }
  return (<>
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="center">
        <Grid lg={4} md={6} sm={8} xs={11} container sx={style} >
          <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="space-between" alignItems="center">
            <h4 className="textBlack InterBd m-0">Generate New Report</h4>
            <CgClose size={32} color={"rgba(0, 0, 0, 1)"} className="pointer" onClick={() => closeModal()} />
          </Grid>

          <Box sx={{
            width: "100%",
            height: "calc(100% - 100px)",
            overflowY: "scroll",
            scrollbarWidth: "thin",
            "&::-webkit-scrollbar": {
              width: "1px",
              borderRadius: "1px"
            },
            "&::-webkit-scrollbar-track": {
              background: "#fff",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#fff",
            },
          }}>
            <Grid lg={12} xs={12}>
              <Grid lg={12} md={12} sm={12} xs={12} container className="mb-5">
                <Stack direction="column" spacing={2} className="w-100">
                  <Grid lg={12} xs={12} container>
                    {email === "ROLE_SUPERADMIN" ? (<>
                      <font className="textBlack InterSm pt-2">Organization Name</font>
                      <Select
                        styles={customStylesReactselect}
                        isSearchable={false}
                        className="w-100 mt-2"
                        placeholder="Select Organization Name"
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        options={OrganizationNames}
                        onChange={(e) => onSensorsuploadOrg(e)}
                      />
                    </>) : (<>
                      <font className="textBlack InterSm pt-2">Report Name</font>
                      <input className="inputBox1 px-2 py-2 mt-2" placeholder="Enter report name" onChange={(e) => setReportName(e.target.value)} />
                    </>)}
                  </Grid>
                  <Grid lg={12} xs={12} container>
                    <font className="textBlack InterSm">Shipment Name</font>
                    <Select
                      styles={customStylesReactselect}
                      isSearchable={false}
                      className="w-100 mt-2"
                      placeholder="Select Shipment Name"
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      onChange={(e) => onSensorsupload(e)}
                      options={ShipmentNames}
                    />
                  </Grid>
                  <Grid lg={12} xs={12} container>
                    <font className="textBlack InterSm">Assigned Tracker</font>
                    <Select
                      styles={customStylesReactselect}
                      isSearchable={false}
                      className="w-100 mt-2"
                      placeholder="Select Assigned Tracker"
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      onChange={(e) => setUserAssignedTracker(e)}
                      options={Trackers}
                    />
                  </Grid>
                  <Grid lg={12} xs={12} container>
                    <font className="textBlack InterSm">Send as</font>
                    <Grid lg={12} xs={12} container justifyContent="flex-start" className="mt-2">
                      <Grid lg={6} xs={6} container justifyContent="space-between">
                        <Grid lg={5.6} xs={5.6} container>
                          <Button className={fileFormat === "pdf" ? "reportsbtnBlueKeyMetrices py-2" : "reportsbtnKeyMetrices py-2"} onClick={() => SetUpdteFileFormat("pdf")}>PDF</Button>
                        </Grid>
                        <Grid lg={5.6} xs={5.6} container>
                          <Button className={fileFormat === "csv" ? "reportsbtnBlueKeyMetrices py-2" : "reportsbtnKeyMetrices py-2"} onClick={() => SetUpdteFileFormat("csv")}>CSV</Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* <Grid lg={12} xs={12} container>
                    <font className="textBlack InterSm">Timezone</font>
                    <Select
                      styles={customStylesReactselect}
                      isSearchable={false}
                      className="w-100 mt-2"
                      placeholder="Select the Timezone"
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      options={TimeZone}
                    />
                  </Grid> */}
                  <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="space-between" alignItems="center">
                    <Grid lg={5.5} xs={5} container>
                      <font className="textBlack InterSm">Date Range from</font>
                      <div className="inputBox1 overflowsBox px-2 py-2 mt-2">
                        <input type="text" onFocus={(e) => (e.target.type = "date")} onBlur={(e) => (e.target.type = "text")} className="DateInputsfocus" placeholder="DD/MM/YYYY" max={disablePastDate()}
                          // value={startDate} 
                          onChange={(e) => setStartDate(e.target.value)} />
                      </div>
                    </Grid>
                    <Grid lg={5.5} xs={5} container>
                      <font className="textBlack InterSm">Date Range to</font>
                      <div className="inputBox1 overflowsBox px-2 py-2 mt-2">
                        <input type="text" onFocus={(e) => (e.target.type = "date")} onBlur={(e) => (e.target.type = "text")} className="DateInputsfocus" placeholder="DD/MM/YYYY" min={disablePastDate("endDate")} max={disablePastDate()}
                          // value={EndDate}
                          onChange={(e) => setEndDate(e.target.value)} />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="space-between" alignItems="center">
                    <font className="textBlack InterSm">Key Metrics</font>
                    <Grid lg={12} xs={12} container justifyContent="space-between" alignItems="center" className="mt-2">
                      <Grid lg={3.6} xs={3.6} container>
                        <Button className={sensorsBtn === "temp" ? "reportsbtnBlueKeyMetrices py-2" : "reportsbtnKeyMetrices py-2"} onClick={() => { sensorsBtn === "temp" ? handlesensorBtn1("temp1") : handlesensorBtn("temp") }}>Temperature</Button>
                      </Grid>
                      <Grid lg={3.6} xs={3.6} container>
                        <Button className={sensorsBtn1 === "humidity" ? "reportsbtnBlueKeyMetrices py-2" : "reportsbtnKeyMetrices py-2"} onClick={() => { sensorsBtn1 === "humidity" ? handlesensorBtn1("humidity1") : handlesensorBtn("humidity") }}>Humidity</Button>
                      </Grid>
                      <Grid lg={3.6} xs={3.6} container>
                        <Button className={sensorsBtn2 === "shock" ? "reportsbtnBlueKeyMetrices py-2" : "reportsbtnKeyMetrices py-2"} onClick={() => { sensorsBtn2 === "shock" ? handlesensorBtn1("shock1") : handlesensorBtn("shock") }}>Shock</Button>
                      </Grid>
                      <Grid lg={3.6} xs={3.6} container className="pt-3">
                        <Button className={sensorsBtn3 === "light" ? "reportsbtnBlueKeyMetrices py-2" : "reportsbtnKeyMetrices py-2"} onClick={() => { sensorsBtn3 === "light" ? handlesensorBtn1("light1") : handlesensorBtn("light") }}>Light</Button>
                      </Grid>
                      <Grid lg={3.6} xs={3.6} container className="pt-3">
                        <Button className={sensorsBtn4 === "geofence" ? "reportsbtnBlueKeyMetrices py-2" : "reportsbtnKeyMetrices py-2"} onClick={() => { sensorsBtn4 === "geofence" ? handlesensorBtn1("geofence1") : handlesensorBtn("geofence") }}>Location</Button>
                      </Grid>
                      <Grid lg={3.6} xs={3.6} container className="pt-3"></Grid>
                    </Grid>
                  </Grid>
                  {email === "ROLE_SUPERADMIN" ? (<></>) : (<>
                    <Grid lg={12} xs={12} container>
                      <font className="textBlack InterSm pt-2">Generate Report</font>
                      <Grid lg={12} xs={12} container className="mt-2">
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                          >
                            <FormControlLabel value="immediately" control={<Radio />} label="Immediately" />
                            {/* <FormControlLabel value="when delivery completes" control={<Radio />} label="When Delivery Completes" /> */}
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </>)}
                  <Grid lg={12} xs={12} container>
                    <font className="textBlack InterSm">Send to Users</font>
                    {
                      email != "ROLE_SUPERADMIN" ?
                        <Select
                          styles={customStylesReactselect1}
                          // defaultValue={NotifiedEmails[0]}
                          isMulti
                          isSearchable={false}
                          className="w-100 mt-2"
                          placeholder="Select the Users"
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          onChange={(e) => { setUserEmail(e) }}
                          options={NotifiedEmails}
                        />
                        :
                        <input className="inputBox1 px-2 py-2 mt-2" placeholder="Enter Email" onChange={(e) => setSeletedEmail(e.target.value)} />
                      //  setSeletedEmail
                    }

                  </Grid>
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Grid lg={12} xs={12} container justifyContent="center" alignItems="center" className="mt-3">
            <Grid lg={10} xs={10} container justifyContent="space-around" alignItems="center">
              <Grid lg={5} xs={5}>
                <Button className="btngray shipmentsbtnspadding" onClick={() => closeModal()}>Cancel</Button>
              </Grid>
              <Grid lg={5} xs={5}>
                {email === "ROLE_SUPERADMIN" ? (<>
                  <Button className="btn shipmentsbtnspadding" onClick={() => { CreateReport(); }}>
                    {
                      Loader ?
                        <Spinner animation="border" variant="light" />
                        : "Generate"
                    }
                  </Button>
                </>) : (<>
                  <Button className="btn shipmentsbtnspadding" onClick={() => { CreateReport(); }}>
                    {
                      Loader ?
                        <Spinner animation="border" variant="light" />
                        : "Generate"
                    }
                  </Button>
                </>)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
    <Modal
      open={openSuccessfully}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="center">
        <Grid lg={4} md={6} sm={8} xs={11} container sx={style1} >
          <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="space-between" alignItems="center">
            <h4 className="textBlack InterBd m-0">Report Generated Successfully!</h4>
            <CgClose size={32} color={"rgba(0, 0, 0, 1)"} className="pointer" onClick={() => setOpenSuccessFully(false)} />
          </Grid>
          <Grid lg={12} xs={12} container className="pt-3">
            <font className="textBlack InterRg">Your Report for the shipment {reportName} ,
              Report has been generated Successfully,
              sent to the given contacts and it also can be downloaded from reports table</font>
          </Grid>
          <Grid lg={12} xs={12} container justifyContent="flex-end" alignItems="center" className="pt-3">
            <Grid lg={5} xs={5}>
              <Button className="btn shipmentsbtnspadding" onClick={() => setOpenSuccessFully(false)}>Ok</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  </>)
}

export default CreateReportModal;