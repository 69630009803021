import React, { useState, useEffect } from "react";
import { Grid, Button, Avatar, Modal } from "@mui/material";
import { CgSearch } from "react-icons/cg";
import ContactsTable from "../../components/table/contactsTable/contactsTable";
import logoProfile from "../../assets/images/logoProfile.png";
import AddContact from "../../components/modal/addContact";
import EditUserDetails from "../../components/modal/EditUserDetails";
import EditOrganization from "../../components/modal/EditOrganization";
import { SlArrowRight } from "react-icons/sl";
import Profilechangepassword from "../../components/modal/profilechangepassword";
import { CgClose } from "react-icons/cg";
import Plans from "./Plan";
import { Spinner } from "react-bootstrap";
import { postMethod } from "../../apis";
import { useSelector } from "react-redux";
const Profile = () => {
  const count = useSelector((state) => state.WalletConnect);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255, 255, 255, 1)",
    boxShadow: "3px 2px 12px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    width: "100%",
    padding: "2%",
  };
  let email = count?.userData?.roles[0];
  let UsersInfo = count?.userData;
  const [addNew, setAddNew] = useState(false);
  const [userDetail, setUserDetail] = useState(false);
  const [org, setorg] = useState(false);
  const [changepass, setchangepass] = useState(false);
  // const [preview, setpreview] = useState(false)
  // const [purchaseData, setPurchaseData] = useState(false);

  const [userName, setUserName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [contactAddress, setContactAddress] = useState("");
  const [description, setDescription] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [organizationType, setOrganizationType] = useState("");
  const [city, setcity] = useState("");
  const [zipcode, setzipcode] = useState("")
  const [state, setstate] = useState("")
  const [oragnizationemail, setEmail] = useState("");
  const [organizationLogo, setOrganizationLogo] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const [confirmOTP, SetconfirmOTP] = useState(true);
  const [sendOTPLoad1, SetsendOTPLoad1] = useState(false);
  const [forgetpinModal, setForgetpinModal] = useState(false);
  const [oldPIN, SetoldPIN] = useState("");
  const [newPIN, SetnewPIN] = useState("");
  const [confirmPIN, SetconfirmPIN] = useState("");

  const [userDta, setUserDta] = useState("");

  const [userContactData, setUserContactData] = useState([]);
  // const purchasePlan = (row) => {
  //   setPurchaseData(row);
  //   setpreview(true)
  // }
  const [userData, setUserData] = useState("");
  const getUsersDetails = async () => {
    try {
      const params = {
        email: UsersInfo.email,
      };
      let headers = {
        "Content-Type": "application/json",
        "x-access-token": count?.userData?.accessToken,
        "Access-Control-Allow-Origin": "*",
      };
      let url = "api/organization/viewMyprofile";
      let response = await postMethod({ url, params, headers });
      if (response.status === true) {
        setUserData(response.result);
        mapping(response.result);
      } else {
        setUserData([]);
        mapping([]);
      }
    } catch (err) {
      console.log("Error in getUserDetails", err);
    }
  };

  const getContactsDetails = async () => {
    try {
      const params = {
        email: UsersInfo.email,
      };
      let headers = {
        "Content-Type": "application/json",
        "x-access-token": count?.userData?.accessToken,
        "Access-Control-Allow-Origin": "*",
      };
      let url = "api/organization/viewMycontact";
      let response = await postMethod({ url, params, headers });
      if (response.status === true) {
        setUserContactData(response.result);
        setUserDta(response.result);
      } else {
        setUserContactData([]);
        setUserDta([]);
      }
    } catch (err) {
      console.log("Error in getUserDetails", err);
    }
  };

  const mapping = (data) => {
    setUserName(data?.userName);
    setContactNo(data?.conatctNumber);
    setContactAddress(data?.address);
    setcity(data?.city ? data?.city : "")
    setzipcode(data?.zipcode ? data?.zipcode : "")
    setstate(data?.state ? data?.state : "")
    setDescription(data?.Description ? data?.Description : "");
    setEmail(data?.email);
    setOrganizationName(data?.organizationName);
    setOrganizationType({ label: data?.organizationType, value: data?.organizationType });
    setOrganizationLogo(data?.Logo);
    setProfilePic(data?.profilePic);
  };

  const handleSearch = (e) => {
    var data = userDta.filter((res) => {
      return (
        res.Name.toLowerCase().match(e.toLowerCase()) ||
        res.mail.toLowerCase().match(e.toLowerCase()) ||
        res.phone.toLowerCase().match(e.toLowerCase())
      );
    });
    setUserContactData(data);
  };

  useEffect(() => {
    getUsersDetails();
    getContactsDetails();
  }, []);
  const ChangePIN = async () => {
    if (oldPIN.length < 6) {
      console.log()
      return
    }
    if (newPIN.length < 6) {
      console.log()
      return
    }
    if (confirmPIN.length < 6) {
      console.log()
      return
    }
    let file = localStorage.getItem("userkey")
    if (!file) {
      console.log("")
      return
    }
    try {
      const params = {
        token: "",
        userId: ""
      };
      let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
      };
      let url = "api/2FA/validate";
      await postMethod({ url, params, headers });

    } catch (err) {
      console.log("err", err)
    }
    SetsendOTPLoad1(false)
    setForgetpinModal(false)
    SetconfirmOTP(false)
  }
  return (
    <>
      {email === "ROLE_SUPERADMIN" ? (
        <>
          <Grid container className="" sx={{ padding: "2%" }}>
            <Grid
              lg={12}
              md={12}
              xs={12}
              sx={{ padding: "1%" }}
              flexDirection="row"
            >
              <div className="" style={{ overflow: "hidden" }}>
                <Grid
                  lg={12}
                  xs={12}
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <h5 className="textBlack InterBd m-0">Profile Details</h5>
                  <Grid lg={6} xs={6} container justifyContent="flex-end">
                    <Grid lg={2} xs={12} container>
                      <Button
                        className="btn "
                        onClick={() => {
                          setorg(true);
                        }}
                      >
                        Edit
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              <Grid
                lg={12}
                md={12}
                xs={12}
                container
                justifyContent="space-between"
              >
                <Grid lg={3.5} md={4} xs={6}>
                  <Grid
                    alignItems="center"
                    display="flex"
                    direction="column"
                    className="FlydeCard2 mt-3  h-100 scrollBars justify-content-center"
                  >
                    {userData.profilePic ? (
                      <Avatar
                        src={`${process.env.REACT_APP_S3_LINK}profile/${userData?.profilePic}`}
                        className="img-fluid h-75 w-75  mx-auto  mt-3 rounded-circle align-items"
                        alt="Profile_logo"
                      />
                    ) : (
                      <Avatar
                        src={userData?.profilePic}
                        sx={{ width: "40vh", height: "40vh" }}
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid lg={8.2} md={12} xs={12}>
                  <div className="FlydeCard2 mt-3  h-100 scrollBars">
                    <Grid
                      lg={12}
                      xs={12}
                      className="border border-top-0 border-right-0 border-left-0"
                    >
                      <Grid
                        lg={12}
                        xs={12}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ padding: "2% 2%" }}
                      >
                        <Grid lg={4} xs={10} container>
                          <font className="textBlack InterMd m-0">Name</font>
                        </Grid>
                        <Grid lg={8} xs={10} container>
                          <font className="textBlack InterMd m-0">
                            {userData?.userName}
                          </font>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      lg={12}
                      xs={12}
                      className="border border-top-0 border-right-0 border-left-0"
                    >
                      <Grid
                        lg={12}
                        xs={12}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ padding: "2% 2%" }}
                      >
                        <Grid lg={4} xs={10} container>
                          <font className="textBlack InterMd m-0">Email</font>
                        </Grid>
                        <Grid lg={8} xs={12} container>
                          <font className="textBlack InterMd m-0">
                            {userData?.email}
                          </font>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      lg={12}
                      xs={12}
                      className="border border-top-0 border-right-0 border-left-0"
                    >
                      <Grid
                        lg={12}
                        xs={12}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ padding: "2% 2%" }}
                      >
                        <Grid lg={4} xs={10} container>
                          <font className="textBlack InterMd m-0">Phone</font>
                        </Grid>
                        <Grid lg={8} xs={12} container>
                          <font className="textBlack InterMd m-0">
                            {userData?.conatctNumber
                              ?.replace(/\D+/g, "")
                              .replace(
                                /(\d{1})(\d{3})(\d{3})(\d{4})/,
                                "+$1 ($2) $3-$4"
                              )}
                          </font>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      lg={12}
                      xs={12}
                      className="border border-top-0 border-right-0 border-left-0"
                    >
                      <Grid
                        lg={12}
                        xs={12}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ padding: "2% 2%" }}
                      >
                        <Grid lg={4} xs={6} container>
                          <font className="textBlack InterMd m-0">Address</font>
                        </Grid>
                        <Grid lg={8} xs={6} container>
                          <font className="textBlack InterMd m-0">
                            {userData?.address}
                          </font>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      lg={12}
                      xs={12}
                      className="border border-top-0 border-right-0 border-left-0"
                    >
                      <Grid
                        lg={12}
                        xs={12}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ padding: "2% 2%" }}
                      >
                        <Grid lg={4} xs={6} container>
                          <font className="textBlack InterMd m-0">
                            Description
                          </font>
                        </Grid>
                        <Grid lg={8} xs={6} container>
                          <font className="textBlack InterMd m-0">
                            {userData?.Description
                              ? userData?.Description
                              : "-"}
                          </font>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid lg={12} xs={12} className="">
                      <Grid
                        lg={12}
                        xs={12}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ padding: "2% 2%" }}
                      >
                        <Grid lg={5} xs={5} container alignItems="center">
                          <Grid
                            className="pointer"
                            onClick={() => setchangepass(true)}
                          >
                            <font className="textBlue InterMd m-0">
                              Change Password{" "}
                            </font>{" "}
                            <SlArrowRight className="textBlue InterMd mx-2" />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid
            lg={12}
            container
            alignItems="center"
            sx={{ padding: "0% 3%", marginTop: "1%" }}
          >
            <h5 className="InterBd textBlack">Profile</h5>
          </Grid>
          <Grid container sx={{ padding: "0% 2%" }}>
            <Grid lg={6} md={12} xs={12} sx={{ padding: "1%" }}>
              <div className="" style={{ overflow: "hidden" }}>
                <Grid
                  lg={12}
                  xs={12}
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ paddingTop: "2%" }}
                >
                  <h5 className="textBlack InterBd m-0">User Details</h5>
                  <Grid lg={6} xs={6} container justifyContent="flex-end">
                    <Grid lg={3.6} xs={12} container>
                      <Button
                        className="btn "
                        onClick={() => setUserDetail(true)}
                      >
                        Edit
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              <div
                className="FlydeCard2 mt-3  scrollBars"
                style={{ overflowY: "scroll", height: "350px" }}
              >
                <Grid
                  lg={12}
                  xs={12}
                  className="border border-top-0 border-right-0 border-left-0"
                >
                  <Grid
                    lg={12}
                    xs={12}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ padding: "2% 2%" }}
                  >
                    <Grid lg={4} xs={10} container>
                      <font className="textBlack InterMd m-0">Full Name</font>
                    </Grid>
                    <Grid lg={8} xs={10} container>
                      <font className="textBlack InterMd m-0 text-capitalize">
                        {userData?.userName}
                      </font>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  lg={12}
                  xs={12}
                  className="border border-top-0 border-right-0 border-left-0"
                >
                  <Grid
                    lg={12}
                    xs={12}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ padding: "2% 2%" }}
                  >
                    <Grid lg={4} xs={10} container>
                      <font className="textBlack InterMd m-0">Phone</font>
                    </Grid>
                    <Grid lg={8} xs={12} container>
                      <font className="textBlack InterMd m-0">
                        {userData?.conatctNumber
                          ?.replace(/\D+/g, "")
                          .replace(
                            /(\d{1})(\d{3})(\d{3})(\d{4})/,
                            "+$1 ($2) $3-$4"
                          )}
                      </font>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  lg={12}
                  xs={12}
                  className="border border-top-0 border-right-0 border-left-0"
                >
                  <Grid
                    lg={12}
                    xs={12}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ padding: "2% 2%" }}
                  >
                    <Grid lg={4} xs={6} container>
                      <font className="textBlack InterMd m-0">Address</font>
                    </Grid>
                    <Grid lg={8} xs={6} container>
                      <font className="textBlack InterMd m-0">
                        {`${userData?.address} ${userData?.city ? ", " + userData?.city : ""} ${userData?.state ? ", " + userData?.state : ""}${userData?.zipcode ? ", " + userData?.zipcode : ""}`}
                      </font>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  lg={12}
                  xs={12}
                  justifyContent="center"
                  sx={{ padding: "2% 2%" }}
                >
                  <Grid lg={6} xs={12} container>
                    <font className="textBlack InterMd m-0">Description</font>
                  </Grid>
                  {userData && userData?.Description ? (
                    <>
                      <Grid lg={12} xs={12} container>
                        <font className="textBlack InterMd m-0 text-justify mt-3">
                          {userData?.Description}
                         
                        </font>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid
                        lg={12}
                        xs={12}
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <font className="textBlack InterSm m-0 text-justify mt-3">
                          No Description Available to Display
                        </font>
                      </Grid>
                    </>
                  )}
                  <Grid sx={{ padding: "2%" }}></Grid>
                </Grid>
              </div>
            </Grid>
            <Grid lg={6} md={12} xs={12} sx={{ padding: "1%" }}>
              <div style={{ overflow: "hidden" }}>
                <Grid
                  lg={12}
                  xs={12}
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ paddingTop: "2%" }}
                >
                  <h5 className="textBlack InterBd m-0">Contacts for Notification</h5>
                  <Grid lg={7} xs={7} container justifyContent="flex-end">
                    <Grid lg={5.6} xs={5.6} container className="mr-3">
                      <div className="inputBox1  px-2 d-flex align-items-center">
                        <input
                          className="shipmentsSearchbar "
                          placeholder="Search Contact"
                          onChange={(e) => {
                            handleSearch(e.target.value);
                          }}
                        />
                        <CgSearch size={22} color={"#999999"} />
                      </div>
                    </Grid>
                    <Grid lg={3.6} xs={3.6} container>
                      <Button className="btn " onClick={() => setAddNew(true)}>
                        Add Contact
                      </Button>
                    </Grid>
                  </Grid>

                </Grid>
              </div>
              <div
                className="FlydeCard2 mt-3  scrollBars"
                style={{ overflowY: "scroll", height: "350px" }}
              >
                <Grid lg={12} xs={12}>
                  <ContactsTable
                    ContactsData={userContactData}
                    getContactsDetails={getContactsDetails}
                  />
                </Grid>
              </div>
            </Grid>
          </Grid>
          {/* section-2 */}
          <Grid container className="" sx={{ padding: "0% 2%" }}>
            <Grid
              lg={12}
              md={12}
              xs={12}
              sx={{ padding: "1%" }}
              flexDirection="row"
            >
              <div className="" style={{ overflow: "hidden" }}>
                <Grid
                  lg={12}
                  xs={12}
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <h5 className="textBlack InterBd m-0">
                    Organization Details
                  </h5>
                  <Grid lg={6} xs={6} container justifyContent="flex-end">
                    <Grid lg={2} xs={12} container>
                      <Button className="btn " onClick={() => setorg(true)}>
                        Edit
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </div>

              <Grid container>
                <Grid lg={3} md={4} xs={6} sx={{ padding: "1% 0.5%" }}>
                  <Grid
                    alignItems="center"
                    display="flex"
                    direction="column"
                    className="FlydeCard2 mt-3  h-100 scrollBars justify-content-center"
                  >
                    <font>Organization Logo</font>
                    {userData?.Logo ? (
                      <>
                        <Avatar
                          src={`${process.env.REACT_APP_S3_LINK}profile/${userData?.Logo}`}
                          className="img-fluid  mx-auto  w-75 h-75 rounded-circle mt-3"
                          alt="Profile_logo"
                        />
                      </>
                    ) : (
                      <>
                        <img
                          src={logoProfile}
                          className="img-fluid"
                          alt="Profile_logo"
                        />
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid lg={9} md={12} xs={12} sx={{ padding: "1% 0.5%" }}>
                  <div
                    className="FlydeCard2 mt-3  h-100 scrollBars"
                  // style={{ overflowY: "scroll", height: "350px" }}
                  >
                    <Grid
                      lg={12}
                      xs={12}
                      className="border border-top-0 border-right-0 border-left-0"
                    >
                      <Grid
                        lg={8}
                        xs={8}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ padding: "2% 2%" }}
                      >
                        <Grid lg={6} xs={10} container>
                          <font className="textBlack InterMd m-0">
                            Email
                          </font>
                        </Grid>
                        <Grid lg={6} xs={10} container>
                          <font className="textBlack InterMd m-0">
                            {userData?.email}
                          </font>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      lg={12}
                      xs={12}
                      className="border border-top-0 border-right-0 border-left-0"
                    >
                      <Grid
                        lg={8}
                        xs={8}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ padding: "2% 2%" }}
                      >
                        <Grid lg={6} xs={10} container>
                          <font className="textBlack InterMd m-0">
                            Organization Name
                          </font>
                        </Grid>
                        <Grid lg={6} xs={10} container>
                          <font className="textBlack InterMd m-0">
                            {userData?.organizationName}
                          </font>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      lg={12}
                      xs={12}
                      className="border border-top-0 border-right-0 border-left-0"
                    >
                      <Grid
                        lg={8}
                        xs={8}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ padding: "2% 2%" }}
                      >
                        <Grid lg={6} xs={10} container>
                          <font className="textBlack InterMd m-0">
                            Organization Type
                          </font>
                        </Grid>
                        <Grid lg={6} xs={12} container>
                          <font className="textBlack InterMd m-0">
                            {userData?.organizationType}
                          </font>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      lg={12}
                      xs={12}
                      className="border border-top-0 border-right-0 border-left-0"
                    >
                      <Grid
                        lg={8}
                        xs={8}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ padding: "2% 2%" }}
                      >
                        <Grid lg={6} xs={10} container>
                          <font className="textBlack InterMd m-0">Phone</font>
                        </Grid>
                        <Grid lg={6} xs={12} container>
                          <font className="textBlack InterMd m-0">
                            {userData?.conatctNumber
                              ?.replace(/\D+/g, "")
                              .replace(
                                /(\d{1})(\d{3})(\d{3})(\d{4})/,
                                "+$1 ($2) $3-$4"
                              )}
                            {/* {userData?.conatctNumber} */}
                          </font>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      lg={12}
                      xs={12}
                      className="border border-top-0 border-right-0 border-left-0"
                    >
                      <Grid
                        lg={8}
                        xs={8}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ padding: "2% 2%" }}
                      >
                        <Grid lg={6} xs={6} container>
                          <font className="textBlack InterMd m-0">Address</font>
                        </Grid>
                        <Grid lg={6} xs={6} container>
                          <font className="textBlack InterMd m-0">
                            {`${userData?.address} ${userData?.city ? ", " + userData?.city : ""} ${userData?.state ? ", " + userData?.state : ""}${userData?.zipcode ? ", " + userData?.zipcode : ""}`}
                          </font>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid lg={12} xs={12} className="">
                      <Grid
                        lg={8}
                        xs={8}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ padding: "2% 2%" }}
                      >
                        <Grid lg={5} xs={5} container alignItems="center">
                          <Grid
                            className="pointer"
                            onClick={() => setchangepass(true)}
                          >
                            <font className="textBlue InterMd m-0">
                              Change Password{" "}
                            </font>{" "}
                            <SlArrowRight className="textBlue InterMd mx-2" />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Modal
            open={forgetpinModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="center">
              <Grid lg={4} md={6} sm={8} xs={11} container sx={style}>
                <Grid lg={12} xs={12} container>
                  <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="space-between" alignItems="center">
                    <h4 className="textBlack InterBd m-0">Change PIN</h4>
                    <CgClose size={32} color={"rgba(0, 0, 0, 1)"} className="pointer" onClick={() => setForgetpinModal(false)} />
                  </Grid>
                  <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="space-between" alignItems="center">
                  </Grid>
                  {
                    !confirmOTP && <Spinner animation="border" variant="light" />
                  }
                  {
                    confirmOTP &&
                    <>
                      <Grid lg={12} xs={12} container alignItems="flex-start" direction="column" className="pt-2">
                        <font className="InterSm textBlack mt-3">Enter Old PIN</font>
                        <input
                          className="inputBox px-2 py-2 mt-1"
                          placeholder="Enter Old PIN"
                          type="number"
                          max={999999}
                          onChange={(e) => { SetoldPIN(e.target.value) }}
                        />
                      </Grid>
                      <Grid lg={12} xs={12} container alignItems="flex-start" direction="column" className="pt-2">
                        <font className="InterSm textBlack mt-3">New PIN</font>
                        <input
                          className="inputBox px-2 py-2 mt-1"
                          placeholder="Enter Pin"
                          onChange={(e) => SetnewPIN(e.target.value)}
                        />
                      </Grid>
                      <Grid lg={12} xs={12} container alignItems="flex-start" direction="column" className="pt-2">
                        <font className="InterSm textBlack mt-3">Confirm PIN</font>
                        <input
                          className="inputBox px-2 py-2 mt-1"
                          placeholder="Confirm Pin"
                          // value={forgotPasswordEmail}
                          onChange={(e) => SetconfirmPIN(e.target.value)}
                        />
                      </Grid>
                      <Button className="btn py-2 mt-3"
                        onClick={() => { ChangePIN() }}
                      >
                        {
                          sendOTPLoad1 ?
                            <Spinner animation="border" variant="light" />
                            :
                            "Submit"
                        }
                      </Button>
                    </>
                  }
                </Grid>
              </Grid>
            </Grid>
          </Modal>
          {/* section-3 */}

          <Grid container className="" sx={{ padding: "0% 2%" }}>
            <Grid id="plans" lg={12} md={12} xs={12}>
              <Plans />
            </Grid>
          </Grid>
          <AddContact
            setOpen={setAddNew}
            open={addNew}
            userData={userData}
            getContactsDetails={getContactsDetails}
          />
          <EditUserDetails
            setOpen={setUserDetail}
            open={userDetail}
            userName={userName}
            setUserName={setUserName}
            contactNo={contactNo}
            setContactNo={setContactNo}
            city={city}
            zipcode={zipcode}
            state={state}
            contactAddress={contactAddress}
            setcity={setcity}
            setzipcode={setzipcode}
            setstate={setstate}
            setContactAddress={setContactAddress}
            description={description}
            setDescription={setDescription}
            userData={userData}
            getUsersDetails={getUsersDetails}
          />
          <EditOrganization
            setOpen={setorg}
            open={org}
            organizationName={organizationName}
            organizationType={organizationType}
            setOrganizationName={setOrganizationName}
            setOrganizationType={setOrganizationType}
            oragnizationemail={oragnizationemail}
            setEmail={setEmail}
            contactNo={contactNo}
            setContactNo={setContactNo}
            contactAddress={contactAddress}
            setcity={setcity}
            setzipcode={setzipcode}
            setstate={setstate}
            // city={city}
            // zipcode={zipcode}
            // state={state}
            setContactAddress={setContactAddress}
            organizationLogo={organizationLogo}
            setOrganizationLogo={setOrganizationLogo}
            OrganizationData={userData}
            getUsersDetails={getUsersDetails}
          />
          <Profilechangepassword
            open={changepass}
            setopen={setchangepass}
            userData={UsersInfo?.email}
          />
        </>
      )}
      <EditOrganization
        setOpen={setorg}
        open={org}
        userName={userName}
        setUserName={setUserName}
        organizationName={organizationName}
        organizationType={organizationType}
        setOrganizationName={setOrganizationName}
        setOrganizationType={setOrganizationType}
        oragnizationemail={oragnizationemail}
        setEmail={setEmail}
        contactNo={contactNo}
        setContactNo={setContactNo}
        contactAddress={contactAddress}
        setcity={setcity}
        setzipcode={setzipcode}
        setstate={setstate}
        city={city}
        zipcode={zipcode}
        state={state}
        setContactAddress={setContactAddress}
        organizationLogo={organizationLogo}
        setOrganizationLogo={setOrganizationLogo}
        profilePic={profilePic}
        setProfilePic={setProfilePic}
        OrganizationData={userData}
        getUsersDetails={getUsersDetails}
        setDescription={setDescription}
        description={description}
      />
      <Profilechangepassword
        open={changepass}
        setopen={setchangepass}
        userData={UsersInfo?.email}
      />

    </>
  );
};

export default Profile;
