import React, { useState } from "react";
import { Grid, Button, Modal } from "@mui/material";
import { CgClose } from "react-icons/cg";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { postMethod } from "../../apis";
import {useSelector} from "react-redux";

const Profilechangepassword = ({ open, setopen, userData }) => {

  const count = useSelector((state) => state.WalletConnect)
  const [flydePassword1, setFlydePassword1] = useState({
    password: "",
    showPassword: false,
    newpassword:"",
    shownewpassword: false,
    resetPassword: "",
    showresetPassword: false,
  });

  const [emailchck, setemailchck] = useState(true);

  const handleClickShowPassword1 = (val) => {
    if (val === "password") {
      setFlydePassword1({
        ...flydePassword1,
        showPassword: !flydePassword1.showPassword,
      });
    } else if (val === "resetPassword") {
      setFlydePassword1({
        ...flydePassword1,
        showresetPassword: !flydePassword1.showresetPassword,
      });
    }
    else if (val === "newpassword") {
      setFlydePassword1({
        ...flydePassword1,
        shownewpassword: !flydePassword1.shownewpassword,
      });
    }
  };

  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange1 = (prop) => (event) => {
    setFlydePassword1({ ...flydePassword1, [prop]: event.target.value });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255, 255, 255, 1)",
    boxShadow: "3px 2px 12px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    width: "100%",
    padding: "2%",
  };

  const [loading, setLoading] = useState(false);


  const handleValidation = () => {
    var passwordValidation = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    { flydePassword1.password === "" ? (toast.warn("Current Password Can't Be Empty")) : flydePassword1.password === "" ? (toast.warn("Current Password Can't Be Empty")) : !passwordValidation.test(flydePassword1.password) ? (toast.warn("Current Password Shall Be Minimum 8 Characters With One Uppercase, One Number And One Special Character")) : (onhandleChangePassword()) }
  }
  const checkconfirmpassword =(password)=>{
    var passwordValidation = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    const values = password === "" ? (toast.warn("Confirm Password Can't Be Empty")) : password === "" ? (toast.warn("Confirm Password Can't Be Empty")) : !passwordValidation.test(password) ? (toast.warn("Confirm Password Shall Be Minimum 8 Characters With One Uppercase, One Number And One Special Character")) : password !== flydePassword1.newpassword ? (toast.warn("Both Password Should Be Same")) : true
    return values
  }

  const checknewpassword =(password)=>{
    var passwordValidation = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    const values = password === "" ? (toast.warn("New Password Can't Be Empty")) : password === "" ? (toast.warn("New Password Can't Be Empty")) : !passwordValidation.test(password) ? (toast.warn("New Password Shall Be Minimum 8 Characters With One Uppercase, One Number And One Special Character")) : true
    return values
  }

  const onhandleChangePassword = async () => {
    if(checknewpassword(flydePassword1.newpassword) == true ){
      if(checkconfirmpassword(flydePassword1.resetPassword) == true){
        try {
          setLoading(true);
          const params = {
            email:userData,
            password: flydePassword1.password,
            NewPassword:flydePassword1.resetPassword,
          };
          let headers = {
            "Content-Type": "application/json",
            "x-access-token": count?.userData?.accessToken,
            "Access-Control-Allow-Origin": "*"
          };
          let url = "api/reset/changepassword";
          let response = await postMethod({ url, params,headers });
          if (response.status === false) {
            if (response.message === "no user found") {
              toast.warn("Email Address Not Exist,Please SignUp!");
              setLoading(false);
            }
            else if (response.message === "password invalid") {
              toast.warn("Invalid Current Password");
              setLoading(false);
            }
            else if (response.message === "password change failed") {
              toast.warn("Try Again");
              setLoading(false);
            }else{
              toast.warn(response.message);
              setLoading(false);
            }
          }
          else if (response.status === true) {
            toast.success("Password Updated");
            setLoading(false);
            setopen(false);
            setFlydePassword1({...flydePassword1,
              password: "",
              showPassword: false,
              newpassword:"",
              shownewpassword: false,
              resetPassword: "",
              showresetPassword: false,
            });
          }
        }catch (err) {
          setLoading(false)
          console.log("Error in login", err);
        }
      }
      setLoading(false)
    }
    setLoading(false)
  }

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="center">
          <Grid lg={4} md={6} sm={8} xs={11} container sx={style}>
            <Grid lg={12} xs={12} container>
              <Grid
                lg={12}
                md={12}
                sm={12}
                xs={12}
                container
                justifyContent="center"
                alignItems="center"
              >
                <Grid lg={11} md={11} xs={11}>
                  <h4 className="textBlack InterBd m-0">Change Password</h4>
                </Grid>
                <Grid lg={1} md={1} xs={1}>
                  <CgClose
                    size={32}
                    color={"rgba(0, 0, 0, 1)"}
                    className="pointer"
                    onClick={() => {
                      setopen(false);
                      setFlydePassword1({...flydePassword1,
                        password: "",
                        showPassword: false,
                        newpassword:"",
                        shownewpassword: false,
                        resetPassword: "",
                        showresetPassword: false,
                      });
                    }}
                  />
                </Grid>
              </Grid>
              {emailchck === false ? (
                <Grid
                  lg={12}
                  xs={12}
                  container
                  alignItems="flex-start"
                  direction="column"
                  className="pt-2"
                >
                  <font className="textBlack interMd mt-3">
                    Confirm Your Email{" "}
                  </font>
                  <input
                    className="inputBox1 w-100 mt-3 px-2 py-2 newshipmentsinputbox"
                    placeholder=""
                    defaultValue={userData}
                    disabled
                  />
                  <p className="textBlack mt-3">
                    We will send you a link to change your password. If you
                    don&apos;t receive the email within a few minutes, check
                    your spam folder.
                  </p>
                  <button className="btn" onClick={() => setemailchck(true)}>
                    Send Reset Link
                  </button>
                </Grid>
              ) : (
                <>
                  <Grid
                    lg={12}
                    xs={12}
                    container
                    alignItems="flex-start"
                    direction="column"
                    className="pt-2"
                  >
                    <font className="InterSm textBlack mt-3">
                      Current Password
                    </font>
                    <div className="inputBox px-2 py-2 spacebetween mt-1">
                      <input
                        type={flydePassword1.showPassword ? "text" : "password"}
                        className="passwordInput"
                        placeholder="Current password"
                        onChange={handlePasswordChange1("password")}
                      />
                      {flydePassword1.showPassword ? (
                        <BsEyeFill
                          className="eyeIcons pointer"
                          onClick={() => handleClickShowPassword1("password")}
                          onMouseDown={handleMouseDownPassword1}
                        />
                      ) : (
                        <BsEyeSlashFill
                          className="eyeIcons pointer"
                          onClick={() => handleClickShowPassword1("password")}
                          onMouseDown={handleMouseDownPassword1}
                        />
                      )}
                    </div>

                    <font className="InterSm textBlack mt-3">New Password</font>
                    <div className="inputBox px-2 py-2 spacebetween mt-1">
                      <input
                        type={
                          flydePassword1.shownewpassword ? "text" : "password"
                        }
                        className="passwordInput"
                        placeholder="New password"
                        onChange={handlePasswordChange1("newpassword")}
                      />
                      {flydePassword1.shownewpassword ? (
                        <BsEyeFill
                          className="eyeIcons pointer"
                          onClick={() =>
                            handleClickShowPassword1("newpassword")
                          }
                          onMouseDown={handleMouseDownPassword1}
                        />
                      ) : (
                        <BsEyeSlashFill
                          className="eyeIcons pointer"
                          onClick={() =>
                            handleClickShowPassword1("newpassword")
                          }
                          onMouseDown={handleMouseDownPassword1}
                        />
                      )}
                    </div>

                    <font className="InterSm textBlack mt-3">
                      Confirm Password
                    </font>
                    <div className="inputBox px-2 py-2 spacebetween mt-1">
                      <input
                        type={
                          flydePassword1.showresetPassword ? "text" : "password"
                        }
                        className="passwordInput"
                        placeholder="Confirm password"
                        onChange={handlePasswordChange1("resetPassword")}
                      />
                      {flydePassword1.showresetPassword ? (
                        <BsEyeFill
                          className="eyeIcons pointer"
                          onClick={() =>
                            handleClickShowPassword1("resetPassword")
                          }
                          onMouseDown={handleMouseDownPassword1}
                        />
                      ) : (
                        <BsEyeSlashFill
                          className="eyeIcons pointer"
                          onClick={() =>
                            handleClickShowPassword1("resetPassword")
                          }
                          onMouseDown={handleMouseDownPassword1}
                        />
                      )}
                    </div>
                  </Grid>
                  <Grid lg={12} xs={12} container className="pt-4">
                    <h6 className="textRed InterLt m-0">
                      Your password should be at least 8 characters, including
                      one upper case letter, one lower case letter, and one
                      number.
                    </h6>
                  </Grid>
                  <Grid
                    lg={12}
                    xs={12}
                    container
                    justifyContent=""
                    className="pt-4"
                  >
                    <Button className="btn py-2" onClick={handleValidation}>
                      {loading ? (
                        <>
                          <Spinner animation="border" variant="light" />
                        </>
                      ) : (
                        <>Change Password</>
                      )}
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};
export default Profilechangepassword;
