import React, { useState } from "react";
import { Grid, Button, Stack, Modal } from "@mui/material";
import LoginLogos from "../../assets/images/registerPageImages/LoginLogo.svg";
import Logo from "../../assets/images/logo.png";
import profile1 from "../../assets/images/registerPageImages/profile1.svg";
import profile2 from "../../assets/images/registerPageImages/profile2.svg";
import { useNavigate } from "react-router-dom";
import { CgClose } from "react-icons/cg";
import OTPInput from "otp-input-react";
import EnableGoogle2FA from "../modal/EnableGoogle2FA";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Spinner } from "react-bootstrap";
import { postMethod } from "../../apis";
import AutoComplete from "../googleAutocomplete"
const SignUp = () => {
  const navigate = useNavigate();
  const [registrationModal, setRegistrationModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [otpmodal, setotpmodal] = useState(false);
  const [usaState, setUsaState] = useState("");
  const [Zipcode, setZipcode] = useState("")
  const [usaCity, setUsaCity] = useState("")
  const [organizationName, setOrganizationName] = useState("");
  const [organizationType, setOrganizationType] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [address, setAddress] = useState("");
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [hover, setHover] = useState(false);
  const [countdown, setCountdown] = useState(30);

  const handleRefreshData = () => {
    setUsaState("");
    setUsaCity("");
    setZipcode("");
    setOrganizationName("");
    setOrganizationType("");
    setUserName("");
    setEmail("");
    setContactNo("");
    setAddress("");
    setLoading(false);
    navigate("/");
  };
  const validationemails = (email) => {
    console.log("organizationType",organizationType)
    /* eslint-disable */
    const regExp =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return regExp.test(email);
  };

  const handleCheckValidation = () => {
    const emailformater = /^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w\w+)+$/;
    if (!userName) {
      toast("Please Enter User Name");
      return;
    } else if (contactNo?.length !== 11) {
      toast("Please Enter Valid Contact Number");
      return;
    } else
      if (!email) {
        toast("Please Enter Email Address");
        return;
      } else if (!validationemails(email)) {
        toast("Please Enter Valid Email");
        return;
      } 
      if (!address) {
        toast("Please Select City");
        return;
      }
    else {
      userName === ""
        ? toast.warn("Name Can't Be Empty")
        : contactNo?.length !== 11
          ? toast.warn("Contact Number Can't Be Empty")
          : email === ""
            ? toast.warn("Work Email Can't Be Empty")
            : !emailformater.test(email)
              ? toast.warn("Enter valid Email")
              : address === ""
                ? toast.warn("Address Can't Be Empty")
                : onRegitrations();
    }
  };

  const onRegitrations = async () => {
    try {
      setLoading(true);
      const params = {
        userName: userName,
        email: email,
        conatctNumber: contactNo,
        address: address,
        state: usaState?.value,
        city: usaCity?.value,
        zipcode: Zipcode,
        organizationName: organizationName,
        // organizationType: organizationType?.value,
      };
      let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      };
      let url = "api/user/signup";
      let response = await postMethod({ url, params, headers });
      if (response.status === true) {
        setLoading(false);
        setotpmodal(true);
      } else {
        toast.warn(response.message);
        setLoading(false);
      }
    } catch (err) {
      console.log("Error in signup", err);
    }
  };

  const verifyuserotp = async () => {
    if (otp && otp.length == 6) {
      try {
        setLoading1(true);
        const params = {
          otp: otp,
          email: email
        };
        let headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        };
        let url = "api/user/validuserotp";
        let response = await postMethod({ url, params, headers });
        if (response.status === true) {
          setLoading1(false);
          setotpmodal(false);
          setRegistrationModal(true);
        } else {
          toast.warn(response.message);
          setLoading1(false);
        }
      } catch (err) {
        setLoading1(false);
        console.log("Error", err)
      }
    } else {
      toast.warn("Enter Valid OTP")
    }
  }

  function startCountdown() {
    const countdownInterval = setInterval(() => {
      setCountdown(prevCountdown => prevCountdown - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(countdownInterval);
    }, countdown * 1000);
  }

  const resenduserotp = async () => {
    setIsDisabled(true);
    startCountdown()
    try {
      const params = {
        email: email
      };
      let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      };
      let url = "api/user/resenduserotp";
      let response = await postMethod({ url, params, headers });
      if (response.status === true) {
        toast.warn(response.message);
      } else {
        toast.warn(response.message);
      }
    } catch (err) {
      console.log("Error", err)
    }


    // After 30 seconds, enable the button
    setTimeout(() => {
      setIsDisabled(false);
      setCountdown(30)
    }, 30000);
  }

  const enterzipcode = async (eve) => {
    setZipcode(eve)
  }
  const customStylesReactselect = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#fff",
      color: "#000",
      border: "1px solid rgba(140, 145, 150, 1)",
      outline: "0px",
      boxShadow: "0px",
      borderRadius: "4px",
      fontFamily: "InterMedium",
      cursor: "pointer",
      padding: "0.5%",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 100,
      fontFamily: "InterMedium",
    }),
  };
  const OtpInputBox = {
    width: "60px",
    height: "60px",
    textAlign: "center",
    marginRight: window.innerWidth > 600 ? 0 : "4%",
    border: "none",
    outline: "none",
    backgroundColor: "#D9D9D9",
    borderRadius: "40px",
    color: "#000",
    fontSize: "20px",
    fontFamily: "InterSemiBold"
  }
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255, 255, 255, 1)",
    boxShadow: "3px 2px 12px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
    width: "100%",
    padding: "2%",
  };



  const handleStreetData = (target, index, name, index2) => {
    console.log("target", target)
    if (target.value) {
      setAddress(target.value)
    }
  }
  return (
    <>
      <Grid lg={12} md={12} sm={12} xs={12} container>
        <Grid
          lg={6}
          md={6}
          sm={6}
          xs={12}
          container
          sx={{ position: "relative" }}
        >
          {window.innerWidth > 600 ? (
            <>
              <img
                src={profile1}
                className="img-fluid LoginimgPosition"
                alt="logo"
              />
              <img
                src={profile2}
                className="img-fluid LoginimgPosition1"
                alt="logo"
              />
            </>
          ) : (
            <></>
          )}
          <Grid
            lg={12}
            md={12}
            sm={12}
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              lg={6.6}
              md={10}
              sm={10}
              xs={8}
              container
              justifyContent="center"
              alignItems="center"
              sx={{ marginTop: { lg: "0", md: "0", sm: "0", xs: "10%" } }}
            >
              <Stack direction="column" spacing={2} alignItems="center">
                <Grid lg={4} md={5} sm={6} xs={6}>
                  <img src={Logo} className="img-fluid app-logo" alt="logo" />
                </Grid>

                  <>
                    <h3 className="InterSm textBlack m-0 pt-3 text-center">
                      Create an Account
                    </h3>
                    <Grid lg={12} md={12} sm={12} xs={12} container>
                      <Stack direction="column" spacing={2} className="w-100">
                        <font className="InterSm textBlack">
                          Organization Name
                        </font>
                        <input
                          className="inputBox px-2 py-2 LoginTextCapitalize"
                          placeholder="Enter the Organization Name"
                          value={organizationName}
                          onChange={(e) => setOrganizationName(e.target.value)}
                        />

                        <font className="InterSm textBlack">Customer Name</font>
                        <input
                          className="inputBox px-2 py-2 LoginTextCapitalize"
                          placeholder="Enter Name"
                          value={userName}
                          onChange={(e) => setUserName(e.target.value)}
                        />
                        <font className="InterSm textBlack">
                          Contact Number
                        </font>
                        <PhoneInput
                          className="phoneinput_modal textBlack"
                          country={"us"}
                          containerStyle={{ width: "100%" }}
                          style={{ zIndex: 0 }}
                          countryCodeEditable={false}
                          disableSearchIcon={true}
                          disableDropdown={true}
                          placeholder="Enter Contact Number"
                          value={contactNo}
                          onChange={(e) => setContactNo(e)}
                        />
                        <font className="InterSm textBlack">Work email</font>
                        <input
                          className="inputBox px-2 py-2"
                          placeholder="Enter Work email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <font className="InterSm textBlack">Address</font>

                        <AutoComplete type={"startAddress"} index={1} name={"trip"} index2={1} handleShipments={handleStreetData} />

                        <Grid
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          container
                          justifyContent="center"
                          alignItems="center"
                          className="pt-2"
                        >
                          <Grid lg={7} md={7} sm={7} xs={7} container>
                            <Button
                              className="btn py-2"
                              onClick={() => {
                                handleCheckValidation()
                              }}
                            >
                              {loading ? (
                                <>
                                  <Spinner animation="border" variant="light" />
                                </>
                              ) : (
                                <>Submit</>
                              )}
                            </Button>
                          </Grid>
                        </Grid>
                        <Grid
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          container
                          justifyContent="center"
                          className="pt-2"
                        >
                          <font className="InterSm textBlack">
                            Already have an account?{" "}
                            <font
                              className="InterSm textBlue pointer textHovering"
                              role="presentation"
                              onClick={() => navigate("/")}
                            >
                              Sign in
                            </font>
                          </font>
                        </Grid>
                      </Stack>
                    </Grid>
                  </>
                {/* )} */}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          lg={6}
          md={6}
          sm={6}
          xs={12}
          container
          sx={{ display: { lg: "flex", md: "flex", sm: "flex", xs: "none" } }}
        >
          <div className="loginImgBox">
            <img
              src={LoginLogos}
              className="img-fluid loginLogoimg"
              alt="loginlogo"
            />
          </div>
        </Grid>
      </Grid>
      <Modal
        open={registrationModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="center">
          <Grid lg={4} md={6} sm={8} xs={11} container sx={style}>
            <Grid lg={12} xs={12} container>
              <Grid
                lg={11}
                md={11}
                sm={11}
                xs={11}
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <h4 className="textBlack InterBd m-0">
                  Thank you for Registering with Flyde!
                </h4>
              </Grid>
              <Grid lg={1} md={1} sm={1} xs={1}>
                <CgClose
                  size={32}
                  color={"rgba(0, 0, 0, 1)"}
                  className="pointer"
                  onClick={() => {
                    setRegistrationModal(false);
                  }}
                />
              </Grid>
              <font className="textGray1 InterRg pt-3">{email}</font>
              <Grid
                lg={12}
                md={12}
                sm={12}
                xs={12}
                container
                justifyContent="flex-start"
                className="pt-3"
              >
                <font className="InterRg textBlack">
                  Your details are currently being reviewed,
                  someone from Flyde will get back in next 3 to 5 business days!
                </font>
              </Grid>
              <Grid
                lg={12}
                md={12}
                sm={12}
                xs={12}
                container
                justifyContent="center"
                className="pt-3"
              >
                <Button
                  className="btn w-50 py-2"
                  onClick={() => {
                    setRegistrationModal(false);
                    setLoading(true);
                    handleRefreshData();
                  }}
                >
                  {loading ? (
                    <>
                      <Spinner animation="border" variant="light" />
                    </>
                  ) : (
                    <>Close</>
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <Modal
        open={otpmodal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="center">
          <Grid lg={4} md={6} sm={8} xs={11} container sx={style} >
            <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="space-between" alignItems="center">
              <h4 className="textBlack InterBd m-0">Email OTP Verification</h4>
           </Grid>
            <Grid lg={12} md={12} sm={12} xs={12} container>
              <Stack direction="column" spacing={2} className="w-100">
                <font className="textGray1 InterRg pt-2">A 6-digit OTP sent to your Email.</font>
                <Grid lg={12} md={12} sm={12} xs={12} container>
                  <h6 className="textBlack InterSm m-0">Enter the OTP</h6>
                  <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="space-between" alignItems="center" className="pt-4" sx={{ overflow: "hidden" }}>
                    <OTPInput value={otp} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }} inputStyles={OtpInputBox} onChange={setOtp} OTPLength={6} otpType="number" disabled={false} />
                  </Grid>
                  <Grid lg={12} md={12} sm={12} xs={12} container justifyContent="center" alignItems="center" className="mt-3" sx={{ overflow: "hidden" }}>
                    <font className="textGray1 InterRg pt-2">Did not Receive OTP? <font className={!hover ? "textBlue InterSm m-0" : "textGreen InterSm m-0"} style={!isDisabled ? { cursor: "pointer" } : { color: "Black" }} onMouseEnter={() => { setHover(true); }} onMouseLeave={() => { setHover(false); }} onClick={() => { !isDisabled ? resenduserotp() : "" }}> {!isDisabled ? "Resend Again" : `Resend after  ${countdown > 0 && countdown + " sec"}`}  </font > </font>
                  </Grid>
                </Grid>
                <Button className="btn py-2" onClick={() => { verifyuserotp() }}>{loading1 ? <Spinner animation="border" variant="light" /> : "Submit"}</Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <EnableGoogle2FA open={open} setOpen={setOpen} />
    </>
  );
};

export default SignUp;
