import React from "react";
import ReactECharts from "echarts-for-react";
const PieChart = ({chartData}) => {
  const Basic = chartData?.filter((e)=>e.Plan[0].PlanName === "BasicPlan");
  const Intermediate = chartData?.filter((e)=>e.Plan[0].PlanName === "Intermedia")
  const Essential = chartData?.filter((e)=>e.Plan[0].PlanName === "Essential")
  const data = [
    { value:Basic?.length, name: "Basic" },
    { value: Intermediate?.length, name: "Intermediate" },
    { value:Essential?.length, name: "Advance" },
  ];
  const colors = ["#AE059E", "#46C287", "#DC9B1C"];

  const option = {
    tooltip: {
      trigger: "item",
    },
    legend: {
      orient: window.innerWidth > 600 ? "vertical" : "horizontal",
      right: window.innerWidth > 600 ? "10%" : "20%",
      top: window.innerWidth > 600 ? "20%" : "88%",
      bottom: window.innerWidth > 600 ? "50%" : "",
      icon: "circle",
      textStyle: {
        rich: {
          a: {
            color: "#333",
          },
          b: {
            color: "#333",
          },
          c: {
            color: function (params) {
              return params.color;
            },
          },
        },
        color: function (params) {
          return colors[params.dataIndex];
        },
      },
    },
    series: [
      {
        type: "pie",
        radius: ["50%", "70%"],
        center: window.innerWidth > 600 ? ["35%", "50%"] : ["50%", "50%"],
        avoidLabelOverlap: false,
        label: {
          show: true,
          formatter: "{c} ({d}%)",
          fontWeight: "bold",
          color: "#46C287",
          fontSize: 12, // Set the font size of the label
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 15,
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: false,
        },
        data: data,
        color: colors,
        itemStyle: {
          color: function (params) {
            return colors[params.dataIndex];
          },
        },
      },
    ],
  };
  

  return (
    <div>
      <ReactECharts option={option} />
    </div>
  );
};

export default PieChart;
